import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import "../index.css";

// Extend Day.js with plugins
dayjs.extend(duration);
dayjs.extend(utc);

export function DateCountdown({ date }) {
  const [message, setMessage] = useState("");

  useEffect(() => {
    const targetDate = dayjs.utc(date); // Parse the Zulu time (UTC)

    const interval = setInterval(() => {
      const now = dayjs.utc(); // Current time in UTC
      const diffMs = targetDate.diff(now); // Difference in milliseconds

      if (diffMs > 0) {
        const diffDuration = dayjs.duration(diffMs); // Create a duration object
        const hours = Math.floor(diffDuration.asHours()).toString().padStart(2, "0");
        const minutes = diffDuration.minutes().toString().padStart(2, "0");
        const seconds = diffDuration.seconds().toString().padStart(2, "0");

        // Countdown message for same-day or multi-day transitions
        setMessage(`Drawing in: ${hours}:${minutes}:${seconds}`);
      } else {
        clearInterval(interval);
        setMessage(
          `Drawing held on ${targetDate.format("MMM D, YYYY @ hh:mm A")}`
        );
      }
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [date]);

  return <span className="time-text">{message}</span>;
}
