import React from "react"
import { QuestionAskType } from "../../../../constants/question";
import { choiceStatsSummary } from "../../Utilities";
import { showPanel } from "../../../../redux/actions/panel-actions";
import { setPanelView } from "../../../../redux/actions/context-actions";
import { connect } from "react-redux";
import { PanelType } from "../../../../constants/panel";
import { Feeds } from "../../../../constants/feedids";
import OrderedPopover from "../OrderedPopover";

const mapStateToProps = (state, ownProps) => {
    return {
        //displayQuestion: state.feed.items[state.feed.index[ownProps.questionId]],
        panel: state.context.panel,
        isObserver: state.profile.isPremium,
    };
};

const EditItemDisplay = React.memo(function EditItemDisplay({ question, choiceId, children, panel, dispatch }) {
    const { questionType, choiceStats, questionId, isOrdered, choices, userFeed:{stats:{RESQ} }} = question;
    const text = choices[choiceId].text;
    const layout = "border roundedBorder text-start px-1 pb-1 mb-1";

    const showChoiceUsers=({choiceId,orderId})=>{
        if ((questionId === panel.targetId && choiceId === panel.choiceId && (orderId===panel.orderId))) {
            dispatch(showPanel());
        }
        else {
            const feedId = Feeds.ChoiceUsers;
            dispatch(setPanelView({
                panelType: PanelType.QuestionUsers, displayQuestion: question,
                targetId: questionId, feedId, choiceId, orderId: orderId
            }));
        }
    }

    if(isOrdered){
        return (
            <div className={`${layout} d-flex ${RESQ?"activeColor":""}`}>
                <OrderedPopover choiceId={choiceId} choiceStats={choiceStats} text={text} showChoiceUsers={showChoiceUsers} />
                {children}
            </div>
        )  
    }

    switch (questionType) {
        case QuestionAskType.Select:
        case QuestionAskType.Pictures:
            {
                const summaryStats = choiceStatsSummary(choiceStats);
                if (isOrdered && summaryStats[choiceId].count>0)
                    return (
                        <div className={`${layout} d-flex activeColor roundedBorder`}>
                            <OrderedPopover choiceId={choiceId} choiceStats={choiceStats} text={text} showChoiceUsers={showChoiceUsers} />
                            {children}
                        </div>
                    )
                else
                    return (
                        <div className={layout}>
                            {text}<sup></sup>{children}
                        </div>
                    )
            }
        case QuestionAskType.Numeric:
            return (
                    <div className={layout} style={{ fontSize: ".9em" }}>
                        {text}{children}
                    </div>
    
            )
        // case QuestionAskType.MoveMatch:
        //     return (
        //         <div className={layout}>
        //             {text}<sup></sup>{children}
        //         </div>
        //     )
        default:
            return (
                <div className={layout}>
                    {text}<sup></sup>{children}
                </div>
            )
    }
})

export default connect(mapStateToProps, null)(EditItemDisplay)