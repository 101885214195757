import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
// core components
import "./index.css";
import { connect } from "react-redux";
import { setChatImagePreviewUrl, stageChatImage } from "../../redux/actions/chat-actions";
import { showMsgModal } from "../../redux/actions/modal-actions";

function ChatImageUpload({ children, dispatch }) {

  let fileInput = React.createRef();

    const handleImageChange = e => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            let size = Math.round((file.size * 10) / (1024 * 1024)) / 10;
            if (size > 5)
                dispatch(showMsgModal({ 
                    content: <span>File size must be less than 5Mb. Size of the selected file is <b> {size + "Mb"}</b></span>, 
                    title: "File  Is Too Large!" }))
            else {
              dispatch(stageChatImage(file));
               // scaleImage(file);
                reader.onloadend = () => {
                    dispatch(setChatImagePreviewUrl({ previewUrl: reader.result }));
                };
                reader.readAsDataURL(file);

            }
        }
        fileInput.current.value = null;
    };


  const handleClick = () => {
    fileInput.current.click();
  };
  
  return (
      <div className=" text-center">
          <input type="file" hidden accept='image/*' onChange={handleImageChange} ref={fileInput} />
                <div onClick={() => handleClick()} id="placeHolder">
                    {children}
                </div>
          </div>
  );
}

export default connect(null,null)(ChatImageUpload);

ChatImageUpload.propTypes = {
  handleImageRemove: PropTypes.func,
  onChange: PropTypes.func
};
