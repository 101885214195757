import { all, fork } from "redux-saga/effects";
import questionSagas from "./question-sagas";
import feedSagas from "./feed-sagas";
import imageSagas from "./image-sagas";
import profileSagas from "./profile-sagas";
import chatSagas from "./chat-sagas";
import appsyncSagas from "./appsync-sagas";


export default function* test() {
  yield all([
     fork(feedSagas), 
     fork(questionSagas), 
     fork(imageSagas), 
     fork(profileSagas), 
     fork(appsyncSagas ), 
     fork(chatSagas)
  ]);
}