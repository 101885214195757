export const countColor = " brand1";
export const tig = " grey-text";
export const activeColor = " activeColor ";
export const inactiveColor = " silver ";
export const disabledColor = " brand1";

export const Icons = {
   ACCOUNT: "fal fa-file-invoice-dollar ",
   ACTIVE: "fal fa-fire-smoke " ,
   ACTIVITY: "fal fa-digging ",
   AD: "fal fa-ad ",
   ADD: "fal fa-plus ",
   ALERT:"fas fa-exclamation",
   AWARD: "fal fa-award ",
   BARS: "far fa-bars fa-lg ",
   BORROW: "fal fa-clone ",
   CAMERA: "fas fa-camera ",
   CARETDOWN: "fa-solid fa-caret-down",
   CARETUP: "fa-solid fa-caret-up",
   CHAT: "fal fa-comments ",
   CHATACTIVE: "fas fa-comments ",
   CHATJOINED: "fad fa-comments ",
   CHECK: "fal fa-check ",
   CIRCLE: "fal fa-circle",
   CHECKBOX: "fal fa-square activeColor",
   CHECKBOX_ACTIVE: "fal fa-check activeColor",
   CLOCK: "fal fa-clock ",
   CLOCKACTIVE: "fal fa-clock ",
   COFFEE: "fal fa-coffee ",
   COMMENT: "fal fa-comment ",
   CONNECTED: "fal fa-project-diagram ",
   CONVERSATION: "fal fa-comment-lines ",
   COPY: "fa-sharp fa-thin fa-copy",
   CREATE: "fal fa-question ",
   CREATEACTIVE: "fas fa-question ",
   CREATED: "fas fa-question ",
   DASHBOARD: "fal fa-chart-line ",
   DIAMOND: "fal fa-diamond ",
   DIGGING: "fal fa-digging ",
   DRAWER: "fa-sharp fa-regular fa-chevron-right ",
   EDIT: "fas fa-pencil activeColor ",
   EDITING: "fal fa-pencil inactiveColor ",
   EDITING_ACTIVE: "fas fa-pencil activeColor",
   EXAMPLES: "fal fa-traffic-light-go fa-lg ",
   EXPAND: "fas fa-caret-down ",
   EXPIRING: "fal fa-hourglass ",
   FAVORITE: "fal fa-heart ",
   FEATURE: "fal fa-gem ",
   FEATURED: "fal fa-gem ",
   FEATUREDACTIVE: "fas fa-gem activeColor ",
   FEED: "fa-sharp fa-regular fa-bars-staggered ",
   FEEDSELECTED: "fa-sharp fa-solid fa-bars-staggered ",
   FLAME: "fal fa-fire-alt ",
   FOLLOWERS: "fal fa-user-friends ",
   FOLLOWING:"fal fa-users ",
   FRIENDS: "fal fa-user-friends ",
   FRIENDSACTIVE: "fas fa-user-friends " + activeColor,
   FRIENDSINACTIVE: "fal fa-user-friends " + inactiveColor,
   GLOBE: "fal fa-globe-americas fa-lg ",
   GLASSES: "fal fa-glasses ",
   HASHTAG: "fal fa-hashtag ",
   HASHTAGACTIVE: "fas fa-hashtag activeColor ",
   HEAT: "fal fa-heat ",
   HIDDEN: "fal fa-eye-slash ",
   HOME: "fa-sharp fa-regular fa-house ",
   IMAGE: "fal fa-image ",
   IMAGES: "fal fa-images ",
   INFO: "fal fa-info-square ml-1",
   INFORMATION: "fal fa-info ",
   INFOACTIVE: "fad fa-info activeColor ",
   LEFT: "fal fa-arrow-alt-circle-left fa-lg",
   LIGHTBULB: "fal fa-lightbulb ",
   LINK: "fal fa-link ",
   LINK_NEXT:"fa-light fa-right ",
   LINK_PREVIOUS:"fa-light fa-left ",
   LOCATION: "fal fa-map-marker-alt ",
   LOTTERY: "fal fa-dice-d20 ",
   MENU: "fas fa-ellipsis-h fa-2x shrink " ,
   MESSAGES: "fal fa-envelop ",
   MODERATED: "fas fa-exclamation-triangle ",
   MOVE: "fal fa-expand-arrows-alt",
   NETWORK: "fal fa-chart-network ",
   NEXT: "fas fa-long-arrow-right fa-lg pointer activeColor",
   OVERVIEW: "fal  fa-chart-network",
   PANEL_CLOSE: "fa-sharp fa-regular fa-chevron-right ",
   PANEL_OPEN: "fa-sharp fa-regular fa-chevron-left ",
   PARENT: "fal fa-project-diagram ",
   PEOPLE: "fa-regular fa-people",
   PLUS: "fal fa-plus-circle",
   POPULAR: "fal fa-users-crown ",
   PREMIUM: "fad fa-badge-check ",
   PROFILE: "fa-sharp fa-regular fa-right ",
   PROFILEEDIT: "fal fa-user-circle ",
   PROMOTION: "fas fa-badge-check ",
   QUESTION_ADVERTISEMENT: "fal fa-ad ",
   QUESTION_DATE: "fal fa-calendar-alt ",
   QUESTION_LINEAR: "fal fa-sliders-h ",
   QUESTION_LOCATION: "fal fa-map-marker-alt ",
   QUESTION_LOTTERY: "fal fa-dice-d20 ",
   QUESTION_NUMBER: "fal fa-tally ",
   QUESTION_PICTURES: "fal fa-images ",
   QUESTION_RANK: "fal fa-sort-numeric-down ",
   QUESTION_RATE: "fal fa-tachometer-alt-slow ",
   QUESTION_RECIPE: "fal fa-mortar-pestle ",
   QUESTION_SCORES: "fal fa-pennant ",
   QUESTION_SELECT: "fal fa-circle ",
   QUESTION_SELECTN: "fal fa-square",
   QUESTION_SELECTINORDER: "fal fa-diamond",   
   QUESTION_TIME: "fal fa-clock ",
   QUESTION_WEIGHT: "fal fa-balance-scale fa-lg " + activeColor,
   QUESTIONS: "fa-solid fa-question ",
   QUESTIONSACTIVE: "fa-solid fa-question ",
   QUESTIONSINACTIVE: "fal fa-question " + inactiveColor,
   QRCODE: "fal fa-qrcode",
   RADIO: "fal fa-circle activeColor ",
   RADIO_ACTIVE: "fal fa-check activeColor ",
   REMOVE: "far fa-snooze ",
   REPORT: "fal fa-flag ",
   REPORTACTIVE: "fas fa-flag ",
   RESET: "far fa-undo fa-sm pt-2 ml-2 ",
   RESPOND: "fal fa-user-check ",
   RESPONDACTIVE: "fas fa-reply ",
   RESPONDED: "fa-thin fa-user-check ",
   RIGHT: "fal fa-arrow-alt-circle-right fa-lg",
   ROUTE: "fal fa-route ",
   SCORES: "fal fa-pennant ",
   SEARCH: "fa-sharp fa-regular fa-magnifying-glass ",
   SEND: "fas fa-arrow-alt-up",
   SHARE: "fal fa-share ",
   SHAREACTIVE: "fas fa-share activeColor",
   SIMILARITY: "fal fa-treasure-chest",
   SIGNOUT: "fa-light fa-right-from-bracket",
   SMS: "fa-sharp fa-thin fa-comment-sms ",
   SMILE: "fal fa-smile fa-lg ",
   SPINNER: "fad fa-spinner fa-spin ",
   SPONSORED: "fal fa-stars ",
   STAR: "fal fa-star ",
   STEALTH: "fad fa-user-secret ",
   STREAM: "fal fa-stream",
   TAG: "fal fa-tag ",
   TAGGED: "fas fa-tag ",
   TEST: "fas fa-skull-crossbones fa-lg ",
   TIMES: "fal fa-times",
   TRASH: "fal fa-trash-alt activeColor ",
   TRENDING: "fal fa-chart-line ",
   UP_DOWN: "fas fa-sort-alt activeColor ",
   USER: "fa-regular fa-user ",
   USERS: "fa-regular fa-users ",
   USERFEED: "fal fa-stream ",
   VIEW: "fal fa-eyes ",
   VISIBLE: "fal fa-eye ",
   WORLD_ACTIVE: "fas fa-globe " + activeColor,
   WORLD: "fal fa-globe ",
}

export const ActivityIcons = {
   SHAQ: "fal fa-share " + tig,
   SHAQ_ACTIVE: "fas fa-share brand1",
   BOOK: "fal fa-bookmark " + tig,
   BOOK_ACTIVE: "fas fa-bookmark brand1",
   BORR: "fal fa-clone " + tig,
   BORR_ACTIVE: "fas fa-clone brand1",
   CHAQ: "fal fa-comment " + tig,
   CHAQ_ACTIVE: "fas fa-comments brand1",
   LIKQ: "fal fa-heart " + tig,
   LIKQ_ACTIVE: "fas fa-heart brand1",
   LINK: "fal fa-link " + tig,
   LINK_ACTIVE: "fas fa-link brand1"
}
