import React, {  useState } from "react";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { updateQuestionImage } from "../../../redux/actions/questionedit-actions";
import { resizeFile } from "../../../constants/image";
import {  mainImageUrl } from "../Utilities";

function mapStateToProps(state) {
    return {
        imageKey: state.questionedit.question.imageKey,
        imageURL: state.questionedit.question.imageURL,
        identityId: state.questionedit.question.creator?state.questionedit.question.creator.identityId:"",
        isNew: state.questionedit.isNew,
        profile: state.profile.currentUser
    };
};

const FileUploader = ({ imageKey, imageURL, identityId, isNew, dispatch }) => {
    const [originalKey, setOriginalKey] = useState(imageKey)
    const [preview, setPreview] = useState(imageURL==='' && imageKey?mainImageUrl(identityId,imageKey):imageURL);
    const [data, setData] = useState(null);
    const [iframeHeight, setIframeHeight] = useState(300);

    const generateEmbedURL = (link) => {
        try {
            const url = new URL(link);
            const { hostname, pathname, searchParams } = url;

            if (hostname.includes("youtube.com") || hostname.includes("youtu.be")) {
                const videoId = searchParams.get("v") || pathname.split("/").pop();
                setIframeHeight(Math.round(430 / (16 / 9))); // YouTube 16:9 aspect ratio
                return `https://www.youtube.com/embed/${videoId}?autoplay=1`;
            } else if (hostname.includes("cnn.com")) {
                const videoId = pathname.split("/").pop();
                setIframeHeight(Math.round(430 * (16 / 9))); // 
                return `https://www.cnn.com/embed/${videoId}?embedType=embedOnly&lang=en`;
            } else if (hostname.includes("tiktok.com")) {
                const videoId = pathname.split("/").pop();
                setIframeHeight(Math.round(430 * (9 / 16))); // TikTok 9:16 aspect ratio
                return `https://www.tiktok.com/embed/${videoId}?embedType=embedOnly&lang=en`;
            } else if (hostname.includes("facebook.com")) {
                setIframeHeight(Math.round(430 / (4 / 3))); // Facebook 4:3 aspect ratio
                return `https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(link)}&autoplay=1`;
            } else if (hostname.includes("instagram.com")) {
                setIframeHeight(600); // Instagram square aspect ratio
                return `https://www.instagram.com/p/${pathname.split("/")[2]}/embed/captioned`;
            } else {
                setIframeHeight(300); // Default height
                return null;
            }
        } catch {
            return null;
        }
    };


  const frameHeight = (link) => {
    let height;
    try {
        const url = new URL(link);
        const { hostname } = url;

        if (hostname.includes("youtube.com") || hostname.includes("youtu.be") || hostname.includes("cnn.com")) {
            height = Math.round(430 / (16 / 9)); // YouTube 16:9 aspect ratio
        } else if (hostname.includes("tiktok.com")) {
            height = Math.round(430 * (9 / 16)); // TikTok 9:16 aspect ratio
        } else if (hostname.includes("facebook.com")) {
            height = Math.round(430 / (4 / 3)); // Facebook 4:3 aspect ratio
        } else if (hostname.includes("instagram.com")) {
            height=500; // Instagram square aspect ratio
        } else {
            height = 300; // Default height
        }
        return height;
    } catch {
        return height;
    }
};

    // Handle social media link submission
    const handleLinkSubmit = () => {
        const link = prompt("Paste a Facebook, TikTok, Instagram, or YouTube video link:");
        const embedURL = generateEmbedURL(link);

        if (embedURL) {
            const linkKey = uuidv4();
            setData({ imageURL: link, imageKey: linkKey });
            setIframeHeight(frameHeight(link));
            console.log("frame height:",iframeHeight);
            setPreview(embedURL);
            let imageActions = [];
            if (originalKey) {
                imageActions.push({
                    action: "delete",
                    imageKey: originalKey,
                    image: null,
                });
                setOriginalKey(null);
            }
            dispatch(updateQuestionImage({
                imageActions: imageActions,
                question: {
                    imageKey: linkKey,
                    imageURL: embedURL
                }
            }))
        } else {
            alert("Invalid or unsupported link. Please try again.");
        }
    };

    // Handle file upload
    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        const oldImageKey = imageKey;
        if (file) {
            const fileKey = uuidv4();
            let x = await resizeFile(file);
            setData({ file, imageKey: fileKey });
            setPreview(URL.createObjectURL(x));
            let imageActions = [];
            if (originalKey) {
                imageActions.push({
                    action: "delete",
                    imageKey: oldImageKey,
                    image: null,
                });
                setOriginalKey(null);
            }
            imageActions.push({
                action: "add",
                imageKey: fileKey,
                image: x
            });
            dispatch(updateQuestionImage({
                imageActions: imageActions,
                question: {
                    imageKey: fileKey,
                    imageURL: ""
                }
            }))
        }
    };

    // const handleImageRemove = () => {
    //     setPreview(null);
    //     let imageActions = [];
    //     if (imageKey) {
    //         imageActions.push({
    //             action: "delete",
    //             imageKey: imageKey,
    //             image: null,
    //         });
    //     }
    //     dispatch(updateQuestionImage({
    //         imageActions: imageActions,
    //         question: { imageKey: '', imageURL: "" }
    //     }));
    // };

    return (
        <div style={{ textAlign: "center" }}>
            {/* Upload and Link Icon */}
            <div>
                <button
                    onClick={() => document.getElementById("fileInput").click()}
                    style={{
                        fontSize: "24px",
                        border: "none",
                        background: "transparent",
                        cursor: "pointer",
                    }}
                >
                    <i className="fa-regular fa-camera activeColor"></i>
                </button>
                <button
                    onClick={handleLinkSubmit}
                    style={{
                        fontSize: "24px",
                        border: "none",
                        background: "transparent",
                        cursor: "pointer",
                    }}
                >
                    <i className="fa-regular fa-link activeColor"></i>
                </button>
            </div>

            {/* Hidden File Input */}
            <input
                type="file"
                id="fileInput"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileUpload}
            />

            {/* Full Width Preview Section */}
            {preview && (
                <div
                    style={{
                        marginTop: "0px",
                        width: "100%",
                        maxWidth: "430px", // Maximum width for the container
                        marginLeft: "auto",
                        marginRight: "auto",
                        border: "1px solid #ccc",
                        borderRadius: "0px",
                        overflow: "hidden", // Ensures no scrollbars
                        position: "relative",
                        height: `${iframeHeight}px`,  // Dynamic height
                    }}
                >
                    {data?.file || (!isNew && preview && data?.file) ? (
                        <img
                            src={preview}
                            alt="Uploaded Preview"
                            style={{
                                width: "100%",
                                height: "100%", // Maintain aspect ratio
                            }}
                        />
                    ) : (
                
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                overflow: "hidden",
                            }}
                        >
                            <iframe
                                title="videolink"
                                src={preview}
                                style={{
                                    width: "100%",
                                    height: `${iframeHeight}px`,
                                    border: "none",
                                }}
                                allowFullScreen
                                scrolling="no" // Disable scrollbars on the iframe
                            ></iframe>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default connect(mapStateToProps, null)(FileUploader);




