import config from "../config";
import Resizer from "react-image-file-resizer";

export const SMS_IMAGE_WIDTH = 1000;
export const MAIN_IMAGE_WIDTH = 414;
export const MAIN_IMAGE_HEIGHT = 300;
export const CARD_IMAGE_WIDTH = 310;
export const CARD_IMAGE_HEIGHT = 170;
export const CHOICE_IMAGE_SIZE = 170;
export const AVATAR_SIZE = 120;
export const SM_AVATAR_SIZE = 40;
export const XS_AVATAR_SIZE = 30;
export const MENU_AVATAR_SIZE = 25;
export const ICON_SIZE = 20;

export const CHOICE2_WIDTH = 184;
export const CHOICE2_HEIGHT = 138;
export const CHOICE2_TALL_HEIGHT = 245;
export const CHOICE3_WIDTH = 118;
export const CHOICE3_HEIGHT = 87;
export const CHOICE3_TALL_HEIGHT = 157;
export const LOTTERY_BALL_WIDTH = 32;
export const LOTTERY_BALL_HEIGHT = 32;

export const SMALL_BALL_SIZE = 20;
export const CHAT_IMAGE_WIDTH = 300;
export const CHAT_IMAGE_HEIGHT = 300;
//******     image storage      ******/
export const IMAGE_MAX_WIDTH =640;
export const IMAGE_MAX_HEIGHT = 480;
export const IMAGEURL = config.IMAGE_URL;
export const IMAGE_STORAGE_API_ERROR = "IMAGE_STORAGE_API_ERROR";
export const IMAGE_CONTENT_TYPE = "image/jpeg";
export const IMAGE_STORAGE_LOCATION= "images/";

export const ImageOptions = {
    ImageUrl:config.IMAGE_URL,
    ImageFolder: 'images/',
    VideoFolder: 'videos/',
    ImageContentType: 'image/jpeg',
    UploadWidth:'430',
    UploadHeight:'600',
    Quality: 100
};

export const resizeFile = (file) =>
new Promise((resolve) => {
    Resizer.imageFileResizer(
        file,
        ImageOptions.UploadWidth,
        ImageOptions.UploadHeight,
        "JPEG",
        ImageOptions.Quality,
        0,
        (uri) => {
            resolve(uri);
        },
        "blob"
    );
});
