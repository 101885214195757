import React from "react";
import { connect } from "react-redux";
import { Feeds } from "../../constants/feedids";
import { setView } from "../../redux/actions/context-actions";
import { SystemId } from "../../constants/profile";
import { useNavigate } from "react-router-dom";
import { hidePanel, toggleLeftPanel } from "../../redux/actions/panel-actions";
import { AvatarSm } from "../../shared/Avatars";

const mapStateToProps = (state) => {
   return {
      feedId: state.context.view.feedId,
   };
};


const LyqnessProfileSelector = React.memo(function LyqnessProfileSelector({ dispatch, feedId }) {
   const navigate = useNavigate();
   const isSelected = feedId === Feeds.AllQuestions;

   function loadFeed(feedId) {
      if (!isSelected) {
         navigate('/');
         dispatch(setView({ profileId: SystemId, targetId: SystemId, feedId: Feeds.AllQuestions }));
      }
   }

   const handleDrawerToggle = () => {
      dispatch(hidePanel());
      dispatch(toggleLeftPanel());
   };

   if (isSelected)
      return (
         <div className=" text-center pointer d-flex  py-1 ">
            <span onClick={()=>handleDrawerToggle()}>
               <AvatarSm userFolder={"assets"} avatarKey={"avatarlight.png"} initials={"LY"} square={true} />
            </span>
         </div>
      )
   else
      return (
         <div className=" text-center pointer  d-flex py-1  " onClick={loadFeed}>
            <span>
               <AvatarSm userFolder={"assets"} avatarKey={"avatarlight.png"} initials={"LY"} square={true} />
            </span>
         </div>
      )
})


export default connect(mapStateToProps, null)(LyqnessProfileSelector);