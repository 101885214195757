import React from "react";
import { connect } from "react-redux";
import MsgModal from "./MsgModal";
import { ModalTypes } from "../../constants/modal";
import PreviewModal from "./PreviewModal";
import ConfirmModal from "./ConfirmModal";
import TagLinkModal from "./TagLinkModal";
import StealthMsgModal from "./StealthMsgModal";
import QuestionModal from "./QuestionModal";
import QRModal from "./QRModal";
import ProfileListModal from "./ProfileListModal";

const MODAL_COMPONENTS = {
  [ModalTypes.PROFILE_LIST_MODAL]: ProfileListModal,
  [ModalTypes.QUESTION_MODAL]: QuestionModal,
  [ModalTypes.PREVIEW_MODAL]: PreviewModal,
  [ModalTypes.STEALTH_MSG_MODAL]: StealthMsgModal,
  [ModalTypes.MSG_MODAL]: MsgModal,
  [ModalTypes.QR_MODAL]: QRModal,
  [ModalTypes.CONFIRM_MODAL]: ConfirmModal,   
  [ModalTypes.TAG_LINK_MODAL]: TagLinkModal,   
  /* other modals */
}

const ModalRoot = ({ modalType, modalProps,...props }) => {
  if (!modalType) {
    return null
  }

  const SpecificModal = MODAL_COMPONENTS[modalType]
  return <SpecificModal {...modalProps}  {...props}/>
}

export default connect(state => state.modal)(ModalRoot)