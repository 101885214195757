import {
    ContextActions as Actions,
  } from "../constants";


export function setView(payload) {
    return { type: Actions.SET_VIEW, payload };
  }

  export function openSpeedDial(payload) {
    return { type: Actions.OPEN_SPEEDDIAL, payload };
  }

  export function closeSpeedDial(payload) {
    return { type: Actions.CLOSE_SPEEDDIAL, payload };
  }

  export function setScreenSize(payload) {
    return { type: Actions.SET_SCREEN_SIZE, payload };
  }  

  export function setPanelView(payload) {
    return { type: Actions.SET_PANEL_VIEW, payload };
  }  

  export function clearPanelView(payload) {
    return { type: Actions.CLEAR_PANEL_VIEW, payload };
  }  


  export function closePanels(payload) {
    return { 
        type:Actions.CLOSE_PANELS,
        payload};
}

export function setNotification(payload) {
  return { type:Actions.SET_NOTIFICATION,payload};
}