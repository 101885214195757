import React from "react";
import Popover from '@mui/material/Popover';
import { Icons } from "../../../shared/QIcons";
import "./index.css";


const LyqPopover = React.memo(function LyqPopover({ id, title, content, children }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClose = () => {
        setAnchorEl(null);
      };

      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };

      const open = Boolean(anchorEl);

    return (
        <React.Fragment>
            <span aria-describedby={id} variant="contained" className="pointer brand1"
                onClick={handleClick}
                //onMouseOver={handleClick}
                onBlur={handleClose}
            >
                <span className="pointer " ><i className={Icons.INFO + "  info-text ps-1"}></i></span>
            </span>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                className="mt-4"
                onClose={handleClose}
                onClick={handleClose}                
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}                
            >
                <div className="lyqPopover">
                    <div className="row brand1 brand3-bg no-gutters px-2 pt-1" >
                        <div className="col-11 h4 ">
                            {title ?? "Confirm"}
                        </div>
                        <div className=" col-1 h4 text-right pointer"><i className="far fa-times"></i></div>
                    </div>
                    <div className="p-2" >
                        {content}
                    </div>
                </div>
            </Popover>
        </React.Fragment>
    )
})

export default LyqPopover;