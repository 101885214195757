
export const PanelType = {
    Default:0,
    QuestionUsers: 1,
    Group: 2,
    Questions: 3,
    Messages: 4,
    Users: 5,
    Chat: 6,
    Conversations:7,
    Share:8,
    Link:9,
    PublicConversations:10,
    } 
