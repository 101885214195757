import React from "react";
import { ViewType } from "../../constants/profile"
import MainFeedActionCard from "./MainFeedActionCard";
import { connect } from "react-redux";
import ProfileCard from "./ProfileCard";
import UserFeedActionCard from "./UserFeedActionCard";
import PublicFeedActionCard from "./PublicFeedActionCard";


const mapStateToProps = (state, ownProps) => {
  return {
    viewType: state.context.view.viewType,
  };
};

const ActionViewSelector = React.memo(function ActionViewSelector({ viewType }) {
  switch (viewType) {
    case ViewType.MainView:
      return (<MainFeedActionCard />)
    case ViewType.UserView:
        return (<UserFeedActionCard />) 
    case ViewType.QuestionView:
    case ViewType.SharedView:
    case ViewType.PublicView:
    case ViewType.UserProfile:
      return (<PublicFeedActionCard />)
    default:
      console.log ('Illegal view selected');
      return;
  }
});

export default connect(mapStateToProps, null)(ActionViewSelector);