import React, { useEffect } from "react";
import { connect } from "react-redux";
import "../index.css";
import { Feeds, PublicFeeds, UserFeeds } from "../../constants/feedids";
import { closePanels, setView } from "../../redux/actions/context-actions";
import { SystemId } from "../../constants/profile";



const mapStateToProps = (state, ownProps) => {
    let isPremium, tags, feedId, targetId, questionFeedId;
    switch (state.context.view.profileId) {
        case state.profile.currentUser.profileId:
            tags = state.profile.currentUser.tags;
            questionFeedId = UserFeeds.TaggedQuestions;
            targetId = state.profile.currentUser.profileId;
            isPremium = state.profile.isPremium;
            feedId = UserFeeds.UserTags
            break;
        case SystemId:
            feedId = Feeds.AllHashtags;
            tags = state.profile.systemUser.tags;
            questionFeedId = Feeds.TaggedQuestions;
            targetId = state.profile.systemUser.profileId;
            break;
        default:
            feedId = PublicFeeds.UserTags;
            tags = state.profile.viewProfile.tags;
            questionFeedId = PublicFeeds.TaggedQuestions;
            targetId = state.profile.viewProfile.profileId;
            isPremium = state.profile.isPremium;
        }
    return {
        items: tags ?? [],
        feedId: feedId,
        questionFeedId: questionFeedId,
        isPremium: isPremium,
        targetId: targetId,
        panelIsOpen: state.context.panel.isOpen,
    };
};

const TagFeed = React.memo(function TagFeed({ items, feedId, targetId, panelIsOpen, userName, isPremium, questionFeedId, dispatch }) {

    // const [searchStr, setSearchStr] = useState("");


    useEffect(() => {
        return () => {

        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    function getQuestions(tag) {
        if (panelIsOpen)
            dispatch(closePanels());
        dispatch(setView({ tag: tag, targetId: targetId, feedId: questionFeedId, name: userName }));
    }

    // function showQRCode(item) {
    //     //dispatch(resetPanels());
    //     dispatch(showQRModal({
    //         title: `${item} QR Code`,
    //         content: item
    //     }
    //     ))
    // }

    function renderItem(idx) {
        let item = items[idx];
                return (
                    <div key={item.tag} className=" d-flex activeColor tagfont pointer px-1" onClick={() => getQuestions(item.tag)} >
                        {item.tag}&nbsp;<span className="tagcount brand1">({item.count})</span>
                    </div>
                )
    }

    // function handleSearch(str) {
    //     setSearchStr(str);
    //     if (str || (!str && searchStr))
    //         loadNextPage(str);
    // };

    if (items)
        return (
            <div className=" row no-gutters scrollbar" style={{ height: "200px" }}>
                <div className="col-12">
                    <div className="row no-gutters">
                        {items.map((item, i, arr) => (
                            renderItem(i, arr)
                        ))
                        }
                    </div>
                </div>
            </div>
        )
    else
        return <span />
})

export default connect(mapStateToProps, null)(TagFeed);