//import {  DataStore } from "aws-amplify";
import {  API, DataStore, graphqlOperation } from "aws-amplify";
import { takeEvery, call, put, all } from "redux-saga/effects";
import { ProfileActions, ProfileStats, } from "../constants"; 
import * as queries from '../../graphql/lyqnessQueries';
import { Profile} from "../../models/index";
import * as mutations from '../../graphql/lyqnessMutations';
import global from "../../constants";
import { SystemId } from "../../constants/profile";

  //***         Create new profile from cognito data             ******/
export function* watchGetCreateProfile() {
    yield takeEvery(ProfileActions.CURRENT_USER_PROFILE_REQUESTED, getCreateProfileWorker);
  }
  
  function* getCreateProfileWorker(action) {
    try {     
        let profiles = yield call(getCreateProfile, action.payload);
        let payload={profile:profiles.user};
        payload.profile={...payload.profile};
        yield put({ type: ProfileActions.CURRENT_USER_PROFILE_RECEIVED, payload}); 
        payload={profile:profiles.lyqness};
        yield put({ type: ProfileActions.USER_PROFILE_UPDATED, payload}); 
      } 
      catch (e) {
        yield put({ type: ProfileActions.PROFILE_API_ERROR, payload: e });
    }
  }

  async function getCreateProfile(payload) { 
      let profile=null;
    try { 
        const {attributes:{sub,given_name,family_name,email}, identityId} = payload;
        profile = await DataStore.query(Profile,sub);

        if(!profile)
        {
            let qryResponse = await API.graphql(graphqlOperation(queries.getProfile, {profileId:sub}));
                if(qryResponse.data.getProfile)
                {
                    profile=qryResponse.data.getProfile;
                }
            if(!profile)  
                {   
                  const pFeed = {
                    firstName:given_name,
                    identityId: identityId,
                    lastName: family_name,
                    profileId: sub,
                    creatorId:sub,
                    email: email,
                    category: "User",
                    stats: ProfileStats
                };
                let input = {input : {...pFeed}};
                let gql  = await API.graphql(graphqlOperation(mutations.createProfile, input));
                profile=gql.data.createProfile;
                }               
        }
        global.userId = profile.profileId;

                let gql  = await API.graphql(graphqlOperation(queries.getProfile, {profileId:SystemId}));
              let  lyqness=gql.data.getProfile;

        profile={...profile,identityId: identityId}
        return {user:profile, lyqness:lyqness}
    } catch (error) {
        console.log('**** error  **** : ', error)
    }
  }


  export default function* rootSaga() { 
    yield all([ 
        watchGetCreateProfile()
    ]) 
  }