import React from "react";
import toast from "react-hot-toast";
import { Icons } from "../../shared/QIcons";


const NotifyAlert = (msg) => {
    toast.custom(
        (t) => (
            <div
                className="infoCard card shadow rounded border "
                onClick={() => toast.dismiss(t.id)}
                style={{
                    opacity: t.visible ? 1 : 0,
                    transition: "opacity 100ms ease-in-out"
                }}
            >
                <div className="card-body px-2 py-1 text-center yellow">
                    <div className="text-left "><i className="fa-duotone fa-solid fa-info brand1 fa-lg"></i></div>
                    <p className="pt-0 yellow">{msg}</p>
                </div>
                <div className="card-footer no-border smallfont text-center activeColor py-1">click anywhere to dismiss</div>
            </div>
        ), {
        duration: 2000
    }
    );
};

export default NotifyAlert;