import React, { useState, useRef, useCallback, memo } from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import "./MoveMatchChoices.css";
import { connect } from "react-redux";
import {
  updateEditQuestion,
  updatePictureChoices,
} from "../../../redux/actions/questionedit-actions";
import notify from "../../notify/Notify";
import { resizeFile } from "../../../constants/image";
import { initNumericChoiceStats } from "../../../shared/utilities";
import { Select, MenuItem, FormControl } from "@mui/material";
import { pictureChoiceUrl, smsImageUrl } from "../Utilities";
import { moveMatchOptions } from "../constants";
import FullscreenImageViewer from "./FullScreenImageViewer";

const generateGUID = () =>
  crypto.randomUUID
    ? crypto.randomUUID()
    : Math.random().toString(36).substr(2, 9);

function mapStateToProps(state) {
  return {
    question: state.questionedit.question,
    identityId: state.profile.currentUser.identityId,
  };
}

const MemoizedSortableItem = memo(SortableItem);

const CreateMoveMatchQuestion = ({ question, identityId, maxImages = 3, dispatch }) => {
  const { choiceHeadings, isOrdered } = question;
  const [choices, setChoices] = useState(
    question.choices.map((choice) => ({
      ...choice,
      imageURL: choice.imageKey
        ? pictureChoiceUrl({ identityId, imageKey: choice.imageKey })
        : "",
      imageKey: choice.imageKey || generateGUID(),
    }))
  );
  const [selectedOption, setSelectedOption] = useState("");
  const colWidth = choices.length === 2 ? "col-6" : "col-4";
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const fileInputRefs = useRef({});

  const handleTriggerFileUpload = (imageKey) => {
    if (fileInputRefs.current[imageKey]) {
      fileInputRefs.current[imageKey].click();
    }
  };

  const handleFileUpload = async (event, imageKey) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const resizedFile = await resizeFile(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          const newImageURL = e.target.result;

          if (choices.some((item) => item.imageURL === newImageURL)) {
            notify("Duplicate images are not allowed.", "error");
            return;
          }

          const updatedChoices = choices.map((item) =>
            item.imageKey === imageKey
              ? { ...item, imageURL: newImageURL, file: resizedFile }
              : item
          );

          setChoices(updatedChoices);
          dispatch(updatePictureChoices({ question: { choices: updatedChoices } }));
        };
        reader.readAsDataURL(resizedFile);
      } catch (error) {
        notify("Error processing the image. Please try again.", "error");
      }
    }
  };

  const FullscreenImage = ({ imageURL, onClose }) => (
    <div className="fullscreen-overlay" onClick={onClose}>
      <img src={imageURL} alt="Fullscreen Preview" className="fullscreen-image" />
    </div>
  );

  const deleteImage = useCallback(
    (imageKey) => {
      if (!imageKey || choices.length <= 2) {
        return;
      }
      const updatedChoices = choices.filter((item) => item.imageKey !== imageKey);
      setChoices(updatedChoices);
      dispatch(
        updatePictureChoices({
          question: {
            choices: updatedChoices,
          },
        })
      );
    },
    [choices, dispatch]
  );

  const handleSortEnd = (oldIndex, newIndex) => {
    if (oldIndex !== newIndex) {
      const updatedChoices = arrayMoveImmutable(choices, oldIndex, newIndex);
      setChoices(updatedChoices);
      dispatch(updatePictureChoices({ question: { choices: updatedChoices } }));
    }
  };

  const handleChoiceHeadingUpdate = (event, index) => {
    if (selectedOption !== "Custom2" && selectedOption !== "Custom3") {
      return;
    }
    const newHeadings = [...choiceHeadings];
    newHeadings[index] = event.target.value;
    dispatch(updateEditQuestion({ question: { choiceHeadings: newHeadings } }));
  };

  const toggleFullscreenImage = (imageURL) => {
    setFullscreenImage(fullscreenImage === imageURL ? null : imageURL);
  };

  const handleTextUpdate = (event, choiceKey) => {
    const { value } = event.target;
    const updatedChoices = choices.map((item) =>
      item.imageKey === choiceKey ? { ...item, text: value } : item
    );
    setChoices(updatedChoices);
    dispatch(updatePictureChoices({ question: { choices: updatedChoices } }));
  };

  const handleOptionChange = (event) => {
    const selected = event.target.value;
    setSelectedOption(selected);

    const newHeadings = moveMatchOptions[selected];
    const updatedChoices = newHeadings.map((heading, index) => {
      const existingChoice = choices[index] || {};
      return {
        imageKey: existingChoice.imageKey || generateGUID(),
        imageURL: existingChoice.imageURL || null,
        text: existingChoice.text || "",
      };
    });

    const minSel = updatedChoices.length;
    const maxSel = minSel;
    const buckets = maxSel;

    const choiceStats = initNumericChoiceStats({
      buckets,
      minSel,
      maxSel,
      isOrdered,
      isNumeric: false,
    });

    setChoices(updatedChoices);
    dispatch(
      updatePictureChoices({
        question: {
          choices: updatedChoices,
          minSel,
          maxSel,
          buckets,
          choiceStats,
          choiceHeadings: newHeadings,
        },
      })
    );
  };

  return (
    <div className="px-2 mb-2">
        <FullscreenImageViewer fullscreenImage={fullscreenImage} identityId={identityId} toggleFullscreenImage={toggleFullscreenImage}/>

      <div className="row justify-content-center g-2">
        {choiceHeadings.map((choice, index) => (
          <div key={index} className={`${colWidth}`}>
            <input
              className="w-100 mb-2"
              maxLength={17}
              placeholder="heading..."
              value={choice}
              onChange={(event) => handleChoiceHeadingUpdate(event, index)}
              disabled={selectedOption !== "Custom2" && selectedOption !== "Custom3"}
            />
          </div>
        ))}
      </div>

      <SortableList
        onSortEnd={handleSortEnd}
        className="row justify-content-center no-gutters g-3"
        draggedItemClassName="dragged"
      >
        {choices.map((choice) => (
          <MemoizedSortableItem key={choice.imageKey}>
            <div className={`${colWidth} grid-item px-1`}>
              {choice.imageURL ? (
                <img
                  src={choice.imageURL}
                  alt="Uploaded Preview"
                  className="image-thumbnail"
                  onClick={() => toggleFullscreenImage(choice.imageURL)}
                />
              ) : (
                <div
                  className="placeholder orange"
                  onClick={() => handleTriggerFileUpload(choice.imageKey)}
                >
                  Click to Upload Image
                </div>
              )}

              <input
                maxLength={12}
                placeholder="enter text..."
                value={choice.text}
                onChange={(e) => handleTextUpdate(e, choice.imageKey)}
              />

              <div className="button-container">
                <button
                  onClick={() => handleTriggerFileUpload(choice.imageKey)}
                >
                  <i className="fas fa-pencil fa-xs"></i>
                </button>
              </div>

              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                ref={(el) => (fileInputRefs.current[choice.imageKey] = el)}
                onChange={(event) => handleFileUpload(event, choice.imageKey)}
              />
            </div>
          </MemoizedSortableItem>
        ))}
      </SortableList>
<div className="mt-3 brand4">select a heading category</div>
      <Select
          labelId="preset-options-label"
          variant="outlined"
          id="preset-options"
          size="small"
          value={selectedOption}
          onChange={handleOptionChange}
          sx={{ minHeight: 20, width:250 }}
        >
          {Object.keys(moveMatchOptions).map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
    </div>
  );
};

export default connect(mapStateToProps)(CreateMoveMatchQuestion);


