import React from "react";

import { connect } from "react-redux";
import { Icons } from "../../shared/QIcons";
import { toggleQuestionInteraction } from "../../redux/actions/question-actions";
import { LyqnessActions } from "../../redux/constants";
import notify from "../notify/Notify";
import { responseMsg } from "../../constants/question";


const mapStateToProps = (state) => {
    return {
        viewProfileId: state.context.view.profileId,
        viewType: state.profile.viewProfile.viewType,
        userId: state.profile.currentUser.profileId,
    };
};

const FavoriteLink = React.memo(function FavoriteLink({ viewProfileId, userId, viewType, displayQuestion, dispatch }) {
    const { questionId, creatorId, userFeed, draft } = displayQuestion;
    const { stats } = userFeed

    const toggleLike = (e) => {
        e.preventDefault(e);
        if (!draft)
            dispatch(toggleQuestionInteraction({
                displayQuestion: displayQuestion,
                profileId: userId,
                questionId: questionId,
                creatorId: creatorId,
                viewProfileId: viewProfileId,
                state: !stats.LIKQ,
                action: LyqnessActions.LIKQ
            }))
            else
            notify(responseMsg);
    }

    return (
        <span aria-label="favorite" size="small" onClick={(e) => toggleLike(e)}>
            <i className={stats.LIKQ ? Icons.FAVORITE + " activeColor pointer linkPadRight" : Icons.FAVORITE + " inactiveColor pointer linkPadRight"} />
        </span>
     )

})

export default connect(mapStateToProps, null)(FavoriteLink);