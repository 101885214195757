import React from "react";
import { connect } from "react-redux";
import { getProfileFeed} from "../../redux/actions/profile-actions";
import Search from "./Search";
import notify from "../notify/Notify";
import { Icons } from "../../shared/QIcons";


const mapStateToProps = (state) => {
  return  { 
            currentProfileView: state.profile.currentProfileView,
            viewProfileId: state.profile.viewProfile.profileId,
            feedId:state.context.panel.feedId
        }

};

const UserSearch = React.memo(function UserSearch({ currentProfileView, feedId, viewProfileId, dispatch}) {
    var [searchStr, setSearchStr] = React.useState("");
    var [initialFeedId, setInitialFeedId] = React.useState(null);

    const handleSearch = (str) => {
        if (str!=="" && str!==searchStr) 
        {   
            if(!initialFeedId)
                setInitialFeedId(feedId);
            dispatch(getProfileFeed({feedId: feedId, targetId:viewProfileId, searchString:str}));
        }
        else 
        {
            dispatch(getProfileFeed({feedId: initialFeedId, targetId:viewProfileId}));
        }
        setSearchStr(str);
        }

    return(
        <input
        style={{
            fontSize: ".95em",
            width: "120px"
        }}
        type="text"
        className="roundedBorder ps-2  float-end py-0"
        placeholder="search"
        //value={semicolonInput}
       // onChange={(e) => setSemicolonInput(e.target.value)}
       // onKeyPress={handleSemicolonInputKeyPress}
    />
    )
})

export default connect(mapStateToProps,null)(UserSearch);