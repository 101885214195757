import React, { useEffect } from "react";
import { connect } from "react-redux";
import "../index.css";
import { onError } from "../../shared/errorLib";
import { getUserFeed } from "../../redux/actions/feed-actions";
import Panel from "../panel/Panel";


const mapStateToProps = state => {
   return {
      feed: state.profile.feed,
      panel: state.context.panel,
      firstName: state.profile.viewProfile.firstName,
   };
};

const ActivityFeed = React.memo(function ActivityFeed({ feed, panel, dispatch }) {
   const {  nextToken } = feed;
   const { targetId, feedId } = panel;
   useEffect(() => {
      async function onLoad() {
         try {
            loadNextPage();
         }
         catch (e) {
            if (e !== 'No current user') {
               onError(e);
            }
         }
      };

      onLoad();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      return () => {
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   function loadNextPage() {
      dispatch(getUserFeed({ feedId: feedId, targetId: targetId, nextToken: nextToken }));
   }


      return (
         <div className="col px-0 py-0 h-100 brand2">
            <Panel
               title={<span className="h5 ">
                  <small>Activity</small>
               </span>}
               bottomLeft={<span>&nbsp;</span>}
               body={
                  <div className="brand1">not implemented yet</div>

               }
               >
            </Panel>
         </div>
      )

})

export default connect(mapStateToProps, null)(ActivityFeed);