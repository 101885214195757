import { Avatar } from "@mui/material";
import React from "react";
import { avatarUrl, xsAvatarUrl } from "../components/question/Utilities";
import { AVATAR_SIZE, ICON_SIZE, SM_AVATAR_SIZE, XS_AVATAR_SIZE } from "../constants/image";
import "../shared/index.css";
import { IMAGE_URL } from "../constants/url";



export function AvatarLg({ url, initials, square }) {
    if (url) {
        const imageUrl = IMAGE_URL + url;
        return (
            <Avatar src={imageUrl} variant={square === true ? "square" : ""} className="mx-auto" style={{ height: AVATAR_SIZE, width: AVATAR_SIZE }}><h1>{initials}</h1></Avatar>
        )
    }
    else
        return (
            <Avatar className="mx-auto brand0-bg" style={{ height: AVATAR_SIZE, width: AVATAR_SIZE }}><h1>{initials}</h1></Avatar>)
}

export function AvatarSm({ url, initials, square }) {
    if (url) {
        const imageUrl = IMAGE_URL + url;
        return (
            <Avatar src={imageUrl} variant={square ? "square" : ""} className="align-self-start " style={{ height: SM_AVATAR_SIZE, width: SM_AVATAR_SIZE }}>{initials}</Avatar>
        )
    }
    else
        return (
            <Avatar className="align-self-start brand0-bg" style={{ height: SM_AVATAR_SIZE, width: SM_AVATAR_SIZE }}>{initials}</Avatar>
        )
}
export function AvatarXs({ url, initials, square }) {
    if (url) {
        const imageUrl = IMAGE_URL + url;
        return (
            <Avatar src={imageUrl} variant={square ? "square" : ""} style={{ height: XS_AVATAR_SIZE, width: XS_AVATAR_SIZE }} >{initials}</Avatar>
        )
    }
    else
        return (
            <Avatar className={"brand1-bg"} style={{ height: XS_AVATAR_SIZE, width: XS_AVATAR_SIZE }}>{initials}</Avatar>
        )
}

export function LyqnessIcon() {
    let imageSrc = IMAGE_URL + xsAvatarUrl("assets", "avatarlight.png");
    return (
        <Avatar src={imageSrc} style={{ height: ICON_SIZE, width: ICON_SIZE }} ></Avatar>
    )
}

export const avatarLight = avatarUrl("assets", "avatarlight.png");
export const avatarDark = avatarUrl("assets", "avatardark.png");
export const avatarOnWhite = avatarUrl("assets", "avataronwhite.jpg");

