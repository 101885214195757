import { API, DataStore, graphqlOperation } from "aws-amplify";
import { Question, UserFeed, Profile } from "../models/index";
import { timeNow } from "../constants";
import * as mutations from '../graphql/mutations';
import * as lyqnessMutations from '../graphql/lyqnessMutations';

export async function addResponse(payload) {
    const { profileId, ...responseInput } = payload;
    try {
        let input = { input: { ...responseInput } };
        const gql = await API.graphql(graphqlOperation(lyqnessMutations.respondToQuestion, input));
        let question = gql.data.respondToQuestion;
        return structuredClone(question);
    }
    catch (error) {
        console.log(error);
    }
}

export async function addProfile(payload) {
    console.log("creating profile " + Date.now())
    const { profileId } = payload;
    let qry = await DataStore.query(Profile, profileId);
    if (!qry) {
        try {
            let newProfile = await DataStore.save(new Profile({ ...payload }));
            return newProfile;
        }
        catch (error) {
            console.log(error)
        }
    }
    else
        return qry;
}

export async function createQuestionShare(payload) {
    const { questionId, shareAction, viewProfileId, users, choiceOrder, relatedId } = payload;
    let input = { input: {...payload} };
    try {
        const gql = await API.graphql(graphqlOperation(lyqnessMutations.shareQuestion, input));
        let interaction = gql.data.processQuestionInteraction;
        return interaction;
    }
    catch (error) {
        console.log(error);
        return;
    }
}

export async function processSharedQuestion(payload) {
    const { questionId, profileId, shareInfo, viewProfileId} = payload;
    let input = { input: {...payload}};
    try {
        const gql = await API.graphql(graphqlOperation(mutations.processSharedQuestion, input));
        let response = gql.data.processSharedQuestion;
        return response;
    }
    catch (error) {
        console.log(error);
        return;
    }
}

export async function processQuestionInteraction(payload) {
    const { action, questionId, state, viewProfileId, tags } = payload;
    let input = { input: { action: action, questionId: questionId, state: state, tags: tags, viewProfileId: viewProfileId } };
    try {
        const gql = await API.graphql(graphqlOperation(lyqnessMutations.processQuestionInteraction, input));
        let question = gql.data.processQuestionInteraction;
        return question;
    }
    catch (error) {
        console.log(error);
        return;
    }
}

export async function processProfileInteraction(payload) {
    const { action, associatedId, state, relatedId } = payload;
    let input = { input: { action: action, associatedId: associatedId, state: state, relatedId: relatedId } };
    try {
        const gql = await API.graphql(graphqlOperation(lyqnessMutations.processProfileInteraction, input));
        let profileFeed = gql.data.processProfileInteraction;
        return profileFeed;
    }
    catch (error) {
        console.log(error);
        return;
    }
}

export async function deleteQuestion(payload) {
    const { questionId, _version } = payload;
    try {
        let input = { input: { questionId: questionId, _version: _version } };
        const gql = await API.graphql(graphqlOperation(lyqnessMutations.deleteQuestion, input));
        let question = gql.data.deleteQuestion;
        return question;
    }
    catch (error) {
        console.log(error);
        return;
    }
};

export async function deleteUserFeed(payload) {
    const { profileId, questionId, _version } = payload;
    try {
        let input = { input: { profileId: profileId, questionId: questionId, _version: _version } };
        const gql = await API.graphql(graphqlOperation(mutations.deleteUserFeed, input));
        let userFeed = gql.data.deleteUserFeed;
        return userFeed;
    }
    catch (error) {
        console.log(error);
        return;
    }
};

export async function updateQuestion(payload) {
    try {

        const { questionId } = payload;

        let original = await DataStore.query(Question, questionId);
        let copy = Question.copyOf(original, updated => {
            updated.imageKey = payload.imageKey;
            updated.imageURL = payload.imageURL;
            updated.startTime = payload.startTime;
            updated.endTime = payload.endTime;
            updated.choiceHeadings = payload.choiceHeadings;
            updated.howOften = payload.howOften;
            updated.howLong = payload.howLong;
            updated.isMultipleChoice = payload.isMultipleChoice;
            updated.buckets = payload.buckets;
            updated.landscapeImages = payload.landscapeImages;
            updated.minSel = payload.minSel;
            updated.maxSel = payload.maxSel;
            updated.maxRange1 = payload.maxRange1;
            updated.questionText = payload.questionText;
            updated.maxRange2 = payload.maxRange2;
            updated.minRange1 = payload.minRange1;
            updated.minRange2 = payload.minRange2;
            updated.choices = payload.choices;
            updated.choiceStats = payload.choiceStats;
            updated.tags = payload.tags;
            updated.updatedAt = timeNow();
            updated.isOrdered = payload.isOrdered;
            updated.questionText = payload.questionText;
        });
        copy = structuredClone(copy);
        delete copy._deleted;
        delete copy.creator;
        delete copy._lastChangedAt;
        delete copy.createdAt;
        let input = { input: { ...copy } };

        const gql = await API.graphql(graphqlOperation(mutations.updateQuestion, input));
        let question = gql.data.updateQuestion;
        let userFeed = await DataStore.query(UserFeed, { profileId: question.creatorId, questionId: questionId });
        question = { ...question, userFeed: { ...userFeed } }
        return {question:question};
    }
    catch (error) {       
        console.log(error)
    }
}

export async function addQuestion(payload) {
    try {
        let input = { input: { ...payload, targetId: payload.creatorId } };
        const gql = await API.graphql(graphqlOperation(lyqnessMutations.createQuestion, input));
        let question = gql.data.createQuestion;
        return structuredClone(question);
    }
    catch (error) {
        console.log(error)
    }
}

export function getInteractionId(action, associatedId, relatedId) {

    try {
        let targetId = relatedId ? `${action}${associatedId}${relatedId ?? null}` : `${action}${associatedId}`;
        return targetId;
    }
    catch (error) {
    }
}

export async function updateUserTags(payload) {
    const { profileId, questionId, tags } = payload;
    const now = timeNow();
    try {
        const original = await DataStore.query(UserFeed, { profileId: profileId, questionId: questionId });
        const userfeed = await DataStore.save(
            UserFeed.copyOf(original, updated => {
                updated.tags = tags;
                updated.updatedAt = now;
            }))
        return userfeed;
    }
    catch (err) {
        console.log(err)
    }
}
