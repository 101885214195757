import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DataStore } from '@aws-amplify/datastore';
import { UserFeed } from '../../../models'; // Adjust import path accordingly
import Similarity from './MOS/Similarity'; // Adjust import path accordingly
import notify from '../../notify/Notify';
import NotifyAlert from '../../notify/NotifyAlert';

const options = {
  submit: { text: "submit", message: "" },
  publish: { text: "publish", message: "" },
  previous: { text: "previous", message: "You must respond to the previous question first." },
  similarity: { text: "similarity", message: "" },
  pending: { text: "pending", message: "Question is scheduled to be published." },
  related: { text: "related", message: "This is a related question. You must first answer the previous question." },
  viewing: { text: "viewing", message: "This question is not applicable based on your previous response" },
  select: { text: "select", message: "Select required choices and press submit" },
  expired: { text: "expired", message: "This question has expired." },
  statistics: { text: "statistics", message: "Premium users can only observe responses" },
};

const ResultsActionButton = ({ question,  }) => {

  // Get user profile from Redux store
  const profile = useSelector((state) => state.profile.currentUser);
  const isPremiumUser = useSelector((state) => state.profile.isPremiumUser || state.profile.isAdministrator);
  const { profileId } = profile;

  // Extract question details
  const { qmosr, userFeed:{stats}, endTime, isPremium: isPremiumQuestion, creatorId } = question;
  const userResponded = stats?.RESQ > 0;
  const tooLate = new Date().toISOString() > endTime;
  // Compute conditions
  const isOwner = profileId === creatorId;


  if(isPremiumUser && isOwner)
    return (
      <button className="btn passiveButton btn-sm roundedBorder w-100 m-0 pointer py-0" onClick={() => notify("question statistics will be shown")}>
        Statistics
      </button>
    );

    if(!userResponded && tooLate)
      return (
        <button className="btn passiveButton btn-sm roundedBorder w-100 m-0 pointer py-0" onClick={() => notify("time has expired to respond to this question")}>
          expired
        </button>
      );

        return (
          <div className="w-100 px-2">
            <Similarity value={qmosr} />
          </div>
        );


};

ResultsActionButton.propTypes = {
  question: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default ResultsActionButton;

