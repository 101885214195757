import { API, DataStore,  graphqlOperation } from "aws-amplify";
import {Interaction, Profile, ProfileFeed} from "../models/index";
import * as queries from "../graphql/lyqnessQueries";
import { ObjectType } from "../redux/constants";
import store from "../redux/store";

export async function queryLocalProfilesByInteraction(payload) {
    const {  targetId, feedId} = payload;
    let category = "USER";
    const action = feedId.substring(1,5);
    try {
        let items=[];
        let data= await DataStore.query(Interaction, (p) => p.action.eq(action))
        for (let i = 0; i < data.length; i++) {
            const { profileId, associatedId } = data[i];
            let displayProfile = await interactionToDisplayProfile({ profileId: profileId, associatedId: associatedId, targetId:targetId });
           items.push(displayProfile)
        }
        return {feedId:feedId, nextToken:null, items:items, isFirstFeed:true, objectType:category}; 
    }
    catch (error) {
        console.log(error)
        return null;
    }
}

export async function queryLocalProfiles(payload) {
    const { targetId, feedId } = payload;
    let category = ObjectType.USER;
    try {
        let items=[];
        let data= await DataStore.query(Profile, (p) => p.category.eq(category))
        for (let i = 0; i < data.length; i++) {
            const { profileId} = data[i];
            let displayProfile = structuredClone(data[i]);
            let profile= await DataStore.query(Profile,profileId)
            displayProfile.creator=profile;
            items.push(displayProfile)
        }
        return {feedId:feedId, nextToken:null, items:items, targetId:targetId, isFirstFeed:true, objectType:category};        
    }

    catch (error) {
        console.log(error)
    }
}

export async function getLocalProfiles(ProfileListModal) {
    const userId = store.getState().profile.currentUser.profileId;
    try {
        let profiles = await DataStore.query(Profile, (p)  => 
            p.and(c => [
                p.profileId.ne(userId),
                c.category.eq("User")
              ]));
        profiles = structuredClone(profiles);
        for (let i = 0; i < profiles.length; i++) {
            const profileFeed = await DataStore.query(ProfileFeed,{ profileId: userId, associatedId: profiles[i].profileId })
            profiles[i].myProfileFeed = profileFeed;
        }
        return {profiles};        
    }

    catch (error) {
        console.log(error)
    }
}

export async function queryUsersByQuestionChoice(payload) {
    const { targetId, feedId, choice, order, nextToken } = payload;
    const choiceStr = choice.toString();
    let choiceOrder=choiceStr.padStart(3,'0');
    try {
        if(order!==null )
        {
          let orderStr=order.toString();
          orderStr = orderStr.padStart(3,'0')
          choiceOrder= `${choiceOrder}${orderStr}`
        }
        const response = await API.graphql(
          graphqlOperation(queries.listProfilesByChoiceOrder, { targetId: targetId,feedId: feedId, choiceOrderStr: choiceOrder, nextToken: nextToken })
        );
        let result = response.data.listProfilesByChoiceOrder;
        result.objectType=ObjectType.USER;
        result.isFirstFeed = result.nextToken?false:true;
        result.feedId=feedId;
        result.targetId=targetId;
        result.choiceOrder=choiceOrder;
        return result;      
    }
    catch (error) {
        console.log(error)
    }
}

export async function listProfilesByInteraction(payload) {
    const { targetId, feedId, nextToken } = payload;
    try {
        const response = await API.graphql(
            graphqlOperation(queries.listProfilesByInteraction, {  feedId: feedId, targetId: targetId, nextToken: nextToken })
          );
          let result = response.data.listProfilesByInteraction;
          result.isFirstFeed=nextToken===null;
          result.feedId=feedId;
          result.targetId=targetId;
          return result;       
    }

    catch (error) {
        console.log(error)
    }
}

export async function interactionToDisplayProfile({profileId,associatedId, targetId})
{
    let profile= await DataStore.query(Profile,associatedId);
    let displayProfile = structuredClone(profile);
    //displayProfile.creator = await DataStore.query(Profile,displayProfile.creatorId);
    const profileFeed = structuredClone(await profile.profileFeed);
    displayProfile.profileFeed=profileFeed;
    return displayProfile;
}

export async function listUserProfiles(payload) {
    const {feedId, targetId, nextToken } = payload;
    try {
        const response = await API.graphql(
            graphqlOperation(queries.listProfiles, {  filter: {category: {eq: "User"}}, nextToken: nextToken })
          );
          let result = response.data.listProfiles; 
          result.feedId=payload.feedId;
          result.targetId=payload.targetId;
          return  result;     
    }

    catch (error) {
        console.log(error)
    }
}

