import React, { useState } from "react";
import { connect } from "react-redux";
import {Icons, activeColor, disabledColor} from "../../shared/QIcons";
import "../../shared/index.css";
import { newId } from "../../shared/utilities";
import { clearChatImage, createMessage, joinConversation, sendMessageOptimistic, startQuestionConversation} from "../../redux/actions/chat-actions";
import imageType from "../../constants/imageType";
import ChatImageUpload from "./ChatImageUpload";
import { processImageActions } from "../../redux/sagas/image-sagas";


const mapStateToProps = (state, ownProps) => {
  return { 
          panel:state.context.panel,
          imagePreviewUrl:state.chat.imagePreviewUrl,
          image:state.chat.image,
          currentUserId:state.profile.currentUser.profileId,
          viewProfileId:state.profile.viewProfile.profileId  
    }
};

const ChatFooter = React.memo(function ChatFooter({ panel, imagePreviewUrl,image,viewProfileId, currentUserId, dispatch }) {
  const {conversationId,conversation,chatType,chatProfile} = panel;  
  const [content, setcontent] = useState("");
  const canSubmit=(content!=="" || imagePreviewUrl);

  function handleMessageSend(message) {
    if(!conversation){
        message.isFirst=true;
        dispatch(startQuestionConversation({
                message:message , 
                start:{conversationId: conversationId, chatType: chatType,nextToken:null},
                chatProfile:chatProfile
            }));
    }
    else
        if(conversation && !conversation.userConversation)
        {
            message.isFirst=true;
            dispatch(joinConversation({
                message:message , 
                start:{conversationId: conversationId, chatType: chatType,nextToken:null},
                chatProfile:chatProfile
            }));
        }
    else
       dispatch(createMessage(message))
  }

     function sendOptimistic(message){
      dispatch(clearChatImage());
      const optimisticMessage={...message.input,        
        sender:currentUserId,
        isSent:false,
        fileKey:imagePreviewUrl,
        author:{profileId:currentUserId,lastName:'',firstName:'',identityId:''}}
      dispatch(sendMessageOptimistic(optimisticMessage));
    }

    function handleOnKeyDown(e){
      if (e.key === 'Enter')
      {
        handleSubmit(e);
      }
    }

      async function handleSubmit (e) {
        e.preventDefault()
        if (content.trim().length === 0 && imagePreviewUrl===null) {
          return
        }
        let imageActions=[];
        let newKey=imagePreviewUrl?newId():null;
        let messageId=newId();

        if(newKey){
           imageActions=[...imageActions,{action:"add",imageKey:newKey,image:image,profileId:currentUserId,messageId:messageId}];
           await processImageActions(imageActions)
        }
           let message={input:{
             viewProfileId: viewProfileId,
             messageId:messageId,
             content:content.trim(),
             conversationId: conversationId,
             fileKey:newKey
           }}
        handleMessageSend(message);
        sendOptimistic(message);
        setcontent('');

      }

    return (
      <div className="row text-center no-gutters p-0">
          <div className="col pt-1 pr-1 col-1">
              <ChatImageUpload imgType={imageType.Discussion}>
              <i className={Icons.CAMERA + " iconSize pointer til1"}></i>
            </ChatImageUpload>
          </div>     
          <div className="col m-0 p-0 col-10">
              <textarea
                  className="form-control smedit w-100"
                  onChange={(e)=>setcontent(e.target.value)}
                  onKeyDown={(e)=>handleOnKeyDown(e)}
                  value={content}
              />
          </div>
          <div className="col t-1 pl-1 col-1">               
          {
              canSubmit?<i className={Icons.SEND.concat(" iconSize ",activeColor ," pointer ") } onClick={(e)=>handleSubmit(e)}></i>
              : <i className={Icons.SEND.concat(" iconSize ",disabledColor)}></i>
          }                        
          </div>
      </div>
    )
})

export default connect(mapStateToProps,null)(ChatFooter);