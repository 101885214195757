import React from "react";
import { Icons } from "../../../shared/QIcons";
import MiddleThing from "./MiddleThing";
import { responseMsg } from "../../../constants/question";
import notify from "../../notify/Notify";
import { questionInstructions } from "../constants";
import RespondActionButton from "./RespondActionButton";

const RespondSummary = React.memo(function RespondSummary({ handleSubmit, handleReset, instructions, displayQuestion,  canReset, canSubmit }) {
    const { stats: { RESQ },isPremium: isPremiumQuestion, draft} = displayQuestion;

    function respondedText() {
        if (RESQ === 0 && draft && !isPremiumQuestion)
            return (
                <>
                    <span className="float-end inactiveColor pr-2">
                        <small>draft</small>
                    </span>
                </>)
        else
            return (
                <>
                    <span className="float-left grey-text p-0 ">
                    </span>
                    <span className="float-end inactiveColor pr-2 " onClick={() => notify(responseMsg)}>
                        {RESQ}<span className="respondLabel">{RESQ === 1 ? " user" : " users"}</span>
                    </span>
                </>
            )
    }

    if (displayQuestion)
        return (
            <div>
                <div className="smallfont brand0 mb-1">{questionInstructions(displayQuestion)}</div>
                <div>
                    <MiddleThing
                        left={<span className=" float-left pt-2"></span>}
                        center={<RespondActionButton handleSubmit={handleSubmit} question={displayQuestion} handleReset={handleReset} canSubmit={canSubmit} />}
                        right={<div className="text-start pt-2" >
                            <span className="ps-2">{canReset && <i className={"pointer activeColor " + Icons.RESET} onClick={() => handleReset()}></i>}</span>
                            <span className="text-end pt-2">{respondedText()}</span>
                        </div>}
                    />
                </div>
            </div>
        );
    else
        return <span />
})

export default RespondSummary;

