import React, {  useState } from "react";
import LotteryOdds from "./LotteryOdds";
import LotteryBallView from "./LotteryBallView";
import { connect } from "react-redux";
import { Icons } from "../../../shared/QIcons";
import PropTypes from "prop-types";
import RespondSummary from "../core/RespondSummary";
import { respondToQuestion } from "../../../redux/actions/question-actions";
import ResultsSummary from "../core/ResultsSummary";
import QuestionUserCount from "../core/Choices/QuestionUserCount";

function mapStateToProps(state) {
    return {
        viewProfileId: state.context.view.profileId,
    };
}

const RespondToLotteryQuestion = ({ displayQuestion, viewProfileId, dispatch }) => {
    const { minSel, questionId, endTime, buckets, createdAt, stats:{RESQ}, choiceStats, userFeed:{selections} } = displayQuestion;
    const [checkedItems, setCheckedItems] = useState([]);
    const [canSubmit, setCanSubmit] = useState(false);

    
    const handleBallClick = (item) => {
        let newArray;
        if (checkedItems.includes(item)) {
            newArray = checkedItems.filter((checkedItem) => checkedItem !== item);
        } else {
            if (checkedItems.length < minSel) {
                newArray = [...checkedItems, item];
            } else {
                newArray = checkedItems;
            }
        }
    
        setCheckedItems(newArray);
        setCanSubmit(newArray.length === minSel);
    };
 
    const handleReset = () => {
        setCheckedItems([]);
        setCanSubmit(false);
      }

        const handleSubmit = () => {
          var data = {
            "displayQuestion": displayQuestion,
            "viewProfileId": viewProfileId,
            "selections": checkedItems,
          };
          dispatch(respondToQuestion({ ...data }))
        }

    const checkedAction = (i) => {
        if (checkedItems.includes(i)) {
            return <span><i className={Icons.CHECKBOX_ACTIVE + " fa-lg"}></i></span>;
        }
        return null;
    };

    const resultAction = (i) => {
            return <span><QuestionUserCount choiceId={i}  question={displayQuestion} /></span>;
        }


if(!RESQ)
    return (
        <div className="container mt-4 px-2 text-center">
            <LotteryBallView buckets={buckets} handleBallClick={handleBallClick} checkedAction={checkedAction} />
            <LotteryOdds buckets={buckets + 1} minSel={minSel}endTime={endTime}/>
            <RespondSummary handleSubmit={handleSubmit} handleReset={handleReset} displayQuestion={displayQuestion} canSubmit={canSubmit} canReset={checkedItems.length > 0} />
        </div >
    );
    else 
    return (
        <div className="container mt-4 px-2 text-center">
        <LotteryBallView buckets={buckets} checkedAction={resultAction}/>
        <LotteryOdds buckets={buckets + 1} minSel={minSel} endTime={endTime}/>
        <ResultsSummary key={questionId + "10"} displayQuestion={displayQuestion} />
    </div >
    )
};

export default connect(mapStateToProps, null)(RespondToLotteryQuestion);

