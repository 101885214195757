import React from "react";
import { connect } from "react-redux";
import { Feeds } from "../../constants/feedids";
import { setPanelView } from "../../redux/actions/context-actions";
import { Icons } from "../../shared/QIcons";
import { SystemId } from "../../constants/profile";
import { PanelType } from "../../constants/panel";
import { setPanelOpen } from "../../redux/actions/panel-actions";
import ShowMdDn from "../../shared/ShowMdDn";
import ShowLgUp from "../../shared/ShowLgUp";


const mapStateToProps = (state) => {
   return {
      panel: state.context.panel,
   };
};


const UsersSelector = React.memo(function UsersSelector({ panel, dispatch }) {
   const { panelType, feedId } = panel;
   const isSelected = panelType === PanelType.Users && feedId === Feeds.AllUsers;


   function loadFeed() {
      if(isSelected)
         toggle();
      else
         dispatch(setPanelView({ panelType: PanelType.Users, feedId: Feeds.AllUsers, targetId: SystemId }));
   }

   const toggle = (shouldOpen) => {
      dispatch(setPanelOpen({isOpen:true}));
  };

   return (
      <div className=" text-center pointer activeColor" onClick={loadFeed}>
         <ShowLgUp><i className={Icons.USERS + "fa-lg"}></i><span className="ml-1 pt-1">Users</span></ShowLgUp>
         <ShowMdDn><i className={Icons.USERS}></i><div className="col-12 footerlabel">Users</div></ShowMdDn>
      </div>
   )
})

export default connect(mapStateToProps, null)(UsersSelector);