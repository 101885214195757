import { ItemFeed } from "../../constants/feedData";
import { ChatActions as Actions} from "../constants"; 

const initialState = {
    messages:ItemFeed,
    conversations:ItemFeed,
    userConversations:{...ItemFeed, isVisible:false},
    questionConversations:ItemFeed,
    ProfileId:null,
    content:"",
    image:{},
    imagePreviewUrl:null,
    conversationId:null,
    viewConversationId: null,
    viewSubscription: null,
    subscription: null
};

function getIndex(items){
    return items.reduce(function (map, obj, i) {
        map[obj.messageId] = i;
        return map;
      }, {});
}


function chatReducers(state = initialState, action) {

    function isInConvoIndex(id){
        return typeof state.userConversations.index[id] !== 'undefined';  
      } 

    function isInIndex(id){
        return typeof state.messages.index[id] !== 'undefined';  
      }  

    switch (action.type)
    {
        case Actions.USER_CONVERSATIONS_REQUESTED:
            return {
                ...state,...action.payload, 
                isLoading:true,
                isFirstFeed:false
            }
        case Actions.CONVERSATION_LAST_VIEW_TIME_UPDATED: 
        {
            const { conversationId, updatedAt } = action.payload;
            if(isInConvoIndex(conversationId))
            {   
                let newItems=[...state.userConversations.items];
                if(newItems.length!==0)
                    {                 
                        const idx = state.userConversations.index[conversationId];
                        newItems[idx].conversation.userConversation.updatedAt=updatedAt;  
                    }
                    
                    return { ...state, userConversations:
                                {...state.userConversations, items:newItems
                                }
                        }
            }        
            else
                return state;
        }          
        case Actions.USER_CONVERSATIONS_RECEIVED:
        
            {   const {items, feedId, isFirstFeed,nextToken} = action.payload;
                let newItems=[];
                if(isFirstFeed)
                    newItems=items;
                else
                    newItems=[...state.userConversations.items, ...items];
                return { ...state, 
                            userConversations:{...state.userConversations,
                                items: newItems,
                                feedId:feedId,
                                isLoading:false,
                                isLoaded:true,
                                isFirstFeed:isFirstFeed ,
                                index: newItems.reduce(function (map, obj, i) {
                                    map[obj.conversation.conversationId] = i;
                                    return map;
                                }, {}),
                                nextToken:nextToken,
                        }
                }
            }             
        case Actions.START_CONVERSATION:
            {
                return {...state, messages:{...state.messages, ...action.payload.start,
                        isLoading:true,items:[],
                        index:[]},
                        chatProfile:action.payload.chatProfile
                    }
                }            
        case Actions.CONVERSATION_STARTED:
            if(action.payload.chatType==="CHAU")
              {
                  let newProfile={...state.chatProfile,conversation:action.payload};
                  let newItems=[newProfile,...state.userConversations.items];
                  let newIndex=newItems.reduce(function (map, obj, i) {
                    map[obj.conversation.conversationId] = i;
                    return map;
                    }, {});
                      return {
                        ...state,
                        chatProfile:null,
                        userConversations: {...state.userConversations, items:newItems,index:newIndex},
                        conversationId:action.payload.conversationId                       
                      }
              } 
              else
                return state;
        case Actions.QUESTION_DELETED:
            return {
    
              ...state,
              currentUser: {
                ...state.currentUser,
                stats: action.payload.userStats
             }
            }

        case "SIGN_OUT":
            return {...initialState};

        case Actions.STAGE_CHAT_IMAGE:
            return {...state, image:action.uri }

        case Actions.SET_CHAT_PREVIEW_URL:
                return {...state, imagePreviewUrl:action.payload.previewUrl }

        case Actions.CLEAR_CHAT_IMAGE:
                return {...state, image:{}, imagePreviewUrl:null }      


        case Actions.SET_SUBSCRIPTION:
            return {...state,...action.payload}
        
            case Actions.SET_MESSAGE_SUBSCRIPTION:
                return {...state, ...action.payload}

        case Actions.CONVERSATION_MESSAGE_RECEIVED:
        case Actions.OPTIMISTIC_MESSAGE_CREATED:
        case Actions.MESSAGE_CREATED:
            {
            let message=action.payload;
            const { conversationId, sender, createdAt } = action.payload;
            let isUserMsg=conversationId.includes(sender) && action.type===Actions.MESSAGE_CREATED;
            if(state.messages.conversationId === message.conversationId)
            {
                let newConversations=[...state.userConversations.items];
                if(isUserMsg){
                    newConversations[state.userConversations.index[conversationId]].conversation.updatedAt=createdAt;
                    newConversations[state.userConversations.index[conversationId]].conversation.userConversation.updatedAt=createdAt;
                }
                let newMessages = [...state.messages.items];
                if (isInIndex(message.messageId)) 
                    newMessages[state.messages.index[message.messageId]] = message;  
                else
                    newMessages=[...newMessages, message]; 
                return {
                    ...state,
                    userConversations:{...state.userConversations, items: newConversations},
                    messages: {...state.messages,
                        items: [...newMessages],
                        index: getIndex(newMessages),
                    } 
                }                                       
            }
                else
                    return state;
        }

        case Actions.USER_MESSAGE_RECEIVED:

            {
                let message=action.payload;
                const { author, conversationId, createdAt,nextToken } = action.payload;
                if(state.messages.conversationId === message.conversationId)
                {
                    let newConversations=[...state.userConversations.items];
                    newConversations[state.userConversations.index[conversationId]].conversation.userConversation.updatedAt=createdAt;

                    let newMessages = [...state.messages.items];
                    if (isInIndex(message.messageId)) 
                        newMessages[state.messages.index[message.messageId]] = message;  
                    else
                        newMessages=[...newMessages, message]; 
                    return {
                        ...state,
                        userConversations:{...state.userConversations, items: newConversations},
                        messages: {...state.messages,
                            items: [...newMessages],
                            index: getIndex(newMessages),
                            isLoaded:true,
                            isLoading:false,
                            nextToken:nextToken
                        } 
                    }                                       
                }
                    else
                        if(isInConvoIndex(conversationId))
                        {                            

                            let newConversations=[...state.userConversations.items];
                            newConversations[state.userConversations.index[conversationId]].conversation.updatedAt=createdAt;
                            return {
                                ...state,
                                userConversations:{...state.userConversations, 
                                    items: state.userConversations.items.map(el => el.conversation.conversationId === conversationId ?
                                        {...el, conversation:{...el.conversation, updatedAt:createdAt}} : el)
                                },
                            }
                        }
                        else
                        {
                            let newConversation={...author, 
                                conversation:{updatedAt:createdAt,conversationId:conversationId, 
                                    userConversation:{updatedAt:createdAt}}}
                            let newConversations=[newConversation,...state.userConversations.items];
                            return {
                                ...state,
                                userConversations:{...state.userConversations, 
                                    items:newConversations, 
                                    index: newConversations.reduce(function (map, obj, i) {
                                        map[obj.conversation.conversationId] = i;
                                        return map;
                                    }, {}),
                            } 
                        } 
                        }

            }

        case Actions.CLEAR_MESSAGES:
            return {...state, messages:ItemFeed} 
          

        case Actions.CONVERSATION_MESSAGES_REQUESTED:
            {
                if(action.payload.nextToken===null)
                    return {...state, messages:{...state.messages,
                        isLoading:true,items:[], index:[]}}
                else
                    return {...state, isLoading:true}
                    }
        default:
            return state;

    }
}

export default chatReducers