import { QuestionAskType } from "../constants/question";

export function ComputeCMOSR(questions) {

    let weightSum = 0.0;
    let qmosrWeightSum = 0.0;
    let respCount = 0;
    if (questions && questions.length > 0) {
        try {
            questions.forEach(question => {
                const { weight, qmosr, userFeed: { weight: userWeight, stats: { RESQ: responded } }, stats: { RESQ } } = question;
                if (responded > 0 && RESQ > 1) {
                    respCount++;
                    weightSum += weight;
                    qmosrWeightSum += weight * qmosr * userWeight;
                }
                else {
                    qmosrWeightSum += 0;
                }
            })
        }
        catch {
            console.log("compute cmosr error");
        }
    }
    let cmosr = respCount ? qmosrWeightSum / weightSum : 0.0;
    return cmosr;
}

export function ComputeCMOST(questions) {
    let weightSum = 0.0;
    let qmosWeightSum = 0.0;
    try {
        if (questions && questions.length > 0) {
            questions.forEach((question, index) => {
                let { questionType, weight,
                    userFeed: { selections, weight: userWeight, stats: { RESQ } },
                    viewUserFeed  } = question;
                if (viewUserFeed ) {
                    const { selections: viewSelections, stats: { RESQ: viewRESP }} = viewUserFeed;
                    if(RESQ > 0 && viewRESP > 0){
                    let qmost = computeQMOST(selections, viewSelections, questionType);
                    weightSum += weight;
                    qmosWeightSum += weight * qmost * userWeight;
                    }
                }
            })
        }
        else
            return 0.0;
        const cmost = qmosWeightSum / weightSum;
        return cmost;
    }
    catch (err) {
        console.log(err);
    }
}

export function addToCMOSR(currentValue, currentWeight, addValue, addWeight) {
    var newCmosr = {
        weight: currentWeight + addWeight,
        value: (currentValue * currentWeight + addValue * addWeight) / (currentWeight + addWeight)
    };
    return newCmosr;
}

export function subtractFromCMOSR(currentValue, currentWeight, subtractValue, subtractWeight) {
    var newCmosr = {
        weight: currentWeight + subtractWeight,
        value: (currentValue * currentWeight + subtractValue * subtractWeight) / (currentWeight + subtractWeight)
    };
    return newCmosr;
}

function countOrder(userSelections, targetSelections) {

    let cnt = 0;
    for (let i = 0; i < userSelections.length; i++) {
        if (userSelections[i] === targetSelections[i]) cnt++;
    }
    return cnt;
}
export function computeQMOST(userSelections, targetSelections, questionType, isOrdered) {
    switch (questionType) {
        case QuestionAskType.Select:
        case QuestionAskType.Rate:
            {
                let intersection = userSelections.filter(x => targetSelections.includes(x));
                return intersection.length / userSelections.length
            }
        case QuestionAskType.Rank:
        case QuestionAskType.SelectInOrder:
            {
                return countOrder(userSelections, targetSelections) / userSelections.length;
            }
        case QuestionAskType.Lottery:
            if (isOrdered)
                return countOrder(userSelections, targetSelections) / userSelections.length;
            else {
                let intersection = userSelections.filter(x => targetSelections.includes(x));
                return intersection.length / userSelections.length
            }
        case QuestionAskType.Linear:
        case QuestionAskType.Numeric:
        case QuestionAskType.Time:
        case QuestionAskType.Date:
            break;

        default:
            break;
    }
}
