import React, { useCallback } from "react";
import { connect } from "react-redux";
import { hideModal } from "../../redux/actions/modal-actions";
import { Modal, Box } from "@mui/material";
import "./index.css";
import { ModalClose, ModalDialog } from "@mui/joy";

const mapStateToProps = (state) => ({
    isOpen: state.modal.modalType === "CONFIRM_MODAL",
});

const ConfirmModal = ({ isOpen, content, title, handleConfirm, dispatch }) => {
    const confirm = useCallback(() => {
        handleConfirm();
        dispatch(hideModal());
    }, [handleConfirm, dispatch]);

    const toggle = useCallback(() => {
        dispatch(hideModal());
    }, [dispatch]);

    return (
        <Modal open={isOpen} onClose={toggle} >
            <ModalDialog
                className="w-100"
                // variant="outlined"
                // layout="center"
                sx={{
                    maxWidth: "360px",
                    // borderRadius: "lg",
                    // boxShadow: "lg",
                    p: 0,
                    // bgcolor: "background.surface",

                }}
            >
                
                <div>
                    <div className="row g-0 px-2 pt-2">
                        <div className="col-11 h5">{title ?? "Confirm"}</div>
                        <div className="col-1  text-end pointer" onClick={toggle}>
                            <i className="far fa-times fa-lg"></i>
                        </div>
                    </div>
                    <div className="p-2">{content}</div>
                    <div className="p-3 text-end">
                    <button className="btn btn-secondary btn-sm m-0 px-2 py-0" onClick={toggle}>
                            Cancel
                        </button>
                        <button className="btn btn-primary btn-sm m-0 mx-2 px-2 py-0" onClick={confirm}>
                            Confirm
                        </button>

                    </div>
                </div>
            </ModalDialog>
        </Modal>
    );
};

export default connect(mapStateToProps)(ConfirmModal);

