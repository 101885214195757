import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import { prettyNum } from "../../../shared/utilities";
import { Icons } from "../../../shared/QIcons";


const OrderedPopover = ({ choiceId, choiceStats, text, showChoiceUsers }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  const getChoices = () => {
    if (!choiceStats[choiceId]) return [];
    return choiceStats[choiceId].slice(0, 8); // Limit to 8 choices
  };

  const formatOrdinal = (number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = number % 100;
    return `${number}${suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]}`;
  };

  function showProfiles(orderId) {
    handleClose();
    showChoiceUsers({ choiceId: choiceId, orderId: orderId })
  }
  return (
    <div className="w-100">
      <div
        onClick={handleOpen}
      >
        {text}
      </div>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div>

          <div className="row g-1 pt-1 brand2-bg">
            <div className="p-1 activeColor pointer smallfont" onClick={() => handleClose()}><i className={Icons.TIMES + " fa-lg float-end activeColor"}></i></div>
            <div className="col-6 smallfont ps-1" ><strong>Choice</strong></div>
            <div className="col-6 smallfont pe-2 text-end" ><strong>Count</strong></div>
          </div>
          {getChoices().map((item, index) => (
            <div key={index} className={item.count ? "activeColor pointer row g-1" : "row g-1"} onClick={() => showProfiles(index)}>
              <div className="col-6 ps-2">{formatOrdinal(index + 1)}</div>
              <div className="col-6 pe-3 text-end">{item.count > 0 ? prettyNum(item.count) : "-"}</div>
            </div>
          ))}

        </div>
      </Popover>
    </div>
  );
};

export default OrderedPopover;
