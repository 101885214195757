
export function base64ToUuid(base64) {
    // Replace URL-safe characters with standard base64 characters
    const base64String = base64.replace(/-/g, '+').replace(/_/g, '/');
    
    // Decode base64 string to binary string
    const binaryString = atob(base64String);
    
    // Convert binary string to hexadecimal
    let hexString = '';
    for (let i = 0; i < binaryString.length; i++) {
        const hex = binaryString.charCodeAt(i).toString(16);
        hexString += hex.length === 1 ? '0' + hex : hex;
    }
    
    // Format hexadecimal string as UUID
    const uuid = [
        hexString.slice(0, 8),
        hexString.slice(8, 12),
        hexString.slice(12, 16),
        hexString.slice(16, 20),
        hexString.slice(20, 32)
    ].join('-');

    return uuid;
}


export function uuidToBase64(uuid) {
    // Remove dashes from UUID
    const cleanedUuid = uuid.replace(/-/g, '');

    // Convert UUID to binary data
    const binaryData = [];
    for (let i = 0; i < cleanedUuid.length; i += 2) {
        binaryData.push(parseInt(cleanedUuid.substr(i, 2), 16));
    }

    // Convert binary data to Base64
    const base64String = btoa(String.fromCharCode.apply(null, binaryData))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');

    return base64String;
}