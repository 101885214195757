import React from "react";
import { connect } from "react-redux";
import "./index.css";
import { updateProfileData } from "../redux/actions/profile-actions";
import { useFormFields } from "../shared/FormFields";
import { imageFromS3Key, s3ImageKey} from "../components/question/Utilities";
import { newId } from "../shared/utilities";
import { Avatar, TextField } from "@mui/material";
import { setView } from "../redux/actions/context-actions";
import { UserFeeds } from "../constants/feedids";
import ImageEditMenu from "../components/menus/ImageEditMenu";
import { AVATAR_SIZE } from "../constants/image";
import { ViewType } from "../constants/profile";

const mapStateToProps = (state) => {
    return {
        currentFeedId: state.feed.currentFeedId,
        profile: state.profile.currentUser,
        view: state.context.view,
    };
};
const UserProfileEdit = React.memo(function UserProfileEdit({ profile, dispatch}) {
    const {identityId,avatarKey,avatarURL} = profile;
    const [fields, handleFieldChange] = useFormFields({
        firstName: profile.firstName,
        lastName: profile.lastName,
        userName: profile.userName,
        title: profile.title,
        description: profile.description ? profile.description : "",
    });

    let fileInput = React.createRef();

    const avatarImgUrl = profile.avatarKey
        ? avatarURL
        : null;

    const [previewUrl, setPreviewUrl] = React.useState(imageFromS3Key({imageURL:avatarImgUrl}))
    const [newImage, setNewImage] = React.useState(null)

    // const notUniqueError = profile.isNotUnique ? true : false;
    const [avatarChanged, setAvatarChanged] = React.useState(false);
    const userName = profile.userName ?? "";

    const handleEdit = () => {
        fileInput.current.click();
    };

    const handleAvatarChange = e => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            //  scaleImage(file);
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
            setNewImage(file);
        }
        setAvatarChanged(true);
    };

    const handleAvatarRemove = () => {
        setPreviewUrl(null);
        setAvatarChanged(profile.avatarKey ? true : false);
    };

    function ShowImage() {
                return (
                    <div  >
                        <Avatar src={previewUrl} className="mx-auto" style={{ height: AVATAR_SIZE, width: AVATAR_SIZE }}>you</Avatar>
                    </div>
                )
        }

    const hasChanged =
        fields.firstName !== profile.firstName ||
        fields.lastName !== profile.lastName ||
        fields.title !== profile.title ||
        fields.description !== profile.description ||
        avatarChanged;

    const backToFeed = () => {
        const { profileId } = profile;
        //navigate("/feed");
        dispatch(
            setView({
                viewType: ViewType.UserView,
                profileId: profileId,
                targetId: profileId,
                feedId: UserFeeds.UserFeedQuestions,
            })
        );
    };

    async function handleSave(event) {
        const { avatarKey: oldAvatarKey } = profile;

        let imageKey = avatarChanged ? (newImage ? newId() : null) : oldAvatarKey;
        let imageActions = [];

        if (avatarChanged && oldAvatarKey) {
            imageActions.push({
                action: "delete",
                imageKey: oldAvatarKey,
                image: null,
            });
        }

        if (avatarChanged && newImage)
            imageActions.push({
                action: "add",
                imageKey: imageKey,
                image: newImage,
                profileId: profile.profileId,
                creatorId: profile.profileId,
            });
        
        let avatarURL, profileURL;
        if(avatarChanged)
            if(imageKey){
            avatarURL = s3ImageKey({identityId, imageKey, height:AVATAR_SIZE, width:AVATAR_SIZE});
            profileURL = s3ImageKey({identityId, imageKey, height:AVATAR_SIZE, width:AVATAR_SIZE});
            }
            else
            {
                avatarURL=null;
                profileURL = null;
            }


        let data = {
            lastName: fields.lastName,
            firstName: fields.firstName,
            avatarURL: avatarURL,
            profileURL: profileURL,
            title: fields.title,
            profileId: profile.profileId,
            userName: userName,
            description: fields.description,
            avatarKey: avatarKey,
        };
        let updateData = {
            data: data,
            imageActions: imageActions,
        };
        dispatch(updateProfileData(updateData));
        setAvatarChanged(false);
    }

    if (profile) {


        return (
            <div className="container p-0 white h-100">
                 <input type="file" hidden accept='image/*,video/*' id="test" name="test" onChange={handleAvatarChange} ref={fileInput} />
                <div className=" brand3-bg py-2 text-center px-0 brand1-bg">
                    <span className="h4 brand1">Edit Your Profile</span>
                </div>
                <div className="text-center">
                    <div key="viewProfileId" className="container p-3">
                        <div>
                            <div className="col text-center">
                                <h5>You</h5>
                                    <ImageEditMenu
                                        handleEdit={handleEdit}
                                        handleDelete={handleAvatarRemove}
                                    >
                                        <ShowImage />
                                    </ImageEditMenu>
                            </div>
                        </div>
                        <div className="row no-gutters mt-3">
                            <div className="col mr-2">
                                <TextField
                                    id="firstName"
                                    margin="dense"
                                    size="small"
                                    className="myform"
                                    label="First name"
                                    defaultValue={fields.firstName}
                                    helperText="Required"
                                    onChange={handleFieldChange}
                                />
                            </div>
                            <div className="col">
                                <TextField
                                    id="lastName"
                                    margin="dense"
                                    size="small"
                                    className="myform"
                                    label="Last name"
                                    defaultValue={fields.lastName}
                                    helperText="Required"
                                    onChange={handleFieldChange}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <TextField
                                    id="title"
                                    margin="dense"
                                    size="small"
                                    className="myform w-100"
                                    label="Who or what are you?"
                                    defaultValue={fields.title ?? ""}
                                    //helperText="Required"
                                    onChange={handleFieldChange}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <TextField
                                    id="description"
                                    margin="dense"
                                    size="small"
                                    className="myform w-100"
                                    multiline
                                    rows={2}
                                    label="Say something about youself"
                                    defaultValue={fields.description ?? ""}
                                    onChange={handleFieldChange}
                                />
                            </div>
                        </div>
                        <div className="row justify-content-center mt-3">
                            {hasChanged ? (
                                <button className="btn btn-primary btn-sm m-0 px-2 py-0" onClick={() => handleSave()} >Save</button>
                            ) : (
                                <button className="btn inactiveBackgroundColor btn-sm m-0 px-2 py-0" disabled>Save</button>
                            )}
                            <button
                                className="btn btn-sm btn-primary px-2 py-0 white-text mx-2"
                                onClick={() => backToFeed()}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else return <span />;
});

export default connect(mapStateToProps, null)(UserProfileEdit);
