import React from "react";


import {connect} from "react-redux";


import QCheckbox from "../../../../../shared/QCheckbox";
import LyqPopover from "../../../core/LyqPopOver";
import { updateEditQuestion } from "../../../../../redux/actions/questionedit-actions";

function mapStateToProps(state) {
    return {
      question: state.questionedit.question,

    };
  };

const IsOrderedCheckbox = React.memo(function IsOrderedCheckBox({ question, dispatch }) {
    const {isOrdered, minSel, maxSel, buckets} = question;
    const msg = () => {
        return (<span>When this box is checked the order of the user's choices will matter when computing similarity.
        </span>
        )
    }
    let input={isOrdered:!isOrdered};
    if(maxSel===buckets && isOrdered)
        input.maxSel=maxSel-1;
    return (
            <div className="clearfix">
                <span className="float-start">Order matters?
                    <LyqPopover title={"Multiple Selections"} content={msg()}>test</LyqPopover>
                </span>
                 <span className="float-end "> 
                     <QCheckbox disabled = {minSel===1||maxSel===buckets} checked={isOrdered} toggle={() => dispatch(updateEditQuestion({question:{...input}}))} /> 
                </span> 
            </div>

    );
})

export default connect(mapStateToProps,null)( IsOrderedCheckbox);