import { QuestionAskType } from "../constants/question";
import { UserFeedStats} from "../redux/constants";
export const questionActions =[
    'CHAQ', 
    'CREA', 
    'CREI', 
    'CREQ', 
    'HCRQ', 
    'HLNQ', 
    'HREQ', 
    'HLKQ', 
    'FEAQ', 
    'FLAQ', 
    'INCA', 
    'INCQ', 
    'INCR', 
    'LIKQ', 
    'LINQ', 
    'MODQ', 
    'RESQ', 
    'SHAQ', 
    'SPOQ', 
    'STEA', 
    'TAGQ',    
    ];

export const emptyUserFeed={
    questionId:"",
    profileId:"",
    isPrivate: false,
    draft: true,
    tags:[],
    choiceStats:null,
    questionStats:null,
    selections: [],
    stealthTime: null,
    toggledStealth: false,
    weight: 1,
    stats:UserFeedStats
    }

export function isOrdered(questionType){
    return questionType===QuestionAskType.Rank || questionType===QuestionAskType.SelectInOrder;
}
