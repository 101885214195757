import { QuestionAskType } from "../../constants/question";
import { initNumericChoiceStats } from "../../shared/utilities";
import { DefinitionActions as Actions, UserFeedStats } from "../constants";

export const QuestionDefinition = {
    buckets: 0,
    choices: [],
    choiceStats: [],
    creatorId: null,
    endTime: null,
    howLong: 18,
    howOften: 0,
    imageKey: null,
    isModerated: false,
    isMultipleChoice: false,
    isOrdered: false,
    isPremium: false,
    isPrivate: false,
    draft: true,
    key: [],
    landscapeImages: false,
    linkSelections: [],
    maxRange1: null,
    maxRange2: null,
    maxSel: 1,
    minRange1: null,
    minRange2: null,
    minSel: 1,
    numericType: 0,
    questionId: null,
    questionText: "",
    questionType: 0,
    startTime: null,
    stats: UserFeedStats,
    stealthTime: new Date(0).toISOString(),
    tags: [],
    weight: 1.0,
};

const initialState = {
    question: { ...QuestionDefinition },
    choiceImages: [],
    originalImageKeys: [],
    choicesAreGood: false,
    questionIsGood: false,
    hasResponses: false,
    isNew: true,
    imageActions: [],
    previewUrl: null,
    imageChanged: false,
    choicesChanged: false,
    choiceHeadings:[],
    errorMsg: '',
    textChanged: false,
    maxSelChanged: false,

};

function questioneditReducers(state = initialState, action) {
    switch (action.type) {
        case "SIGN_OUT":
            return { ...initialState };
        case Actions.RESET_DEFINITION:
            return initialState;

        case Actions.SET_ERRORMSG: {
            return { ...state, errorMsg: action.payload.errorMsg }
        }

        case Actions.STAGE_CHOICE_PREVIEW: {
            const { index, previewUrl } = action.payload;
            let newData = state.choiceImages[index];

            newData.previewUrl = previewUrl;
            newData.isNew = true;
            return {
                ...state,
                choiceImages: state.choiceImages.map((el, i) => i === index ?
                    newData : el),
            }
        };
        case Actions.UPDATE_CHOICETEXT:
            {
                const { index, text } = action.payload;
                let newChoice = state.question.choices[index];
                newChoice.text = text;
                return {
                    ...state,
                    question: {
                        ...state.question, choices: state.question.choices.map((el, i) => i === index ?
                            newChoice : el)
                    }
                }
            }
        case Actions.STAGE_CHOICE_IMAGE:
            {
                const { index, image, imageKey, imageURL } = action.payload;
                const { questionId, creatorId } = state.question;
                let newChoice = state.question.choices[index];
                const oldImageKey = newChoice.imageKey;

                let newImageActions = [...state.imageActions];
                newChoice.imageKey = imageKey;
                newChoice.imageURL = imageURL;
                newImageActions = [...newImageActions,
                { action: "add", imageKey: imageKey, image: image, profileId: creatorId, questionId: questionId }]
                if (oldImageKey) {
                    const imgIndex = newImageActions.findIndex(item => item.imageKey === oldImageKey);
                    if (imgIndex > 0)
                        newImageActions.splice(imgIndex, 1);
                    else
                        newImageActions = [...newImageActions,
                        { action: "delete", imageKey: oldImageKey, image: null }]
                }

                return {
                    ...state,
                    imageActions: [...newImageActions],
                    question: {
                        ...state.question, choices: state.question.choices.map((el, i) => i === index ?
                            newChoice : el)
                    }
                }
            }

        case Actions.REMOVE_CHOICE_IMAGE:
            {
                const { index } = action.payload;
                let newChoice = state.question.choices[index];
                const oldImageKey = newChoice.image;
                newChoice.image = null;
                let newChoiceImages = [...state.choiceImages];
                newChoiceImages[index].previewUrl = null;
                newChoiceImages[index].isNew = true;

                let newImageActions = [...state.imageActions];
                const imgIndex = newImageActions.findIndex(item => item.imageKey === oldImageKey);
                if (imgIndex >= 0)
                    newImageActions.splice(imgIndex, 1);
                else
                    newImageActions = [...newImageActions,
                    { action: "delete", imageKey: oldImageKey, image: null }]

                return {
                    ...state, choiceImages: state.choiceImages.map((el, i) => i === index ?
                        { file: null, previewUrl: null } : el),
                    imageActions: [...newImageActions],
                    question: {
                        ...state.question, choices: state.question.choices.map((el, i) => i === index ?
                            newChoice : el)
                    }
                }
            }
        case Actions.UPDATE_CHOICEIMAGES:
            return {
                ...state, choiceImages: action.payload.choiceImages
            }

        case Actions.UPDATE_PICTURE_CHOICES: {
            let { question: { choices} } = action.payload;
            let { originalImageKeys } = state;
            let imageActions = [];
            if (choices) {
                    choices.forEach((choice) => {
                        if (!originalImageKeys.find((key) => key === choice.imageKey)) {
                            if (choice.file) {
                                imageActions.push({
                                    action: "add",
                                    imageKey: choice.imageKey,
                                    image: choice.file,
                                });
                            }
                        }
                    })
                    originalImageKeys.forEach((imageKey) => {
                        if (!choices.find((choice) => choice.imageKey === imageKey)) {
                            imageActions.push({
                                action: "delete",
                                imageKey: imageKey,
                            });
                        }
                    })
                }
            else
                return state;

            return {
                ...state,
                imageActions: imageActions,
                question: {
                    ...state.question, ...action.payload.question
                }
            }
        }

        case Actions.UPDATE_QUESTION_IMAGE: {
            let { imageActions, question } = action.payload;
            return {
                ...state,
                imageActions: imageActions,
                question: {
                    ...state.question, ...question
                }
            }
        }

        case Actions.UPDATE_QUESTION_TEXT: {
            let { question: { questionText, choices, minSel, maxSel, tags } } = action.payload;
            const { question, isNew, question: {  isOrdered } } = state;
            if (isNew && choices) {
                const isNumeric = question.questionType === QuestionAskType.Numeric;
                const buckets = choices.length;
                let choiceStats = state.question.choiceStats;
                choiceStats = initNumericChoiceStats({ buckets: buckets, minSel: minSel, maxSel: maxSel, isOrdered: isOrdered, isNumeric: isNumeric })
                return {
                    ...state,
                    question: {
                        ...state.question, ...action.payload.question, choiceStats: choiceStats, buckets: buckets, tags: tags
                    }
                }
            }
            return {
                ...state,
                question: {
                    ...state.question, questionText: questionText, tags: tags
                }
            }
        }

        case Actions.UPDATE_QUESTION_CHOICES: {
            let { choices } = action.payload;
            const { question, question: { isOrdered } } = state;
            // choices = choices ?? question.choices;
            if (choices && question.stats.RESQ===0) {
                const isNumeric = question.questionType === QuestionAskType.Numeric;
                const isRanking = question.questionType === QuestionAskType.Rank;
                const buckets = choices.length;
                const minSel=isRanking?buckets:question.minSel;
                const maxSel=isRanking?buckets:question.maxSel;
                //let choiceStats = state.question.choiceStats;
                let choiceStats = initNumericChoiceStats({ buckets: buckets, minSel: minSel, maxSel: maxSel, isOrdered: isOrdered, isNumeric: isNumeric })
                return {
                    ...state,
                    question: { ...state.question, choices: choices, choiceStats: choiceStats, buckets:buckets,  minSel:minSel,maxSel:maxSel } 
                }
            }
            else
                return {
                    ...state,
                }
        }

        case Actions.UPDATE_RANGES:{
            const question = action.payload.question;
            return {
                ...state,  question: { ...state.question, ...question}
            }
        }

        case Actions.UPDATE_EDITQUESTION: {
            let { question: { choices, minSel, maxSel, isOrdered, choiceHeadings } } = action.payload;
            const { question } = state;
            minSel = minSel ?? question.minSel;
            maxSel = maxSel ?? question.maxSel;
            const headingMin = choiceHeadings ? choiceHeadings.reduce((a, b) => a.length <= b.length ? a.length : b.length) : 0;
            const headingsAreGood = !choiceHeadings ? true : headingMin >= 3;
            choiceHeadings = choiceHeadings ?? question.choiceHeadings;
            choices = choices ?? question.choices;
            isOrdered = isOrdered ?? question.isOrdered;
            const isNumeric = question.questionType === QuestionAskType.Numeric;
            const buckets = choices.length;
            // choices = choices ?? question.choices;
            if (!question.stats.RESQ && choices.length>0) {
                const choiceStats = initNumericChoiceStats({ buckets: buckets, minSel: minSel, maxSel: maxSel, isOrdered: isOrdered, isNumeric: isNumeric, choiceHeadings: choiceHeadings })
                return {
                    ...state, headingsAreGood: headingsAreGood, question: { ...question, ...action.payload.question, choiceStats: choiceStats, buckets: buckets, minSel: minSel, maxSel: maxSel, isOrdered: isOrdered }
                }
            }
            return {
                ...state, headingsAreGood: headingsAreGood, question: { ...question, ...action.payload.question, choiceHeadings }
            }
        }

        case Actions.SET_SELECTIONS:
            return {
                ...state,
                question: {
                    ...state.question,
                    minSel: Number(action.payload.minSel),
                    maxSel: Number(action.payload.maxSel)
                },
                minSelChanged: state.question.minSel !== action.payload.minSel
            }
        case Actions.TOGGLE_ORDERED:
            return { ...state, question: { ...state.question, isOrdered: !state.question.isOrdered } }
        case Actions.TOGGLE_STEALTH:
            return { ...state, question: { ...state.question, isPrivate: !state.question.isPrivate } }
        case Actions.STAGE_HEADER_PREVIEW:
            return {
                ...state, previewUrl: action.payload.previewUrl
            }
        case Actions.STAGE_HEADER_IMAGE:
            {
                const { questionId, creatorId } = state.question;
                const oldImageKey = state.imageKey;
                const { imageKey, image } = action.payload;
                let newImageActions = [...state.imageActions];
                const imgIndex = newImageActions.findIndex(item => item.imageKey === oldImageKey);
                if (imgIndex > 0)
                    newImageActions.splice(imgIndex, 1);
                else
                    if (oldImageKey) {
                        newImageActions = [...newImageActions,
                        { action: "delete", imageKey: oldImageKey, image: null }];
                    }
                newImageActions = [...newImageActions,
                { action: "add", imageKey: imageKey, image: image, profileId: creatorId, questionId: questionId }]
                return {
                    ...state,
                    imageKey: imageKey,
                    imageChanged: true,
                    imageActions: [...newImageActions],
                    question: { ...state.question, imageKey: imageKey }
                }
            }
        case Actions.STAGE_FOR_EDIT:
            let newQuestion = structuredClone(action.payload.question);
            const { choices, imageKey, imageURL } = newQuestion;
            delete newQuestion["_deleted"];
            delete newQuestion["_lastChangedAt"];
            delete newQuestion["_version"];
            let originalImageKeys = [];
            if (newQuestion.questionType === QuestionAskType.MoveMatch || newQuestion.questionType === QuestionAskType.Pictures)
                choices.map((choice) =>
                    originalImageKeys.push(choice.imageKey)
                );
            return {
                ...state,
                question: { ...newQuestion },
                originalImageKeys: originalImageKeys,
                imageKey: imageKey,
                imageUrl: imageURL,
                hasResponses: newQuestion.stats.RESQ > 0,
                isNew: false
            }
        case Actions.REMOVE_HEADER_IMAGE:
            {
                const oldImageKey = state.imageKey;
                let newImageActions = [...state.imageActions];
                if (oldImageKey !== '') {
                    const imgIndex = newImageActions.findIndex(item => item.imageKey === oldImageKey);
                    if (imgIndex >= 0)
                        newImageActions.splice(imgIndex, 1);
                    else
                        newImageActions = [...newImageActions,
                        { action: "delete", imageKey: oldImageKey, image: null }]
                }
                return {
                    ...state, previewUrl: null,
                    imageKey: '',
                    imageActions: newImageActions,
                    imageChanged: state.isNew ? false : true,
                    question: { ...state.question, imageKey: "" }
                }
            }
        default:
            return state;
    }

}

export default questioneditReducers