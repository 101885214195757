import React from "react";
import { connect } from "react-redux";
import { lgScreenSize } from "../constants";

const mapStateToProps = (state) => {
    return {
        screenWidth: state.context.screenWidth,
    };
};


const ShowMdDn = React.memo(function ShowMdDn({ children, screenWidth }) {
    if (screenWidth < lgScreenSize)
        return <div>{ children }</div>;
    else
        return <span />
})

export default connect(mapStateToProps, null)(ShowMdDn);

