import React from "react";
import { captionColor, fontStyle, fontStyleActive } from "../menus/menuIcons";

const DropDownMenuItem = React.memo(function MenuItem({ feedId, icon, isActive, caption, handleClick }) {
    const iconStr= icon.icon.concat(isActive ? fontStyleActive + ' activeColor' : fontStyle + ' inactiveColor');
    if (isActive) {
        return (
            <li className="dropdown-item pointer " onClick={() => handleClick(feedId)}>
                <i className={iconStr}></i> <span className={captionColor}>{caption??icon.text}</span>
            </li>
        )
    }
    else {
        return (
            <li className="dropdown-item inactiveColor disabled" >
                <i className={iconStr}></i><span  className="inactiveColor"> {caption??icon.text}</span>
            </li>
        )
    }
}); 

export default DropDownMenuItem;