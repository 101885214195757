import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Feeds } from "../../constants/feedids";
import ActionViewQuestionItem from "./ActionViewQuestionItem";
import "../index.css";
import { menuIcons } from "../menus/menuIcons";
import MainFeedTagItem from "./MainFeedTagItem";
import { AVATAR_SIZE } from "../../constants/image";
import CardImage from "./CardImage";
import { toggleLeftPanel } from "../../redux/actions/panel-actions";
import { Icons } from "../../shared/QIcons";
import TagFeed from "../feeds/TagFeed";
import HideSmUp from "../../shared/HideSmUp";
import { listTagsByProfile } from "../../repository/tagQueries";
import { SystemId } from "../../constants/profile";
import { setView } from "../../redux/actions/context-actions";


const mapStateToProps = (state) => {
    return {
        systemProfileIsLoaded: state.profile.systemProfileIsLoaded,
        profile: state.profile.systemUser,
    };
};

const MainFeedActionCard = React.memo(function MainFeedActionCard({ profile, systemProfileIsLoaded, dispatch }) {
    const { firstName, lastName, description, } = profile;

    const [hashtags, setHashtags] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchHashtags = async () => {
            try {
                setLoading(true);
                const response = await listTagsByProfile({targetId:SystemId})
                setHashtags(response.items);
            } catch (err) {
                setError(err);
                console.error('Error fetching hashtags:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchHashtags();
    }, [SystemId]);

    const ShowTags = () => {
        return (
            <div>
                {hashtags ? (
                    <div className="flex tagContainer ps-2">
                        {hashtags.map((tag) => (
                            <span key={tag.tag} className="tag brand0" onClick={() => getQuestions(tag.tag)}>
                                {tag.tag} ({tag.count})
                            </span>
                        ))}
                    </div>
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        )
    }

        const drawerToggle = () => {
            dispatch(toggleLeftPanel({ isOpen: false }));
        };

    function getQuestions(tag) {
        dispatch(setView({ tag: tag, targetId: SystemId, feedId: Feeds.TaggedQuestions }));
    }

    if (systemProfileIsLoaded)
        return (
            <div className="drawerCard white shadow-none " style={{ width: "310px", height: "100%" }} key={"mainFeed"}>
                <div className="">
                    <CardImage>
                        <HideSmUp>
                            <i className={Icons.PANEL_OPEN + "float-end m-2 h4 panelAction fa-lg "} onClick={() => drawerToggle()} ></i>
                        </HideSmUp>
                    </CardImage>

                    <div >
                        <div className="profileAvatar text-center  mx-auto mb-1 w-100">
                            <img src="https://images.lyqness.com/images/LYQrAqtrKr6ZEJqFltness/avataronwhite.jpg" className="lyqnessAvatar" alt="LY" style={{ height: AVATAR_SIZE, width: AVATAR_SIZE }}></img>
                            {/* <AvatarLg userFolder={identityId} avatarKey={avatarKey} initials={initials} square={false} /> */}
                        </div>

                        <div className="container text-center mt-2 mb-2 " >
                            <div className="nameFont brand5">
                                {`${firstName} ${lastName}`}
                            </div>
                            {description && <div className="description small ">
                                {description}
                            </div>
                            }
                        </div>
                        <div className="text-center mt-2 w-100 mx-auto no-gutters">
                            <ActionViewQuestionItem icon={menuIcons.Questions} isActive={true} feedId={Feeds.AllQuestions} />
                        </div>
                        <div className=" border-bottom text-center small brand5 m-2">About Lyqness</div>
                        <div className="row px-2 no-gutters">
                            <MainFeedTagItem icon={menuIcons.Navigation} tag={"navigation"} heading={"Getting Around"} />
                            <MainFeedTagItem icon={menuIcons.Examples} tag={"examples"} heading={"Example Questions"} />
                            <MainFeedTagItem icon={menuIcons.Similarity} tag={"similarity"} heading={"Discovering Similarity"} />
                            <MainFeedTagItem icon={menuIcons.ExploringUsers} tag={"users"} heading={"Viewing Others"} />
                            <MainFeedTagItem icon={menuIcons.Tag} tag={"tagging"} heading={"Organizing Interests"} />
                        </div>
                    </div>
                    <div className={` brand2 brand0-bg h6 mb-0  px-2 py-1 `}>
                        <i className={Icons.HASHTAG + " fas brand2"}></i> Hashtags
                    </div>
                    <ShowTags />
                </div >
            </div>
        )

}
)

export default connect(mapStateToProps, null)(MainFeedActionCard);



