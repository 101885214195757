import React from "react";
import { connect } from "react-redux";

import "./index.css";
import QuestionMenu from "../../menus/QuestionMenu";

const mapStateToProps = (state, ownProps) => {
    return {
        currentProfileView: state.profile.currentProfileView,
        userId: state.profile.currentUser.profileId,
    };
};

const AdHeader = React.memo(function AdHeader({ isLink, userId, currentProfileView, displayQuestion,
    displayQuestion: { questionId, creator, questionText, createdAt, creatorId, visibility, imageKey, isModerated, stats } }) {
    try {
        const isCreator = userId === creator.creatorId;

        return (
            <div className={`container px-2 py-1  w-100 bil7`} >
                <div className="media w-100 pl-0">
                    <div className="container w-100 pl-1 pr-0">
                        <div className="h5 brand1 mb-0 text-left">
                            {questionText}
                            <span className="float-end mr-2">
                                {isCreator && <QuestionMenu questionId={questionId} />}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    catch {
        console.log(displayQuestion, " problem")
    }

})

export default connect(mapStateToProps, null)(AdHeader);