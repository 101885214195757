// src/redux/sagas/imagesagas.js
import { Storage } from 'aws-amplify';
import { takeEvery, call, put, all } from "redux-saga/effects";
import { ImageActions as Actions } from "../constants";
import { ImageOptions } from "../../constants/image";


//***         Process Images             ******/ 
export function* watchProcessImages() {
  yield takeEvery(Actions.PROCESS_IMAGES_REQUESTED, processImagesWorker);
}

function* processImagesWorker(action) {
  try {
    let imageActions = action.payload;
    if (imageActions) {
      yield call(processImageActions, imageActions);
      yield put({ type: Actions.IMAGES_PROCESSED, imageActions });
    }
  }
  catch (e) {
    yield put({ type: Actions.IMAGE_STORAGE_ERROR, payload: e });
  }
}

export async function processImageActions(imageActions) {
  var meta;
  await Promise.all(
    imageActions.map(async (imageAction) => {
      meta = { ...imageAction };
      meta.image = null;
      meta.action = null;
      if (imageAction.action === "add")
        try{
        await Storage.put(
          imageAction.imageKey,
          imageAction.image,
          {
            level: 'protected',
            contentType: ImageOptions.ImageContentType,
            // metadata: meta 
          }
        );
      }
      catch (error) {
        console.log(error)
      }
      else if (imageAction.action === "delete")
        await Storage.remove(
          imageAction.imageKey,
          {
            level: 'protected',
          });
    }
    ))
}


//***        delete image             ******/
export function* watchDeleteImage() {
  yield takeEvery(Actions.DELETE_IMAGE_REQUESTED, deleteImageWorker);
}

function* deleteImageWorker(action) {
  try {

    const payload = yield call(deleteImage, action.payload);
    yield put({ type: Actions.IMAGE_DELETED, payload });
  } catch (e) {
    yield put({ type: Actions.IMAGE_STORAGE_ERROR, payload: e });
  }
}

async function deleteImage(payload) {
  await Storage.remove(
    payload.imageKey,
    {
      level: 'protected',
      // customPrefix: { protected: IMAGE_STORAGE_LOCATION }
    })
    .catch(err => alert(err));
}

export default function* rootSaga() {
  yield all([
    watchProcessImages(),
    watchDeleteImage()
  ])
};