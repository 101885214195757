import * as React from 'react';
import Popover from '@mui/material/Popover';
import { MenuList } from '@mui/material';
import DropDownMenuItem from './DropDownMenuItem';
import { menuIcons } from './menuIcons';



export default function ImageEditMenu({ children, handleEdit, handleDelete }) {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEditClick = () => {
        handleClose();
        handleEdit();
    }

    const handleDeleteClick = () => {
        handleClose();
        handleDelete();
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <React.Fragment>
            <span aria-describedby={id} variant="contained" className="pointer brand1"
                onClick={handleClick}
                onBlur={handleClose}
            >
                {children}
            </span>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
            >
                <MenuList dense
                    id="basic-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                >
                    <DropDownMenuItem icon={menuIcons.Edit} isActive={true} handleClick={handleEditClick} />
                    <DropDownMenuItem icon={menuIcons.Delete} isActive={true} handleClick={handleDeleteClick} />

                </MenuList>
            </Popover>
        </React.Fragment>
    );
}

