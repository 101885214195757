import React, { useState, useMemo } from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import "../MoveMatchQuestions/MoveMatchChoices.css"; // Updated CSS import
import { connect } from "react-redux";
import { updateQuestionChoices } from "../../../redux/actions/questionedit-actions"; // Import the action
import { getCol } from "../Utilities";
import MultiChoiceCheckbox from "../CreateQuestion/OptionMenus/options/MultiChoiceCheckbox";
import RequiredSelections from "../CreateQuestion/OptionMenus/options/RequiredSelections";
import IsOrderedCheckBox from "../CreateQuestion/OptionMenus/options/IsOrderedCheckBox";
import { questionInstructions } from "../constants";
import { QuestionAskType } from "../../../constants/question";
import { Icons } from "../../../shared/QIcons";

function mapStateToProps(state) {
  return {
    question: state.questionedit.question,
  };
}

const CreateTextQuestion = ({ question, dispatch }) => {
  const [choices, setChoices] = useState(
    question.choices.map((choice) => ({
      text: choice.text || "",
      imageURL: null,
      imageKey: null
    }))
  );

  const isRanking = question.questionType === QuestionAskType.Rank;

  const [semicolonInput, setSemicolonInput] = useState("");
  const [viewOptions, setViewOptions] = useState(false);

  const handleSortEnd = (oldIndex, newIndex) => {
    if (oldIndex !== newIndex) {
      const updatedChoices = arrayMoveImmutable(choices, oldIndex, newIndex);
      setChoices(updatedChoices);
      dispatch(updateQuestionChoices({ choices: updatedChoices }));
    }
  };

  const handleTextUpdate = (event, index) => {
    const { value } = event.target;

    const updatedChoices = choices.map((item, idx) =>
      idx === index ? { ...item, text: value } : item
    );
    setChoices(updatedChoices);
    dispatch(updateQuestionChoices({ choices: updatedChoices }));
  };

  const deleteChoice = (index) => {
    if (choices.length <= 2) return;
    const updatedChoices = choices.filter((_, idx) => idx !== index);
    setChoices(updatedChoices);
    dispatch(updateQuestionChoices({ choices: updatedChoices }));
  };

  const handleSemicolonInputKeyPress = (event) => {
    if (event.key === "Enter") {
      const newChoices = semicolonInput
        .split(";")
        .map((text) => text.trim()) // Trim whitespace
        .filter((text) => text !== ""); // Remove empty entries

      const lowerCaseExistingChoices = choices.map((choice) =>
        choice.text.toLowerCase()
      );

      if (newChoices.length > 0) {
        const updatedChoices = [...choices];
        let replacementIndex = 0;

        // Replace empty choices first
        updatedChoices.forEach((choice, idx) => {
          if (
            choice.text === "" &&
            replacementIndex < newChoices.length &&
            !lowerCaseExistingChoices.includes(
              newChoices[replacementIndex].toLowerCase()
            )
          ) {
            updatedChoices[idx] = { text: newChoices[replacementIndex], imageURL: null, imageKey: null };
            lowerCaseExistingChoices.push(
              newChoices[replacementIndex].toLowerCase()
            );
            replacementIndex++;
          }
        });

        // Add remaining new choices if they are not duplicates
        const remainingNewChoices = newChoices
          .slice(replacementIndex)
          .filter(
            (text) => !lowerCaseExistingChoices.includes(text.toLowerCase())
          )
          .map((text) => ({ text, imageURL: null, imageKey: null }));

        setChoices([...updatedChoices, ...remainingNewChoices]);
        dispatch(
          updateQuestionChoices({
            choices: [...updatedChoices, ...remainingNewChoices],
          })
        );
      }

      setSemicolonInput(""); // Clear the input field
    }
  };

  const calculateColWidth = useMemo(() => {
    const maxLength = Math.max(...choices.map((choice) => choice.text.length), 1);
    return getCol(maxLength);
  }, [choices]);

  return (
    <div className="px-3 my-3">
      <SortableList
        onSortEnd={handleSortEnd}
        className={`${question.questionType === 2
          ? "d-flex flex-column align-items-center"
          : "row justify-content-center"
          }`}
        draggedItemClassName="dragged"
      >
        {choices.map((choice, index) => (
          <SortableItem key={index}>
            <div
              className={`${question.questionType === 2
                ? `${calculateColWidth} text-center`
                : `${calculateColWidth} px-1`
                } grid-item`}
            >
              <div className="input-container mb-1">
                <input
                  style={{
                    fontSize: ".95em",
                  }}
                  type="text"
                  placeholder="choice..."
                  value={choice.text}
                  onChange={(e) => handleTextUpdate(e, index)}
                />
                <i
                  className="fas fa-times delete-icon"
                  onClick={() => deleteChoice(index)}
                  title="Delete"
                ></i>
              </div>
            </div>
          </SortableItem>
        ))}
      </SortableList>
      <div className="smallfont brand7 mb-3">{questionInstructions(question)}</div>

      <div className="semicolon-input-container mt-1 d-flex justify-content-center">
        <input
          style={{
            fontSize: ".95em",
          }}
          type="text"
          className="semicolon-input roundedBorder ps-2"
          placeholder="add choice(s)..."
          value={semicolonInput}
          onChange={(e) => setSemicolonInput(e.target.value)}
          onKeyPress={handleSemicolonInputKeyPress}
        />
      </div>

      {!isRanking &&
        <>
          <div className="brand0 border-bottom  my-3 text-end smallfont" onClick={() => setViewOptions(!viewOptions)}>
            options <i className={viewOptions?Icons.CARETUP:Icons.CARETDOWN}></i>
          </div>
          {viewOptions &&
            <div >
              <MultiChoiceCheckbox />
              <RequiredSelections />
              <IsOrderedCheckBox />
            </div>
          }
        </>}
    </div>

  );
};

export default connect(mapStateToProps, null)(CreateTextQuestion);
