import keymirror from "keymirror";

//********  Auth actions    *******/
export const AuthActions = keymirror({
    SIGN_IN:null,
    SIGN_OUT:null,
    SIGN_UP:null,
    SET_SOCKET:null,
    SET_IS_LOADING:null,
    SET_ISAUTHENTICATED:null,
    CONFIRM_SIGN_IN:null,
    CONFIRM_SIGN_UP:null,
    FORGOT_PASSWORD:null,
    REQUIRE_NEW_PASSWORD:null,
    VERIFY_CONTACT:null,
    SIGNED_IN:null,
    CURRENT_USER_PROFILE_REQUESTED:null,
    CURRENT_USER_PROFILE_RECEIVED:null,
    SET_SIGNIN_REDIRECT:null,
    SET_CREDENTIALS:null
});

//******     Security      ******/
export const SIGNIN_REQUESTED = "LOGIN_REQUESTED";
export const SIGNOUT_REQUESTED = "SIGNOUT_REQUESTED";
export const SIGNIN_SUCCESSFUL = "LOGIN_SUCCESSFUL";
export const SIGNOUT_SUCCESSFUL = "LOGOUT_SUCCESSFUL";
export const COGNITO_ERROR = "COGNITO_ERROR";