import { QuestionAskType } from "../../../constants/question";
import store from "../../../redux/store";


export function canSave(question) {
    const { questionType, choices, choiceHeadings, buckets, questionText, endTime } = question;
    let canSave = true;
    if (buckets < 2 || choices.length < 2)
        canSave = false;
    if (canSave && questionText.length < 3)
        canSave = false;
    if (canSave && choices.length !== buckets)
        canSave = false;
    if (canSave && (questionType !== QuestionAskType.Lottery && (choices.some(e => e.text === "") || choices.some(e => e.text.length < 3))))
        canSave = false;
    if (questionType === QuestionAskType.Lottery) {
        const now = new Date();
        if (endTime < new Date(now.getTime() +  60 * 1000))
            canSave = false;
    }
    if (canSave && (questionType === QuestionAskType.Pictures || questionType === QuestionAskType.MoveMatch)) {
        if (choices.some(e => e.imageKey === ""))
            canSave = false;
        if (questionType === QuestionAskType.MoveMatch && (choiceHeadings.length !== buckets || choiceHeadings.some(e => e.text === "")))
            canSave = false
    }
    return canSave;
}

export function hasChanged(question) {
    let hasChanged = false;
    const { choices, maxSel, isOrdered, questionType, imageKey, buckets, choiceHeadings, questionId, endTime, questionText } = question;
    const feed = store.getState().feed.items;
    const original = feed.find(e => e.questionId === questionId);
    if (original.imageKey !== imageKey)
        hasChanged = true;
    if (questionText !== original.questionText)
        hasChanged = true;
    if (questionType === QuestionAskType.Lottery && endTime !== original.endTime)
        hasChanged = true;
    if (question.startTime !== original.startTime || question.endTime!==original.endTime)
        hasChanged = true;
    if (JSON.stringify(choices) !== JSON.stringify(original.choices))
        hasChanged = true;
    if (buckets !== original.buckets || maxSel !== original.maxSel || isOrdered !== original.isOrdered)
        hasChanged = true;
    if (questionType === QuestionAskType.MoveMatch && choiceHeadings !== original.choiceHeadings)
        hasChanged = true;
    return hasChanged;
}

export function canSubmit({ question, inputChoices }) {
    let canSubmit = false;
    const numChoices = inputChoices.length;
    const { maxSel, minSel, isOrdered, choices, questionType, buckets, } = question;
    switch (questionType) {
        case QuestionAskType.Rank:
            if (numChoices === buckets)
                canSubmit = true
            break;
        case QuestionAskType.Numeric: {
            if (numChoices === 1 && inputChoices[0] !== null)
                canSubmit = true;
            break;
        }
        case QuestionAskType.Lottery: {
            if (minSel >= 3 && maxSel <= 8 && choices.length === buckets)
                canSubmit = true;
            break;
        }
        default:
            if (isOrdered && numChoices === maxSel)
                canSubmit = true;
            if (numChoices >= minSel && numChoices <= maxSel)
                canSubmit = true;
    }
    return canSubmit;
}