import React from "react";
import { QuestionAskType } from "../../constants/question";
import RespondToNumericQuestion from "./NumericQuestions/RespondToNumericQuestion";
import AdViewer from "./Advertisement/AdViewer";
import RespondToMoveMatchQuestion from "./MoveMatchQuestions/RespondToMoveMatchQuestion";
import RespondToPictureQuestion from "./PictureQuestions/RespondToPictureQuestion";
import RespondToTextQuestion from "./TextQuestions/RespondToTextQuestion";
import RespondToRatingQuestion from "./RatingQuestions/RespondToRatingQuestion";
import CreateTextQuestion from "./TextQuestions/CreateTextQuestion";
import CreatePictureQuestion from "./PictureQuestions/CreatePictureQuestion";
import CreateMoveMatchQuestion from "./MoveMatchQuestions/CreateMoveMatchQuestion";
import CreateRatingQuestion from "./RatingQuestions/CreateRatingQuestion";
import AdCreator from "./Advertisement/AdCreator";
import CreateNumericQuestion from "./NumericQuestions/CreateNumericQuestion";
import CreateLotteryQuestion from "./LotteryQuestions/CreateLotteryQuestion";
import RespondToLotteryQuestion from "./LotteryQuestions/RespondToLotteryQuestion";

export const  QuestionResponder = React.memo(function QuestionRouter({ displayQuestion }) {
    const keyid = displayQuestion.questionId+displayQuestion.choices.length;
    switch (displayQuestion.questionType) {
        case QuestionAskType.Select:
        case QuestionAskType.Rank:
            return (<RespondToTextQuestion key={keyid} question={displayQuestion} />);
        case QuestionAskType.Pictures:
            return (<RespondToPictureQuestion key={keyid} question={displayQuestion} />);
        case QuestionAskType.MoveMatch:
            return (<RespondToMoveMatchQuestion key={keyid} question={displayQuestion} />);
        case QuestionAskType.Numeric:
            return (<RespondToNumericQuestion key={keyid} displayQuestion={displayQuestion} />);
        case QuestionAskType.Rate:
            return (<RespondToRatingQuestion key={keyid} question={displayQuestion} />);
        case QuestionAskType.Lottery:
            return (<RespondToLotteryQuestion key={keyid} displayQuestion={displayQuestion} />);
        case QuestionAskType.Advertisement:
            return (<AdViewer key={keyid} displayQuestion={displayQuestion} />);
        case QuestionAskType.Incentive:
            return (<AdViewer key={keyid} displayQuestion={displayQuestion} />);
        default:
            return (<span />)
    }
})

export const QuestionCreator = React.memo(function QuestionCreator({ questionType }) {
        switch (questionType) {
            case QuestionAskType.Select:
            case QuestionAskType.Rank:
                return (<CreateTextQuestion />);
            case QuestionAskType.Pictures:
                return <CreatePictureQuestion />
            case QuestionAskType.MoveMatch:
                return (<CreateMoveMatchQuestion />)
            case QuestionAskType.Numeric:
                return (<CreateNumericQuestion />);
            case QuestionAskType.Rate:
                return (<CreateRatingQuestion />)
            case QuestionAskType.Lottery:
                return (<CreateLotteryQuestion />)
            case QuestionAskType.Advertisement:
                return <AdCreator />
            default:
                return (<span>oops!</span>)
        }
    });