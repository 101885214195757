// Import required dependencies and utilities
import { FeedData } from "../../constants/feedData";
import { PublicFeeds, UserFeeds } from "../../constants/feedids";
import { ComputeCMOSR, ComputeCMOST } from "../../shared/MOS";
import { FeedActions as Actions } from "../constants";
import { emptyUserFeed } from "../../repository/modelHelpers";
import Notify from "../../components/notify/Notify";
import { UserFeed } from "../../models";
import store from "../store";
import { DataStore } from "aws-amplify";

// Initial State
const initialState = {
    ...FeedData,
    startUp: true,
    subscription: null,
};

// Utility Functions

function isInFeed(state, targetId) {
    try {
        return typeof state.index[targetId] !== 'undefined';
    } catch (error) {
        console.error("Error in isInFeed:", error);
        return false;
    }
}

function updateIndex(items) {
    try {
        return items.reduce((map, obj, i) => {
            map[obj.questionId] = i;
            return map;
        }, {});
    } catch (error) {
        console.error("Error in updateIndex:", error);
        return {};
    }
}

function calculateCMOSR(items) {
    try {
        return { weight: 0, value: ComputeCMOSR(items) };
    } catch (error) {
        console.error("Error in calculateCMOSR:", error);
        return { weight: 0, value: null };
    }
}

function calculateCMOST(feedId, items) {
    try {
        if (feedId[0] === "P" || feedId === PublicFeeds.UserFeedQuestions) {
            return { value: ComputeCMOST(items) };
        }
        return null;
    } catch (error) {
        console.error("Error in calculateCMOST:", error);
        return null;
    }
}

// Reducer Function
function feedReducers(state = initialState, action) {
    switch (action.type) {
        case Actions.SET_CURRENT_PROFILE_VIEW:
            return { ...state, ...action.payload };
        case Actions.QUESTION_RESPONSE_RECEIVED:
            return handleQuestionResponse(state, action);
        // case Actions.SET_FEED_SUBSCRIPTION:
        // case Actions.FEED_SUBSCRIPTION_RECEIVED:
        //     return handleFeedSubscription(state, action);

        case Actions.FEED_UPDATE_RECEIVED:
            return handleFeedUpdateReceived(state, action);

        case Actions.REMOVE_FEED_DATA_REQUESTED:
            return { ...initialState, isLoading: true };

        case Actions.QUESTION_FEED_REQUESTED:
            return handleQuestionFeedRequested(state, action);

        case Actions.FEED_DATA_RECEIVED:
            return handleFeedDataReceived(state, action);

        case Actions.VIEW_QUESTION_UPDATED:
            return handleViewQuestionUpdated(state, action);

        case Actions.USERFEED_SUBSCRIPTION_RECEIVED:
            return handleUserFeedSubscription(state, action);

        case Actions.PROFILE_INTERACTION_PROCESSED:
        // case Actions.PROFILEFEED_SUBSCRIPTION_RECEIVED:
            return handleProfileInteraction(state, action);

        case Actions.QUESTION_RESPONSE_RECEIVED:
        case Actions.QUESTION_INTERACTION_PROCESSED:
            return handleQuestionInteraction(state, action);

        // case Actions.QUESTION_SUBSCRIPTION_RECEIVED:
        //     return handleQuestionSubscriptionReceived(state, action);

        case Actions.QUESTION_UPDATED:
            return handleQuestionUpdate(state, action);

        case Actions.VIEW_QUESTION_RECEIVED:
            return handleViewQuestionReceived(state, action);

        case Actions.QUESTION_ADDED:
            return handleQuestionAdded(state, action);

        case Actions.SIGN_OUT:
            return handleSignOut(state);

        case Actions.FEED_API_ERROR:
        default:
            return state;
    }
}

// Action Handlers
function handleFeedSubscription(state, action) {
    const subscription = action.payload;
    if (state.subscription !== null) {
        state.subscription.unsubscribe();
        return { ...state, subscription: null };
    }
    return { ...state, subscription };
}

function handleFeedUpdateReceived(state, action) {
    const { question, question: { id } } = action.payload;
    const newItems = [...state.items];

    if (isInFeed(state, id)) {
        newItems[state.index[id]] = question;
    } else {
        newItems.push(question);
    }

    return {
        ...state,
        items: newItems,
        cmosr: calculateCMOSR(newItems),
        index: updateIndex(newItems),
    };
}

function handleQuestionFeedRequested(state, action) {
    const { feedId } = action.payload;
    if (feedId[0] === "L" && state.feedId[0] === "L") {
        return state;
    }
    return { ...state, isLoading: true };
}

function handleFeedDataReceived(state, action) {
    const { feedId, items, nextToken, isFirstFeed } = action.payload;
    const newItems = isFirstFeed ? items : [...state.items, ...items];
    return {
        ...state,
        items: newItems,
        index: updateIndex(newItems),
        isLoaded: true,
        isLoading: false,
        cmosr: calculateCMOSR(newItems),
        cmost: calculateCMOST(feedId, newItems),
        hasMore: nextToken !== null,
        nextToken,
        feedId,
    };
}

function handleViewQuestionUpdated(state, action) {
    const item = action.payload;

    if (isInFeed(state, item.questionId)) {
        const newItems = state.items.map(el =>
            el.questionId === item.questionId ? { ...item, creator: el.creator, userFeed: el.userFeed } : el
        );

        return {
            ...state,
            items: newItems,
            cmosr: calculateCMOSR(newItems),
        };
    }
    return state;
}

function handleUserFeedSubscription(state, action) {
    const { question} = action.payload;
    const {_deleted, questionId} = question;
    const existingQuestion = state.items[state.index[questionId]];
    const qmosr=question.qmosr??existingQuestion.qmosr;

    if (_deleted) {
        return {
            ...state,
            cmosr: calculateCMOSR(state.items),
        };
    }

    try {
        let newItems=[];
        if(isInFeed(state,questionId)){
                newItems = state.items.map(el =>
                    el.questionId === questionId ? { ...question,qmosr:qmosr } : el
                );

        }
        else{
            newItems=[question,...state.items];
        }
        return {
            ...state,
            items: newItems,
            index: updateIndex(newItems),
            cmosr: calculateCMOSR(newItems),
        };
    } catch (error) {
        console.error("Error processing question interaction:", error);
        return state;
    }
}

function handleProfileInteraction(state, action) {
    const { profileFeed } = action.payload;
    const { associatedId } = profileFeed;

    return {
        ...state,
        items: state.items.map(el =>
            el.creatorId === associatedId
                ? { ...el, creator: { ...el.creator, myProfileFeed: profileFeed } }
                : el
        ),
    };
}

// function handleQuestionSubscriptionReceived(state, action) {
//     const question = action.payload;
//     const questionId=question.questionId;

//     try {
//         let newItems=[];
//         if(isInFeed(state,questionId)){
//                 newItems = state.items.map(el =>
//                     el.questionId === questionId ? { ...el, ...question } : el
//                 );

//         }
//         else{
//             newItems=[question,...state.items];
//         }
//         return {
//             ...state,
//             items: newItems,
//             cmosr: calculateCMOSR(newItems),
//         };
//     } catch (error) {
//         console.error("Error processing question interaction:", error);
//         return state;
//     }
// }

function handleQuestionInteraction(state, action) {
    try {
        const question = action.payload;
        const {qmosr, questionId} = question;
        const existingQuestion = state.index[questionId];
        const newqmosr=qmosr??existingQuestion.qmosr;
        const newItems = state.items.map(el =>
            el.questionId === questionId ? { ...question, qmosr:newqmosr } : el
        );

        return { ...state, items: newItems };
    } catch (error) {
        console.error("Error processing question interaction:", error);
        return state;
    }
}

function handleQuestionResponse(state, action) {
    try {
        const question  = action.payload || {};

        if (!question || !question.questionId) {
            console.error("Invalid action payload:", action.payload);
            return state;
        }

        const { questionId, draft, stats: { RESQ }, _deleted } = question;
        if (state.feedId === UserFeeds.DraftQuestions) {
            Notify("question has been moved into your feed")
            const newItems = state.items.filter(x => x.questionId !== questionId);
            return {
                ...state,
                items: newItems,
                index: updateIndex(newItems),
            };
        }
        else
        {
            const newItems = state.items.map(el =>
                el.questionId === questionId ? { ...el, ...question } : el
            );
            return {
                ...state,
                items: newItems,
                cmosr: calculateCMOSR(newItems),
            };
        }
    } catch (error) {
        console.error("Error processing question response:", error);
        return state;
    }
}

function handleQuestionUpdate(state, action) {
    try {
        const { question } = action.payload || {};

        if (!question || !question.questionId) {
            console.error("Invalid action payload:", action.payload);
            return state;
        }

        const { questionId, draft, stats: { RESQ }, _deleted } = question;

        if (_deleted || (state.feedId === UserFeeds.DraftQuestions && RESQ === 1)) {
            const newItems = state.items.filter(x => x.questionId !== questionId);
            return {
                ...state,
                items: newItems,
                cmosr: calculateCMOSR(newItems),
                index: updateIndex(newItems),
            };
        }

        if (isInFeed(state, questionId)) {
            const newItems = state.items.map(el =>
                el.questionId === questionId ? { ...el, ...question } : el
            );
            return {
                ...state,
                items: newItems,
                cmosr: calculateCMOSR(newItems),
            };
        }

        return state;
    } catch (error) {
        console.error("Error processing question update:", error);
        return state;
    }
}

function handleViewQuestionReceived(state, action) {
    const { question, question: { questionId, _deleted } } = action.payload;

    if (!isInFeed(state, questionId)) {
        Notify("Question in view has been updated");
        const newItems = [question, ...state.items];
        question.userFeed = emptyUserFeed;
        return {
            ...state,
            items: newItems,
            index: updateIndex(newItems),
        };
    }

    if (_deleted) {
        const newItems = state.items.filter(x => x.questionId !== questionId);
        return {
            ...state,
            items: newItems,
            cmosr: calculateCMOSR(newItems),
            index: updateIndex(newItems),
        };
    }

    return {
        ...state,
        items: state.items.map(el => (el.questionId === questionId ? { ...el, ...question } : el)),
    };
}

function handleQuestionAdded(state, action) {
    const question = action.payload;

    if  (state.feedId === UserFeeds.DraftQuestions || state.feedId===UserFeeds.UserFeedQuestions)
    {
        const newItems = [question, ...state.items];
        return {
            ...state,
            items: newItems,
            index: updateIndex(newItems),
        };
    }
        Notify("Question created. View it in your drafts");


    return state;
}

function handleSignOut(state) {
    if (state.subscription !== null) {
        state.subscription.unsubscribe();
        return { ...state, subscription: null };
    }
    return { ...initialState };
}

export default feedReducers;
