import React from "react";
import { connect } from "react-redux";
import "./index.css"
import { addQuestion, updateQuestion } from "../../../redux/actions/question-actions";
import { resetDefinition } from "../../../redux/actions/questionedit-actions";
import { QuestionAskType } from "../../../constants/question";
import EditHeaderImage from "./EditHeaderImage";
import EditQuestionText from "./EditQuestionText";
import { canSave, hasChanged } from "../core/CreateEditStatus";
import { QuestionCreator } from "../QuestionRouting";



function mapStateToProps(state) {
    return {
        modalProps: state.modal.modalProps,
        question: state.modal.modalProps.question,
        definition: state.questionedit.question,
        hasResponses: state.questionedit.hasResponses,
        qe: state.questionedit,
        isOwner: state.profile.viewProfile.isOwner,
        headerImage: state.questionedit.headerImage,
        viewProfileId: state.context.view.profileId,
        identityId: state.profile.currentUser.identityId,
        isPremium: state.profile.isPremium,
        parentId: state.modal.modalProps.parentId,
        imageKey: state.questionedit.imageKey,
        currentUser:state.profile.currentUser,
        view: state.profile.currentProfileView,
        currentFeedId: state.feed.currentFeedId
    };
};

class CreateEditQuestion extends React.PureComponent {
    state = {
        imageActions: [],
        editor: "",
        editing: false,
        checkedItems: [],
        limitedEdit: this.props.hasResponses,
        editingQuestion: false,
        choicesChanged: this.props.choicesChanged,
        textChanged: this.props.textChanged,
        changedVisibility: false,
        changedIsMultipleChoice: false,
        editingOptions: false,
        draft: true,
        isPrivate: false
    }


    async handleSave(event) {
        const { questionText,  questionType,  minSel } = this.props.qe.question;
        const isPremium = this.props.isPremium;
        const {lastName, firstName, identityId, avatarKey} = this.props.currentUser;
        const profileStub={lastName, firstName, identityId, avatarKey};
        //  let newKey = (this.props.qe.imageChanged && this.props.headerImage && this.props.headerImage!=="landscape") ? newId() : "";
        var question = { ...this.props.qe.question, isPremium: isPremium, profileStub:profileStub }

        let cleanedText = questionText.trim();
        if (["!", ".", "?"].includes(cleanedText[cleanedText.length - 1])) {
            cleanedText = cleanedText.slice(0, cleanedText.length - 1);
        }
        if (questionType === QuestionAskType.Pictures || questionType === QuestionAskType.MoveMatch)
            for (let i = 0; i < question.choices.length; i++) {
                question.choices[i].imageURL = "";
                delete question.choices[i].file;
            }

        var addData = {
            questionData: { ...question, questionText: cleanedText },
            imageActions: this.props.qe.imageActions,
            image: this.props.headerImage,

        }

        this.props.dispatch(addQuestion(addData));
        //this.props.dispatch(resetDefinition());
        this.handleClose();
    }

    async handleUpdate(event) {
        const { questionText, questionType, choices, choiceHeadings } = this.props.qe.question;
        var question = this.props.qe.question;
        delete question.userFeed;
        delete question.creator;
        const imageActions = this.props.qe.imageActions;
        const profileId = this.props.definition.ownerProfileId;

        let cleanedText = questionText.trim();
        if (cleanedText[cleanedText.length - 1] === "?") {
            cleanedText = cleanedText.slice(0, cleanedText.length - 1);
        }
        if (questionType === QuestionAskType.Pictures || questionType === QuestionAskType.MoveMatch)
            for (let i = 0; i < choices.length; i++) {
                question.choices[i].imageURL = "";
                delete question.choices[i].file;
            }
        var questionDTO = {
            ...this.props.definition,
            // tags: tags,
        };
        var updateData = {
            profileId: profileId,
            question: { ...questionDTO, questionText: cleanedText },
            viewFeedId: this.props.viewFeedId,
            usersFeedId: this.props.usersFeedId,
            imageActions: imageActions,
            limitedEdit: this.props.hasResponses,
        }

        this.props.dispatch(updateQuestion(updateData));
        this.handleClose();
    }

    handleClose = () => {
        this.props.closeModal();
       this.props.dispatch(resetDefinition());

    }

    render() {
        const { isNew, hasResponses } = this.props;
        const { choices, questionType, isOrdered, questionId, identityId: userFolder, imageKey, questionText } = this.props.qe.question;
        if (this.props.qe.question && (isNew || (questionId !== "")))
            return (
                <div className="text-center" >
                    <div className="mb-3 mt-0 p-0 ">
                        {questionType !== QuestionAskType.Pictures && questionType !== QuestionAskType.MoveMatch && <EditHeaderImage imageKey={imageKey} userFolder={userFolder} key={questionId} />}
                    </div>
                    <div className="mb-3 px-0">
                        <div className="mb-0 px-1">
                            {!hasResponses ?
                                <EditQuestionText questionText={questionText} choices={choices} questionType={questionType} isOrdered={isOrdered} hasResponses={hasResponses} />
                                :
                                <EditQuestionText hasResponses={true} questionText={questionText} />
                            }
                        </div>
                        <QuestionCreator hasResponses={hasResponses} questionType={questionType} />
                        <div className={" mb-0"}>
                            {isNew ?
                                canSave(this.props.qe.question) ?
                                    <button className="btn btn-sm btn-primary m-0 px-2 py-0 " onClick={() => this.handleSave()} >Save</button>
                                    : <button className="btn btn-sm m-0 px-2 py-0 inactiveBackgroundColor" disabled >Save</button>
                                :
                                hasChanged(this.props.qe.question) && canSave(this.props.qe.question) ?
                                    <button className="btn btn-sm btn-primary m-0 px-2 py-0 " onClick={() => this.handleUpdate()} >Update</button>
                                    : <button className="btn btn-sm m-0 px-2 py-0 inactiveBackgroundColor" disabled >Update</button>

                            }
                        </div>
                    </div>
                </div>
            )
        else
            return (<span />)
    }
}

export default connect(mapStateToProps, null)(CreateEditQuestion);