import React, { useState, useMemo, useEffect } from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import "../MoveMatchQuestions/MoveMatchChoices.css"; // Updated CSS import
import { connect } from "react-redux";
import { getCol } from "../Utilities";
import EditItemDisplay  from "../core/Choices/EditItemDisplay";
import RespondSummary from "../core/RespondSummary";
import { QuestionAskType, SelectorType } from "../../../constants/question";
import { respondToQuestion } from "../../../redux/actions/question-actions";
import InputSelector from "../core/Choices/InputSelector";
import { canSubmit } from "../core/CreateEditStatus";
import QuestionUserCount from "../core/Choices/QuestionUserCount";
import ResultsSummary from "../core/ResultsSummary";

function mapStateToProps(state) {
  return {
    viewProfileId: state.context.view.profileId,
  };
}

const RespondToTextQuestion = ({ question, viewProfileId, dispatch }) => {
  const { questionId, isOrdered, isMultipleChoice, choiceStats, questionType, maxSel, userFeed: { selections, stats: { RESQ } }} = question;
  const isRanking = question.questionType === QuestionAskType.Rank;
  const [choices, setChoices] = useState(question.choices);
  const [inputChoices, setInputChoices] = useState(isRanking ? Array.from(question.choices, (_, index) => index) : [])

  const selector = questionType === QuestionAskType.Rank ? SelectorType.Ranking : isOrdered ? SelectorType.Ordered : isMultipleChoice ? SelectorType.Multi : SelectorType.Single;

  useEffect(() => {
    setChoices(question.choices);
    setInputChoices(isRanking ? Array.from(question.choices, (_, index) => index) : [])
  },[choiceStats,isRanking,question.choices]);


  const handleSortEnd = (oldIndex, newIndex) => {
    if (oldIndex !== newIndex) {
      const updatedChoices = arrayMoveImmutable(choices, oldIndex, newIndex);
      setChoices(updatedChoices);
      setInputChoices(prevSelections => arrayMoveImmutable(prevSelections, oldIndex, newIndex));
    }
  };

  let canReset = isRanking ? Array.from(question.choices, (_, index) => index).toString() !== inputChoices.toString() : inputChoices.length > 0;;

  const handleReset = () => {
    if (isRanking) {
      setInputChoices(Array.from(question.choices, (_, index) => index));
      setChoices(question.choices);
    }
    else
      setInputChoices([]);
  }

  const handleSubmit = () => {
    var data = {
      "displayQuestion": question,
      "viewProfileId": viewProfileId,
      "selections": inputChoices,
    };
    dispatch(respondToQuestion({ ...data }))
  }

  const handleSelection = (item) => {
    var newarray = inputChoices.slice();
    if (selector === SelectorType.Single) {
      if (inputChoices.includes(item))
        newarray.pop()
      else {
        newarray.pop();
        newarray.push(item)
      }
    }
    if (selector === SelectorType.Multi || selector === SelectorType.Ordered || selector === SelectorType.Counter) {
      if (inputChoices.includes(item)) {
        const itemIndex = newarray.indexOf(item);
        newarray.splice(itemIndex, 1);
      }
      else {
        if (newarray.length < maxSel)
          newarray.push(item);
      }
    }
    setInputChoices(newarray);
  }

  const calculateColWidth = useMemo(() => {
    const maxLength = Math.max(...choices.map((choice) => choice.text.length), 1);
    return getCol(maxLength);
  }, [choices]);

  if(!question)
    return (<span/>);

  if (RESQ === 0) {
    return (
      <div className="px-3">
        {isRanking ?
          <SortableList
            onSortEnd={handleSortEnd}
            className={"d-flex flex-column align-items-center"}
            draggedItemClassName="dragged"
          >
            {inputChoices.map((choice, index) => (
              <SortableItem key={index}>
                <div key={questionId + index} className={`${calculateColWidth} text-center grid-item`}>
                  <EditItemDisplay question={question} choiceId={choice} >
                    <span className="activeColor float-end">
                      <InputSelector selector={selector} id={index} order={inputChoices.indexOf(index) + 1} isChecked={inputChoices.includes(index)} />
                    </span>
                  </EditItemDisplay>
                </div>
              </SortableItem>
            ))}
          </SortableList>
          :
          <div className={"row justify-content-center"}>
            {choices.map((choice, index) => (
              <div key={questionId + index} className={`${calculateColWidth} px-1 grid-item roundedBorder`} onClick={() => handleSelection(index)}>
                <EditItemDisplay choiceId={index} question={question} >
                  <span className="activeColor float-end">
                    <InputSelector selector={selector} id={index} order={inputChoices.indexOf(index) + 1} isChecked={inputChoices.includes(index)} handleSelection={handleSelection} />
                  </span>
                </EditItemDisplay>
              </div>
            ))}
          </div>
        }
        <RespondSummary handleSubmit={handleSubmit} handleReset={handleReset} displayQuestion={question} canSubmit={canSubmit({ question, inputChoices })} canReset={canReset} />
      </div>
    );
  }
  else {
    if (isRanking) {
      return (
        <div className="px-2">
          <div className={"d-flex flex-column align-items-center"}>
            {selections.map((selection, index) => (
              <div key={questionId + index} className={`${calculateColWidth} text-center grid-item`}>
                <EditItemDisplay text={choices[selection].text} choiceId={selection} question={question} questionType={question.questionType} >
                  <span className="activeColor float-end">
                    <QuestionUserCount choiceId={selection} orderId={index} question={question} />
                  </span>
                </EditItemDisplay>
              </div>
            ))
            }
          </div>
          <ResultsSummary key={questionId + "9"} displayQuestion={question} />
        </div>
      );
    }
    else {
      return (
        <div className="px-2">
          <div className={"row justify-content-center"}>
            {choices.map((choice, index) => (
              <div key={questionId + index} className={`${calculateColWidth} px-1 grid-item`}>
                <EditItemDisplay text={choice.text} question={question} choiceId={index} questionType={question.questionType} >
                  <span className="activeColor float-end">
                    <QuestionUserCount choiceId={index} question={question} />
                  </span>
                </EditItemDisplay>
              </div>
            ))
            }
          </div>
          <ResultsSummary key={questionId + "9"} displayQuestion={question} />
        </div>
      );
    }
  }
}

export default connect(mapStateToProps, null)(RespondToTextQuestion);
