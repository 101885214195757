import React, {  useMemo, useState } from "react";
import "../MoveMatchQuestions/MoveMatchChoices.css";
import { connect } from "react-redux";
import ResultsSummary from "../core/ResultsSummary";
import RespondSummary from "../core/RespondSummary";
import { respondToQuestion } from "../../../redux/actions/question-actions";
import QuestionUserCount from "../core/Choices/QuestionUserCount";
import InputSelector from "../core/Choices/InputSelector";
import { SelectorType } from "../../../constants/question";

const mapStateToProps = (state) => {
  return {
    viewProfileId: state.context.view.profileId,
  };
};

const RespondToRatingQuestion = ({ question, viewProfileId, dispatch }) => {
  const {
    questionId,
    isOrdered,
    isMultipleChoice,
    minSel,
    maxSel,
    userFeed: { stats: { RESQ } },
  } = question;

  const [checkedItems, setCheckedItems] = useState([]);
  const [canSubmit, setCanSubmit] = useState(false);

  const selector = useMemo(() => {
    if (isOrdered) return SelectorType.Counter;
    if (isMultipleChoice) return SelectorType.Multi;
    return SelectorType.Single;
  }, [isOrdered, isMultipleChoice]);

  const canReset = useMemo(() => checkedItems.length > 0, [checkedItems]);

  const handleSelection = (item) => {
    setCheckedItems((prev) => {
      if (selector === SelectorType.Single) {
        return prev.includes(item) ? [] : [item];
      }
      if (selector === SelectorType.Multi || selector === SelectorType.Ordered || selector === SelectorType.Counter) {
        return prev.includes(item)
          ? prev.filter((i) => i !== item)
          : prev.length < maxSel
          ? [...prev, item]
          : prev;
      }
      return prev;
    });

    setCanSubmit(checkedItems.length + 1 >= minSel);
  };

  const handleSubmit = () => {
    const data = {
      displayQuestion: question,
      viewProfileId,
      selections: checkedItems,
    };
    dispatch(respondToQuestion({ ...data }));
  };

  const handleReset = () => {
    setCheckedItems([]);
    setCanSubmit(false);
  };

  const renderChoices = () =>
    question.choices.map((choice, index) => (
      <div key={index} className="col choice-header border-bottom">
        {choice.text}
      </div>
    ));

  const renderInputSelectors = () =>
    question.choices.map((choice, index) => (
      <div key={index} className="col choice-header">
        {RESQ ? (
          <QuestionUserCount choiceId={index} question={question} />
        ) : (
          <InputSelector
            selector={selector}
            id={index}
            order={checkedItems.indexOf(index) + 1}
            isChecked={checkedItems.includes(index)}
            handleSelection={handleSelection}
          />
        )}
      </div>
    ));

  if (!RESQ) {
    return (
      <div className="px-2">
        <div className="row justify-content-center px-1 gx-5">
          <div className="container">
          <div className="row  g-0 align-bottom">{renderChoices()}</div>
            <div className="row text-center g-0">{renderInputSelectors()}</div>
          </div>
        </div>
        <RespondSummary
          handleSubmit={handleSubmit}
          handleReset={handleReset}
          displayQuestion={question}
          canSubmit={canSubmit}
          canReset={canReset}
        />
      </div>
    );
  } else {
    return (
      <div className="px-2">
        <div className="row justify-content-center px-1 gx-5">
          <div className="container">
            <div className="row px-1 g-0 align-bottom">{renderChoices()}</div>
            <div className="row text-center">{renderInputSelectors()}</div>
          </div>
        </div>
        <ResultsSummary key={`${questionId}9`} displayQuestion={question} />
      </div>
    );
  }
}


export default connect(mapStateToProps, null)(RespondToRatingQuestion);