import React from "react";
import UsersSelector from "./UsersSelector";
import Search from "../search/Search";
import HomeSelector from "./HomeSelector";
import MyFeed from "./MyFeed";
import UserMenu from "../menus/UserMenu";

const AuthNav = () => {

   return (
      <nav
         className="navBar ps-2 white shadow-none py-1 w-100"
         expand='md'
         fixed='top'
      >
         <div className=" w-100">
            <div className=" w-100 row justify-content-center no-gutters pb-0">
               <div className="col text-center">
                  <HomeSelector />
               </div>
               <div className="col text-center">
                  <UsersSelector />
               </div>
               <div className="col text-center ">
                  <MyFeed />
               </div>
               <div className="col text-center">
                  <Search />
               </div>
               <div className="col text-center">
                  <UserMenu />
               </div>
            </div>
         </div>
      </nav >


   );
}

export default AuthNav;