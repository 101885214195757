import React, { useState, useCallback } from 'react';
import { Button, InputAdornment, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateEditQuestion, updateQuestionRanges } from '../../../redux/actions/questionedit-actions';
import { calculateRanges, CreateChoices, CreateChoicesButton, endLabels, startLabels } from './utilities';
import { NumericType } from '../../../constants/question';

const MinMaxNumber = ({ numericType }) => {
  const dispatch = useDispatch();

  // Select values from Redux state
  const minRange1 = useSelector((state) => state.questionedit.question.minRange1);
  const maxRange1 = useSelector((state) => state.questionedit.question.maxRange1);

  // Local state for immediate updates
  const [localMinValue, setLocalMinValue] = useState(minRange1);
  const [localMaxValue, setLocalMaxValue] = useState(maxRange1);
  const [errMsg, setErrMsg] = useState("");

  // min value change handling
  const handleLocalMinValueChange = useCallback((event) => {
    const val = event.target.value;
    setLocalMinValue(val);
    setErrMsg(Number(val) + 3 < Number(localMaxValue) ? "" : "maximum must be 5 more than minimum");
  }, [localMinValue]);

  const handleMinValueBlur = useCallback(() => {
      dispatch(updateQuestionRanges({ question: { minRange1: localMinValue } }));
  }, [dispatch, localMinValue]);

  // const createChoices = () => {
  //   const choices = calculateRanges({ numericType })
  //   dispatch(updateEditQuestion({ question: { choices, numericType: numericType } }));
  // }
  // max value change handling
  const handleLocalMaxValueChange = useCallback((event) => {
    const val = event.target.value;
    setLocalMaxValue(val);
    dispatch(updateQuestionRanges({ question: { maxRange1: val } }));
    setErrMsg(Number(val) > Number(localMinValue) + 3 ? "" : "maximum must be 5 more than minimum");
  }, [localMaxValue]);

  const handleMaxValueBlur = useCallback(() => {
      dispatch(updateQuestionRanges({ question: { maxRange1: localMaxValue } }));
  }, [dispatch, localMaxValue]);

  return (
    <div className="row mb-0 mx-0 justify-content-center">
      <div className="col-5 p-1">
        <TextField
          label={startLabels[numericType]}
          type="number"
          size="small"
          value={localMinValue??''}
          onChange={handleLocalMinValueChange}
          onBlur={handleMinValueBlur}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {numericType === NumericType.Money ? '$' : ''}
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="col-5 p-1">
        <TextField
          label={endLabels[numericType]}
          type="number"
          size="small"
          value={localMaxValue??''}
          onChange={handleLocalMaxValueChange}
          onBlur={handleMaxValueBlur}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {numericType === NumericType.Money ? '$' : ''}
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="error-text smallfont">{errMsg}&nbsp;</div>
      <CreateChoicesButton canCreate={errMsg==="" && minRange1!==null && maxRange1!==null}/>
      {/* <div variant="contained" disabled={errMsg===""} className={`button white-text p-1 w-50 ${errMsg===""?"brand0-bg":"inactiveBackgroundColor"}`} onClick={() => createTheChoices()}>
        Generate Ranges
      </div> */}
    </div>
  );
};

export default MinMaxNumber;
