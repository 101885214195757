import React from "react";
import { PanelType } from "../../constants/panel"
import { connect } from "react-redux";
import UserProfileFeed from "../feeds/UserProfileFeed";
import QuestionUserFeed from "../feeds/QuestionUserFeed";
import UserConversationFeed from "../feeds/UserConversationFeed";
import ChatFeed from "../feeds/ChatFeed";
import ActivityFeed from "../feeds/ActivityFeed";

const mapStateToProps = (state, ownProps) => {
    return {
      panel:state.context.panel,
    };
  };
const PanelSelector = React.memo(function PanelSelector({panel}){ 
    const {feedId, targetId, panelType,choiceId, orderId} = panel;

    switch(panelType)
    {
        case PanelType.Default:
            return (<div><ActivityFeed/></div>)
        case PanelType.QuestionUsers:
           return (<QuestionUserFeed feedId={feedId} targetId={targetId} choiceId={choiceId} orderId={orderId} key={feedId}/>)      
        case PanelType.Users:
           return (<UserProfileFeed feedId={feedId} targetId={targetId} key={feedId}/>)         
        case PanelType.Chat:
            return (<ChatFeed feedId={feedId} />)      
        case PanelType.Conversations: 
            return (<UserConversationFeed feedId={feedId}/>)       
        default:
            return (<ActivityFeed/>) 
    }
});

export default connect(mapStateToProps, null)(PanelSelector);