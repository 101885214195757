import React from "react";
import { connect } from "react-redux";
import { ViewType } from "../../constants/profile";
import { Drawer } from "@mui/material";

import { hidePanel, toggleLeftPanel } from "../../redux/actions/panel-actions";
import MainFeedActionCard from "./MainFeedActionCard";
import ProfileCard from "./ProfileCard";
import UserFeedActionCard from "./UserFeedActionCard";
import PublicFeedActionCard from "./PublicFeedActionCard";
import ActionViewSelector from "./ActionViewSelector";

const mapStateToProps = (state, ownProps) => {
   return {
      viewType: state.context.view.viewType,
      leftPanelIsOpen: state.context.panel.leftPanelIsOpen,
      profileId: state.profile.currentUser.profileId
   };
};

const MobileViewSelector = React.memo(function MobileViewSelector({ viewType, profileId, leftPanelIsOpen, dispatch }) {


   const handleDrawerToggle = () => {
      dispatch(hidePanel());
      dispatch(toggleLeftPanel());
   };

   // const ActionViewSelector = () => {
   //    switch (viewType) {
   //       case ViewType.MainView:
   //          return (<MainFeedActionCard />)
   //       case ViewType.UserView:
   //          return (<UserFeedActionCard />)
   //       case ViewType.QuestionView:
   //       case ViewType.SharedView:
   //       case ViewType.PublicView:
   //       case ViewType.UserProfile:
   //          return (<PublicFeedActionCard key={profileId} />)
   //       default:
   //          alert("error");
   //    }
   // }

   return (
      <Drawer
         PaperProps={{
            sx: { width: "300" },
         }}
         open={leftPanelIsOpen}
         onClose={handleDrawerToggle}
         transitionDuration={{ enter: 100, exit: 100 }}
         anchor='left'
         variant="temporary" ModalProps={{
            keepMounted: true, // Better open performance on mobile.
         }}>
         <div className={"mobileDrawer"} >
            <ActionViewSelector />
         </div>
      </Drawer>
   )

});

export default connect(mapStateToProps, null)(MobileViewSelector);