import { QuestionAskType } from "../../constants/question";

export function questionInstructions(question) {
    const { questionType, isOrdered, maxSel, buckets } = question;
    let instructions;
    switch (questionType) {
        case QuestionAskType.Rank:
            instructions = `rank choices from top to bottom`;
            break;
        case QuestionAskType.MoveMatch:
            instructions = `slide images to the category you prefer`;
            break;
        case QuestionAskType.Rate:
            instructions = `how would you rate your experience`;
            break;
        case QuestionAskType.Pictures:
        case QuestionAskType.Select:
        case QuestionAskType.Lottery:
            if (isOrdered)
                if (maxSel === buckets)
                    instructions = `select all choices in order of preference`;
                else
                    instructions = `select ${maxSel} choices in order of preference`;
            else {
                if (maxSel === buckets)
                    instructions = `select multiple choices`;
                else
                    if (maxSel > 1)
                        instructions = `select up to ${maxSel} choices`;
                    else
                        instructions = `select a single choice`;
            }
            break;
        case QuestionAskType.Numeric:
            instructions="enter a value between 1 and 20";
            break
        default:
            instructions = `select a choice`
            break;
    };
    return instructions;
}

export function choiceResults(question) {
    const { questionType, isOrdered, maxSel, buckets } = question;
    let instructions;
    switch (questionType) {
        case QuestionAskType.Rank:
            instructions = `ranked from top to bottom`;
            break;
        case QuestionAskType.MoveMatch:
            instructions = `users preferences`;
            break;
        case QuestionAskType.Rate:
            instructions = `user experiences`;
            break;
        case QuestionAskType.Pictures:
        case QuestionAskType.Select:
        case QuestionAskType.Lottery:
            if (isOrdered)
                if (maxSel === buckets)
                    instructions = `user choices by order of preference`;
                else
                    instructions = `${maxSel} user choices in order of preference`;
            else {
                if (maxSel === buckets)
                    instructions = `user choices`;
                else
                    if (maxSel > 1)
                        instructions = `up to ${maxSel} user choices`;
                    else
                        instructions = `single user choice`;
            }
            break;
        default:
            instructions = `select a choice`
            break;
    };
    return instructions;
}

export const moveMatchOptions = {
    "Hot or Not": ["Hot", "Not"],
    "Genius - Not": ["Genius", "Not"],
    "Winner - Loser": ["Winner", "Loser"],
    "Yes No": ["Yes", "No"],
    "Up Down Flat": ["Up", "Down", "Flat"],
    "Catch Release": ["Catch", "Release"],
    "Kiss-Marry-Kill": ["Kiss", "Marry", "Kill"],
    "Breakfast-Lunch-Dinner": ["Breakfast", "Lunch", "Dinner"],
    "Love Hate Don't Care": ["Love", "Hate", "Don't Care"],
    "1st 2nd No Choice": ["1st Choice", "2nd Choice", "No Choice"],
    "Yum! Ok Yuck!": ["Yum", "Just Ok", "Yuck!"],
    "Keep Repair Demolish!": ["Keep", "Repair", "Demolish"],
    "Fix It Trash It": ["Fix It", "Trash It"],
    "Yes - No": ["Yes", "No"],
    "Genius - Not": ["Genius", "Not"],
    Custom2: ["Custom 1", "Custom 2"],
    Custom3: ["Custom 1", "Custom 2", "Custom 3"],
  };