
const global={};
export default global;

export function timeNow(){
    return   new Date().toISOString();
}

export const notImplementedMsg = {
content: "This feature has not yet been implemented.",
title: "Feature Not Implemented"
}

export const tabletSize = 768;
export const lgScreenSize = 1100;
export const smScreenSize = 430;
export const xsScreenSize = 400;
