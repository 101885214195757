import React from "react";
import { connect } from "react-redux";
import { Icons } from "../../shared/QIcons";
import { showTaglinkModal } from "../../redux/actions/modal-actions";
import "../question/core/index.css";
import notify from "../notify/Notify";
import { responseMsg } from "../../constants/question";

const mapStateToProps = (state) => {
    return {
        viewProfileId: state.context.view.profileId,
        viewType: state.profile.viewProfile.viewType,
        userId: state.profile.currentUser.profileId,
    };
};

const TagLink = React.memo(function Taglink({ displayQuestion, dispatch }) {
    const { userFeed, draft } = displayQuestion;
    const { stats } = userFeed

  function editTags(){
    if(draft)
        notify(responseMsg);
    else
         dispatch(showTaglinkModal({displayQuestion:displayQuestion }));         
       }

   return (
       <span>
         <i className={stats.TAGQ ? Icons.TAG + "brand0 pointer linkPadRight" : Icons.TAG + " inactiveColor pointer linkPadRight"}  onClick={()=>editTags()}/>
       </span>
   )
});

export default connect(mapStateToProps, null)(TagLink);