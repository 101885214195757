import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../shared/QIcons";
import { hidePanel, toggleLeftPanel } from "../../redux/actions/panel-actions";
import ShowMdDn from "../../shared/ShowMdDn";
import ShowLgUp from "../../shared/ShowLgUp";
import { UserFeeds } from "../../constants/feedids";

const mapStateToProps = (state) => {
   return {
      profileId: state.context.view.profileId,
      userId: state.profile.currentUser.profileId,
      feedId: state.feed.feedId,
   };
};

const MyFeed = React.memo(function MyFeed({ profile, feedId, profileId, userId, dispatch }) {
   const navigate = useNavigate();
   const isSelected = profileId === userId ;

   function loadFeed() {
      if (isSelected)
         handleDrawerToggle();
      else {
         navigate('/feed');
      }
   }

   const handleDrawerToggle = () => {
      dispatch(hidePanel());
      dispatch(toggleLeftPanel());
   };

   function iconToShow() {
      if (isSelected)
         return Icons.FEEDSELECTED + " brand3";
      else
         return Icons.FEED;
   }

   return (
      <div className=" text-center pointer activeColor" onClick={loadFeed}>
         <ShowLgUp> <i className={Icons.FEED + "fa-lg"}></i><span className="ml-1 pt-1">Feed{isSelected && "*"}</span></ShowLgUp>
         <ShowMdDn>  <i className={iconToShow()}></i><div className="col-12 footerlabel">Feed{isSelected && "*"}</div></ShowMdDn>
      </div>
   )
})

export default connect(mapStateToProps, null)(MyFeed);