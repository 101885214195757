import { takeEvery, call, put, all } from "redux-saga/effects";
import { FeedActions as Actions } from "../constants";
import { Feeds, PublicFeeds, UserFeeds } from "../../constants/feedids";
import {
  queryLocalProfilesByInteraction,
  listProfilesByInteraction,
  queryUsersByQuestionChoice,
  listUserProfiles,
  queryLocalProfiles,
} from "../../repository/profileQueries";
import {
  buildLocalFeed,
  getLocalQuestion,
  queryLocalQuestionsByInteraction,
  listQuestionsByInteraction,
  getSharedQuestion,
  listPublicFeed,
  getQuestion,
  listUserFeed,
  getLocalDrafts,
  buildLocalQuestion,
  checkForLocalQuestion,
} from "../../repository/questionQueries";
import { SystemId } from "../../constants/profile";
import {
  listLocalQuestionsTagged,
  listLocalUserTags,
  listQuestionsByTag,
  listTagsByProfile
} from "../../repository/tagQueries";
import store from "../store";


//***    DataStore question update received             ******/
export function* localQuestionUpdateReceived() {
  yield takeEvery(
    Actions.LOCAL_QUESTION_UPDATE_RECEIVED,
    localQuestionUpdateWorker
  );
}

function* localQuestionUpdateWorker(action) {
  try {
    const payload = structuredClone(action.payload);
    yield put({ type: Actions.LOCAL_QUESTION_UPDATED, payload });
  } catch (e) {
    console.log("error updating question");
    yield put({ type: Actions.FEED_API_ERROR, payload: "e" });
  }
}

export function* watchGetDatabaseFeed() {
  yield takeEvery(
    [
      Actions.QUESTION_FEED_REQUESTED,
      Actions.USER_FEED_REQUESTED,
      Actions.TAG_FEED_REQUESTED,
    ],
    getFeedWorker
  );
}

function* getFeedWorker(action) {
  const { targetId, feedId } = action.payload;
  if (!targetId && !feedId) return;
  try {
    let payload = yield call(getDatabaseFeed, action.payload);
    // if (!payload){
    //     return;
    // }
    switch (action.type) {
      case Actions.QUESTION_FEED_REQUESTED:
        yield put({ type: Actions.FEED_DATA_RECEIVED, payload });
        break;
      case Actions.USER_FEED_REQUESTED:
        yield put({ type: Actions.USER_FEED_RECEIVED, payload });
        break;
      case Actions.TAG_FEED_REQUESTED:
        yield put({ type: Actions.TAG_FEED_RECEIVED, payload });
        break;
      default:
        break;
    }
  } catch (e) {
    yield put({ type: Actions.FEED_API_ERROR, payload: e });
  }
}

export async function getDatabaseFeed(payload) {
  let { feedId, nextToken } = payload;
  nextToken = nextToken ?? null;
  const feedCategory = feedId[0];
  // L = local user, A = specific public user, P = all users
  const feedSubcategory = feedId[4];

  payload.userId = payload.profileId;
  var feedData;

  switch (feedCategory) {
    case "L":
      switch (feedSubcategory) {
        case "U":
        case "C":
          if (feedId.includes("ALL")) {
            feedData = await queryLocalProfiles(payload);
          } else {
            feedData = await queryLocalProfilesByInteraction(payload);
          }
          break;
        case "T":
          if (feedId.includes("DRFT")) {
            feedData = await getLocalDrafts(payload);
          }
          else
            if (feedId === UserFeeds.UserTags) {
              //feedData = await listTagsByProfile(payload);
              feedData = await listLocalUserTags(payload);
            } else {
              feedData = await listLocalQuestionsTagged(payload);
            }
          break;
        case "Z":
          feedData = await getLocalQuestion(payload);
          break;
        default:
          if (feedId.includes("ALL")) {
            feedData = await listUserFeed(payload);
          } else {
            feedData = await queryLocalQuestionsByInteraction(payload);
          }
          break;
      }
      break;
    //return feedData;
    case "A":
      switch (feedId) {
        default:
          feedData = await listProfilesByInteraction(payload);
          break;
      }
      break;
    //return feedData;
    case "P":
      switch (feedSubcategory) {
        case "U":
          feedData = await listProfilesByInteraction(payload);
          break;
        case "T":
          if (feedId === PublicFeeds.UserTags) {
            feedData = await listTagsByProfile(payload);
          } else {
            feedData = await listQuestionsByTag(payload);
          }
          break;
        case "Q":
          if (feedId === PublicFeeds.UserFeedQuestions)
            feedData = await listPublicFeed(payload);
          else
            feedData = await listQuestionsByInteraction(payload);
          break;
        default:
          break;
      }
      break;
    //return feedData;
    case "X":
    case "R":
      feedData = await listQuestionsByInteraction(payload);
      break;
    case "Z":
      switch (feedId) {
        case Feeds.TaggedQuestions:
          delete payload.profileId;
          feedData = await listQuestionsByTag(payload);
          break;
        case Feeds.AllHashtags: {
          const { startedAt } = payload;
          feedData = await listTagsByProfile({
            targetId: SystemId, startedAt: startedAt, feedId: feedId
          });
          break;
        }
        case Feeds.AllUsers:
          feedData = await listUserProfiles(payload );
          break;
        case Feeds.AllQuestions:
          payload.profileId = SystemId;
          feedData = await listPublicFeed(payload);
          break;
        case Feeds.ParentQuestions:
          feedData = await getQuestion(payload);
          break;
        default:
          feedData = await listQuestionsByInteraction(payload);
      }
      break;
    case "F":
      switch (feedId) {
        case Feeds.ChildQuestions:
          feedData = await listQuestionsByInteraction(payload);
          break;
        case Feeds.QuestionView:
          feedData = await getSharedQuestion(payload)
          break;
        case Feeds.ChoiceUsers:
          feedData = await queryUsersByQuestionChoice(payload);
          break;
        case PublicFeeds.UserFeedQuestions:
          feedData = await listPublicFeed(payload);
          break;
        default:
          break;
      }
      //break;
      return feedData;
    default:
      break;
  }
  feedData.isFirstFeed = nextToken === null;
  return feedData;
}

export function* processLocalFeedQuery() {
  yield takeEvery(Actions.LOCAL_FEED_QUERY_RECEIVED, getLocalFeedWorker);
}

function* getLocalFeedWorker(action) {
  try {
    const payload = yield call(buildLocalFeed, action.payload);
    yield put({ type: Actions.FEED_DATA_RECEIVED, payload });
  } catch (e) {
    yield put({ type: Actions.FEED_API_ERROR, payload: e });
  }
}

export function* processQuestionSubscription() {
  yield takeEvery(Actions.PROCESS_QUESTION_SUBSCRIPTION, processQuestionSubscriptionWorker);
}

function* processQuestionSubscriptionWorker(action) {
  try {
    const payload = yield call(checkForLocalQuestion, action.payload);
    if(payload){
      yield put({ type: Actions.QUESTION_UPDATED, payload });
    }
    else
    return;
  } catch (e) {
    yield put({ type: Actions.FEED_API_ERROR, payload: e });
  }
}
export function* processUserFeedSubscription() {
  yield takeEvery(Actions.PROCESS_USERFEED_SUBSCRIPTION, processUserFeedSubscriptionWorker);
}

function* processUserFeedSubscriptionWorker(action) {
  try {
    const payload = yield call(buildLocalQuestion, action.payload);
    if(payload)
      yield put({ type: Actions.USERFEED_SUBSCRIPTION_RECEIVED,  payload});
  } catch (e) {
    yield put({ type: Actions.FEED_API_ERROR, payload: e });
  }
}


export default function* rootSaga() {
  yield all([
    processQuestionSubscription(),
    processUserFeedSubscription(),
    watchGetDatabaseFeed(),
    localQuestionUpdateReceived(),
    processLocalFeedQuery(),
  ]);
}
