import React from "react";
import QuestionUserSummary from "../core/links/QuestionUserSummary";
import MiddleThing from "./MiddleThing";
import { connect } from "react-redux";
import { choiceResults } from "../constants";
import ResultsActionButton from "./ResultsActionButton";

const mapStateToProps = (state) => {
  return {
    isObserver: state.profile.isPremium
  };
};


const ResultsSummary = React.memo(function ResultsSummary({ displayQuestion }) {
  const { questionText, questionId, stats: { RESQ }, userFeed: { isPrivate, draft } } = displayQuestion;

  const handleSubmit = () => {
    console.log("submitting");
  }

  return (
    <div >
      <div className="smallfont brand0 mb-1 ">&nbsp;</div>
      <div>
        <MiddleThing key={questionId}
          left={<span className="brand1 pt-2"></span>}
          center={<ResultsActionButton handleSubmit={() => handleSubmit()} question={displayQuestion} />}
          right={<span className="float-end pt-2">
            <QuestionUserSummary displayQuestion={displayQuestion} isPrivate={isPrivate} responded={true}
              count={RESQ} questionText={questionText} questionId={questionId} />
          </span>}
        /></div>
    </div>
  )
})

export default connect(mapStateToProps, null)(ResultsSummary);