

import { setErrorMsg} from './redux/actions/questionedit-actions';
import store  from './redux/store/index';
import notify from './components/notify/Notify';



export default function notYet(msg) {
    notify("This feature has not yet been implemented." )
}

export function hasChoiceDups(newValue) {
    const choices = store.getState().questionedit.question.choices;
    const found = choices.findIndex(choice => choice.text === newValue);
    const isDup = found!==-1;
   if(isDup)
    {
        store.dispatch(setErrorMsg({errorMsg:"duplicate choices aren't allowed"}));
        return true;
    }
    else {
        if(store.getState().questionedit.errorMsg!=="")
            store.dispatch(setErrorMsg({errorMsg:""}));
        return false;
    }
}
