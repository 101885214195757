import React from "react";


const FeedHeader = ({ avatar, title, titleFont, headerColor = "panelHeader-bg", topRight, details, bottomLeft, bottomCenter, bottomRight }) => {

    return (
        <div className={`feedHeader d-flex ${headerColor} py-1`}>
            <div className="flex-shrink-0 ps-1">
                {avatar}
            </div>
            <div className="flex-grow-1 ms-1 ">
                <div className="row g-0 pt-1 font ">
                    {/* <div className={`d-flex ${titleFont} justify-content-between pe-2 fontWeight-bold`}> */}
                    <div className={`col-8  ${titleFont}`}>
                        <strong>{title}</strong></div>
                    <div className="col-4 pe-2">{topRight}</div>
                </div>
                <div className="row g-0 font">
                    <div className="col-4 ">
                        {bottomLeft}
                    </div>
                    <div className="col-4 ">
                        {bottomCenter}
                    </div>
                    <div className="col-4 ">
                        <span className="float-end pe-2">{bottomRight}</span>
                    </div>
                </div>
                <div className="row g-0 ">
                    {details}
                </div>
            </div>
        </div>
    )
}

export default FeedHeader;

