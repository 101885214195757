import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DataStore } from '@aws-amplify/datastore';
import { UserFeed } from '../../../models'; // Adjust import path accordingly
import Similarity from './MOS/Similarity'; // Adjust import path accordingly
import notify from '../../notify/Notify';
import NotifyAlert from '../../notify/NotifyAlert';

const options = {
  submit: { text: "submit", message: "" },
  publish: { text: "publish", message: "" },
  previous: { text: "previous", message: "You must respond to the previous question first." },
  similarity: { text: "similarity", message: "" },
  pending: { text: "pending", message: "Question is scheduled to be published." },
  related: { text: "related", message: "This is a related question. You must first answer the previous question." },
  viewing: { text: "viewing", message: "This question is not applicable based on your previous response" },
  select: { text: "select", message: "Select required choices and press submit" },
  expired: { text: "expired", message: "This question has expired." },
  statistics: { text: "statistics", message: "Premium users can only observe responses" },
};

const RespondActionButton = ({ question, handleSubmit, canSubmit }) => {
  const [respondedToParent, setRespondedToParent] = useState(false);
  const [linkMatch, setLinkMatch] = useState(false);
  const [btnOption, setBtnOption] = useState("observe");

  // Get user profile from Redux store
  const profile = useSelector((state) => state.profile.currentUser);
  const isPremiumUser = useSelector((state) => state.profile.isPremiumUser || state.profile.isAdministrator);
  const { profileId } = profile;

  // Extract question details
  const { questionId, startTime, endTime, parentId, linkSelections, qmosr, draft, userFeed: userFeedFromQuestion, isPremium: isPremiumQuestion, stats, creatorId } = question;
  const userResponded = userFeedFromQuestion?.stats?.RESQ > 0;

  // Compute conditions
  const isOwner = profileId === creatorId;
  const tooEarly = new Date().toISOString() < startTime;
  const tooLate = new Date().toISOString() > endTime;
  const canRespond = (isPremiumUser && creatorId === profileId) ? false : true;

  // Fetch parent user feed if applicable
  useEffect(() => {
    const fetchParentUserFeed = async () => {
      if (!parentId) return;

      try {
        const userFeedData = await DataStore.query(UserFeed, (u) => u.questionId.eq(parentId));
        if (userFeedData.length > 0) {
          const userFeed = userFeedData[0];
          if (userFeed.stats.RESQ > 0) {
            setRespondedToParent(true);
            setLinkMatch(linkSelections.length === 0 || userFeed.selections.some(selection => linkSelections.includes(selection)));
          }
        }
      } catch (error) {
        console.error('Error fetching parent question:', error);
      }
    };

    fetchParentUserFeed();
  }, [parentId, linkSelections]);


    if (isPremiumUser) {
      if (draft && isOwner) {
        if (tooEarly && !tooLate)
          return (
            <button className="btn btn-primary btn-sm w-100 m-0 pointer py-0 " onClick={() => NotifyAlert("question is queued for publishing")}>
              pending
            </button>
          );
        else
          return (
            <button className="btn btn-primary btn-sm w-100 m-0 pointer py-0" onClick={() => NotifyAlert("put in publish logic")}>
              publish
            </button>
          )
      }
    }
    if (tooLate) {
      return (
        <button className="btn btn-primary btn-sm w-100 m-0 pointer py-0 " onClick={() => NotifyAlert("response time for question has passed")}>
          expired
        </button>
      );
    }
   if (respondedToParent && !linkMatch)
    return (
      <button className="btn btn-primary btn-sm w-100 m-0 roundedBorder pointer py-0 " onClick={() => NotifyAlert("question not applicable based on previous response")}>
        viewing
      </button>
    );
    
    if(parentId && !respondedToParent)
      return (
        <button className="btn btn-primary btn-sm w-100 m-0 roundedBorder pointer py-0 " onClick={() => NotifyAlert("you must answer previous question first")}>
          previous
        </button>
      );

    if(!userResponded)
      if(canSubmit)
        return (
          <button className="btn btn-primary btn-sm roundedBorder w-100 m-0 pointer py-0 "  onClick={() => handleSubmit(questionId)}>
            submit
          </button>
        );
        else
        return (
          <button className="btn btn-primary btn-sm roundedBorder w-100 m-0 pointer py-0 " onClick={() => NotifyAlert("choose message?")}>
            choose
          </button>
        );

};

RespondActionButton.propTypes = {
  question: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  canSubmit: PropTypes.bool.isRequired,
};

export default RespondActionButton;

