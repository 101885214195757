import {
    AuthActions as Actions
     } from "../../constants/auth";
  
  export function sign_Out(payload) {
    return { type:Actions.SIGN_OUT,payload};
  }
  
  export function setAuthenticationState(payload) {
    return { type:Actions.SET_ISAUTHENTICATED,payload};
  }

  export function setSigninRedirect(payload) {
    return { type:Actions.SET_SIGNIN_REDIRECT,payload};
  }

  export function setCredentials(payload) {
    return { type:Actions.SET_CREDENTIALS,payload};
  }

