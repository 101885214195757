import React, { useEffect } from "react";
import { connect } from "react-redux";
import { UserFeeds } from "../../constants/feedids";
import ActionViewSection from "./ActionViewSection";
import { menuIcons } from "../menus/menuIcons";
import ProfileCard from "./ProfileCard";
import { closePanels, setView } from "../../redux/actions/context-actions";
import { Hashtag } from "../../models";
import { DataStore } from "aws-amplify";
import { useState } from "react";

const mapStateToProps = (state) => {
    return {
        userProfileIsLoaded: state.profile.userProfileIsLoaded,
        profile: state.profile.currentUser,
        profileId: state.profile.currentUser.profileId,
        isPremiumUser: state.profile.isPremiumUser,
        panelIsOpen: state.context.panel.isOpen,
        feedId: state.feed.feedId
    };
};


const UserFeedActionCard = React.memo(function UserFeedActionCard({ profileId, feedId, userProfileIsLoaded, panelIsOpen, isPremiumUser, profile: { stats, tags, firstName }, dispatch }) {
    const [hashtags, updateHashtags] = useState(null);


    useEffect(() => {
        let subscription;
        if (!userProfileIsLoaded)
            return;

        const fetchAndSubscribe = async () => {
            // Fetch initial data
            const tags = await DataStore.query(Hashtag);
            updateHashtags(tags);

            // Subscribe to updates
            subscription = DataStore.observe(Hashtag).subscribe((msg) => {
                if (['INSERT', 'UPDATE', 'DELETE'].includes(msg.opType)) {
                    DataStore.query(Hashtag).then(updateHashtags);
                }
            });
        };

        fetchAndSubscribe();

        // Cleanup subscription on unmount
        return () =>{ 
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, []);

    function getQuestions(tag) {
        if (panelIsOpen)
            dispatch(closePanels());
        dispatch(setView({ tag: tag, targetId: profileId, feedId: UserFeeds.TaggedQuestions, name: firstName }));
    }

    const ShowTags=()=>{
        return(
            <div>
            {hashtags ? (
                <div className="flex tagContainer">
                    {hashtags.map((tag) => (
                        <span key={tag.tag}  className="tag brand0" onClick={() => getQuestions(tag.tag)}>
                            {tag.tag} ({tag.count})
                        </span>
                    ))}
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    )
    }

    if (userProfileIsLoaded)
        return (
            <div className="drawerCard white shadow-none " style={{ width: "310px", height: "100%" }} key={"userFeed"}>
                    <ProfileCard/>
                    <ActionViewSection icon={menuIcons.UserTags}>
                        <div className=" m-0 ps-2 ">
                            <ShowTags/> 
                        </div>
                    </ActionViewSection>
                </div>
        )

}
)

export default connect(mapStateToProps, null)(UserFeedActionCard);



