import {
  FeedActions as Actions
   } from "../constants";

   

  export function getQuestionFeed(payload) {
    return { type:Actions.QUESTION_FEED_REQUESTED,payload };
  }
  export function getUserFeed(payload) {
    return { type:Actions.USER_FEED_REQUESTED,payload };
  }


  export function questionUpdated(payload) {
    return { type:Actions.QUESTION_UPDATED ,payload}
  }

  export function userFeedUpdated(payload) {
    return { type:Actions.USERFEED_UPDATED ,payload}
  }

  export function localQuestionUpdated(payload) {
    return { type:Actions.LOCAL_QUESTION_UPDATE_RECEIVED ,payload}
  }

  export function localUserFeedUpdated(payload) {
    return { type:Actions.LOCAL_USERFEED_UPDATE_RECEIVED ,payload}
  }

  export function localProfileUpdated(payload) {
    return { type:Actions.LOCAL_PROFILE_UPDATE_RECEIVED ,payload}
  }

  export function viewQuestionReceived(payload) {
    return { type:Actions.VIEW_QUESTION_RECEIVED ,payload}
  }

  export function questionUpdateReceived(payload) {
   return { type:Actions.VIEW_QUESTION_UPDATED ,payload}
 }

 export function buildUpateFromQuestion(payload) {
  return { type:Actions.BUILD_UPDATE_FROM_QUESTION ,payload}
}

export function buildUpateFromUserFeed(payload) {
  return { type:Actions.BUILD_UPDATE_FROM_USERFEED ,payload}
}

 export function localUpdateReceived(payload) {
  return { type:Actions.VIEW_QUESTION_UPDATED ,payload}
}

 export function questionDeleteReceived(payload) {
  return { type:Actions.QUESTION_DELETE_RECEIVED ,payload}
}

  export function feedDataReceived(payload) {
    return { type:Actions.FEED_DATA_RECEIVED ,payload}
  }

  export function processQuestionSubscription(payload) {
    return { type:Actions.PROCESS_QUESTION_SUBSCRIPTION ,payload}
  }

  export function processUserFeedSubscription(payload) {
    return { type:Actions.PROCESS_USERFEED_SUBSCRIPTION ,payload}
  }

  export function localFeedQueryReceived(payload) {
    return { type:Actions.LOCAL_FEED_QUERY_RECEIVED ,payload}
  }

  export function userFeedSubscriptionReceived(payload) {
    return { type:Actions.USERFEED_SUBSCRIPTION_RECEIVED ,payload}
  }

  export function interactionSubscriptionReceived(payload) {
    return { type:Actions.INTERACTION_SUBSCRIPTION_RECEIVED ,payload}
  }

  export function questionSubscriptionReceived(payload) {
    return { type:Actions.QUESTION_SUBSCRIPTION_RECEIVED ,payload}
  }

  export function profileFeedSubscriptionReceived(payload) {
    return { type:Actions.PROFILEFEED_SUBSCRIPTION_RECEIVED ,payload}
  }

   export function questionFeedReceived(payload) {
    return { type:Actions.FEED_DATA_RECEIVED ,payload}
  }

  export function userFeedReceived(payload) {
    return { type:Actions.USER_FEED_RECEIVED ,payload}
  }

export function toggleSearching(payload) {
  return { type:Actions.TOGGLE_SEARCHING,payload};
}


export function getView(payload) {
    return { type:Actions.QUESTION_VIEW_REQUESTED,payload };
  }

export function getQuestionsByQuestionInteraction(payload) {
    return { type:Actions.QUESTION_INTERACTION_FEED_REQUESTED,payload };
  }  

export function getQuestionsByAssociatedInteraction(payload) {
    return { type:Actions.ASSOCIATED_INTERACTION_FEED_REQUESTED,payload };
  } 

  export function getQuestionsByRelatedInteraction(payload) {
    return { type:Actions.RELATED_INTERACTION_FEED_REQUESTED,payload };
  } 
  export function getLinkFeed(payload) {
    return { type: Actions.LINK_FEED_DATA_REQUESTED, payload };
  }

  export function getViewQuestion(payload) {
    return { type: Actions.VIEW_QUESTION_REQUESTED, payload };
  }


    export function processQuestionInteraction(payload) {
    return { type: Actions.QUESTION_INTERACTION_RECEIVED, payload };
  }

  export function setQuestionSubscription(payload) {
    return { type:Actions.SET_QUESTION_SUBSCRIPTION ,payload}
  }

  export function setFeedSubscription(payload) {
    return { type:Actions.SET_FEED_SUBSCRIPTION ,payload}
  }

  export function clearFeedSubscription(payload) {
    return { type:Actions.CLEAR_FEED_SUBSCRIPTION ,payload}
  }

  export function popFeed() {
    return { type: Actions.POP_FEED };
  }
  
  
export function removeFeedData(payload) {
  return { type:   Actions.REMOVE_FEED_DATA_REQUESTED,payload };
}

