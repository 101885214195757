//Public.js
import React, { useEffect } from "react";
import { connect } from "react-redux";
import "./index.css"
import { ViewType } from "../constants/profile";
import { Feeds, PublicFeeds } from "../constants/feedids";
import { setView } from "../redux/actions/context-actions";
import { getViewProfile } from "../redux/actions/profile-actions";

const mapStateToProps = (state, ownProps) => {
  return {
    userId: state.profile.currentUser.profileId,
    panelFeedId:state.context.panel.feedId
  };
};

const PublicView = React.memo(function PublicView({  profileId,  panelFeedId,userId, dispatch }) {
  let feedToLoad = PublicFeeds.UserFeedQuestions;
  let viewToLoad = ViewType.PublicView;
  let profileToLoad = profileId ?? userId;

  useEffect(() => {
    dispatch(getViewProfile({ profileId: profileToLoad }));
    dispatch(setView({ viewType: viewToLoad, profileId: profileToLoad, feedId: feedToLoad }));
            // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileToLoad]); // Only re-run the effect if count changes

  return (
    <></>
  )
})

export default connect(mapStateToProps, null)(PublicView);