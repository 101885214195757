import React from "react";
import { connect } from "react-redux";
import { smScreenSize } from "../constants";

const mapStateToProps = (state) => {
    return {
        screenWidth: state.context.screenWidth,
    };
};

export const HideSmUp = React.memo(function HideSmUp({ children, screenWidth }) {
    if (screenWidth >smScreenSize)
        return <span/>
    else
        return(<div>{ children }</div>);
})

export default connect(mapStateToProps, null)(HideSmUp);