import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { PublicFeeds, UserFeeds } from "../../constants/feedids";
import { AvatarSm } from "../../shared/Avatars";
import { hidePanel, toggleLeftPanel } from "../../redux/actions/panel-actions";
import { SystemId } from "../../constants/profile";


const mapStateToProps = (state) => {
    return {
        viewProfileId: state.context.view.profileId,
        viewProfileIsLoaded: state.profile.viewProfileIsLoaded,
        userId: state.profile.currentUser.profileId,
    };
};

const AvatarLink = React.memo(function AvatarLink({ userId, profile, viewProfileId, viewProfileIsLoaded,dispatch }) {
    if(!profile || profile.profileId ===SystemId)
        return (
            <span/>
        )
    const { firstName, lastName, profileId, avatarURL } = profile;
    const isActive = profileId === viewProfileId;
    const isOwner = userId === profileId;
    const initials = firstName[0].toUpperCase() + lastName[0].toUpperCase();


    const handleDrawerToggle = () => {
        dispatch(hidePanel());
        dispatch(toggleLeftPanel());
    };

    if (isActive)
        return (
            <div className=" text-center pointer navbaricon d-flex py-1 ">
            <span onClick={() => handleDrawerToggle()}>
                <AvatarSm url={avatarURL} initials={initials} />
            </span>
            </div>
        )
    else
        if (isOwner)
            return (
                <div className=" text-center pointer navbaricon d-flex py-1 ">
                <Link to="/feed"  state={{ profileId: userId, feedId: UserFeeds.UserFeedQuestions }}>
                    <AvatarSm url={avatarURL} initials={initials} />
                </Link>
                </div>
            )
        else
            return (
                <div className=" text-center pointer navbaricon d-flex py-1 ">
                <Link to="/publicView"  state={{ profileId: profileId, feedId: PublicFeeds.UserFeedQuestions }}>
                    <AvatarSm url={avatarURL} initials={initials} />
                </Link>
                </div>
            )
})

export default connect(mapStateToProps, null)(AvatarLink);