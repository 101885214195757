import React from "react";
import { connect } from "react-redux";
import { updateEditQuestion } from "../../../../../redux/actions/questionedit-actions"
import { activeColor, Icons } from "../../../../../shared/QIcons";
import LyqPopover from "../../../core/LyqPopOver";

function mapStateToProps(state) {
    return {
      hasResponses:state.questionedit.hasResponses,
      question: state.questionedit.question
    };
  };

  const msg = () => {
    return (<span>Select the number of slections the user is required to make before they can submit their responses.
        As they select choices the diamond <i className={`${Icons.DIAMOND} ${activeColor}`}></i> will change to show the 
        number they have selected.
    </span>
    )
}
    const SelectOptions = React.memo(function menuOptions({ question, dispatch}) {
      const{buckets, isMultipleChoice, minSel} = question;
      const options = ['choose']; 
      
      if(isMultipleChoice) 
        for (let i=2; i < Math.min(buckets,8); i++) { options.push(i); }

      const updateSelection=(e)=>{
        const selection = e.target.value==='choose'?1:Number(e.target.value);
        const {choices}=question;
        if(selection===1)
          dispatch(updateEditQuestion({question:{minSel:1,maxSel:isMultipleChoice?choices.length:1,isOrdered:false}}))
        else
          if(selection===buckets)
            dispatch(updateEditQuestion({question:{minSel:selection,maxSel:selection, isOrdered:true}}))
            else
          dispatch(updateEditQuestion({question:{minSel:selection,maxSel:selection}}))
      }

      return (
        <select className=" p-0 justify-content-center" id="minSel" disabled={!isMultipleChoice || buckets<3} 
        value={minSel}
            onChange={(e)=>updateSelection(e)}>
          {options.map((x,y) => (
            <option  key={x} >
              {x}
            </option>
          ))}
        </select>
      )
    })

  
    const RequiredSelections = React.memo(function RequiredSelections({question, dispatch}) {
          return (      
            <div>
              <span className="float-start">Required selections?
                <LyqPopover title={"Required number of selections"} content={msg()} />
              </span>
              <span className="float-end">
                <SelectOptions question={question} dispatch={dispatch} />
              </span>
            </div>                              
                     
        )
      })

export default connect(mapStateToProps,null)(RequiredSelections);