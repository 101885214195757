import React from "react";
import { connect } from "react-redux";
import { prettyNum } from "../../../../shared/utilities";
import { PanelType } from "../../../../constants/panel";
import { setPanelView } from "../../../../redux/actions/context-actions";
import { setPanelOpen } from "../../../../redux/actions/panel-actions";
import { responseMsg } from "../../../../constants/question";
import notify from "../../../notify/Notify";
import { clearProfileFeed } from "../../../../redux/actions/profile-actions";

const mapStateToProps = (state, ownProps) => {
    return {
        panel: state.context.panel
    };
};


const UserCount = React.memo(function UserCount({ panel, count, isSelected, isLink, displayQuestion, feedId, children, dispatch }) {
    const { questionId, userFeed: { stats: { RESQ } } } = displayQuestion;

    const showProfiles = (choice) => {
        if (RESQ === 0) {
            notify(responseMsg);
            return;
        }
        if (questionId !== panel.targetId || feedId !== panel.feedId) {
            dispatch(clearProfileFeed());
            dispatch(setPanelView({
                panelType: PanelType.QuestionUsers, displayQuestion: displayQuestion,
                targetId: questionId, feedId: feedId, choice: null
            }));
        }
        else {
            dispatch(setPanelOpen({ isOpen: true }));
        }
    }
    if (isSelected) {
        return (
            <span>
                {count > 0 ?
                    <span className="result grey-text px-1" >{count > 0 && prettyNum(count)} {children}</span >
                    :
                    <span>&nbsp;&nbsp;</span>}
            </span>
        )
    }
    else {
        if (count > 0)
            if (isLink)
                return (
                    <span onClick={() => showProfiles()}>
                        <span className="activeColor px-1 pointer" >{prettyNum(count)} {children}</span >
                    </span>
                )
            else
                return (
                    <span onClick={() => showProfiles()}>
                        <span className="result activeColor px-1 pointer" >{prettyNum(count)} {children}</span >
                    </span>
                )
        else
            (
                <span >{children}
                    <span>&nbsp;&nbsp;</span>
                </span>
            )
    }
})

export default connect(mapStateToProps, null)(UserCount)