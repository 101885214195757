import React from "react";
import { connect } from "react-redux";
import Popover from '@mui/material/Popover';
import { Icons } from "../../shared/QIcons";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { MyURLs } from "../../constants/url";
import { uuidToBase64 } from "../../shared/Base64Conversions";
import Notify from "../notify/Notify";
import { showConfirmModal, showProfileListModal } from "../../redux/actions/modal-actions";
import { ShareActions } from "../../redux/constants";
import { shareQuestion } from "../../redux/actions/question-actions";
import { SystemId } from "../../constants/profile";
import NotifyAlert from "../notify/NotifyAlert";
import { SourceId, responseMsg } from "../../constants/question";
import notify from "../notify/Notify";

const mapStateToProps = (state, ownProps) => {
    return {
        isAdmin: state.profile.currentUser.isAdmin,
        viewProfileId: state.context.view.profileId,
        checkedProfiles: state.profile.checkedProfiles
    };
};


const ShareMenu = ({ displayQuestion, viewProfileId, checkedProfiles, isAdmin, dispatch }) => {
    const { questionId, questionText, creatorId: profileId, userFeed , draft} = displayQuestion;
    const { stats: { RESQ,SHAQ } } = userFeed;
    const ogQuestionText = `${questionText} + "/"`;
    const viewUrl = MyURLs.VIEW.concat(uuidToBase64(questionId), ("/"), uuidToBase64(profileId));

    const copyToClipboard = async (item) => {
        try {
            await navigator.clipboard.writeText(item);
            Notify('Copied to clipboard');
        } catch (err) {
            Notify('Failed to copy text');
            console.log('Failed to copy text: ', err);
        }
    };

    const confirmShareToFollowers = () => {
        if(RESQ===0)
            NotifyAlert("You cannot interact with a draft question")
        else
            dispatch(showConfirmModal({ content: "Share with followers?", handleConfirm: shareToFollowers }));
    }

    const shareToFollowers = async () => {
        dispatch(shareQuestion(
            {
                questionId: questionId,
                shareAction: ShareActions.FOLLOWERS,
                viewProfileId: viewProfileId ?? SystemId,
                relatedId: profileId
            }
        ))
        Notify("Question shared with followers");
    }

    const shareToUsers = async (selectedUsers) => {
        // dispatch(shareQuestion(
        //     {
        //         questionId: questionId,
        //         shareAction: ShareActions.FOLLOWERS,
        //         viewProfileId: viewProfileId ?? SystemId,
        //         relatedId: profileId
        //     }
        //))
       
    }

    const getShareProfiles = async () => {
        dispatch(showProfileListModal({questionId}));
        // dispatch(shareQuestion(
        //     {
        //         questionId: questionId,
        //         shareAction: ShareActions.FOLLOWERS,
        //         viewProfileId: viewProfileId ?? SystemId,
        //         relatedId: profileId
        //     }
        // ))
    }

    const openShareDialog = (item) => {
        if (navigator.share) {
            navigator.share({
                title: 'Ask. Answer. Discover!',
                url: item,
            })
                .then(() => Notify('Ready to share'))
                .catch((error) => console.log('Error sharing', error));
        } else {
            Notify('Web Share API is not supported in your browser.');
        }
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        if(draft)
            {
                notify(responseMsg);
                return;
            }
        else
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);

    return (
        <React.Fragment>
            <span aria-describedby={questionId} variant="contained" className="pointer activeColor"
                onClick={handleClick}
                onBlur={handleClose}
            >
                <i className={SHAQ ? Icons.SHARE + " linkPadRight" : Icons.SHARE + " linkPadRight"} />
            </span>
            <Popover
                id={questionId}
                open={open}
                anchorEl={anchorEl}
                className="mt-4"
                onClose={handleClose}
                onClick={handleClose}
                anchorOrigin={{
                    vertical: -30,
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <div className="my-2 small">
                    <div className="popover-body h6 m-0 p-1">
                        <div className="popover-item">
                            <FacebookShareButton
                                url={viewUrl+'/'+SourceId.Facebook}
                                quote="Discover how you are like others!"
                                hashtag=""
                            >
                                <i className="fa-brands fa-square-facebook fa-2x"></i>
                            </FacebookShareButton>
                        </div>
                        <div className="popover-item">
                            <LinkedinShareButton
                                summary={ogQuestionText}
                                title={"This is a title"}
                                source={viewUrl+'/'+SourceId.LinkedIn}
                                url={viewUrl+'/'+SourceId.LinkedIn}
                            >
                                <i className="fa-brands fa-linkedin fa-2x"></i>
                            </LinkedinShareButton>
                        </div>
                        <div className="popover-item">
                            <TwitterShareButton
                                url={viewUrl+'/'+SourceId.Twitter}
                            // title={ogQuestionText}
                            // hashtag={"question by:"}
                            >
                                <i className="fa-brands fa-x-twitter fa-2x"></i>
                            </TwitterShareButton>
                        </div>
                        <div className="popover-item">
                            <i className={Icons.COPY + 'fa-sharp fa-thin fa-copy fa-2x activeColor mx-auto'} onClick={() => copyToClipboard(viewUrl+'/'+SourceId.Copied)} />
                        </div>
                        <div className="popover-item">
                            <i className={Icons.SMS + "fa-2x activeColor mx-auto"} onClick={() => openShareDialog(viewUrl+'/'+SourceId.Text)}></i>
                        </div>
                        <div className="popover-item text-center">
                            <i className={Icons.USERS + "fa-lg activeColor mx-auto"} onClick={() => confirmShareToFollowers()}></i>
                        </div>
                        <div className="popover-item text-center">
                            <i className={Icons.USER + "fa-lg activeColor mx-auto"} onClick={() => getShareProfiles()}></i>
                        </div>
                    </div>
                </div>
            </Popover>
        </React.Fragment>
    );
}
export default connect(mapStateToProps, null)(ShareMenu);