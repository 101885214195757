import React, { useState, useEffect } from 'react'
import { onMessageListener } from './firebase';
import toast from 'react-hot-toast';
import { Avatar } from '@mui/material';
import {Icons} from "../shared/QIcons";
import {  avatarLight } from '../shared/Avatars';

const Notification = () => {
    const [notification, setNotification] = useState({ title: '', body: '', data:'' });



    useEffect(() => {
        const notify = () => toast.custom((t) => (
            <div
                className={`${t.visible ? 'animate-enter' : ''
                    } lyqcard mt-5 p-2 bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
            >
                <div className="d-flex items-start">
                    <div className="dflex">
                        <Avatar
                            className="h-10 w-10 rounded-full"
                            src={avatarLight}
                            variant="square"
                            alt=""
                        />
                    </div>
                    <div className="dflex ml-3">
                        <span><b>{notification.title}</b></span>
                        <div className=" text-sm text-gray-500">
                            {notification.body}
                        </div>
                    </div>
                    <span className="ml-auto mr-2 til1"><i className={Icons.TIMES + " fa-lg"} onClick={() => toast.dismiss(t.id)}></i></span>
                </div>
            </div>
        ))
        
        if (notification?.title) {
            notify()
        }
    }, [notification])

    // requestForToken();

//  foreground messages
    onMessageListener()
        .then((payload) => {
            const data=payload.data
            console.log("notification received", payload);
            const notificationTitle = data["pinpoint.notification.title"];
            const notificationBody = data["pinpoint.notification.body"]
            const notificationData = JSON.parse(data["pinpoint.jsonBody"]);
            setNotification({ title: notificationTitle, body: notificationBody, data:notificationData });
            console.log(JSON.stringify(JSON.parse(data["pinpoint.jsonBody"],null,2)));
        })
        .catch((err) => console.log('failed: ', err));
}

export default Notification
