import React from "react";


const QButton = React.memo(function QButton({active, handleClick, text}) {

if(active)
    return (<button className="btn btn-primary btn-sm m-0 px-2 py-0" onClick={()=>handleClick()} >{text}</button>)
else
    return (<button className="btn inactiveBackgroundColor btn-sm m-0 px-2 py-0" >{text}</button>)
  })

  export default QButton

