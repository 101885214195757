import React from "react";
import "./index.css";
import { QuestionAskType } from "../../../constants/question";
import { Feeds } from "../../../constants/feedids";
import { connect } from "react-redux";
import { setView } from "../../../redux/actions/context-actions";
import { SystemId } from "../../../constants/profile";


const mapStateToProps = (state) => {
    return {
        viewProfileId: state.context.view.profileId,
        userId: state.profile.currentUser.profileId
    };
};

const Question = React.memo(function Question({ questionId, questionText, questionType, dispatch }) {
    const classes = questionText.length < 46 ? "my-2 px-1 text-center bigquestiontext text-center brand6 " : "questiontext my-2 px-1 text-center brand6 ";

    function hashtag(text) {
        let len = text.split(" ").length;
        return text.split(" ").map((str, i) => {
            //           str= str.replace("?","");
            if (str[0]==="#") {
                return <span onClick={()=>dispatch(setView({ tag: str.toLowerCase() , targetId:SystemId, feedId: Feeds.TaggedQuestions }))} key={questionId + str} className="activeColor pointer ">{i<len-1?str+" ":str}</span>;
            }
            else
                return i < len - 1 ? str + " " : str;
        })
    }

    questionText = hashtag(questionText);

    const QuestionBlock = () => {
        if (questionType === QuestionAskType.Lottery) {
            return (
                <>
                    <div className="my-0 mx-2 mt-2 p-2 h4 text-center brand6">
                        <span className="brand1 mr-2">Prize:</span>
                     <span className="text-left h4">{questionText}</span>
                    </div>
                </>
            )
        }
        else {
                return (
                    <div className={classes}>
                        <span>{questionText}?</span>
                    </div>
                )
        }
    }

    return <QuestionBlock />

})

export default connect(mapStateToProps, null)(Question);