import React from "react";
import { smsImageUrl } from "../Utilities";

const FullscreenImageViewer = ({ fullscreenImage, toggleFullscreenImage, identityId }) => {
  if (!fullscreenImage) return null;

  return (
    <div
      className="fullscreen-overlay"
      onClick={() => toggleFullscreenImage(null)}
    >
      <img
        src={fullscreenImage }
        alt="Fullscreen Preview"
        className="fullscreen-image lyqcard"
      />
    </div>
  );
};

export default FullscreenImageViewer;
