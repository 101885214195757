import { takeEvery, call, put, all } from "redux-saga/effects";
import { processImageActions } from "./image-sagas";
import { LyqnessActions, QuestionActions as Actions, FeedActions } from "../constants";
import { dsAddQuestion, dsDeleteQuestion, dsProcessQuestionInteraction, dsRemoveUserFeed, dsRespondToQuestion, dsUpdateQuestion, dsUpdateUserTags } from "../../repository/datastore";
import { UserFeeds } from "../../constants/feedids";
import notify from "../../components/notify/Notify";
import { createQuestionShare } from "../../repository/crud";

//****  process question share   */
export function* watchProcessQuestionShare() {
  yield takeEvery(Actions.QUESTION_SHARE_REQUESTED, questionShareWorker);
}

function* questionShareWorker(action) {
  try {
    let result = yield call(createQuestionShare, action.payload);
    if (result) {
      yield put({ type: Actions.QUESTION_SHARE_PROCESSED, payload: { ...result } });
    };

  } catch (e) {
    yield put({ type: Actions.QUESTION_API_ERROR, payload: e });
  }
}

//****  process question interaction   */
export function* watchProcessQuestionInteraction() {
  yield takeEvery(Actions.QUESTION_INTERACTION_REQUESTED, questionInteractionWorker);
}

function* questionInteractionWorker(action) {
  try {
    let result = yield call(dsProcessQuestionInteraction, action.payload);
    if (result) {
      yield put({ type: Actions.QUESTION_INTERACTION_PROCESSED, payload: { ...result } });
      if (action.payload.action === LyqnessActions.TAGQ)
        yield put({ type: FeedActions.TAG_FEED_REQUESTED, payload: { feedId: UserFeeds.UserTags } });
    };

  } catch (e) {
    yield put({ type: Actions.QUESTION_API_ERROR, payload: e });
  }
}

//****  respond to question    */
export function* watchRespondToQuestion() {
  yield takeEvery(Actions.SUBMIT_RESPONSE_REQUESTED, respondToQuestionWorker);
}

function* respondToQuestionWorker(action) {
  try {
    let result = yield call(dsRespondToQuestion, action.payload);
    yield put({ type: FeedActions.QUESTION_RESPONSE_RECEIVED, payload: {  ...result } } );

  } catch (e) {
    yield put({ type: Actions.QUESTION_API_ERROR, payload: e });
  }
}

//***         Update question             ******/ 
export function* watchUpdateQuestion() {
  yield takeEvery(Actions.QUESTION_UPDATE_REQUESTED, updateQuestionWorker);
}

function* updateQuestionWorker(action) {
  try {
    const question = action.payload.question;
    if (action.payload.imageActions)
      yield call(processImageActions, action.payload.imageActions);
    const payload = yield call(dsUpdateQuestion, { ...question, usersFeedId: action.payload.usersFeedId });
    yield put({ type: Actions.QUESTION_UPDATED, payload: { ...payload, viewProfileId: action.payload.viewProfileId } });
  }
  catch (e) {

    yield put({ type: Actions.QUESTION_API_ERROR, payload: e });
  }
}

//***         Create new question             ******/ 
export function* watchAddQuestion() {
  yield takeEvery(Actions.QUESTION_ADD_REQUESTED, addQuestionWorker);
}

function* addQuestionWorker(action) {
  try {
    let input = action.payload;
    // add question
    const payload = yield call(dsAddQuestion, input);
    let questionDTO = action.payload;
    let fileData = {
      imageKey: questionDTO.imageKey,
      image: action.payload.image,
      choiceImages: action.payload.choices
    };
    // process image actions
    if (action.payload.imageActions)
      yield call(processImageActions, action.payload.imageActions);

    if (fileData.image)
      payload.headerImage = fileData.image;

    yield put({ type: Actions.QUESTION_ADDED, payload: { ...payload, viewProfileId: action.payload.viewProfileId } });

  }
  catch (e) {
    yield put({ type: Actions.QUESTION_API_ERROR, payload: e });
  }
}

//***         Update user tags             ******/ 
export function* watchUpdateUserTags() {
  yield takeEvery(Actions.UPDATE_USER_TAGS_REQUESTED, updateUserTagsWorker);
}

function* updateUserTagsWorker(action) {
  try {
    yield call(dsUpdateUserTags, action.payload);
    //yield put({ type: FeedActions.TAG_FEED_REQUESTED, payload: { feedId: UserFeeds.UserTags } });
  }
  catch (e) {
    yield put({ type: Actions.QUESTION_API_ERROR, payload: e });
  }
}

//***         Delete Question               ******/ 
export function* watchDeleteQuestion() {
  yield takeEvery(Actions.QUESTION_DELETE_REQUESTED, deleteQuestionWorker);
}

function* deleteQuestionWorker(action) {
  try {
    const payload = yield call(dsDeleteQuestion, action.payload);
    if (action.payload.imageActions.length > 0)
      yield call(processImageActions, action.payload.imageActions);
    notify("Question has been deleted")
    yield put({ type: Actions.QUESTION_DELETED, payload });
  }
  catch (e) {
    yield put({ type: Actions.QUESTION_API_ERROR, payload: e });
  }
}

//***         Delete Question               ******/ 
export function* watchRemoveUserFeed() {
  yield takeEvery(Actions.QUESTION_REMOVE_REQUESTED, removeUserFeedWorker);
}

function* removeUserFeedWorker(action) {
  try {
    const payload = yield call(dsRemoveUserFeed, action.payload)

    yield put({ type: Actions.QUESTION_DELETED, payload });
  }
  catch (e) {
    yield put({ type: Actions.QUESTION_API_ERROR, payload: e });
  }
}

export default function* rootSaga() {
  yield all([
    watchProcessQuestionShare(),
    watchAddQuestion(),
    watchProcessQuestionInteraction(),
    watchDeleteQuestion(),
    watchUpdateUserTags(),
    watchRemoveUserFeed(),
    watchUpdateQuestion(),
    watchRespondToQuestion(),
  ])
};   