import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { mainImageUrl } from "./Utilities";

const QuestionImage = ({ question }) => {
    const { profileStub:{imageKey, identityId}, imageURL } = question;
    const [iframeHeight, setIframeHeight] = useState(300);

    const generateEmbedURL = (link) => {
        try {
            const url = new URL(link);
            const { hostname, pathname, searchParams } = url;

            if (hostname.includes("youtube.com") || hostname.includes("youtu.be")) {
                const videoId = searchParams.get("v") || pathname.split("/").pop();
                setIframeHeight(Math.round(430 / (16 / 9))); // YouTube 16:9 aspect ratio
                return `https://www.youtube.com/embed/${videoId}?autoplay=1`;
            } else if (hostname.includes("tiktok.com")) {
                const videoId = pathname.split("/").pop();
                setIframeHeight(Math.round(430 * (9 / 16))); // TikTok 9:16 aspect ratio
                return `https://www.tiktok.com/embed/${videoId}?embedType=embedOnly&lang=en`;
            } else if (hostname.includes("facebook.com")) {
                setIframeHeight(Math.round(430 / (4 / 3))); // Facebook 4:3 aspect ratio
                return `https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(link)}&autoplay=1`;
            } else if (hostname.includes("instagram.com")) {
                setIframeHeight(500); // Instagram square aspect ratio
                return `https://www.instagram.com/p/${pathname.split("/")[2]}/embed/captioned`;
            } else {
                setIframeHeight(300); // Default height
                return null;
            }
        } catch {
            return null;
        }
    };


    const embedUrl = useMemo(() => generateEmbedURL(imageURL), [imageURL]);
    if(!imageKey)
        return(<span/>)
    return (
        <div style={{ textAlign: "center" }}>
            <div
                style={{
                    marginTop: "0px",
                    width: "100%",
                    maxWidth: "430px",
                    margin: "0 auto",
                    border: "0px solid #ccc",
                    borderRadius: "0px",
                    overflow: "hidden",
                    position: "relative",
                    height: `${iframeHeight}px`,
                }}
            >
                {imageKey && !imageURL ? (
                    <img
                        src={mainImageUrl(identityId, imageKey)}
                        alt="topic"
                        style={{ width: "100%", height: "100%" }}
                    />
                ) : embedUrl ? (
                    <iframe
                        title="videoframe"
                        src={embedUrl}
                        style={{ width: "100%", height: "100%", border: "none" }}
                        allowFullScreen
                        scrolling="no"
                    ></iframe>
                ) : (
                    <p style={{ textAlign: "center", color: "#999" }}>
                        Media format not supported.
                    </p>
                )}
            </div>
        </div>
    );
};

export default QuestionImage;
