
import { Icons } from "../shared/QIcons";
import { prettyNum } from "../shared/utilities";

export const LYQ_CARD_WIDTH = "375px";
export const EDIT_CARD_WIDTH = "390px";
export const PROFILE_EDIT_WIDTH = "275px";

export const WhoCanView = {
    Noone: "NOONE",
    Link: "LINK",
    Everyone: "ALL"
}
export const responseMsg = "you cannot interact with a draft question";
export const choicesLabel = "choices:";

export const NumericType = {
    Number: 0,
    Money: 1,
    DateYear: 3,
    DateMonth: 4,
    DateDay: 5,
    TimeHour: 6,
    Time15Min: 7,
    Time30Min: 8,
    Football: 20,
    Basketball: 21,
    Baseball: 22,
    Hockey: 23,
    Soccer: 24,
    DateTime: 99,
}

export const SourceId = {
    Created: 0,
    Responded: 1,
    Liked: 2,
    Followers: 3,
    Selected: 4,
    Text: 5,
    Copied: 6,
    Twitter: 7,
    LinkedIn: 8,
    Facebook: 9,
    Instagram: 10,
    RespondedUsers: 11,
    ChoiceUsers: 12,
    LikedUsers: 13,
    Generic: 14,
    Advertisement: 20,
    Marketing: 21,
    Lyqness: 99
}

export function numericResponse({ buckets, minRange, maxRange, response }) {
    const range = minRange - maxRange + 1;
    const step = Math.round(range / buckets);
    const lastBucketStart = (buckets - 1) * step;
    const thruRange = (response - minRange) / step;
    const bucket = response > lastBucketStart ? buckets - 1 : Math.trunc(thruRange);
    const order = response < lastBucketStart ? (thruRange % 1) * step : response - lastBucketStart;
    return { bucket: bucket, order: order }
}

export function numericIntervals({ numericType, buckets, minRange, maxRange }) {
    if (numericType === NumericType.DateDay)
        return 31;
    else {
        const delta = maxRange - minRange;
        const step = Math.round(delta / buckets);
        const lastBucketStart = (buckets - 1) * step;
        const maxOrder = Math.max(delta - lastBucketStart, step) - 1;
        return { step: step, maxOrder: maxOrder <= 15 ? maxOrder : 15 };
    }
}

export function numericRange(numericType) {
    let range = {
        rangeStart: 0,
        rangeEnd: 0
    }
    switch (numericType) {
        case NumericType.Number:
            range.rangeStart = 0;
            range.rangeEnd = 100;
            break;
        case NumericType.Money:
            range.rangeStart = 0;
            range.rangeEnd = 300000;
            break;
        case NumericType.DateMonth:
        case NumericType.DateDay:
            range.rangeStart = 1;
            range.rangeEnd = 12;
            break;
        case NumericType.DateYear:
            range.rangeStart = 1923;
            range.rangeEnd = 2023;
            break;
        case NumericType.TimeHour:
        case NumericType.Time30Min:
        case NumericType.Time15Min:
            range.rangeStart = 0;
            range.rangeEnd = 24;
            break;
        default:
            break;
    }
    return range;
}

export function numericBuckets(numericType, minRange, maxRange) {
    const subRange = 15;
    switch (numericType) {
        case NumericType.Number:
        case NumericType.Money:
        case NumericType.DateYear: {
            let delta = maxRange - minRange + 1;
            if (delta <= 6 * subRange)
                return 6;
            else if (delta <= 9 * subRange)
                return 9;
            else return 12
        }
        case NumericType.DateMonth:
        case NumericType.DateDay:
            return 12
        case NumericType.TimeHour:
        case NumericType.Time30Min:
        case NumericType.Time15Min:
            return 6;
        default:
            return 9;
    }
}



export function NumberChoices({ minRange, maxRange, numericType }) {
    var startRange;
    var endRange;
    if (minRange === undefined || maxRange === undefined) {
        const range = numericRange(numericType);
        startRange = range.rangeStart;
        endRange = range.rangeEnd;
    }
    else {
        startRange = minRange;
        endRange = maxRange;
    }
    var step;
    var delta;
    const buckets = numericBuckets(numericType, minRange, maxRange);
    var choices = [];

    switch (numericType) {
        case NumericType.DateMonth:
        case NumericType.DateDay:
            choices = ([
                { text: "Jan", image: "" },
                { text: "Feb", image: "" },
                { text: "Mar", image: "" },
                { text: "Apr", image: "" },
                { text: "May", image: "" },
                { text: "Jun", image: "" },
                { text: "Jul", image: "" },
                { text: "Aug", image: "" },
                { text: "Sep", image: "" },
                { text: "Oct", image: "" },
                { text: "Nov", image: "" },
                { text: "Dec", image: "" }]
            );
            break;
        case NumericType.Money:
        case NumericType.Number: {
            const prefix = numericType === NumericType.Money ? "$" : "";
            delta = endRange - startRange;
            step = Math.trunc(delta / buckets);
            for (let index = 0; index < buckets; index++) {
                let from = startRange + (index) * step;
                let to = startRange + (step + step * index) - 1;
                if (index < buckets - 1)
                    choices.push({ text: `${prefix}${prettyNum(from)}-${prettyNum(to)}`, image: "" });
                else
                    choices.push({ text: `${prefix}${prettyNum(from)}-${prettyNum(endRange)}`, image: "" });
            }
        }
            break;
        case NumericType.DateYear:
            delta = (endRange - startRange);
            step = Math.round(delta / buckets);
            for (let index = 0; index < buckets; index++) {
                let from = startRange + (index) * step;
                let to = startRange + (step + step * index) - 1;
                if (index < buckets - 1)
                    choices.push({ text: `${from} - ${to}`, image: "" });
                else
                    choices.push({ text: `${from} - ${endRange}`, image: "" });
            }
            break;
        case NumericType.TimeHour:
        case NumericType.Time30Min:
        case NumericType.Time15Min:
            delta = endRange - startRange;
            step = Math.round(delta / buckets);
            let ampm = "AM"
            for (let index = 0; index < buckets; index++) {
                let from = startRange + (index) * step;
                if (from === 0)
                    from = 12;
                else
                    from = from > 12 ? from - 12 : from;
                let to = startRange + (step + step * index);
                if (to > 12) {
                    to = to - 12;
                    ampm = "PM"
                }
                if (index < buckets - 1)
                    choices.push({ text: `${from} - ${to > 12 ? to - 12 : to} ${ampm}`, image: "" });
                else
                    choices.push({ text: `${from} - ${endRange > 12 ? endRange - 12 : endRange} ${ampm}`, image: "" });
            }
            break;
        default:
            break;
    }


    return choices;
}


export const QuestionAskType = {
    Select: 0,
    SelectN: 1,
    Rank: 2,
    Rate: 3,
    Numeric: 4,
    Time: 5,
    Date: 6,
    Linear: 7,
    SelectInOrder: 8,
    Lottery: 9,
    Pictures: 10,
    Scores: 11,
    Location: 12,
    MoveMatch: 13,
    Advertisement: 90,
    Incentive: 91
}

export const PremiumQuestion = ({ questionType }) => {
    if ([9, 11, 12, 90, 91].includes(questionType))
        return (<i className={Icons.PREMIUM + " fa-lg "} style={{ "--fa-primary-color": "#080807", "--fa-secondary-color": "#f59f0a", "--fa-secondary-opacity": "1", }}></i>)
    else
        return (<span />)
}

export const QuestionAskTitle = (askType) => {
    switch (askType) {
        case QuestionAskType.Select:
            return "Text Choice";
        case QuestionAskType.Rank:
            return "Rank Choices";
        case QuestionAskType.Rate:
            return "Rating";
        case QuestionAskType.Numeric:
            return "Numbers";
        case QuestionAskType.Pictures:
            return "Picture Choices";
        case QuestionAskType.Lottery:
            return "Lottery";
        case QuestionAskType.Scores:
            return "Sports Scores";
        case QuestionAskType.MoveMatch:
            return "Move and Match";
        case QuestionAskType.Location:
            return "Location";
        case QuestionAskType.Advertisement:
            return "Advertisement";
        case QuestionAskType.Incentive:
            return "Incentive";

        default:
            break;
    }
}

export const QuestionTypeIcon = (askType) => {
    switch (askType) {
        case QuestionAskType.Select:
            return (<i className={Icons.QUESTION_SELECT + " fa-lg"} />);
        case QuestionAskType.Rank:
            return (<i className={Icons.QUESTION_RANK + " fa-lg"} />);
        case QuestionAskType.Rate:
            return (<i className={Icons.QUESTION_RATE + " fa-lg"} />);
        case QuestionAskType.Numeric:
            return (<i className={Icons.QUESTION_NUMBER + " fa-lg"} />);
        case QuestionAskType.Pictures:
            return (<i className={Icons.QUESTION_PICTURES + " fa-lg"} />);
        case QuestionAskType.Lottery:
            return (<i className={Icons.QUESTION_LOTTERY + " fa-lg"} />);
        case QuestionAskType.Scores:
            return (<i className={Icons.QUESTION_SCORES + " fa-lg"} />);
        case QuestionAskType.MoveMatch:
            return (<i className={Icons.MOVE + " fa-lg"} />);
        case QuestionAskType.Location:
            return (<i className={Icons.QUESTION_LOCATION + " fa-lg"} />);
        case QuestionAskType.Advertisement:
            return (<i className={Icons.AD + " fa-lg"} />);
        case QuestionAskType.Incentive:
            return (<i className={Icons.AWARD + " fa-lg"} />);
        default:
            break;
    }
}


export const SelectorType = {
    Single: 0,
    Multi: 1,
    Counter: 2,
    Editing: 3,
    Ranking: 5,
    None: 4,
    Ordered: 6,
    Drag: 7,
}