//Public.js
import React, { useEffect } from "react";
import { connect } from "react-redux";
import "./index.css"
import { ViewType } from "../constants/profile";
import { PublicFeeds } from "../constants/feedids";
import { setView } from "../redux/actions/context-actions";
import { getViewProfile } from "../redux/actions/profile-actions";




const mapStateToProps = (state, ownProps) => {
  return {
    props: ownProps,
    userId: state.profile.currentUser.profileId,
    category:state.profile.currentUser.category,
    viewProfileId: state.context.view.ProfileId
  };
};

const PremiumPublicView = React.memo(function PremiumPublicView({ props, profileId, category,userId, viewProfileId, dispatch }) {
  let feedToLoad = PublicFeeds.UserFeedQuestions;
  let viewToLoad = ViewType.PremiumPublicView;
  let profileToLoad = profileId;

  useEffect(() => {
    dispatch(getViewProfile({ profileId: profileToLoad }));
    dispatch(setView({ viewType: viewToLoad, profileId: profileToLoad, feedId: feedToLoad }));
            // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileToLoad]); // Only re-run the effect if count changes

  return (
    <></>
  )

})

export default connect(mapStateToProps, null)(PremiumPublicView);