import React from "react";
import ShareLink from "../../links/ShareLink";
import LinkLink from "../../links/LinkLink";
import FavoriteLink from "../../links/FavoriteLink";
import { connect } from "react-redux";
import UserCount from "./links/UserCount";
import { WhoFeeds } from "../../../constants/feedids";
import TagLink from "../../links/TagLink";
import LinkPrevious from "../../links/LinkPrevious";
import LinkNext from "../../links/LinkNext";
import { prettyNum } from "../../../shared/utilities";

const mapStateToProps = state => {
    return {
        viewProfileId: state.context.view.profileId,
        profileId: state.profile.currentUser.profilId,
        feedId: state.context.view.feedId
    };
};

const QuestionFooter = React.memo(function QuestionFooter({ displayQuestion, feedId, profileId, dispatch }) {
    const { stats: { LIKQ, SHAQ, TAGQ, LINQ }} = displayQuestion;

    return (
        <div className="row py-1 border-top g-0 px-2 white ">
            <div className="col">
                <div className="pl-2">
                    <LinkPrevious displayQuestion={displayQuestion} />
                </div>
            </div>
            <div className="col">
                <FavoriteLink displayQuestion={displayQuestion} />
                <UserCount displayQuestion={displayQuestion} count={LIKQ}  feedId={WhoFeeds.LikedQuestions}>
                </UserCount>

            </div>
            <div className="col">
                <ShareLink displayQuestion={displayQuestion} />
                <UserCount displayQuestion={displayQuestion} count={SHAQ} feedId={WhoFeeds.SharedQuestions} />
            </div>
            <div className="col">
                <TagLink displayQuestion={displayQuestion} />
                <UserCount displayQuestion={displayQuestion} count={TAGQ} feedId={WhoFeeds.QuestionsTagged} >
                </UserCount>
            </div>
            <div className="col">
                <LinkLink displayQuestion={displayQuestion} isLink />
                <span className="result inactiveColor px-1 " >{LINQ > 0 ? prettyNum(LINQ) : ""} </span >
                {/* <UserCount displayQuestion={displayQuestion} count={LINQ} isLink feedId={WhoFeeds.LinkedQuestions} /> */}
            </div>
            <div className="col-1 text-end">
                    <LinkNext displayQuestion={displayQuestion} />
            </div>
        </div>
    );
})

export default connect(mapStateToProps, null)(QuestionFooter);