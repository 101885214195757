import React from "react";
import { connect } from "react-redux";
import { Icons } from "../../shared/QIcons";
import { closeSpeedDial, openSpeedDial } from "../../redux/actions/context-actions";
import notify from "../notify/Notify";
import { responseMsg } from "../../constants/question";

const mapStateToProps = (state, ownProps) => {
    return {
        speedDial: state.context.speedDial,
        isObserver: state.profile.isPremium,
        profileId: state.profile.currentUser.profileId
    };
};

const LinkLink = React.memo(function LinkLink({ displayQuestion, speedDial, profileId, isObserver, dispatch }) {
    const { questionId, stats, creatorId, userFeed:{stats:{RESQ}}, draft } = displayQuestion;
    const { isOpen, parentId } = speedDial;

    function pleaseRespond(){
        notify(responseMsg)
    }

    if(draft){
        return ( 
        <span>
            <i className={Icons.LINK + " inactiveColor linkPadRight" } onClick={() => pleaseRespond()} ></i>
        </span> )
    }

    if (isOpen && questionId === parentId)
        return (<i className={Icons.SPINNER + " linkPadRight"} onBlur={() => dispatch(closeSpeedDial())} onClick={() => dispatch(closeSpeedDial())}></i>)
    else
        return (
            <span>
                <i className={stats.LINQ ? Icons.LINK + " activeColor linkPadRight" : Icons.LINK + " inactiveColor pointer linkPadRight"} onClick={() => dispatch(openSpeedDial({ parentId: questionId, isOpen: true }))} ></i>
            </span>
        )

})

export default connect(mapStateToProps, null)(LinkLink);