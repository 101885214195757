import React from "react";
import { connect } from "react-redux";
import { setView } from "../../redux/actions/context-actions";
import { Feeds } from "../../constants/feedids";
import { Icons } from "../../shared/QIcons";
import "../../index.css";


const mapStateToProps = (state,ownProps) => {

  return {  
            view:state.context.view,
            count:state.feed.items.length
        };
};

const LinkPrevious = React.memo(function LinkPrevious({ view, dispatch,count, displayQuestion }) {
    const {  parentId } = displayQuestion;
    const {profileId} = view;

    const loadFeed = (e) => {
        e.preventDefault(e);
            //dispatch(resetPanels());
            dispatch(setView({ profileId:profileId, feedId:Feeds.ParentQuestions, targetId:parentId}));
        }

    if (parentId)
        return (
            <span className="activeColor pointer" onClick={(e) => loadFeed(e)}>
                <i  onClick={(e) => loadFeed(e)} className= {Icons.LINK_PREVIOUS  + " fa-lg pointer" } /> 
            </span>  
        )
    else
     if(count===0)
        <div> no more</div>
    else
        return (
            <span className="inactiveColor">
                <i  className= {Icons.LINK_PREVIOUS + " fa-lg"  } /> 
            </span> 
        )
})

export default connect(mapStateToProps,null)(LinkPrevious);