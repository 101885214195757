import React from "react";


class AdCreator extends React.PureComponent {


   render() {
         return ( 
<span/>)
      }
}

export default AdCreator;