import React, { useEffect } from "react";
import { connect } from "react-redux";
import "./index.css"
import { ViewType } from "../constants/profile";
import { UserFeeds } from "../constants/feedids";
import { setView } from "../redux/actions/context-actions";

const mapStateToProps = (state, ownProps) => {
   return {
      profileId: state.profile.currentUser.profileId,
      isObserver: state.profile.isPremium
   };
};

const UserView = React.memo(function UserView({ profileId, isObserver, dispatch }) {
   let feedToLoad = UserFeeds.UserFeedQuestions;
   const viewToLoad = isObserver?ViewType.PremiumUserView: ViewType.UserView;

   // trigger on component mount
   useEffect(() => {
         dispatch(setView({ viewType: viewToLoad, profileId: profileId, feedId: feedToLoad }));
   });

   if (!profileId) {
      return (
         <></>
      )
   }
   else {
      <div><i className="fas fa-spinner fa-spin fa-lg"></i> loading</div>
   }
}

)

export default connect(mapStateToProps, null)(UserView);