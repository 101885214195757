import { ChatActions } from "../constants";

export function createMessage(payload) {
    return { type:ChatActions.CREATE_MESSAGE_REQUESTED, payload}
  }

  export function startQuestionConversation(payload) {
    return { type:ChatActions.START_CONVERSATION ,payload}
  }
  export function joinConversation(payload) {
    return { type:ChatActions.JOIN_CONVERSATION ,payload}
  }


  export function getConversationMessages(payload) {
    return { type:ChatActions.CONVERSATION_MESSAGES_REQUESTED ,payload}
  }


  export function getUserMessages(payload) {
    return { type:ChatActions.USER_MESSAGES_REQUESTED ,payload}
  }

 
  export function getPublicConversations(payload) {
    return { type:ChatActions.PUBLIC_CONVERSATIONS_REQUESTED ,payload}
  }

  export function sendMessageOptimistic(payload) {
    return { type:ChatActions.OPTIMISTIC_MESSAGE_CREATED ,payload}
  }

  export function conversationMessageReceived(payload) {
    return { type:ChatActions.CONVERSATION_MESSAGE_RECEIVED ,payload}
  }

  export function viewMessageReceived(payload) {
    return { type:ChatActions.VIEW_MESSAGE_RECEIVED ,payload}
  }


  export function userMessageReceived(payload) {
    return { type:ChatActions.USER_MESSAGE_RECEIVED ,payload}
  }

  
  export function conversationStarted(payload) {
    return { type:ChatActions.CONVERSATION_STARTED ,payload}
  }

  export function updateLastConversationViewTime(payload) {
    return { type:ChatActions.UPDATE_LAST_CONVO_VIEW_TIME_REQUESTED ,payload}
  }

  export const stageChatImage = (uri) =>({
    type: ChatActions.STAGE_CHAT_IMAGE, uri
}) 
  export function clearChatImage() {
    return { type:ChatActions.CLEAR_CHAT_IMAGE}
  }

  export function setChatImagePreviewUrl(payload) {
    return { type:ChatActions.SET_CHAT_PREVIEW_URL ,payload}
  }

  export function createSubscription(payload) {
    return { type:ChatActions.CREATE_SUBSCRIPTION ,payload}
  }

  export function setChatSubscription(payload) {
    return { type:ChatActions.SET_MESSAGE_SUBSCRIPTION ,payload}
  }
  export function clearMessages(payload) {
    return { type:ChatActions.CLEAR_MESSAGES,payload}
  }

  
  export function getUserConversationFeed(payload) {
    return { type:ChatActions.USER_CONVERSATIONS_REQUESTED,payload}
  }
