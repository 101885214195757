import React from "react";
import { connect } from "react-redux";
import { hideModal } from "../../redux/actions/modal-actions";
import { Icons } from "../../shared/QIcons";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import IconButton from "@mui/joy/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const mapStateToProps = (state) => ({
    isOpen: state.modal.modalType === "STEALTH_MSG_MODAL",
    handleConfirm: state.modal.modalProps.handleConfirm,
});

const exitMsg = () => (
    <Typography>
        You are about to leave <b>stealth mode</b> for this question. Once you have done this, your interactions will be
        visible to others who are not in <b>stealth mode</b>. You will be able to see their interactions as well.
        <Typography color="danger" fontWeight="bold">
            Once you leave stealth mode, you can't go back!
        </Typography>
    </Typography>
);

const stealthMsg = () => (
    <Typography>
        You are currently in <b>stealth mode</b> because the question was
        created as a stealth question or you chose to be stealth.
        In <b>stealth mode</b>, your interactions will be counted but your identifying information, and all others, will be hidden.
        To exit stealth mode, respond to the question and then click the stealth{" "}
        <i className={`${Icons.STEALTH} brand1 `}></i> icon.
    </Typography>
);

const StealthMsgModal = React.memo(function StealthMsgModal({ isOpen, handleConfirm, dispatch }) {
    const confirm = (response) => {
        handleConfirm(response);
        dispatch(hideModal());
    };

    return (
        <Modal open={isOpen} onClose={() => dispatch(hideModal())}>
            <ModalDialog
                variant="outlined"
                layout="center"
                sx={{
                    maxWidth: "400px",
                    borderRadius: "md",
                    boxShadow: "lg",
                    bgcolor: "background.surface",
                    p: 2,
                    textAlign: "center",
                }}
            >
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography level="h5">
                        {handleConfirm ? "Leave Stealth Mode?" : "In Stealth Mode"}
                    </Typography>
                    <IconButton variant="plain" size="sm" onClick={() => dispatch(hideModal())}>
                        <CloseIcon />
                    </IconButton>
                </div>

                <div style={{ margin: "16px 0" }}>{handleConfirm ? exitMsg() : stealthMsg()}</div>

                <div style={{ display: "flex", justifyContent: "center", gap: "12px", marginTop: "16px" }}>
                    {handleConfirm ? (
                        <>
                            <Button variant="solid" color="primary" size="sm" onClick={() => confirm(true)}>
                                Exit Stealth
                            </Button>
                            <Button variant="soft" color="neutral" size="sm" onClick={() => confirm(false)}>
                                Cancel
                            </Button>
                        </>
                    ) : (
                        <Button variant="soft" color="neutral" size="sm" onClick={() => dispatch(hideModal())}>
                            Ok
                        </Button>
                    )}
                </div>
            </ModalDialog>
        </Modal>
    );
});

export default connect(mapStateToProps, null)(StealthMsgModal);
