import {
    DefinitionActions as Actions,
  } from "../constants";
  

export const updateEditQuestion = (payload)=>({
    type: Actions.UPDATE_EDITQUESTION, payload:{...payload}  
})

export const updateQuestionRanges = (payload)=>({
    type: Actions.UPDATE_RANGES, payload:{...payload}  
})

export const updateQuestionChoices = (payload)=>({
    type: Actions.UPDATE_QUESTION_CHOICES, payload:{...payload}  
})

export const updateQuestionText = (payload)=>({
    type: Actions.UPDATE_QUESTION_TEXT, payload:{...payload}  
})

export const updateQuestionImage = (payload)=>({
    type: Actions.UPDATE_QUESTION_IMAGE, payload:{...payload}  
})

export const updatePictureChoices = (payload)=>({
    type: Actions.UPDATE_PICTURE_CHOICES, payload:{...payload}  
})

export const setErrorMsg = (payload)=>({
    type: Actions.SET_ERRORMSG, payload:{...payload}  
})

export const updateChoiceImages = (payload)=>({
    type: Actions.UPDATE_CHOICEIMAGES, payload:{...payload}  
})

export const updateChoiceText = (payload)=>({
    type: Actions.UPDATE_CHOICETEXT, payload:{...payload}  
})

export const updateChoiceImage = (payload)=>({
    type: Actions.UPDATE_CHOICEIMAGE, payload:{...payload}  
})

export const toggleOrdered = () =>({
    type: Actions.TOGGLE_ORDERED 
}) 



export const resetDefinition = () =>({
    type: Actions.RESET_DEFINITION
}) 

export const stageHeaderImage = (payload) =>({
    type: Actions.STAGE_HEADER_IMAGE, payload
}) 

export const stageHeaderPreview = (payload) =>({
    type: Actions.STAGE_HEADER_PREVIEW, payload
})

export const stageChoicePreview = (payload) =>({
    type: Actions.STAGE_CHOICE_PREVIEW, payload
})

export const stageChoiceImage = (payload) =>({
    type: Actions.STAGE_CHOICE_IMAGE, payload
}) 

export const removeChoiceImage = (payload) =>({
    type: Actions.REMOVE_CHOICE_IMAGE, payload
}) 
export const removeHeaderImage = () =>({
    type: Actions.REMOVE_HEADER_IMAGE
}) 

export const stageForEdit = (payload) =>({
    type: Actions.STAGE_FOR_EDIT, payload
}) 