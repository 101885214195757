import React, { createContext, useEffect } from 'react';

export const NotificationContext = createContext();

const NotificationProvider = ({ children }) => {
  //const [ setPermission] = useState(Notification.permission);

  useEffect(() => {
    const requestPermission = async () => {
      if (!("Notification" in window)) {
        console.log("This browser does not support notifications.");
        return;
      }

      let currentPermission = Notification.permission;
      if (currentPermission === "default") {
        try {
          currentPermission = await Notification.requestPermission();
          //setPermission(currentPermission);
        } catch (error) {
          console.error("Notification permission request failed", error);
        }
      }
    };

    requestPermission();
  }, []);

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;

export const showNotification = (title, body) => {
  if (Notification.permission === "granted") {
    console.log("processing notification notificationContext")
    const img="https://images.lyqness.com/images/assets/lyqnessavatar.png";
    navigator.serviceWorker.getRegistration().then((reg) => {
      if (reg) {
        reg.showNotification(title, { body: body, icon: img });
      } else {
         new Notification(title, { body: body, icon: img });
      }
    });
  } else {
    console.error("Notification permission not granted.");
  }
};