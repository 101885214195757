import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { hideModal } from "../../redux/actions/modal-actions";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Panel from "../panel/Panel";
import { Icons } from "../../shared/QIcons";
import ProfileHeader from "../profile/ProfileHeader";
import { getLocalProfiles } from "../../repository/profileQueries";
import { Input, ModalClose } from "@mui/joy";
import { IconButton, InputAdornment } from "@mui/material";
import { shareQuestion } from "../../redux/actions/question-actions";

const mapStateToProps = (state) => ({
    isOpen: state.modal.modalType === "PROFILE_LIST_MODAL",
    questionId: state.modal.modalProps.questionId,
    viewProfileId: state.profile.viewProfile.profileId,
    shareList: state.shareList,
});

const ProfileListModal = React.memo(function ProfileListModal({ isOpen,viewProfileId, questionId, dispatch }) {
    const toggle = () => { dispatch(hideModal()) };
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProfiles, setFilteredProfiles] = useState([]);
    const [selectedProfiles, setSelectedProfiles] = useState([]);
    const [checkedProfiles, setCheckedProfiles] = useState([]);
    const [profiles, setProfiles] = useState([]);

    useEffect(() => {
        // Share selected profiles


        // Fetch profiles from DataStore
        const fetchProfiles = async () => {
            try {
                const data = await getLocalProfiles();
                const profiles = data.profiles;
                setProfiles(profiles);
                setFilteredProfiles(profiles); // Initially set filteredProfiles to full profile list
            } catch (error) {
                console.error("Error fetching profiles:", error);
            }
        };

        fetchProfiles();
    }, []);

    const handleCheckedItems = async () => {
        handleSelectedItems(checkedProfiles)
    }


    const handleCheckboxChange = (profile) => {
        setSelectedProfiles((prevSelected) => {
            const isSelected = prevSelected.some((p) => p.profileId === profile.profileId);
            const newSelected = isSelected
                ? prevSelected.filter((p) => p.profileId !== profile.profileId)
                : [...prevSelected, profile];

            const profileIds = newSelected.map(p => p.profileId);
            setCheckedProfiles(profileIds);
            return newSelected;
        });
    };

    const handleSelectedItems = (selected) => {
        const input = {
            questionId: questionId,
            viewProfileId: viewProfileId,
            shareInfo:{
                shareAction:"SELECTED",
                users:selected
            }   
            }

        dispatch(hideModal());
        dispatch(shareQuestion(input));
        //console.log("questionId:", questionId);
        //console.log(JSON.stringify(input, null, 2));
        // handleSelected(selected);
    };

    const handleSearchChange = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);

        const filtered = profiles.filter(
            (profile) =>
                profile.firstName.toLowerCase().includes(term) ||
                profile.lastName.toLowerCase().includes(term)
        );

        setFilteredProfiles(filtered);
    };

    const clearSearch = () => {
        setSearchTerm('');
        setFilteredProfiles(profiles);
    };

    const EndDecorator = () => {
        if(searchTerm)
            return (
                <span className="pe-1 brand0" onClick={clearSearch}>X</span>
            );

    }

    return (
        <Modal open={isOpen} onClose={toggle} >
            <ModalDialog
                className="w-100"
                variant="outlined"
                layout="center"
                sx={{
                    maxWidth: "360px",
                    borderRadius: "lg",
                    boxShadow: "lg",
                    p: 0,
                    // bgcolor: "background.surface",

                }}
            >
                <ModalClose variant="plain" sx={{ m: -1, color: "neutral" }} onClick={toggle} />
                <div className="brand1 modalHeaderColor no-gutters px-2 py-1">
                    <div className="d-flex">
                    <i className={Icons.USERS + "brand0 py-1 me-2"}></i>
                    <span className="bigfont">Select users</span>
                    </div>
                    <div>
                        <Input
                            style={{
                                width: "120px",
                            }}
                            size="small"
                            type="text"
                            className="roundedBorder ps-2 float-end py-0"
                            value={searchTerm}
                            placeholder="search"
                            onChange={handleSearchChange}
                            endDecorator={<EndDecorator/>}
                        />
                    </div>
                </div>
                <div className="">
                    <div className="row no-gutters pb-2">
                        <div className="col ms-2">{checkedProfiles.length>0?`${checkedProfiles.length} user(s) selected`: "no users selected"}</div>
                        <div className="col text-end me-1">
                            <button className="btn btn-sm brand0-bg text-white py-0 " disabled={selectedProfiles.length === 0} onClick={handleCheckedItems}>
                                Share
                            </button>
                        </div>
                    </div>

                    <div className="scrollable-container" style={{ maxHeight: '500px' }}>
                        {filteredProfiles.map((profile) => (
                            <div key={profile.profileId} className="profileCard  ">
                                <ProfileHeader profile={profile}
                                    topRight={
                                        <input
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange(profile)}
                                            checked={selectedProfiles.some((p) => p.profileId === profile.profileId)}
                                        />}
                                />
                            </div>
                        ))}
                        {filteredProfiles.length === 0 &&
                            <div className="p-1 h6">no profiles selected
                            </div>}
                    </div>
                </div>
            </ModalDialog>
        </Modal>
    );
});

export default connect(mapStateToProps, null)(ProfileListModal);
