import React, { useState, useRef } from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import "../MoveMatchQuestions/MoveMatchChoices.css";
import { connect } from "react-redux";
import { updatePictureChoices } from "../../../redux/actions/questionedit-actions"; // Import the action
import notify from "../../notify/Notify"; // Import notify function
import { resizeFile } from "../../../constants/image";
import MultiChoiceCheckbox from "../CreateQuestion/OptionMenus/options/MultiChoiceCheckbox";
import IsOrderedCheckBox from "../CreateQuestion/OptionMenus/options/IsOrderedCheckBox";
import RequiredSelections from "../CreateQuestion/OptionMenus/options/RequiredSelections";
import { questionInstructions } from "../constants";
import { initNumericChoiceStats } from "../../../shared/utilities";
import { pictureChoiceUrl } from "../Utilities";
import { Icons } from "../../../shared/QIcons";


const generateGUID = () =>
  crypto.randomUUID ? crypto.randomUUID() : Math.random().toString(36).substr(2, 9);

function mapStateToProps(state) {
  return {
    question: state.questionedit.question,
    currentUser:state.profile.currentUser,
  };
}

const CreatePictureQuestion = ({ question, currentUser, maxImages = 6, dispatch }) => {
  const { isOrdered} = question;
  const {identityId} = currentUser;
  const [viewOptions, setViewOptions] = useState(false);
  const [choices, setChoices] = useState(
    question.choices.map((choice) => ({
      ...choice,
      imageKey: choice.imageKey || generateGUID(),
      imageURL: choice.imageKey? pictureChoiceUrl({identityId, imageKey:choice.imageKey}):"",
    }))
  );

  const [fullscreenImage, setFullscreenImage] = useState(null);
  const fileInputRefs = useRef({});

  const handleTriggerFileUpload = (imageKey) => {
    if (fileInputRefs.current[imageKey]) {
      fileInputRefs.current[imageKey].click();
    }
  };

  const handleFileUpload = async (event, imageKey) => {
    const file = event.target.files[0];
    if (file) {
      let x = await resizeFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        const newImageURL = e.target.result;

        // Check if the imageURL already exists in the choices
        const isDuplicate = choices.some((item) => item.imageURL === newImageURL);
        if (isDuplicate) {
          notify("Duplicate images are not allowed.", "error");
          return;
        }

        const newImageKey = generateGUID(); // Generate a new GUID

        // Update the imageURL and assign new imageKey
        const updatedChoices = choices.map((item) =>
          item.imageKey === imageKey
            ? { ...item, imageKey: newImageKey, imageURL: newImageURL, file: x }
            : item
        );

        setChoices(updatedChoices);
        dispatch(updatePictureChoices({ question: { choices: updatedChoices } }));
      };
      reader.readAsDataURL(x);
    }
  };

  const deleteImage = (imageKey) => {
    if (!imageKey || choices.length <= 2) {
      return;
    }
    const updatedChoices = choices.filter((item) => item.imageKey !== imageKey);
    const minSel=updatedChoices.length;
    const maxSel=minSel;
    const buckets=maxSel;
    const choiceStats = initNumericChoiceStats({ buckets: buckets, minSel: minSel, maxSel: maxSel, isOrdered: isOrdered, isNumeric: false })
    setChoices(updatedChoices);
    dispatch(updatePictureChoices({ question: { choices: updatedChoices, minSel,maxSel, buckets,choiceStats } }));
  };

  const handleSortEnd = (oldIndex, newIndex) => {
    if (oldIndex !== newIndex) {
      const updatedChoices = arrayMoveImmutable(choices, oldIndex, newIndex);
      setChoices(updatedChoices);
      dispatch(updatePictureChoices({ question: { choices: updatedChoices } }));
    }
  };

  const toggleFullscreenImage = (imageURL) => {
    setFullscreenImage(fullscreenImage === imageURL ? null : imageURL);
  };

  // Immediately update the text as the user types
  const handleTextUpdate = (event, choiceKey) => {
    const { value } = event.target;

    // Update the choice text in real-time
    const updatedChoices = choices.map((item) =>
      item.imageKey === choiceKey ? { ...item, text: value } : item
    );
    setChoices(updatedChoices);
    dispatch(updatePictureChoices({ question: { choices: updatedChoices } }));
  };

  return (
    <div className="px-2">
      {fullscreenImage && (
        <div
          className="fullscreen-overlay"
          onClick={() => setFullscreenImage(null)}
        >
          <img
            src={fullscreenImage}
            alt="Fullscreen Preview"
            className="fullscreen-image"
          />
        </div>
      )}

      <button
        className="add-button mt-3"
        onClick={() => {
          const newChoice = { imageKey: generateGUID(), imageURL: null, text: "" };
          const updatedChoices = [...choices, newChoice];
          const minSel=updatedChoices.length;
          const maxSel=minSel;
          const buckets=maxSel;
          const choiceStats = initNumericChoiceStats({ buckets: buckets, minSel: minSel, maxSel: maxSel, isOrdered: isOrdered, isNumeric: false })
          setChoices(updatedChoices);
          dispatch(updatePictureChoices({ question: { choices: updatedChoices, minSel,maxSel, buckets,choiceStats } }));
        }}
        disabled={choices.length >= maxImages}
      >
        Add Image
      </button>
      <div className="p-1">
        <SortableList
          onSortEnd={handleSortEnd}
          className="row justify-content-center"
          draggedItemClassName="dragged"
        >
          {choices.map((choice) => (
            <SortableItem key={choice.imageKey}>
              <div className="col-6 grid-item px-1 pb-2"> {/* Bootstrap col-6 for 2 items per row */}
                {choice.imageURL ? (
                  <img
                    src={choice.imageURL}
                    alt="Uploaded Preview"
                    className="image-thumbnail"
                    onClick={() => toggleFullscreenImage(choice.imageURL)} // Open fullscreen
                  />
                ) : (
                  <div
                    className="placeholder"
                    onClick={() => handleTriggerFileUpload(choice.imageKey)}
                  >
                    Click to Upload Image
                  </div>
                )}

                {/* Text input that updates immediately as the user types */}
                <input
                  style={{
                    fontSize: ".95em" ,
                  }}
                  type="text"
                  maxLength={15}
                  placeholder="Enter text"
                  value={choice.text}
                  onChange={(e) => handleTextUpdate(e, choice.imageKey)} // Immediately update text field
                />

                <div className="button-container">
                  <button onClick={() => handleTriggerFileUpload(choice.imageKey)}>
                    <i className="fas fa-pencil fa-xs"></i>
                  </button>
                  <button
                    className={`delete ${choices.length <= 2 ? "disabled" : ""}`}
                    onClick={() => deleteImage(choice.imageKey)}
                    disabled={choices.length <= 2}
                  >
                    <i className="fas fa-times fa-xs"></i>
                  </button>
                </div>

                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  ref={(el) => (fileInputRefs.current[choice.imageKey] = el)}
                  onChange={(event) => handleFileUpload(event, choice.imageKey)}
                />
              </div>
            </SortableItem>
          ))}
        </SortableList>
      </div>
     
        <div className="smallfont">{questionInstructions(question)}</div>
          <>
          <div className="brand0 border-bottom my-3 text-end smallfont" onClick={() => setViewOptions(!viewOptions)}>
            options <i className={viewOptions?Icons.CARETUP:Icons.CARETDOWN}></i>
          </div>
          {viewOptions &&
            <div className="mb-3">
              <MultiChoiceCheckbox />
              <RequiredSelections />
              <IsOrderedCheckBox />
            </div>
          }
        </>
      </div>
      );
};

      export default connect(mapStateToProps, null)(CreatePictureQuestion);
