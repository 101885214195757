
import store from "../../../redux/store/index"
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { NumericType } from "../../../constants/question";
import { useDispatch, useSelector } from "react-redux";
import { updateEditQuestion } from "../../../redux/actions/questionedit-actions";

dayjs.extend(duration);

export const numericDifference = ({ numericType, minValue, maxValue }) => {
  let difference;
  switch (numericType) {
    case NumericType.Number:  
    case NumericType.Money: {
      const min = parseInt(minValue, 10);
      const max = parseInt(maxValue, 10);
      difference = max - min + 1; // Inclusive of minValue and maxValue
      break;
    }
    case NumericType.DateYear: {
      const minYear = dayjs(minValue, 'YYYY');
      const maxYear = dayjs(maxValue, 'YYYY');
      difference = maxYear.diff(minYear, 'years') + 1;
      break;
    }
    case NumericType.DateMonth: {
      const minMonth = dayjs(minValue, 'MMM YYYY');
      const maxMonth = dayjs(maxValue, 'MMM YYYY');
      difference = maxMonth.diff(minMonth, 'months') + 1;
      break;
    }
    case NumericType.DateDay: {
      const minDay = dayjs(minValue, 'MMM DD YYYY');
      const maxDay = dayjs(maxValue, 'MMM DD YYYY');
      difference = maxDay.diff(minDay, 'day') + 1;
      break;
    }
    case NumericType.Time30Min: {
      const minTime = dayjs(minValue, 'hh:mm A');
      const maxTime = dayjs(maxValue, 'hh:mm A');
      const diffMinutes = Math.abs(maxTime.diff(minTime, 'minute'));
      difference = Math.floor(diffMinutes / 30) + 1; // Divide by 5 for 5-minute intervals, inclusive
      break;
    }
    default:
      throw new Error(`Unsupported difference type: ${numericType}`);
  };
  return difference;
}

export function createStepStats(buckets, steps) {
  const array=[];
  //const array = new Array(buckets).fill(null).map(() => new Array(steps).fill(0));
  return array;
}

export const calculateRanges = ({ numericType }) => {
  var theStore = store.getState();
  var minValue = theStore.questionedit.question.minRange1;
  var maxValue = theStore.questionedit.question.maxRange1
  const difference = numericDifference({ numericType, minValue, maxValue });


  let ranges;
  if (difference > 12) {
    let bestNumRanges = 12;
    let bestStep = 1;
    let minResidual = difference;

    for (let i = 12; i >= 5; i--) {
      const step = Math.ceil(difference / i);
      const residual = step * i - difference;

      if (residual >= 0 && residual < step) {
        minResidual = residual;
        bestNumRanges = i;
        bestStep = step;
        break;
      }

      if (residual === 0) break;
    }

    ranges = [];
    let current = 1;

    for (let i = 0; i < bestNumRanges; i++) {
      const next = i === bestNumRanges - 1 ? difference : Math.min(current + bestStep - 1, difference);
      ranges.push({ start: current, end: next }); // Ensure inclusivity and no overlap
      current = next + 1;
    }
  } else {
    ranges = [];
    for (let i = 0; i < difference; i++) {
      ranges.push(1 + i);
    }
  }

  const results = convertRangesToRangeType(numericType, ranges, minValue);

  return results;
}

export function numericParms(numericType) {
  let format, views, openTo, inputLabel, startLabel, endLabel;
  switch (numericType) {
    case NumericType.DateYear:
      format = 'YYYY';
      views = ['year'];
      inputLabel = "Year";
      startLabel = "Start Year";
      endLabel = "End Year";
      break;
    case NumericType.DateMonth:
      format = 'MMM YYYY';
      views = ['month', 'year'];
      inputLabel = "Month/Year";
      startLabel = "Start Month";
      endLabel = "End Month";
      break;
    case NumericType.DateDay:
      openTo = 'month'
      format = 'MMM DD YYYY';
      views = ['month', 'day', 'year'];
      inputLabel = "Date";
      startLabel = "Start Date";
      endLabel = "End Date";
      break;
    case NumericType.Time30Min:
      format = "hh:mm A";
      inputLabel = "Time";
      startLabel = "Start Time";
      endLabel = "End Time";
      break;
    default:
      break;
  }
  return { format, views, openTo, startLabel, endLabel };
}

export const isTimeInRange = (time, minTime, maxTime) => {
  const time24 = dayjs(time, "hh:mm A").format("HH:mm");
  const minTime24 = dayjs(minTime, "hh:mm A").format("HH:mm");
  const maxTime24 = dayjs(maxTime, "hh:mm A").format("HH:mm");

  if (minTime24 > maxTime24) {
    // Overnight range
    return time24 >= minTime24 || time24 <= maxTime24;
  } else {
    // Regular range
    return time24 >= minTime24 && time24 <= maxTime24;
  }
};


export const startLabels = {
  [NumericType.DateYear]: "Start Year",
  [NumericType.DateMonth]: "Start Month",
  [NumericType.DateDay]: "Start Date",
  [NumericType.Time30Min]: "Start Time",
  [NumericType.Money]: "Start Amount",
  [NumericType.Number]: "Start Number",
};

export const endLabels = {
  [NumericType.DateYear]: "End Year",
  [NumericType.DateMonth]: "End Month",
  [NumericType.DateDay]: "End Date",
  [NumericType.Time30Min]: "End Time",
  [NumericType.Money]: "End Amount",
  [NumericType.Number]: "End Number",
};

export const inputLabels = {
  [NumericType.DateYear]: "Select Year",
  [NumericType.DateMonth]: "Select Month",
  [NumericType.DateDay]: "Select Date",
  [NumericType.Time30Min]: "Select Time",
  [NumericType.Money]: "Enter Amount",
  [NumericType.Number]: "Enter Number",
};

export function convertRangesToRangeType(numericType, ranges, minValue) {
  const results = [];
  const choices = [];

  switch (numericType) {
    case NumericType.Number: {
      const min = parseInt(minValue, 10);
      ranges.forEach(range => {
        if (typeof range === 'object') {
          results.push(`${(min + range.start - 1).toLocaleString()} - ${(min + range.end - 1).toLocaleString()}`);
        } else {
          results.push(`${(min + range - 1).toLocaleString()}`);
        }
      });
      break;
    }
    case NumericType.Money: {
      const min = parseInt(minValue, 10);
      ranges.forEach(range => {
        if (typeof range === 'object') {
          if (range.start === range.end)
            results.push(`${range.end}`);
          else
            results.push(`$${(min + range.start - 1).toLocaleString()} - $${(min + range.end - 1).toLocaleString()}`);
        } else {
          results.push(`$${(min + range - 1).toLocaleString()}`);
        }
      });
      break;
    }
    case NumericType.DateYear: {
      let currentYear = dayjs(minValue, 'YYYY');
      ranges.forEach(range => {
        if (typeof range === 'object') {
          const startYear = currentYear.add(range.start - 1, 'year').format('YYYY');
          const endYear = currentYear.add(range.end - 1, 'year').format('YYYY');
          if (startYear === endYear) results.push(startYear)
          else
            results.push(`${startYear} - ${endYear}`);
        } else {
          results.push(currentYear.add(range - 1, 'year').format('YYYY'));
        }
      });
      break;
    }
    case NumericType.DateMonth: {
      let currentMonth = dayjs(minValue, 'MMM YYYY');
      ranges.forEach(range => {
        if (typeof range === 'object') {
          const startMonth = currentMonth.add(range.start - 1, 'month').format('MMM');
          const endMonth = currentMonth.add(range.end - 1, 'month').format('MMM YYYY');
          if (range.start === range.end)
            results.push(`${endMonth}`);
          else
            results.push(`${startMonth} - ${endMonth}`);
        } else {
          results.push(currentMonth.add(range - 1, 'month').format('MMM YYYY'));
        }
      });
      break;
    }
    case NumericType.DateDay: {
      let currentDay = dayjs(minValue, 'MMM DD YYYY');
      ranges.forEach(range => {
        if (typeof range === 'object') {
          const startDay = currentDay.add(range.start - 1, 'day').format('MMM DD');
          const endDay = currentDay.add(range.end - 1, 'day').format("MMM DD 'YY");
          if (range.start === range.end)
            results.push(`${endDay}`);
          else
            results.push(`${startDay}-${endDay}`);
        } else {
          results.push(currentDay.add(range - 1, 'day').format('MMM DD YYYY'));
        }
      });
      break;
    }
    case NumericType.Time30Min: {
      let currentTime = dayjs(minValue, 'hh:mm A');
      ranges.forEach(range => {
        if (typeof range === 'object') {
          const startTime = currentTime.add((range.start - 1) * 30, 'minute').format('hh:mm');
          const endTime = currentTime.add((range.end - 1) * 30, 'minute').format('hh:mm A');
          if (range.start === range.end)
            results.push(`${endTime}`);
          else
            results.push(`${startTime} - ${endTime}`);
        } else {
          results.push(currentTime.add((range - 1) * 30, 'minute').format('hh:mm A'));
        }
      });
      break;
    }
    default:
      throw new Error(`Unsupported range type: ${numericType}`);
  }
  results.forEach(result => {
    choices.push({
      text: result,
      imageKey: null,
      imageURL: null
    })
  })

  return choices;
}

export const findBucket = ({ displayQuestion, value }) => {
  const { numericType, minRange1, maxRange1, buckets } = displayQuestion;
  const range = numericDifference({ numericType, minValue: minRange1, maxValue: maxRange1 });
  const steps = Math.ceil(range / buckets);
  const thruRange = numericDifference({ numericType, minValue: minRange1, maxValue: value }) - 1;
  const userBucket = Math.floor(thruRange / steps);
  return userBucket;
}

export const findStep = ({ displayQuestion, value }) => {
  const { numericType, minRange1, maxRange1, buckets } = displayQuestion;
  const range = numericDifference({ numericType, minValue: minRange1, maxValue: maxRange1 });
  const steps = Math.ceil(range / buckets);
  const thruRange = numericDifference({ numericType, minValue: minRange1, maxValue: value }) - 1;
  const step = thruRange % steps;
  return step;
}

export const CreateChoicesButton=({canCreate})=>{
  const question = useSelector((state) => state.questionedit.question);
    const dispatch = useDispatch();
  const { numericType, minRange1, maxRange1 } = question;

  // Calculate choices and related values
  const choices = calculateRanges({ numericType });
  const range = numericDifference({ numericType, minValue: minRange1, maxValue: maxRange1 });
  const steps = Math.ceil(range / choices.length);
  const stepStats = createStepStats(choices.length, steps);

  const createChoices=()=>{
  dispatch(
    updateEditQuestion({
      question: { choices, numericType, stepStats },
    })
  );
}

  return(
    <div variant="contained" disabled={!canCreate} className={`button white-text p-1 w-50 ${canCreate?"brand0-bg":"inactiveBackgroundColor"}`} onClick={() => createChoices()}>
    Generate Ranges
  </div>
  )
}