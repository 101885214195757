/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const processSharedQuestion = /* GraphQL */ `
  mutation ProcessSharedQuestion(
    $questionId: ID
    $profileId: ID
    $sourceId: Int
  ) {
    processSharedQuestion(
      questionId: $questionId
      profileId: $profileId
      sourceId: $sourceId
    )
  }
`;
export const processProfileInteraction = /* GraphQL */ `
  mutation ProcessProfileInteraction($input: ProfileInteractionInput) {
    processProfileInteraction(input: $input) {
      profileId
      associatedId
      lastViewed
      category
      stats {
        CHAU
        FEAU
        FLAU
        FOLU
        LIKU
        MODU
        SHAU
        SPOU
        __typename
      }
      userFollows
      isOwner
      targetId
      lastMessageTime
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const processQuestionInteraction = /* GraphQL */ `
  mutation ProcessQuestionInteraction($input: QuestionInteractionInput) {
    processQuestionInteraction(input: $input) {
      audience
      buckets
      choices {
        text
        imageKey
        imageURL
        __typename
      }
      category
      choiceHeadings
      conversationId
      creatorId
      profileStub {
        avatarKey
        lastName
        firstName
        identityId
        __typename
      }
      endTime
      howLong
      howOften
      questionId
      imageURL
      imageKey
      choiceStats {
        count
        __typename
      }
      stepStats
      key
      stats {
        CHAQ
        CREA
        CREI
        CREQ
        HCRQ
        HLNQ
        HREQ
        HLKQ
        FEAQ
        FLAQ
        INCA
        INCQ
        INCR
        LIKQ
        LINQ
        MODQ
        RESQ
        SHAQ
        SPOQ
        STEA
        TAGQ
        __typename
      }
      tags
      linkSelections
      incentive {
        count
        frequency
        imageKey
        description
        selections
        values
        __typename
      }
      landscapeImages
      isModerated
      isMultipleChoice
      isOrdered
      isPremium
      draft
      isPrivate
      isNew
      targetId
      minRange1
      minRange2
      maxRange1
      maxRange2
      maxSel
      minSel
      numericType
      parentId
      questionType
      questionText
      qmosr
      shareProfile {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        lastFeedView
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      startTime
      status
      stealthTime
      updatedAt
      updateDate
      updateTime
      userFeed {
        category
        createdAt
        publicTime
        feedTime
        pushTime
        updatedAt
        profileId
        questionId
        tags
        isPrivate
        selections
        values
        steps
        hidden
        lastMessageTime
        sharedBy
        stealthTime
        toggledStealth
        targetId
        ownerId
        isOwner
        weight
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      viewUserFeed {
        category
        createdAt
        publicTime
        feedTime
        pushTime
        updatedAt
        profileId
        questionId
        tags
        isPrivate
        selections
        values
        steps
        hidden
        lastMessageTime
        sharedBy
        stealthTime
        toggledStealth
        targetId
        ownerId
        isOwner
        weight
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      viewProfileId
      weight
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion($input: CreateQuestionInput) {
    createQuestion(input: $input) {
      audience
      buckets
      choices {
        text
        imageKey
        imageURL
        __typename
      }
      category
      choiceHeadings
      conversationId
      creatorId
      profileStub {
        avatarKey
        lastName
        firstName
        identityId
        __typename
      }
      endTime
      howLong
      howOften
      questionId
      imageURL
      imageKey
      choiceStats {
        count
        __typename
      }
      stepStats
      key
      stats {
        CHAQ
        CREA
        CREI
        CREQ
        HCRQ
        HLNQ
        HREQ
        HLKQ
        FEAQ
        FLAQ
        INCA
        INCQ
        INCR
        LIKQ
        LINQ
        MODQ
        RESQ
        SHAQ
        SPOQ
        STEA
        TAGQ
        __typename
      }
      tags
      linkSelections
      incentive {
        count
        frequency
        imageKey
        description
        selections
        values
        __typename
      }
      landscapeImages
      isModerated
      isMultipleChoice
      isOrdered
      isPremium
      draft
      isPrivate
      isNew
      targetId
      minRange1
      minRange2
      maxRange1
      maxRange2
      maxSel
      minSel
      numericType
      parentId
      questionType
      questionText
      qmosr
      shareProfile {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        lastFeedView
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      startTime
      status
      stealthTime
      updatedAt
      updateDate
      updateTime
      userFeed {
        category
        createdAt
        publicTime
        feedTime
        pushTime
        updatedAt
        profileId
        questionId
        tags
        isPrivate
        selections
        values
        steps
        hidden
        lastMessageTime
        sharedBy
        stealthTime
        toggledStealth
        targetId
        ownerId
        isOwner
        weight
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      viewUserFeed {
        category
        createdAt
        publicTime
        feedTime
        pushTime
        updatedAt
        profileId
        questionId
        tags
        isPrivate
        selections
        values
        steps
        hidden
        lastMessageTime
        sharedBy
        stealthTime
        toggledStealth
        targetId
        ownerId
        isOwner
        weight
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      viewProfileId
      weight
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const shareQuestion = /* GraphQL */ `
  mutation ShareQuestion($input: ShareQuestionInput) {
    shareQuestion(input: $input) {
      action
      actionTime
      category
      createdAt
      interactionId
      targetId
      updatedAt
      order
      shareInfo {
        shareAction
        users
        choiceOrder
        __typename
      }
      userFeedId
      viewFeedId
      question {
        audience
        buckets
        category
        choiceHeadings
        conversationId
        creatorId
        endTime
        howLong
        howOften
        questionId
        imageURL
        imageKey
        stepStats
        key
        tags
        linkSelections
        landscapeImages
        isModerated
        isMultipleChoice
        isOrdered
        isPremium
        draft
        isPrivate
        isNew
        targetId
        minRange1
        minRange2
        maxRange1
        maxRange2
        maxSel
        minSel
        numericType
        parentId
        questionType
        questionText
        qmosr
        startTime
        status
        stealthTime
        updatedAt
        updateDate
        updateTime
        viewProfileId
        weight
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      profileFeed {
        profileId
        associatedId
        lastViewed
        category
        userFollows
        isOwner
        targetId
        lastMessageTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      viewProfileId
      profile {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        lastFeedView
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      profileId
      associatedId
      relatedId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile($input: CreateProfileInput) {
    createProfile(input: $input) {
      avatar
      avatarKey
      avatarURL
      category
      conversationId
      createdAt
      creator {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        lastFeedView
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      creatorId
      description
      email
      firstName
      follower
      identityId
      lastLogon
      lastName
      photo
      photoKey
      photoURL
      profileFeed {
        profileId
        associatedId
        lastViewed
        category
        userFollows
        isOwner
        targetId
        lastMessageTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      profileId
      profileURL
      questions {
        nextToken
        startedAt
        __typename
      }
      showTooltips
      stats {
        __typename
      }
      myProfileFeed {
        profileId
        associatedId
        lastViewed
        category
        userFollows
        isOwner
        targetId
        lastMessageTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      targetId
      title
      updatedAt
      lastFeedView
      userFeed {
        category
        createdAt
        publicTime
        feedTime
        pushTime
        updatedAt
        profileId
        questionId
        tags
        isPrivate
        selections
        values
        steps
        hidden
        lastMessageTime
        sharedBy
        stealthTime
        toggledStealth
        targetId
        ownerId
        isOwner
        weight
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userFeedQuestionId
      userName
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion($input: DeleteQuestionInput) {
    deleteQuestion(input: $input) {
      audience
      buckets
      choices {
        text
        imageKey
        imageURL
        __typename
      }
      category
      choiceHeadings
      conversationId
      creatorId
      profileStub {
        avatarKey
        lastName
        firstName
        identityId
        __typename
      }
      endTime
      howLong
      howOften
      questionId
      imageURL
      imageKey
      choiceStats {
        count
        __typename
      }
      stepStats
      key
      stats {
        CHAQ
        CREA
        CREI
        CREQ
        HCRQ
        HLNQ
        HREQ
        HLKQ
        FEAQ
        FLAQ
        INCA
        INCQ
        INCR
        LIKQ
        LINQ
        MODQ
        RESQ
        SHAQ
        SPOQ
        STEA
        TAGQ
        __typename
      }
      tags
      linkSelections
      incentive {
        count
        frequency
        imageKey
        description
        selections
        values
        __typename
      }
      landscapeImages
      isModerated
      isMultipleChoice
      isOrdered
      isPremium
      draft
      isPrivate
      isNew
      targetId
      minRange1
      minRange2
      maxRange1
      maxRange2
      maxSel
      minSel
      numericType
      parentId
      questionType
      questionText
      qmosr
      shareProfile {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        lastFeedView
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      startTime
      status
      stealthTime
      updatedAt
      updateDate
      updateTime
      userFeed {
        category
        createdAt
        publicTime
        feedTime
        pushTime
        updatedAt
        profileId
        questionId
        tags
        isPrivate
        selections
        values
        steps
        hidden
        lastMessageTime
        sharedBy
        stealthTime
        toggledStealth
        targetId
        ownerId
        isOwner
        weight
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      viewUserFeed {
        category
        createdAt
        publicTime
        feedTime
        pushTime
        updatedAt
        profileId
        questionId
        tags
        isPrivate
        selections
        values
        steps
        hidden
        lastMessageTime
        sharedBy
        stealthTime
        toggledStealth
        targetId
        ownerId
        isOwner
        weight
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      viewProfileId
      weight
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const respondToQuestion = /* GraphQL */ `
  mutation RespondToQuestion($input: QuestionResponseInput) {
    respondToQuestion(input: $input) {
      audience
      buckets
      choices {
        text
        imageKey
        imageURL
        __typename
      }
      category
      choiceHeadings
      conversationId
      creatorId
      profileStub {
        avatarKey
        lastName
        firstName
        identityId
        __typename
      }
      endTime
      howLong
      howOften
      questionId
      imageURL
      imageKey
      choiceStats {
        count
        __typename
      }
      stepStats
      key
      stats {
        CHAQ
        CREA
        CREI
        CREQ
        HCRQ
        HLNQ
        HREQ
        HLKQ
        FEAQ
        FLAQ
        INCA
        INCQ
        INCR
        LIKQ
        LINQ
        MODQ
        RESQ
        SHAQ
        SPOQ
        STEA
        TAGQ
        __typename
      }
      tags
      linkSelections
      incentive {
        count
        frequency
        imageKey
        description
        selections
        values
        __typename
      }
      landscapeImages
      isModerated
      isMultipleChoice
      isOrdered
      isPremium
      draft
      isPrivate
      isNew
      targetId
      minRange1
      minRange2
      maxRange1
      maxRange2
      maxSel
      minSel
      numericType
      parentId
      questionType
      questionText
      qmosr
      shareProfile {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        lastFeedView
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      startTime
      status
      stealthTime
      updatedAt
      updateDate
      updateTime
      userFeed {
        category
        createdAt
        publicTime
        feedTime
        pushTime
        updatedAt
        profileId
        questionId
        tags
        isPrivate
        selections
        values
        steps
        hidden
        lastMessageTime
        sharedBy
        stealthTime
        toggledStealth
        targetId
        ownerId
        isOwner
        weight
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      viewUserFeed {
        category
        createdAt
        publicTime
        feedTime
        pushTime
        updatedAt
        profileId
        questionId
        tags
        isPrivate
        selections
        values
        steps
        hidden
        lastMessageTime
        sharedBy
        stealthTime
        toggledStealth
        targetId
        ownerId
        isOwner
        weight
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      viewProfileId
      weight
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const pushPublicQuestion = /* GraphQL */ `
  mutation PushPublicQuestion($input: PushQuestionInput) {
    pushPublicQuestion(input: $input) {
      audience
      buckets
      choices {
        text
        imageKey
        imageURL
        __typename
      }
      category
      choiceHeadings
      conversationId
      creatorId
      profileStub {
        avatarKey
        lastName
        firstName
        identityId
        __typename
      }
      endTime
      howLong
      howOften
      questionId
      imageURL
      imageKey
      choiceStats {
        count
        __typename
      }
      stepStats
      key
      stats {
        CHAQ
        CREA
        CREI
        CREQ
        HCRQ
        HLNQ
        HREQ
        HLKQ
        FEAQ
        FLAQ
        INCA
        INCQ
        INCR
        LIKQ
        LINQ
        MODQ
        RESQ
        SHAQ
        SPOQ
        STEA
        TAGQ
        __typename
      }
      tags
      linkSelections
      incentive {
        count
        frequency
        imageKey
        description
        selections
        values
        __typename
      }
      landscapeImages
      isModerated
      isMultipleChoice
      isOrdered
      isPremium
      draft
      isPrivate
      isNew
      targetId
      minRange1
      minRange2
      maxRange1
      maxRange2
      maxSel
      minSel
      numericType
      parentId
      questionType
      questionText
      qmosr
      shareProfile {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        lastFeedView
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      startTime
      status
      stealthTime
      updatedAt
      updateDate
      updateTime
      userFeed {
        category
        createdAt
        publicTime
        feedTime
        pushTime
        updatedAt
        profileId
        questionId
        tags
        isPrivate
        selections
        values
        steps
        hidden
        lastMessageTime
        sharedBy
        stealthTime
        toggledStealth
        targetId
        ownerId
        isOwner
        weight
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      viewUserFeed {
        category
        createdAt
        publicTime
        feedTime
        pushTime
        updatedAt
        profileId
        questionId
        tags
        isPrivate
        selections
        values
        steps
        hidden
        lastMessageTime
        sharedBy
        stealthTime
        toggledStealth
        targetId
        ownerId
        isOwner
        weight
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      viewProfileId
      weight
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const pushQuestion = /* GraphQL */ `
  mutation PushQuestion($input: PushQuestionInput) {
    pushQuestion(input: $input) {
      audience
      buckets
      choices {
        text
        imageKey
        imageURL
        __typename
      }
      category
      choiceHeadings
      conversationId
      creatorId
      profileStub {
        avatarKey
        lastName
        firstName
        identityId
        __typename
      }
      endTime
      howLong
      howOften
      questionId
      imageURL
      imageKey
      choiceStats {
        count
        __typename
      }
      stepStats
      key
      stats {
        CHAQ
        CREA
        CREI
        CREQ
        HCRQ
        HLNQ
        HREQ
        HLKQ
        FEAQ
        FLAQ
        INCA
        INCQ
        INCR
        LIKQ
        LINQ
        MODQ
        RESQ
        SHAQ
        SPOQ
        STEA
        TAGQ
        __typename
      }
      tags
      linkSelections
      incentive {
        count
        frequency
        imageKey
        description
        selections
        values
        __typename
      }
      landscapeImages
      isModerated
      isMultipleChoice
      isOrdered
      isPremium
      draft
      isPrivate
      isNew
      targetId
      minRange1
      minRange2
      maxRange1
      maxRange2
      maxSel
      minSel
      numericType
      parentId
      questionType
      questionText
      qmosr
      shareProfile {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        lastFeedView
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      startTime
      status
      stealthTime
      updatedAt
      updateDate
      updateTime
      userFeed {
        category
        createdAt
        publicTime
        feedTime
        pushTime
        updatedAt
        profileId
        questionId
        tags
        isPrivate
        selections
        values
        steps
        hidden
        lastMessageTime
        sharedBy
        stealthTime
        toggledStealth
        targetId
        ownerId
        isOwner
        weight
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      viewUserFeed {
        category
        createdAt
        publicTime
        feedTime
        pushTime
        updatedAt
        profileId
        questionId
        tags
        isPrivate
        selections
        values
        steps
        hidden
        lastMessageTime
        sharedBy
        stealthTime
        toggledStealth
        targetId
        ownerId
        isOwner
        weight
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      viewProfileId
      weight
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const pushHashtag = /* GraphQL */ `
  mutation PushHashtag($input: PushHashtagInput) {
    pushHashtag(input: $input) {
      tagId
      tag
      sortId
      count
      imageKey
      imageURL
      updatedAt
      createdAt
      questionId
      profileId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const pushProfile = /* GraphQL */ `
  mutation PushProfile($input: PushProfileInput) {
    pushProfile(input: $input) {
      avatar
      avatarKey
      avatarURL
      category
      conversationId
      createdAt
      creator {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        lastFeedView
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      creatorId
      description
      email
      firstName
      follower
      identityId
      lastLogon
      lastName
      photo
      photoKey
      photoURL
      profileFeed {
        profileId
        associatedId
        lastViewed
        category
        userFollows
        isOwner
        targetId
        lastMessageTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      profileId
      profileURL
      questions {
        nextToken
        startedAt
        __typename
      }
      showTooltips
      stats {
        __typename
      }
      myProfileFeed {
        profileId
        associatedId
        lastViewed
        category
        userFollows
        isOwner
        targetId
        lastMessageTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      targetId
      title
      updatedAt
      lastFeedView
      userFeed {
        category
        createdAt
        publicTime
        feedTime
        pushTime
        updatedAt
        profileId
        questionId
        tags
        isPrivate
        selections
        values
        steps
        hidden
        lastMessageTime
        sharedBy
        stealthTime
        toggledStealth
        targetId
        ownerId
        isOwner
        weight
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userFeedQuestionId
      userName
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const pushUserFeed = /* GraphQL */ `
  mutation PushUserFeed($input: PushUserFeedInput) {
    pushUserFeed(input: $input) {
      category
      createdAt
      publicTime
      feedTime
      pushTime
      updatedAt
      profileId
      questionId
      tags
      isPrivate
      selections
      values
      steps
      stats {
        CHAQ
        CREA
        CREI
        CREQ
        HCRQ
        HLNQ
        HREQ
        HLKQ
        FEAQ
        FLAQ
        INCA
        INCQ
        INCR
        LIKQ
        LINQ
        MODQ
        RESQ
        SHAQ
        SPOQ
        STEA
        TAGQ
        __typename
      }
      hidden
      lastMessageTime
      sharedBy
      sharerProfile {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        lastFeedView
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      stealthTime
      incentive {
        achievedDate
        redeemedDate
        redemptionCode
        posCode
        __typename
      }
      toggledStealth
      targetId
      ownerId
      isOwner
      weight
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const pushProfileFeed = /* GraphQL */ `
  mutation PushProfileFeed($input: PushProfileFeedInput) {
    pushProfileFeed(input: $input) {
      profileId
      associatedId
      lastViewed
      category
      stats {
        CHAU
        FEAU
        FLAU
        FOLU
        LIKU
        MODU
        SHAU
        SPOU
        __typename
      }
      userFollows
      isOwner
      targetId
      lastMessageTime
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const pushInteraction = /* GraphQL */ `
  mutation PushInteraction($input: PushInteractionInput) {
    pushInteraction(input: $input) {
      action
      actionTime
      category
      createdAt
      interactionId
      targetId
      updatedAt
      order
      shareInfo {
        shareAction
        users
        choiceOrder
        __typename
      }
      userFeedId
      viewFeedId
      question {
        audience
        buckets
        category
        choiceHeadings
        conversationId
        creatorId
        endTime
        howLong
        howOften
        questionId
        imageURL
        imageKey
        stepStats
        key
        tags
        linkSelections
        landscapeImages
        isModerated
        isMultipleChoice
        isOrdered
        isPremium
        draft
        isPrivate
        isNew
        targetId
        minRange1
        minRange2
        maxRange1
        maxRange2
        maxSel
        minSel
        numericType
        parentId
        questionType
        questionText
        qmosr
        startTime
        status
        stealthTime
        updatedAt
        updateDate
        updateTime
        viewProfileId
        weight
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      profileFeed {
        profileId
        associatedId
        lastViewed
        category
        userFollows
        isOwner
        targetId
        lastMessageTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      viewProfileId
      profile {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        lastFeedView
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      profileId
      associatedId
      relatedId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createChoiceOrder = /* GraphQL */ `
  mutation CreateChoiceOrder(
    $input: CreateChoiceOrderInput!
    $condition: ModelChoiceOrderConditionInput
  ) {
    createChoiceOrder(input: $input, condition: $condition) {
      choice
      choiceOrderTime
      choiceOrder
      questionChoice
      updatedAt
      createdAt
      order
      questionId
      profileId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateChoiceOrder = /* GraphQL */ `
  mutation UpdateChoiceOrder(
    $input: UpdateChoiceOrderInput!
    $condition: ModelChoiceOrderConditionInput
  ) {
    updateChoiceOrder(input: $input, condition: $condition) {
      choice
      choiceOrderTime
      choiceOrder
      questionChoice
      updatedAt
      createdAt
      order
      questionId
      profileId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteChoiceOrder = /* GraphQL */ `
  mutation DeleteChoiceOrder(
    $input: DeleteChoiceOrderInput!
    $condition: ModelChoiceOrderConditionInput
  ) {
    deleteChoiceOrder(input: $input, condition: $condition) {
      choice
      choiceOrderTime
      choiceOrder
      questionChoice
      updatedAt
      createdAt
      order
      questionId
      profileId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createConversation = /* GraphQL */ `
  mutation CreateConversation(
    $input: CreateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    createConversation(input: $input, condition: $condition) {
      actionTime
      activeCount
      category
      chatType
      conversationId
      createdAt
      creator {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        lastFeedView
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      creatorId
      lastMessageTime
      messages {
        nextToken
        startedAt
        __typename
      }
      relatedId
      userConversation {
        action
        actionTime
        category
        createdAt
        interactionId
        targetId
        updatedAt
        order
        userFeedId
        viewFeedId
        viewProfileId
        profileId
        associatedId
        relatedId
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateConversation = /* GraphQL */ `
  mutation UpdateConversation(
    $input: UpdateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    updateConversation(input: $input, condition: $condition) {
      actionTime
      activeCount
      category
      chatType
      conversationId
      createdAt
      creator {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        lastFeedView
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      creatorId
      lastMessageTime
      messages {
        nextToken
        startedAt
        __typename
      }
      relatedId
      userConversation {
        action
        actionTime
        category
        createdAt
        interactionId
        targetId
        updatedAt
        order
        userFeedId
        viewFeedId
        viewProfileId
        profileId
        associatedId
        relatedId
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteConversation = /* GraphQL */ `
  mutation DeleteConversation(
    $input: DeleteConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    deleteConversation(input: $input, condition: $condition) {
      actionTime
      activeCount
      category
      chatType
      conversationId
      createdAt
      creator {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        lastFeedView
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      creatorId
      lastMessageTime
      messages {
        nextToken
        startedAt
        __typename
      }
      relatedId
      userConversation {
        action
        actionTime
        category
        createdAt
        interactionId
        targetId
        updatedAt
        order
        userFeedId
        viewFeedId
        viewProfileId
        profileId
        associatedId
        relatedId
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createInteraction = /* GraphQL */ `
  mutation CreateInteraction(
    $input: CreateInteractionInput!
    $condition: ModelInteractionConditionInput
  ) {
    createInteraction(input: $input, condition: $condition) {
      action
      actionTime
      category
      createdAt
      interactionId
      targetId
      updatedAt
      order
      shareInfo {
        shareAction
        users
        choiceOrder
        __typename
      }
      userFeedId
      viewFeedId
      question {
        audience
        buckets
        category
        choiceHeadings
        conversationId
        creatorId
        endTime
        howLong
        howOften
        questionId
        imageURL
        imageKey
        stepStats
        key
        tags
        linkSelections
        landscapeImages
        isModerated
        isMultipleChoice
        isOrdered
        isPremium
        draft
        isPrivate
        isNew
        targetId
        minRange1
        minRange2
        maxRange1
        maxRange2
        maxSel
        minSel
        numericType
        parentId
        questionType
        questionText
        qmosr
        startTime
        status
        stealthTime
        updatedAt
        updateDate
        updateTime
        viewProfileId
        weight
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      profileFeed {
        profileId
        associatedId
        lastViewed
        category
        userFollows
        isOwner
        targetId
        lastMessageTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      viewProfileId
      profile {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        lastFeedView
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      profileId
      associatedId
      relatedId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateInteraction = /* GraphQL */ `
  mutation UpdateInteraction(
    $input: UpdateInteractionInput!
    $condition: ModelInteractionConditionInput
  ) {
    updateInteraction(input: $input, condition: $condition) {
      action
      actionTime
      category
      createdAt
      interactionId
      targetId
      updatedAt
      order
      shareInfo {
        shareAction
        users
        choiceOrder
        __typename
      }
      userFeedId
      viewFeedId
      question {
        audience
        buckets
        category
        choiceHeadings
        conversationId
        creatorId
        endTime
        howLong
        howOften
        questionId
        imageURL
        imageKey
        stepStats
        key
        tags
        linkSelections
        landscapeImages
        isModerated
        isMultipleChoice
        isOrdered
        isPremium
        draft
        isPrivate
        isNew
        targetId
        minRange1
        minRange2
        maxRange1
        maxRange2
        maxSel
        minSel
        numericType
        parentId
        questionType
        questionText
        qmosr
        startTime
        status
        stealthTime
        updatedAt
        updateDate
        updateTime
        viewProfileId
        weight
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      profileFeed {
        profileId
        associatedId
        lastViewed
        category
        userFollows
        isOwner
        targetId
        lastMessageTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      viewProfileId
      profile {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        lastFeedView
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      profileId
      associatedId
      relatedId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteInteraction = /* GraphQL */ `
  mutation DeleteInteraction(
    $input: DeleteInteractionInput!
    $condition: ModelInteractionConditionInput
  ) {
    deleteInteraction(input: $input, condition: $condition) {
      action
      actionTime
      category
      createdAt
      interactionId
      targetId
      updatedAt
      order
      shareInfo {
        shareAction
        users
        choiceOrder
        __typename
      }
      userFeedId
      viewFeedId
      question {
        audience
        buckets
        category
        choiceHeadings
        conversationId
        creatorId
        endTime
        howLong
        howOften
        questionId
        imageURL
        imageKey
        stepStats
        key
        tags
        linkSelections
        landscapeImages
        isModerated
        isMultipleChoice
        isOrdered
        isPremium
        draft
        isPrivate
        isNew
        targetId
        minRange1
        minRange2
        maxRange1
        maxRange2
        maxSel
        minSel
        numericType
        parentId
        questionType
        questionText
        qmosr
        startTime
        status
        stealthTime
        updatedAt
        updateDate
        updateTime
        viewProfileId
        weight
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      profileFeed {
        profileId
        associatedId
        lastViewed
        category
        userFollows
        isOwner
        targetId
        lastMessageTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      viewProfileId
      profile {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        lastFeedView
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      profileId
      associatedId
      relatedId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      action
      sender {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        lastFeedView
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      content
      category
      conversationId
      conversation {
        actionTime
        activeCount
        category
        chatType
        conversationId
        createdAt
        creatorId
        lastMessageTime
        relatedId
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      fileKey
      isSent
      messageId
      questionId
      question {
        audience
        buckets
        category
        choiceHeadings
        conversationId
        creatorId
        endTime
        howLong
        howOften
        questionId
        imageURL
        imageKey
        stepStats
        key
        tags
        linkSelections
        landscapeImages
        isModerated
        isMultipleChoice
        isOrdered
        isPremium
        draft
        isPrivate
        isNew
        targetId
        minRange1
        minRange2
        maxRange1
        maxRange2
        maxSel
        minSel
        numericType
        parentId
        questionType
        questionText
        qmosr
        startTime
        status
        stealthTime
        updatedAt
        updateDate
        updateTime
        viewProfileId
        weight
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      relatedId
      senderId
      updatedAt
      viewProfileId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      action
      sender {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        lastFeedView
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      content
      category
      conversationId
      conversation {
        actionTime
        activeCount
        category
        chatType
        conversationId
        createdAt
        creatorId
        lastMessageTime
        relatedId
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      fileKey
      isSent
      messageId
      questionId
      question {
        audience
        buckets
        category
        choiceHeadings
        conversationId
        creatorId
        endTime
        howLong
        howOften
        questionId
        imageURL
        imageKey
        stepStats
        key
        tags
        linkSelections
        landscapeImages
        isModerated
        isMultipleChoice
        isOrdered
        isPremium
        draft
        isPrivate
        isNew
        targetId
        minRange1
        minRange2
        maxRange1
        maxRange2
        maxSel
        minSel
        numericType
        parentId
        questionType
        questionText
        qmosr
        startTime
        status
        stealthTime
        updatedAt
        updateDate
        updateTime
        viewProfileId
        weight
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      relatedId
      senderId
      updatedAt
      viewProfileId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      action
      sender {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        lastFeedView
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      content
      category
      conversationId
      conversation {
        actionTime
        activeCount
        category
        chatType
        conversationId
        createdAt
        creatorId
        lastMessageTime
        relatedId
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      fileKey
      isSent
      messageId
      questionId
      question {
        audience
        buckets
        category
        choiceHeadings
        conversationId
        creatorId
        endTime
        howLong
        howOften
        questionId
        imageURL
        imageKey
        stepStats
        key
        tags
        linkSelections
        landscapeImages
        isModerated
        isMultipleChoice
        isOrdered
        isPremium
        draft
        isPrivate
        isNew
        targetId
        minRange1
        minRange2
        maxRange1
        maxRange2
        maxSel
        minSel
        numericType
        parentId
        questionType
        questionText
        qmosr
        startTime
        status
        stealthTime
        updatedAt
        updateDate
        updateTime
        viewProfileId
        weight
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      relatedId
      senderId
      updatedAt
      viewProfileId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      avatar
      avatarKey
      avatarURL
      category
      conversationId
      createdAt
      creator {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        lastFeedView
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      creatorId
      description
      email
      firstName
      follower
      identityId
      lastLogon
      lastName
      photo
      photoKey
      photoURL
      profileFeed {
        profileId
        associatedId
        lastViewed
        category
        userFollows
        isOwner
        targetId
        lastMessageTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      profileId
      profileURL
      questions {
        nextToken
        startedAt
        __typename
      }
      showTooltips
      stats {
        __typename
      }
      myProfileFeed {
        profileId
        associatedId
        lastViewed
        category
        userFollows
        isOwner
        targetId
        lastMessageTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      targetId
      title
      updatedAt
      lastFeedView
      userFeed {
        category
        createdAt
        publicTime
        feedTime
        pushTime
        updatedAt
        profileId
        questionId
        tags
        isPrivate
        selections
        values
        steps
        hidden
        lastMessageTime
        sharedBy
        stealthTime
        toggledStealth
        targetId
        ownerId
        isOwner
        weight
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userFeedQuestionId
      userName
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      avatar
      avatarKey
      avatarURL
      category
      conversationId
      createdAt
      creator {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        lastFeedView
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      creatorId
      description
      email
      firstName
      follower
      identityId
      lastLogon
      lastName
      photo
      photoKey
      photoURL
      profileFeed {
        profileId
        associatedId
        lastViewed
        category
        userFollows
        isOwner
        targetId
        lastMessageTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      profileId
      profileURL
      questions {
        nextToken
        startedAt
        __typename
      }
      showTooltips
      stats {
        __typename
      }
      myProfileFeed {
        profileId
        associatedId
        lastViewed
        category
        userFollows
        isOwner
        targetId
        lastMessageTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      targetId
      title
      updatedAt
      lastFeedView
      userFeed {
        category
        createdAt
        publicTime
        feedTime
        pushTime
        updatedAt
        profileId
        questionId
        tags
        isPrivate
        selections
        values
        steps
        hidden
        lastMessageTime
        sharedBy
        stealthTime
        toggledStealth
        targetId
        ownerId
        isOwner
        weight
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userFeedQuestionId
      userName
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createProfileFeed = /* GraphQL */ `
  mutation CreateProfileFeed(
    $input: CreateProfileFeedInput!
    $condition: ModelProfileFeedConditionInput
  ) {
    createProfileFeed(input: $input, condition: $condition) {
      profileId
      associatedId
      lastViewed
      category
      stats {
        CHAU
        FEAU
        FLAU
        FOLU
        LIKU
        MODU
        SHAU
        SPOU
        __typename
      }
      userFollows
      isOwner
      targetId
      lastMessageTime
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateProfileFeed = /* GraphQL */ `
  mutation UpdateProfileFeed(
    $input: UpdateProfileFeedInput!
    $condition: ModelProfileFeedConditionInput
  ) {
    updateProfileFeed(input: $input, condition: $condition) {
      profileId
      associatedId
      lastViewed
      category
      stats {
        CHAU
        FEAU
        FLAU
        FOLU
        LIKU
        MODU
        SHAU
        SPOU
        __typename
      }
      userFollows
      isOwner
      targetId
      lastMessageTime
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteProfileFeed = /* GraphQL */ `
  mutation DeleteProfileFeed(
    $input: DeleteProfileFeedInput!
    $condition: ModelProfileFeedConditionInput
  ) {
    deleteProfileFeed(input: $input, condition: $condition) {
      profileId
      associatedId
      lastViewed
      category
      stats {
        CHAU
        FEAU
        FLAU
        FOLU
        LIKU
        MODU
        SHAU
        SPOU
        __typename
      }
      userFollows
      isOwner
      targetId
      lastMessageTime
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      audience
      buckets
      choices {
        text
        imageKey
        imageURL
        __typename
      }
      category
      choiceHeadings
      conversationId
      creatorId
      profileStub {
        avatarKey
        lastName
        firstName
        identityId
        __typename
      }
      endTime
      howLong
      howOften
      questionId
      imageURL
      imageKey
      choiceStats {
        count
        __typename
      }
      stepStats
      key
      stats {
        CHAQ
        CREA
        CREI
        CREQ
        HCRQ
        HLNQ
        HREQ
        HLKQ
        FEAQ
        FLAQ
        INCA
        INCQ
        INCR
        LIKQ
        LINQ
        MODQ
        RESQ
        SHAQ
        SPOQ
        STEA
        TAGQ
        __typename
      }
      tags
      linkSelections
      incentive {
        count
        frequency
        imageKey
        description
        selections
        values
        __typename
      }
      landscapeImages
      isModerated
      isMultipleChoice
      isOrdered
      isPremium
      draft
      isPrivate
      isNew
      targetId
      minRange1
      minRange2
      maxRange1
      maxRange2
      maxSel
      minSel
      numericType
      parentId
      questionType
      questionText
      qmosr
      shareProfile {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        lastFeedView
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      startTime
      status
      stealthTime
      updatedAt
      updateDate
      updateTime
      userFeed {
        category
        createdAt
        publicTime
        feedTime
        pushTime
        updatedAt
        profileId
        questionId
        tags
        isPrivate
        selections
        values
        steps
        hidden
        lastMessageTime
        sharedBy
        stealthTime
        toggledStealth
        targetId
        ownerId
        isOwner
        weight
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      viewUserFeed {
        category
        createdAt
        publicTime
        feedTime
        pushTime
        updatedAt
        profileId
        questionId
        tags
        isPrivate
        selections
        values
        steps
        hidden
        lastMessageTime
        sharedBy
        stealthTime
        toggledStealth
        targetId
        ownerId
        isOwner
        weight
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      viewProfileId
      weight
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createResponse = /* GraphQL */ `
  mutation CreateResponse(
    $input: CreateResponseInput!
    $condition: ModelResponseConditionInput
  ) {
    createResponse(input: $input, condition: $condition) {
      profileId
      questionId
      createdAt
      updatedAt
      viewProfileId
      selections
      order
      twins
      values
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateResponse = /* GraphQL */ `
  mutation UpdateResponse(
    $input: UpdateResponseInput!
    $condition: ModelResponseConditionInput
  ) {
    updateResponse(input: $input, condition: $condition) {
      profileId
      questionId
      createdAt
      updatedAt
      viewProfileId
      selections
      order
      twins
      values
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteResponse = /* GraphQL */ `
  mutation DeleteResponse(
    $input: DeleteResponseInput!
    $condition: ModelResponseConditionInput
  ) {
    deleteResponse(input: $input, condition: $condition) {
      profileId
      questionId
      createdAt
      updatedAt
      viewProfileId
      selections
      order
      twins
      values
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createHashtag = /* GraphQL */ `
  mutation CreateHashtag(
    $input: CreateHashtagInput!
    $condition: ModelHashtagConditionInput
  ) {
    createHashtag(input: $input, condition: $condition) {
      tagId
      tag
      sortId
      count
      imageKey
      imageURL
      updatedAt
      createdAt
      questionId
      profileId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateHashtag = /* GraphQL */ `
  mutation UpdateHashtag(
    $input: UpdateHashtagInput!
    $condition: ModelHashtagConditionInput
  ) {
    updateHashtag(input: $input, condition: $condition) {
      tagId
      tag
      sortId
      count
      imageKey
      imageURL
      updatedAt
      createdAt
      questionId
      profileId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteHashtag = /* GraphQL */ `
  mutation DeleteHashtag(
    $input: DeleteHashtagInput!
    $condition: ModelHashtagConditionInput
  ) {
    deleteHashtag(input: $input, condition: $condition) {
      tagId
      tag
      sortId
      count
      imageKey
      imageURL
      updatedAt
      createdAt
      questionId
      profileId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUserFeed = /* GraphQL */ `
  mutation CreateUserFeed(
    $input: CreateUserFeedInput!
    $condition: ModelUserFeedConditionInput
  ) {
    createUserFeed(input: $input, condition: $condition) {
      category
      createdAt
      publicTime
      feedTime
      pushTime
      updatedAt
      profileId
      questionId
      tags
      isPrivate
      selections
      values
      steps
      stats {
        CHAQ
        CREA
        CREI
        CREQ
        HCRQ
        HLNQ
        HREQ
        HLKQ
        FEAQ
        FLAQ
        INCA
        INCQ
        INCR
        LIKQ
        LINQ
        MODQ
        RESQ
        SHAQ
        SPOQ
        STEA
        TAGQ
        __typename
      }
      hidden
      lastMessageTime
      sharedBy
      sharerProfile {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        lastFeedView
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      stealthTime
      incentive {
        achievedDate
        redeemedDate
        redemptionCode
        posCode
        __typename
      }
      toggledStealth
      targetId
      ownerId
      isOwner
      weight
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUserFeed = /* GraphQL */ `
  mutation UpdateUserFeed(
    $input: UpdateUserFeedInput!
    $condition: ModelUserFeedConditionInput
  ) {
    updateUserFeed(input: $input, condition: $condition) {
      category
      createdAt
      publicTime
      feedTime
      pushTime
      updatedAt
      profileId
      questionId
      tags
      isPrivate
      selections
      values
      steps
      stats {
        CHAQ
        CREA
        CREI
        CREQ
        HCRQ
        HLNQ
        HREQ
        HLKQ
        FEAQ
        FLAQ
        INCA
        INCQ
        INCR
        LIKQ
        LINQ
        MODQ
        RESQ
        SHAQ
        SPOQ
        STEA
        TAGQ
        __typename
      }
      hidden
      lastMessageTime
      sharedBy
      sharerProfile {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        lastFeedView
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      stealthTime
      incentive {
        achievedDate
        redeemedDate
        redemptionCode
        posCode
        __typename
      }
      toggledStealth
      targetId
      ownerId
      isOwner
      weight
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUserFeed = /* GraphQL */ `
  mutation DeleteUserFeed(
    $input: DeleteUserFeedInput!
    $condition: ModelUserFeedConditionInput
  ) {
    deleteUserFeed(input: $input, condition: $condition) {
      category
      createdAt
      publicTime
      feedTime
      pushTime
      updatedAt
      profileId
      questionId
      tags
      isPrivate
      selections
      values
      steps
      stats {
        CHAQ
        CREA
        CREI
        CREQ
        HCRQ
        HLNQ
        HREQ
        HLKQ
        FEAQ
        FLAQ
        INCA
        INCQ
        INCR
        LIKQ
        LINQ
        MODQ
        RESQ
        SHAQ
        SPOQ
        STEA
        TAGQ
        __typename
      }
      hidden
      lastMessageTime
      sharedBy
      sharerProfile {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        lastFeedView
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      stealthTime
      incentive {
        achievedDate
        redeemedDate
        redemptionCode
        posCode
        __typename
      }
      toggledStealth
      targetId
      ownerId
      isOwner
      weight
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
