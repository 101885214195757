import React from "react";
import { connect } from "react-redux";
import {  WhoFeeds } from "../../../../constants/feedids";

import UserCount from "./UserCount";
import { Icons } from "../../../../shared/QIcons";

const mapStateToProps = (state, ownProps) => {
    return {
        // displayQuestion: state.feed.items[state.feed.index[ownProps.questionId]]
            };
  };

const QuestionUserSummary = React.memo(function QuestionUserSummary ({questionId, count, userResponded, responded, isPrivate, draft, questionText,displayQuestion })  {

    return(
        <span className="activeColor pointer">
            <UserCount count={count} isPrivate={isPrivate} isLink questionId={questionId} description={questionText} 
                displayQuestion={displayQuestion} feedId={WhoFeeds.RespondedQuestions} >
                <span className="respondLabel"><i className={Icons.USERS}></i></span>
            </UserCount>

        </span>
    )
})

export default connect(mapStateToProps,null)(QuestionUserSummary);
