import React from "react";


const CardImage = React.memo(function CardImage({ image, children }) {

  return (
    <div className=" brand1-bg" style={{ width: "310px", height: "120px" }}>
        {children}
    </div>
  )
});

export default CardImage;
