import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { PublicFeeds } from "../../constants/feedids";
import ActionViewSection from "./ActionViewSection";
import { menuIcons } from "../menus/menuIcons";
import TagFeed from "../feeds/TagFeed";
import ProfileCard from "./ProfileCard";
import { closePanels } from "../../redux/actions/panel-actions";
import { setView } from "../../redux/actions/context-actions";
import { Hashtag } from "../../models";
import { listTagsByProfile } from "../../repository/tagQueries";


const mapStateToProps = (state) => {
    return {
        view: state.context.view,
        user: state.profile.viewProfile,
        profileId: state.profile.viewProfile.profileId,
        profileIsLoaded: state.profile.viewProfileIsLoaded,
    };
};



const PublicFeedActionCard = React.memo(function PublicFeedActionCard({ profileId, profileIsLoaded, view, dispatch }) {
    const [hashtags, setHashtags] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchHashtags = async () => {
            try {
                setLoading(true);
                const response = await listTagsByProfile({ targetId: profileId })
                setHashtags(response.items);
            } catch (err) {
                setError(err);
                console.error('Error fetching hashtags:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchHashtags();
    }, [profileId]);

    const ShowTags = () => {
        return (
            <div>
                {hashtags ? (
                    <div className="flex tagContainer">
                        {hashtags.map((tag) => (
                            <span key={tag.tag} className="tag brand0" onClick={() => getQuestions(tag.tag)}>
                                {tag.tag} ({tag.count})
                            </span>
                        ))}
                    </div>
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        )
    }

    function getQuestions(tag) {
        dispatch(setView({ tag: tag, targetId: profileId, feedId: PublicFeeds.TaggedQuestions }));
    }


        return (
            <div className="drawerCard white shadow-none" style={{ width: "310px", height: "100%" }} key={"profilefeed"}>
                <ProfileCard />
                <ActionViewSection icon={menuIcons.UserTags}>
                    <div className="row my-2 px-2 no-gutters">
                        <ShowTags />
                    </div>
                </ActionViewSection>
            </div>
        )
}
)

export default connect(mapStateToProps, null)(PublicFeedActionCard);





