import React from "react";
import { connect } from "react-redux";
import { toggleProfileInteraction } from "../../redux/actions/profile-actions";
import "../../index.css";
import { actionIsValid } from "../utilities";
import { ActionEnum } from "../../models";
import { SystemId } from "../../constants/profile";


const mapStateToProps = (state, ownProps) => {
  return {
    viewProfileId: state.context.view.profileId,
    viewType: state.profile.viewProfile.viewType,
    pendingInteractions: state.profile.pendingInteractions,
    userId: state.profile.currentUser.profileId
  };
};

const FollowLink = React.memo(function FollowLink({ displayProfile, profileId, userId, following, viewProfileId, pendingInteractions, dispatch }) {

  const follow = (e) => {
    //e.preventDefault(e);
    const action = ActionEnum.FOLU;
    const isPending = pendingInteractions.includes(profileId);
    const payload = { associatedId: displayProfile.profileId, displayProfile: displayProfile, profileId: userId, action: action, state: !following, value: following ? 1 : 0, viewProfileId: viewProfileId ?? SystemId };
    if (actionIsValid(payload && !isPending))
      dispatch(toggleProfileInteraction(payload));
    return;
  }

  if (userId === displayProfile.profileId) {
    return (
      <div className="justify-content-center muted inactiveColor small">&nbsp;</div>
    )
  }
  else {
    if (following) {
      return (
        // <span className="activeColor follow-text pointer p-0 m-0" onClick={(e) => follow(e)}>following</span>
        <button className="btn btn-sm activeColor follow-text  px-1 py-0" onClick={(e) => follow(e)}>following</button>
      )
    }
    else {
      return (
        // <span className="activeColor follow-text pointer p-0 m-0" onClick={(e) => follow(e)}>follow</span>
        <button  className="btn btn-sm activeColor follow-text p-0" onClick={(e) => follow(e)}>follow</button>
      )
    }
  }
})

export default connect(mapStateToProps, null)(FollowLink);
