import * as React from 'react';
import Popover from '@mui/material/Popover';
import { Divider, MenuList } from '@mui/material';
import { Icons } from '../../shared/QIcons';
import { showConfirmModal,  showQuestionModal } from '../../redux/actions/modal-actions';
import { connect } from 'react-redux';
import { hidePanel } from '../../redux/actions/panel-actions';
import { deleteQuestion, removeFromFeed } from '../../redux/actions/question-actions';
import DropDownMenuItem from './DropDownMenuItem';
import { menuIcons } from './menuIcons';
import { stageForEdit } from '../../redux/actions/questionedit-actions';
import { QuestionAskType } from '../../constants/question';
import notYet from '../../storeUtilities';
import notify from '../notify/Notify';

const mapStateToProps = (state, ownProps) => {
    return {
//        question: {...state.feed.items[state.feed.index[ownProps.questionId]]},
        viewProfileId: state.context.view.profileId,
        currentUser: state.profile.currentUser,
        contextProfileId: state.context.view.profileId,
        groups: state.profile.groups ?? []
    }
};

function QuestionMenu({ question, viewProfileId, contextProfileId, currentUser, groups, dispatch }) {
    const { questionId, questionText, parentId, questionType, userFeed:{stats}, choices, imageKey, stats: questionStats, creatorId } = question;
    const isActive = stats.BORQ+stats.CHAQ+stats.CREQ+stats.FEAQ+stats.FLAQ+stats.FLAQ+stats.LIKQ+stats.LINQ+stats.MODQ+stats.RESQ+stats.SHAQ+stats.SPOQ+stats.STEA;
  
    const { profileId: userId, identityId, } = currentUser;
    let isOwner = creatorId === userId;
    const isUserFeed = userId===contextProfileId;
    const isFeaturedAdmin = groups.includes("FeaturedAdmin");
    const isAdmin = groups.includes("Administrators");
    const isPremium = groups.includes("PremiumUsers");
    const isLinked =parentId!==null;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const deleteMsg = () => {
        return (<span>{"Once deleted, '"}<span><b>{questionText}</b></span> {"' will automatically be removed for all users. It will immediately be removed from your feed. It will be removed from other feeds in which it appears once they are refreshed."}</span>
        )
    }

    const handleEdit = () => {
        handleClose();
        dispatch(hidePanel());
        dispatch(stageForEdit({identityId:identityId, question:question, choicesAreGood:true, questionIsGood:true, isPremium:isAdmin || isPremium}));
        dispatch(showQuestionModal({
            question:question, questionType: questionType, hasResponses: questionStats.RESQ > 0, isNew: false
        }));
    }

    const confirmDelete = () => {
        const hasChildren = questionStats.LINQ>0 || questionStats.HLNQ>0;
        if(hasChildren){
            notify("All related questions must be deleted first")
        }
        else
        {
        dispatch(showConfirmModal({ content: deleteMsg(), title:"Confirm Delete", handleConfirm: handleConfirm }));
        }
    }

    const confirmRemove = () => {
        dispatch(showConfirmModal({ content: "Remove from feed?", handleConfirm: handleRemove }));
    }

    function handleConfirm() {
        console.log('delete question');
        let imageActions=[];
        if(imageKey!=='')
            imageActions.push({ action: "delete", imageKey: imageKey});
        if(questionType===QuestionAskType.Pictures || questionType===QuestionAskType.MoveMatch)
            choices.forEach((choice)=>
                imageActions.push({ action: "delete", imageKey: choice.imageKey })
            )
        dispatch(deleteQuestion({ questionId: questionId, userId: userId, viewFeedId: viewProfileId, isLinked: isLinked, imageActions:imageActions }))
    }

    function handleRemove() {
        console.log('remove question');
        dispatch(removeFromFeed({ questionId: questionId, profileId: userId }));
    }

    let menulist = [];
    if (isOwner) {
        if(questionStats.RESQ===0)
            menulist.push(<DropDownMenuItem key={questionId + "edit2"}  icon={menuIcons.Edit} isActive={true} handleClick={() => handleEdit()} />)
        menulist.push(<DropDownMenuItem key={questionId + "del1"} icon={menuIcons.Delete} isActive={true} handleClick={confirmDelete} />);
        menulist.push(<Divider key={questionId +"123"}/>);
    }
    else
        if(isUserFeed && !isActive )
            menulist.push(<DropDownMenuItem key={questionId + "del1"} icon={menuIcons.Remove} isActive={true} handleClick={confirmRemove} />);

    menulist.push(<DropDownMenuItem key={questionId + "rep"} id={questionId + "rep"} icon={menuIcons.Report} isActive={true} handleClick={() => notYet(menuIcons.Report.text)} />);
    menulist.push(<DropDownMenuItem key={questionId + "hide"} id={questionId + "hide"} icon={menuIcons.Hide} isActive={true} handleClick={() => notYet(menuIcons.Hide.text)} />);

    if(isAdmin || isFeaturedAdmin){
        menulist.push(<Divider key={questionId + "435"} className="m-0 p-0" ><small>Admin</small></Divider>);
        menulist.push(<DropDownMenuItem key={questionId + "fea"} id={questionId + "fea"} icon={menuIcons.Feature} isActive={true} handleClick={() => notYet(menuIcons.Hide.text)} />);
    }
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className="float-end">
            <span aria-describedby={id}  variant="contained" className="pointer "
                key={questionId}
                onClick={handleClick}
                onBlur={handleClose}
            >
                <i className={Icons.MENU + " brand5"}></i>
            </span>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <MenuList 
                    className="p-2"
                    id="basic-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                >
                    {menulist}
                </MenuList>
            </Popover>
        </div>
    );
}

export default connect(mapStateToProps, null)(QuestionMenu);
