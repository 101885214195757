import React from "react";
import "../../question/core/index.css";
import Updating from "../../../shared/Updating";

export default class BubbleFeedDisplay extends React.PureComponent {
    constructor(props) {
        super(props);

        this.scrollRef = null;
        this.state = {
            feedId: props.feedId,
            offset: this.props.offset ?? 108,
            width: 800,
            height: 182
        };
    }
    scrollbarsRef = React.createRef();
    // Binds our scroll event handler


    handleScroll = e => {
        const { isLoading, hasMore, loadNextPage, reverseScroll } = this.props;
        const SCROLL_LIMIT = 250;
        let element = e.target;
        if (isLoading || !hasMore) return;
        // Checks that the page has scrolled to the bottom
        const shouldLoad = reverseScroll ?
            element.scrollHeight !== element.clientHeight && element.scrollTop !== 0 && element.scrollTop < SCROLL_LIMIT
            : (element.scrollHeight - element.scrollTop <= element.clientHeight * 1.4);

        if (shouldLoad)
            loadNextPage();
    };

    /**
     * Calculate & Update state of new dimensions
     */
    updateDimensions() {
        // console.log(`innerWidth:${window.innerWidth} innerHeight:${window.innerHeight}`)
        let update_width = window.innerWidth - 100;
        let update_height = window.innerHeight - this.state.offset;
        // console.log(`update width:${update_width} update height:${update_height}`)
        this.setState({ width: update_width, height: update_height });
    }

    componentDidUpdate(props, prevProps) {
        if (this.props.isFirstFeed && props.feedId !== this.state.feedId) {
            this.scrollToTop()
        }
    }


    scrollToTop() {
        var scrollbarsRef = this.scrollbarsRef;
        if ((scrollbarsRef && scrollbarsRef.current)) {
            scrollbarsRef.current.scrollToTop();
        }
    }
    /**
     * Add event listener
     */
    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    /**
     * Remove event listener
     */
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }


    render() {
            const { renderItem, items, noLoadStr,isLoading } = this.props;
            if (!isLoading && items && items.length !== 0) {
                return (
                    <div className=" border-0 parent mx-auto shadow-none ">
                        <div className="col mx-0 px-0 timeline timeline-simple brand1-bg scrollbar ">
                            {items.map((item, i,arr) => (
                                 renderItem(i,arr)
                             ))
                             }
                            <div className="bottomScrollAdjustment">&nbsp;</div>
                        </div>
                    </div>
                );
            }
            else
                if (isLoading) {
                    return (
                        <div className="card parent mx-auto shadow-none ">
                            <div className="col mx-0 px-0 scrollbar ">
                                <Updating />
                            </div>
                        </div>
                    )
                }
                else
                    return (
                        <div className="card parent mx-auto shadow-none ">
                            <div className="row m-0">
                                <div className="col p-0 m-0 h5 grey-text">{noLoadStr ? noLoadStr : <span />}</div>
                            </div>
                        </div>
                    )
        }

        // const { renderItem, isLoaded, items, noLoadStr } = this.props;
        // const scrollContainerStyle = { padding: 0, width: "100%", height:"100%" };

        // if (items.length !== 0) {
        //     return (
        //         // <div className={"m-0 p-0 brand1-bg thin  scrollbar"} id="_pfeed" onScroll={(e) => this.handleScroll(e)} style={scrollContainerStyle} >
        //         //     <div className=" h6 px-2 pb-1 pt-2  card-timeline brand1-bg card-plain  scrollbar" style={scrollContainerStyle}>
        //         <div className="lyqcard border-0 parent mx-auto shadow-none ">
        //             <div className="col mx-0 px-0 card-timeline brand1-bg card-plain scrollbar " style={scrollContainerStyle}>
        //                 <ul className="timeline timeline-simple " onScroll={(e) => this.handleScroll(e)} >
        //                     {items.map((item, i, arr) => (
        //                         renderItem(i, arr)
        //                     ))}
        //                 </ul>
        //                 <ul>&nbsp;</ul>
        //             </div>
        //         </div>
        //     );
        // }
        // else
        //     if (items.length === 0 && isLoaded)
        //         return (
        //             <div className="text-center fs-1 h5 text-muted ml-2"><p>{noLoadStr ?? `  no users found`}</p></div>
        //         )
        //     else
        //         return (<span />
        //         )
    
}
