import config from "../../config";
import { AVATAR_SIZE, MAIN_IMAGE_HEIGHT, CARD_IMAGE_HEIGHT, CHAT_IMAGE_WIDTH, CHAT_IMAGE_HEIGHT, CARD_IMAGE_WIDTH, SM_AVATAR_SIZE, XS_AVATAR_SIZE, SMS_IMAGE_WIDTH, MAIN_IMAGE_WIDTH, CHOICE_IMAGE_SIZE } from "../../constants/image";
import { MyURLs } from "../../constants/url";
import { encode } from 'js-base64';
import "../../index.css";

export function choiceStatsSummary(choiceStats) {
    var result = [];
    for (let z = 0; z < choiceStats.length; z++) {
        let cnt = 0;
        for (let x = 0; x < choiceStats[z].length; x++)
            cnt = cnt + choiceStats[z][x].count;
        let sum = { "count": cnt }
        result[z] = sum;
    }
    return result;
}


export function getSortColSize(choices, isPic) {
    if (isPic) {
        return choices.length <= 2 ? "col-6" : "col-4";
    }
    else {
        var length = choiceLength(choices) - 1;
        length = Math.min(length, 40);
        let response = sortColSize[length];
        return response;
    }
}

export function choiceLength(choices) {
    if (choices.length > 0)
        return choices.reduce(function (a, b) { return a.text.length > b.text.length ? a : b; }).text.length;
    else
        return 0;
}

export function getTableWidth(choices) {
    let max = choiceWordLength(choices);
    if (max < 5)
        return ("w-50 mx-auto")
    else
        if (max < 7)
            return ("w-75 mx-auto")
        else
            return ("mx-auto")
}

export function findLongestWord(s) {
    return (s.split(' ').map(x => x.length).reduce((c, p) => Math.max(c, p), 0))
}

export function choiceWordLength(choices) {
    let i;
    let max = 0;
    for (i = 0; i < choices.length; i++) {
        let a = findLongestWord(choices[i].text);
        max = a > max ? a : max;
    }
    return max;
    // return choices.reduce(function (a, b) { return findLongestWord(a.text).length > findLongestWord(b.text).length ? a : b; }).text.length;
}

export function mainImageUrl(identityId, imageKey) {
    return imageUrl({identityId, imageKey, width:MAIN_IMAGE_HEIGHT, fit:"cover"})
}

export function smsImageUrl({identityId, imageKey}) {
    if (!imageKey)
        return imageUrl({identityId:"assets", imageKey:"curious2.jpg", width:SMS_IMAGE_WIDTH, fit:"cover"})
    else
        return imageUrl({identityId, imageKey, width:SMS_IMAGE_WIDTH, fit:"cover"})
}

export function cardImageUrl(identityId, imageKey) {
    return imageUrl({identityId, imageKey, width:CARD_IMAGE_WIDTH, height:CARD_IMAGE_HEIGHT, fit:"cover"})
}

export function chatImageUrl(identityId, imageKey) {
    return imageUrl({identityId, imageKey, width:CHAT_IMAGE_WIDTH, height:CHAT_IMAGE_HEIGHT, fit:"inside"})
}

export function smAvatarUrl(identityId, imageKey) {
    return imageUrl({identityId, imageKey, height:SM_AVATAR_SIZE, width:SM_AVATAR_SIZE, fit:"cover"})
}

export function xsAvatarUrl({identityId, imageKey}) {
    return imageKey ? imageUrl({identityId, imageKey, height: XS_AVATAR_SIZE, width:XS_AVATAR_SIZE, fit:"cover"}) : null;
}

export function avatarUrl({identityId, imageKey}) {
    return imageKey ? imageUrl({identityId, imageKey, height:AVATAR_SIZE, width:AVATAR_SIZE, fit:"cover"}) : null;
}

export function pictureChoiceUrl({identityId, imageKey}) {
   const URL=choiceImageUrl({identityId, imageKey, height:CHOICE_IMAGE_SIZE, fit:"cover"});
    return URL;
}

export function s3ImageKey({identityId, imageKey, width, height, fit}) {
    const imageFit = fit ?? "cover";
    const imageData = identityId ? "".concat("images/", identityId, "/", imageKey) : "".concat("images/", imageKey);
    var imageRequest = width !== null ?
        JSON.stringify({
            bucket: config.s3.bucket,
            key: imageData,
            edits: {
                resize: {
                    width: width,
                    height: height,
                    fit: imageFit
                }
            }
        })
        :
        JSON.stringify({
            bucket: config.s3.bucket,
            key: imageData,
            edits: {
                resize: {
                    height: height,
                    fit: imageFit
                }
            }
        });
    const imageStr = encode(imageRequest);
    return  imageStr;

}

export function choiceImageUrl({identityId, imageKey}) {
    const url = "".concat(MyURLs.IMAGE_URL, "/", s3ImageKey({identityId, imageKey, width:CHOICE_IMAGE_SIZE, fit:"cover"}));
    return url;
}

export function imageUrl({identityId, imageKey, width=MAIN_IMAGE_WIDTH, height, fit}) {
    const url = "".concat(MyURLs.IMAGE_URL, "/", s3ImageKey({identityId, imageKey, width:MAIN_IMAGE_WIDTH, fit:"cover"}));
    return url;
}

export function imageFromS3Key({imageURL}) {
    return "".concat(MyURLs.IMAGE_URL, imageURL);
}

export const getCol = (length) => {
    if (length < 0) throw new Error("Length cannot be negative");

    if (length < 5) return "col-3";
    if (length >= 5 && length < 10) return "col-4";
    if (length >= 10 && length < 16) return "col-5";
    if (length >= 16 && length < 22) return "col-6";
    if (length >= 22 && length < 26) return "col-7";
    if (length >= 26 && length < 28) return "col-8";
    if (length >= 28 && length < 31) return "col-9";
    if (length >= 31 && length < 34) return "col-10";
    if (length >= 34 && length < 38) return "col-11";
    return "col-12"; // Default for length >= 38
};


const sortColSize = [
    "col-3 col-md-2 ",  //1
    "col-3 col-md-2 ",  //2  <3
    "col-3 col-md-3 ",  //3  
    "col-3 col-md-3 ",  //4 <5 
    "col-3 col-md-3 ",  //5  
    "col-4 col-md-4 ",  //6  
    "col-4 col-md-4 ",  //7  
    "col-4 col-md-4 ",  //8
    "col-4 col-md-4 ",  //9  <10
    "col-5 col-md-4 ",  //10
    "col-5 col-md-4 ",  //11 <12
    "col-5 col-md-5 ",  //12  
    "col-5 col-md-5 ",  //13   <14
    "col-5 col-md-5 ",  //14  
    "col-6 col-md-6 ",  //15  
    "col-6 col-md-6 ",  //16
    "col-6 col-md-6 ",  //17
    "col-6 col-md-6 ",  //18 <19 
    "col-8 col-md-7 ",  //19
    "col-8 col-md-7 ",  //20 
    "col-8 col-md-8 ",  //21 
    "col-8 col-md-8 ",  //22 <23
    "col-9 col-md-8 ", //23
    "col-9 col-md-8 ",  //24 
    "col-9 col-md-8 ",  //25 
    "col-9 col-md-8 ",  //26 <27
    "col-10 col-md-8 ",  //27 
    "col-10 col-md-9 ",  //28 
    "col-10 col-md-9 ",  //29 
    "col-10 col-md-9 ",  //30 <31
    "col-11 col-md-9 ",  //31
    "col-11 col-md-10 ",  //32
    "col-11 col-md-10 ",  //33
    "col-11 col-md-10 ",  //34  <35
    "col-11 col-md-11 ",  //35  
    "col-11 col-md-11 ",  //36  <37
    "col-12 col-md-11 ",  //37  
    "col-12 col-md-11 ",  //38
    "col-12 col-md-11 ",  //39
    "col-12 col-md-11 ",  //40 
    "col-12 col-md-11 ",  //41
]

export function resultColor(isSelected, responseCount, isRanking) {
    if(isSelected)
        if(responseCount>1)
            return " activeBackgroundColor text-white pointer "
        else
            return " inactiveBackgroundColor text-white pointer "
    else 
    if(responseCount>1)
        return " activeColor text-white pointer "

    // let color;
    // color = isRanking ?
    //     responseCount > 1 ? " activeBackgroundColor text-white pointer " : responseCount === 1 ? " inactiveBackgroundColor text-white pointer" : " white "
    //     :
    // color = isSelected ? responseCount > 1? " activeBackgroundColor text-white  pointer " : responseCount === 1 ? " inactiveBackgroundColor text-white pointer " : "  white " : " white ";
    // return color;
}

