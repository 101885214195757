import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setView } from "../redux/actions/context-actions";
import { useParams } from "react-router-dom";
import { PublicFeeds } from "../constants/feedids";
import { ViewType } from "../constants/profile";
import { getViewProfile } from "../redux/actions/profile-actions";


const mapStateToProps = (state,ownProps) => {
    return { 
            //profileId:state.profile.currentUser.profileId
            };
  };

const CollectionView = React.memo(function CollectionView({  dispatch}) {
  const { profileId, tag} = useParams();
  let feedToLoad = PublicFeeds.TaggedQuestions;
  let viewToLoad = ViewType.PremiumPublicView;
  let profileToLoad = profileId;

  useEffect(() => {
    dispatch(getViewProfile({ profileId: profileToLoad }));
    dispatch(setView({viewType:viewToLoad, profileId:profileToLoad, targetId: profileId, tag: tag, feedId: feedToLoad }));
            // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileToLoad]); // Only re-run the effect if count changes

  return (
    <></>
  )

})

export default connect(mapStateToProps, null)(CollectionView);