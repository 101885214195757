import * as React from 'react';
import Popover from '@mui/material/Popover';
import {MenuList } from '@mui/material';
import { Icons } from '../../shared/QIcons';
import { connect } from 'react-redux';
import { Avatar } from '@mui/joy';
import { sign_Out } from '../../redux/actions/auth-actions';
import { Auth, DataStore } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { setView } from '../../redux/actions/context-actions';
import { Feeds } from '../../constants/feedids';

const mapStateToProps = (state, ownProps) => {
    return {
        currentUser: state.profile.currentUser,
        feedId: state.feed.feedId,
        groups: state.profile.groups ?? []
    }
};

function UserMenu({ currentUser, feedId, dispatch }) {

    const { profileId,  initials } = currentUser;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate = useNavigate();
    const isSelected = feedId === Feeds.UserProfile;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function editProfile() {
        if (!isSelected) {
            dispatch(setView({ targetId: profileId, feedId: Feeds.UserProfile }));
        }
    }

    async function signOut() {
        navigate('/');        
        dispatch(sign_Out({}));
        await DataStore.clear();
        await DataStore.stop();
        await Auth.signOut();
     }


    let menulist = [];
    menulist.push(
        <li key = "m1" className="dropdown-item pointer brand0 px-3" onClick={() => editProfile()}>
            <i className={Icons.EDIT}></i> <span className="brand0">Edit profile</span>
        </li>
    )
    menulist.push(
        <li key="m2" className="dropdown-item pointer brand0 px-3" onClick={() => signOut()}>
            <i className={Icons.SIGNOUT}></i> <span className="brand0">Sign out</span>
        </li>
    )

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <React.Fragment>
            <span aria-describedby={id} variant="contained"
                key={profileId}
                onClick={handleClick}
                onBlur={handleClose}
            >
                <Avatar size="sm" className="mx-auto small brand2 brand0-bg" >{`${initials.toUpperCase()}`}</Avatar>
            </span>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <MenuList dense
                    id="basic-menu"
                    open={open}
                    className="small"
                    onClose={handleClose}
                    onClick={handleClose}
                >
                    {menulist}
                </MenuList>
            </Popover>
        </React.Fragment>
    );
}

export default connect(mapStateToProps, null)(UserMenu);
