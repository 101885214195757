import React from "react";
import { connect } from "react-redux";
import { PublicFeeds, UserFeeds } from "../../constants/feedids";
import { closePanels, setView } from "../../redux/actions/context-actions";
import { QuestionAskType } from "../../constants/question";
import { Icons } from "../../shared/QIcons";
import { ViewType } from "../../constants/profile";



const mapStateToProps = (state) => {
    return {
        viewProfileId: state.context.view.profileId,
        //profileId: state.profile.currentUser.profileId,
        viewType: state.context.view.viewType,
        panelIsOpen: state.context.panel.isOpen
    };
};

const TagList = React.memo(function TagList({ tags, feedId, profileId, placement, showIcon, name, questionType, panelIsOpen, closeModal, dispatch, viewType, viewProfileId }) {
    function getQuestions(tag) {
        if (panelIsOpen)
            dispatch(closePanels());
        switch (feedId) {
            case UserFeeds.UserTags: {
                dispatch(setView({ targetId: profileId, targetId: viewProfileId, tag: tag, feedId: UserFeeds.TaggedQuestions }));
                break;
            }
            case PublicFeeds.UserTags: {
                dispatch(setView({ targetId: profileId, tag: tag, feedId: PublicFeeds.TaggedQuestions, name: name, viewProfileId:profileId, viewType:ViewType.PublicView }));
                break;
            }
            default:
        }
        closeModal && closeModal();
    }

    if (tags && tags.length > 0)
        return (<div className={"container px-1 mb-1 small text-".concat(placement)}>
            <i className={Icons.TAG + " brand0"} ></i>
            {tags.map((item, i) =>
                <span key={i + item} className="activeColor" onClick={() => getQuestions(item)}>
                    &middot;{item}&nbsp;&nbsp; 
                </span>
            )}
        </div>
        )
    else
        if (questionType === QuestionAskType.Advertisement || questionType === QuestionAskType.Incentive)
            return <span />
        else
            return <div className={"container px-1 small text-".concat(placement)}>&nbsp;</div>
})

export default connect(mapStateToProps, null)(TagList);