import React from "react";
import "../../question/core/index.css";

import { connect } from "react-redux";
import { updateLastConversationViewTime } from "../../../redux/actions/chat-actions";
import ChatMessage from "../../chat/ChatMessage";
import { ChatImageEdit } from "../../chat/ChatImage";



const mapStateToProps = (state,ownProps) => {
    return {
        chatData: state.chat,
        isFirstFeed:state.chat.isFirstFeed,
        subscription:state.chat.subscription,
        messages:state.chat.messages.items,
        imagePreviewUrl:state.chat.imagePreviewUrl,
        isLoading:state.chat.messages.isLoading,
        conversationId:state.chat.messages.conversationId,
        nextToken:state.chat.messages.nextToken,
        currentUserId:state.profile.currentUser.profileId,
        viewProfileId:state.profile.viewProfile.profileId
    };
};

class ChatFeed extends React.PureComponent {
    constructor(props) {
        super(props);

        this.scrollRef = null;
        this.state = {
            offset:this.props.offset??108,
            width: 800,
            height: 182,
            unsubscribe:null,
        };
    }
    
    scrollbarsRef = React.createRef();
    bottomRef=React.createRef();
    
    // Binds our scroll event handler
    handleScroll = e => {
        const { isLoading, hasMore, loadNextPage, reverseScroll } = this.props;
        const SCROLL_LIMIT=200;
        let element = e.target; 
        // this.setState({isAtBottom:element.scrollHeight - element.scrollTop === element.clientHeight})
        if (isLoading || !hasMore) return;

        // Checks that the page has scrolled to the bottom
        const shouldLoad= reverseScroll?
            element.scrollHeight !== element.clientHeight && element.scrollTop !== 0 && element.scrollTop < SCROLL_LIMIT 
        : element.scrollTop < SCROLL_LIMIT ;
        if(shouldLoad)
            loadNextPage();
    };
    /**
     * Calculate & Update state of new dimensions
     */
    updateDimensions() {
        let update_width = window.innerWidth - 100;
        let update_height = window.innerHeight - this.state.offset;
        this.setState({ width: update_width, height: update_height });
    }

    componentDidUpdate(prevProps) {
        const prevMsgs = prevProps.messages || [];
        const messages = this.props.messages || [];
        if (prevMsgs.length !== messages.length) {
            const p0 = prevMsgs[prevMsgs.length-1]
            const m0 = messages[messages.length - 2]
              if(p0===m0){
                this.scrollToBottom('new-message at bottom')
            }
        }
        else
            if (prevProps.imagePreviewUrl !== this.props.imagePreviewUrl) {
                this.scrollToBottom('new preview image');
            }
    }

    scrollToBottom(reason){
        var scrollbarsRef=this.scrollbarsRef;
        if ((scrollbarsRef && scrollbarsRef.current) ) 
        {
            scrollbarsRef.current.scrollToBottom();
        }
    }
 
    componentDidMount() {
            this.updateDimensions();
            window.addEventListener("resize", this.updateDimensions.bind(this));
            this.scrollToBottom('initial load');
        }


    /**
     * Remove event listener
     */
    componentWillUnmount() {
        const {chatData:{conversation, conversationId}} = this.props;
        window.removeEventListener("resize", this.updateDimensions.bind(this));
        conversation && conversation.userConversation &&
             this.props.dispatch(updateLastConversationViewTime({ conversationId: conversationId }));
    }

    renderMessage = (idx, arr,isFirstFeed) => {
        let messages=this.props.messages;
        let msg = messages[idx];
        if(idx===messages.length-1)
            this.scrollToBottom('post render')
        return (
            <ChatMessage
            key={msg.messageId}
            handleLoad={isFirstFeed?()=>this.scrollToBottom('image load'):null}
            message={msg}
            sameAsPrev={idx > 0 && arr[idx - 1].author.profileId === msg.author.profileId}
            sameAsNext={idx < (messages.length -1) && arr[idx + 1].author.profileId === msg.author.profileId
              && (arr[idx + 1].content && msg.content)}
          />
        )
    }
 
    render() {
        const {isLoading,messages, imagePreviewUrl, isFirstFeed } = this.props;
        if (isFirstFeed && isLoading)
        return (
                <span/>
        )
        else
            //if (messages.length !== 0) {
                return (
                    <div className="lyqcard shadow-none rounded-0 h-100 w-100">
                            <div className="d-flex flex-column h-100">                        
                            {messages.map((el, i, arr) => (
                                    this.renderMessage(i,arr,isFirstFeed)
                            ))
                            }
                        
                        {imagePreviewUrl && <ChatImageEdit 
                            imageUrl={imagePreviewUrl} 
                            isEditing={true} 
                            handleLoad={(e)=>this.scrollToBottom(e)}
                            right handleClick={()=>this.props.clearChatImage()}/>
                        }
                        </div>
                    </div>           
                );
    }
}

export default connect(mapStateToProps, null)(ChatFeed)
