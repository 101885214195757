import { PanelActions } from "../constants";


export function setPanelOpen(payload) {
    return { 
        type:PanelActions.SET_PANEL_OPEN,
        payload};
}

export function toggleLeftPanel(payload) {
    return { 
        type:PanelActions.TOGGLE_LEFT_PANEL,
        payload};
}

export function showPanel(payload) {
    return { 
        type:PanelActions.SHOW_PANEL,
        payload};
}

export function showLeftPanel(payload) {
    return { 
        type:PanelActions.SHOW_LEFTPANEL,
        payload};
}


export function hideLeftPanel(payload) {
    return { 
        type:PanelActions.HIDE_LEFTPANEL,
        payload};
}

export function hidePanel(payload) {
    return { 
        type:PanelActions.HIDE_PANEL,
        payload};
}

export function closePanels(payload) {
    return { 
        type:PanelActions.CLOSE_PANELS,
        payload};
}

export function UpdateChatPanelView(payload) {
    return { 
        type:PanelActions.UPDATE_CHAT_PANEL_VIEW,
        payload};
}

export function getAnnouncements(payload) {
    return { 
        type:PanelActions.LOAD_ANNOUNCEMENTS_REQUESTED,
        payload};
}

export function resetPanels(payload) {
    return { 
        type:PanelActions.RESET_TO_DEFAULT,
        payload};
}