import { DataStore } from "aws-amplify";
import { Interaction } from "../models";

export async function actionIsValid(payload) {
    const { associatedId, state, profileId, action} = payload;
    try {
        let isValid=false;
        let interaction= await DataStore.query(Interaction, {profileId:profileId, interactionId:action + associatedId});
        if (state) {
            if(!interaction)
                isValid=true;
        else 
            if(interaction)
                if(!interaction["_deleted"])
                    isValid=true
                else
                    alert("it is deleted already")             
        }
        return isValid;
    }
    catch (err) {
        return null;
    }
}

