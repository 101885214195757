import React from "react";
import { LyqnessProgress } from "../LyqnessProgress";

const Similarity = React.memo(function Similarity({ value, small, singleUser }) {

    const lyqnessLabel="like you";
    var pct = Math.round(value * 100);
    if (value > 0)
        return (
            <LyqnessProgress value={value} >{`${pct}% ${lyqnessLabel}`}</LyqnessProgress>
        )
    else
        return (

            <LyqnessProgress value={value} >{`0% ${lyqnessLabel}`}</LyqnessProgress>
        )
})

export default Similarity;