// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const ShareActionEnum = {
  "FOLLOWERS": "FOLLOWERS",
  "LIKED": "LIKED",
  "SELECTED": "SELECTED",
  "RESPONDED": "RESPONDED",
  "EXTERNAL": "EXTERNAL"
};

const ActionEnum = {
  "BORQ": "BORQ",
  "CHAC": "CHAC",
  "CHAQ": "CHAQ",
  "CHAU": "CHAU",
  "CREA": "CREA",
  "CREC": "CREC",
  "CREI": "CREI",
  "CREQ": "CREQ",
  "FEAC": "FEAC",
  "FEAQ": "FEAQ",
  "FEAU": "FEAU",
  "FLAC": "FLAC",
  "FLAQ": "FLAQ",
  "FLAU": "FLAU",
  "FOLC": "FOLC",
  "FOLU": "FOLU",
  "HCRQ": "HCRQ",
  "HLNQ": "HLNQ",
  "HREQ": "HREQ",
  "HLKQ": "HLKQ",
  "INCA": "INCA",
  "INCQ": "INCQ",
  "INCR": "INCR",
  "LIKC": "LIKC",
  "LIKQ": "LIKQ",
  "LIKU": "LIKU",
  "LINQ": "LINQ",
  "MODC": "MODC",
  "MODQ": "MODQ",
  "MODU": "MODU",
  "RESQ": "RESQ",
  "SHAC": "SHAC",
  "SHAQ": "SHAQ",
  "SHAU": "SHAU",
  "SPOC": "SPOC",
  "SPOQ": "SPOQ",
  "SPOU": "SPOU",
  "STEA": "STEA",
  "TAGQ": "TAGQ"
};

const ChatType = {
  "CHAC": "CHAC",
  "CHAG": "CHAG",
  "CHAQ": "CHAQ",
  "CHAU": "CHAU"
};

const FeedIds = {
  "ABORQ": "ABORQ",
  "ACHAC": "ACHAC",
  "ACHAQ": "ACHAQ",
  "ACHAU": "ACHAU",
  "ACREC": "ACREC",
  "ACREQ": "ACREQ",
  "AHCRQ": "AHCRQ",
  "AHLNQ": "AHLNQ",
  "AHREQ": "AHREQ",
  "AHLKQ": "AHLKQ",
  "AFEAC": "AFEAC",
  "AFEAQ": "AFEAQ",
  "AFEAU": "AFEAU",
  "AFLAC": "AFLAC",
  "AFLAQ": "AFLAQ",
  "AFLAU": "AFLAU",
  "AFOLC": "AFOLC",
  "AFOLU": "AFOLU",
  "AINCA": "AINCA",
  "AINCQ": "AINCQ",
  "AINCR": "AINCR",
  "ALIKC": "ALIKC",
  "ALIKQ": "ALIKQ",
  "ALIKU": "ALIKU",
  "ALNPQ": "ALNPQ",
  "AMODC": "AMODC",
  "AMODQ": "AMODQ",
  "AMODU": "AMODU",
  "ARESQ": "ARESQ",
  "ASHAC": "ASHAC",
  "ASHAQ": "ASHAQ",
  "ASHAU": "ASHAU",
  "ASPOC": "ASPOC",
  "ASPOQ": "ASPOQ",
  "ASPOU": "ASPOU",
  "ATAGQ": "ATAGQ",
  "PBORQ": "PBORQ",
  "PCHAC": "PCHAC",
  "PCHAQ": "PCHAQ",
  "PCHAU": "PCHAU",
  "PCREC": "PCREC",
  "PCREQ": "PCREQ",
  "PHCRQ": "PHCRQ",
  "PHLNQ": "PHLNQ",
  "PHREQ": "PHREQ",
  "PHLKQ": "PHLKQ",
  "PFEAC": "PFEAC",
  "PFEAQ": "PFEAQ",
  "PFEAU": "PFEAU",
  "PFLAC": "PFLAC",
  "PFLAQ": "PFLAQ",
  "PFLAU": "PFLAU",
  "PFOLC": "PFOLC",
  "PFOLU": "PFOLU",
  "PINCA": "PINCA",
  "PINCQ": "PINCQ",
  "PINCR": "PINCR",
  "PLIKC": "PLIKC",
  "PLIKQ": "PLIKQ",
  "PLIKU": "PLIKU",
  "PLINQ": "PLINQ",
  "PMODC": "PMODC",
  "PMODQ": "PMODQ",
  "PMODU": "PMODU",
  "PRESQ": "PRESQ",
  "PSHAC": "PSHAC",
  "PSHAQ": "PSHAQ",
  "PSHAU": "PSHAU",
  "PSPOC": "PSPOC",
  "PSPOQ": "PSPOQ",
  "PSPOU": "PSPOU",
  "PTAGQ": "PTAGQ",
  "RLINQ": "RLINQ",
  "ZLINQ": "ZLINQ",
  "XLINQ": "XLINQ"
};

const QuestionStatus = {
  "SCHEDULED": "SCHEDULED",
  "ACTIVE": "ACTIVE",
  "EXPIRED": "EXPIRED"
};

const VisibilityEnum = {
  "NOONE": "NOONE",
  "LINK": "LINK",
  "COLL": "COLL",
  "ALL": "ALL"
};

const ModelSortDirection = {
  "ASC": "ASC",
  "DESC": "DESC"
};

const ModelAttributeTypes = {
  "BINARY": "binary",
  "BINARY_SET": "binarySet",
  "BOOL": "bool",
  "LIST": "list",
  "MAP": "map",
  "NUMBER": "number",
  "NUMBER_SET": "numberSet",
  "STRING": "string",
  "STRING_SET": "stringSet",
  "NULL": "_null"
};

const { ChoiceOrder, Conversation, Interaction, Message, Profile, ProfileFeed, Question, Response, Hashtag, UserFeed, QuestionIncentive, UserIncentive, ProfileStub, Choice, ShareInfo, ChoiceStat, Count, Result, ProfileStats, UpdateTimes, UserFeedStats, ProfileFeedStats, TallyStats, DisplayProfile, ActivityUpdate, ModelInteractionConnection, ModelProfileConnection, ModelQuestionConnection, DisplayProfileConnection, cmos, ModelProfileFeedConnection, ModelUserFeedConnection, tag, ModelHashtagConnection } = initSchema(schema);

export {
  ChoiceOrder,
  Conversation,
  Interaction,
  Message,
  Profile,
  ProfileFeed,
  Question,
  Response,
  Hashtag,
  UserFeed,
  ShareActionEnum,
  ActionEnum,
  ChatType,
  FeedIds,
  QuestionStatus,
  VisibilityEnum,
  ModelSortDirection,
  ModelAttributeTypes,
  QuestionIncentive,
  UserIncentive,
  ProfileStub,
  Choice,
  ShareInfo,
  ChoiceStat,
  Count,
  Result,
  ProfileStats,
  UpdateTimes,
  UserFeedStats,
  ProfileFeedStats,
  TallyStats,
  DisplayProfile,
  ActivityUpdate,
  ModelInteractionConnection,
  ModelProfileConnection,
  ModelQuestionConnection,
  DisplayProfileConnection,
  cmos,
  ModelProfileFeedConnection,
  ModelUserFeedConnection,
  tag,
  ModelHashtagConnection
};