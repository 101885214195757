import React, { useEffect, useState, useCallback } from "react";
import SpeedDial from "@mui/material/SpeedDial";
import { Auth } from "aws-amplify";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import QuestionIcon from "@mui/icons-material/QuestionMark";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import "../../index.css";
import { QuestionAskTitle, QuestionAskType, QuestionTypeIcon } from "../../constants/question";
import { hidePanel } from "../../redux/actions/panel-actions";
import { updateEditQuestion } from "../../redux/actions/questionedit-actions";
import { showQuestionModal } from "../../redux/actions/modal-actions";
import { connect } from "react-redux";
import { newId } from "../../shared/utilities";
import { closeSpeedDial } from "../../redux/actions/context-actions";
import { setUserGroups } from "../../redux/actions/profile-actions";
import RatingScales from "../question/RatingQuestions/RatingScales";
import PropTypes from "prop-types"; // For prop validation

// Redux state mapping
const mapStateToProps = (state) => ({
  view: state.context.view,
  profile: state.profile.currentUser,
  showQuestionTypeTips: state.profile.currentUser.showQuestionTypeTips,
  isPremiumUser: state.profile.isPremiumUser??false,
  isAdministrator: state.profile.isAdministrator??false,
  speedDial: state.context.speedDial,
});

// Helper function to generate question based on type
const generateQuestion = (questionType, profileId, parentId) => {
  const id = newId();
  let question = {
    questionId: id,
    questionText: "",
    questionType,
    isMultipleChoice: false,
    creatorId: profileId,
    parentId,
    isOrdered: questionType === QuestionAskType.Rank,
    choices:
      questionType === QuestionAskType.Rate
        ? RatingScales[0].choices.map((item) => ({
            text: item,
            imageKey: "",
            imageURL: "",
          }))
        : [],
  };

  if (questionType === QuestionAskType.Pictures) {
    question.buckets = 2;
    question.choices = [
      { text: "", imageKey: null, imageURL: null },
      { text: "", imageKey: null, imageURL: null },
    ];
  }

  if (questionType === QuestionAskType.MoveMatch) {
    question.buckets = 2;
    question.isOrdered = true;
    question.minSel = 2;
    question.maxSel = 2;
    question.choices = [
      { imageKey: null, imageURL: null, text: "" },
      { imageKey: null, imageURL: null, text: "" },
    ];
    question.choiceHeadings = ["", ""];
  }

  return question;
};

function BasicSpeedDial({ profile, speedDial, isPremiumUser, isAdministrator, dispatch }) {
  const { profileId, identityId } = profile;
  const { isOpen, parentId } = speedDial;
  const [show, setShowing] = useState(isOpen);

  // Toggle SpeedDial visibility
  const handleToggle = useCallback(() => setShowing((prev) => !prev), []);

  // Fetch user groups on mount
  useEffect(() => {
    const getGroups = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
        dispatch(setUserGroups({ groups }));
      } catch (error) {
        console.error("Error fetching user groups:", error);
      }
    };

    getGroups();
  }, [dispatch]);

  // Update show state when `isOpen` changes
  useEffect(() => {
    setShowing(isOpen);
  }, [isOpen]);

  // Handle adding new questions
  const handleAdd = useCallback(
    (e) => {
      e.preventDefault();
      const { key } = e.currentTarget.dataset;
      const questionType = Number(key);

      const question = generateQuestion(questionType, profileId, parentId);

      dispatch(hidePanel());
      dispatch(updateEditQuestion({ userFolder: identityId, question, choiceImages: [] }));
      dispatch(showQuestionModal({ ...question, isNew: true }));
      dispatch(closeSpeedDial());
    },
    [dispatch, identityId, parentId, profileId]
  );

  // Define question actions
  const premiumQuestionTypes = [9, 12, 90, 91];
  const dialClass = (questionType) =>
    premiumQuestionTypes.includes(questionType) ? "gold" : "brand0-bg white-text";

  const actions = [
    { icon: QuestionTypeIcon(QuestionAskType.Select), name: QuestionAskTitle(QuestionAskType.Select), key: QuestionAskType.Select },
    { icon: QuestionTypeIcon(QuestionAskType.Rank), name: QuestionAskTitle(QuestionAskType.Rank), key: QuestionAskType.Rank },
    { icon: QuestionTypeIcon(QuestionAskType.Rate), name: QuestionAskTitle(QuestionAskType.Rate), key: QuestionAskType.Rate },
    { icon: QuestionTypeIcon(QuestionAskType.Pictures), name: QuestionAskTitle(QuestionAskType.Pictures), key: QuestionAskType.Pictures },
    { icon: QuestionTypeIcon(QuestionAskType.MoveMatch), name: QuestionAskTitle(QuestionAskType.MoveMatch), key: QuestionAskType.MoveMatch },
    { icon: QuestionTypeIcon(QuestionAskType.Numeric), name: QuestionAskTitle(QuestionAskType.Numeric), key: QuestionAskType.Numeric },
  ];

  if (isAdministrator || isPremiumUser) {
    actions.push(
      { icon: QuestionTypeIcon(QuestionAskType.Advertisement), name: QuestionAskTitle[QuestionAskType.Advertisement], key: QuestionAskType.Advertisement },
      { icon: QuestionTypeIcon(QuestionAskType.Incentive), name: QuestionAskTitle[QuestionAskType.Incentive], key: QuestionAskType.Incentive },
      { icon: QuestionTypeIcon(QuestionAskType.Lottery), name: QuestionAskTitle[QuestionAskType.Lottery], key: QuestionAskType.Lottery }
    );
  }

  return (
    <SpeedDial
      ariaLabel="SpeedDial for adding questions"
      sx={{ position: "absolute", bottom: 30, right: 16 }}
      icon={<SpeedDialIcon openIcon={<QuestionIcon />} />}
      FabProps={{ size: "small", style: { backgroundColor: "brand0-bg" } }}
      onClick={handleToggle}
      onMouseLeave={() => setShowing(false)}
      open={show}
      aria-haspopup="true"
      aria-expanded={show}
    >
      {actions.map((action,index) => (
        <SpeedDialAction
          key={index}
          icon={action.icon}
          onClick={handleAdd}
          data-key={Number(action.key)}
          tooltipTitle={action.name}
          className={dialClass(action.key)}
        />
      ))}
    </SpeedDial>
  );
}

// PropTypes validation
BasicSpeedDial.propTypes = {
  profile: PropTypes.object.isRequired,
  speedDial: PropTypes.object.isRequired,
  isPremiumUser: PropTypes.bool.isRequired,
  isAdministrator: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(BasicSpeedDial);
