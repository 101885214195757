import React from "react";
import { connect } from "react-redux";
import { setView } from "../../redux/actions/context-actions";
import ProfileMenuItem from "../menus/ProfileMenuItem";
import { LyqnessId } from "../../constants/profile";
import { PublicFeeds } from "../../constants/feedids";

const mapStateToProps = (state, ownProps) => {
    return {
      isOpen:state.context.panel.leftPanelIsOpen,
      viewTag:state.context.view.tag,
      viewType:state.context.view.viewType,
      feedId:state.context.view.feedId
    };
  };

const MainFeedTagItem = React.memo(function MainFeedTagItem({icon,  viewType, viewTag, heading, tag, feedId, dispatch}) {
    const isActive=viewTag===tag?false:true;
    const loadFeed = () => {
          dispatch(setView({ viewType:viewType, targetId:LyqnessId, feedId:PublicFeeds.TaggedQuestions, tag:tag, name:"", heading:heading }));
      }

      
      return (

         <ProfileMenuItem icon={icon} feedId={feedId} isActive={isActive } handleClick={()=>loadFeed()} />
      )      
});


export default connect(mapStateToProps, null)(MainFeedTagItem);
