import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { NumericType } from "../../../constants/question";
import { xsScreenSize } from "../../../constants";
import RespondSummary from "../core/RespondSummary";
import { DateInputField } from "./DateInput";
import { NumberInputField } from "./NumberInput";
import { findBucket, findStep, inputLabels, isTimeInRange } from "./utilities";
import QuestionUserCount from "../core/Choices/QuestionUserCount";
import ResultsSummary from "../core/ResultsSummary";
import { canSubmit } from "../core/CreateEditStatus";
import { respondToQuestion } from "../../../redux/actions/question-actions";
import EditItemDisplay from "../core/Choices/EditItemDisplay";
import { set } from "date-fns";
import { InputAdornment, TextField } from "@mui/material";

function mapStateToProps(state) {
    return {
        screenWidth: state.context.screenWidth,
        viewProfileId: state.context.view.profileId,
    };
}

const NumericInput = React.memo(function NumericInput({ screenWidth, viewProfileId, displayQuestion, dispatch }) {
    const { questionId, numericType, choices, minRange1, maxRange1, userFeed: { stats: { RESQ } } } = displayQuestion;
    const colWidth = numericType === NumericType.DateYear ? "col-5" : `col-6 ${screenWidth > xsScreenSize && "px-2"}`;
    const isNumber = numericType === NumericType.Number || numericType === NumericType.Money;
    const [inputChoices, setInputChoices] = useState([]);
    const [steps, setSteps] = useState([]);
    const [values, setValues] = useState([]);
    const [errorMessage, setErrorMessage] = useState(" ");
    const inputElement = useRef(null);

    const handleChange = (value) => {
        const isValidRange = (val, min, max) => val >= Number(min) && val <= Number(max);

        const updateState = (errorMessage, values, choices) => {
            setErrorMessage(errorMessage);
            setValues(values);
            setInputChoices(choices);
            setSteps([step]);
        };


        let bucket;
        let step;

        switch (numericType) {
            case NumericType.Time30Min:
            case NumericType.DateYear:
            case NumericType.DateMonth:
            case NumericType.DateDay:
                if (isTimeInRange(value, minRange1, maxRange1)) {
                    bucket = findBucket({ displayQuestion, value });
                    step = findStep({ displayQuestion, value });
                    updateState("", [value], [bucket]);
                } else {
                    updateState(`${value} is out of range`, [value], []);
                }
                break;

            case NumericType.Money:
            case NumericType.Number:
                const numVal = Number(value);
                if (value === '') {
                    updateState("", [value], []);
                } else if (isValidRange(numVal, minRange1, maxRange1)) {
                    bucket = findBucket({ displayQuestion, value });
                    step = findStep({ displayQuestion, value });
                    updateState("", [value], [bucket]);
                } else {
                    updateState("value is out of range", [value], []);
                }
                break;

            default:
                break;
        }
    };

    const handleSubmit = () => {
        var data = {
            "displayQuestion": displayQuestion,
            "viewProfileId": viewProfileId,
            "selections": inputChoices,
            "values": values,
            "steps": steps,
        };
        dispatch(respondToQuestion({ ...data }))
    }

    const InputField = () => {
        const [value, setValue] = useState(null);

        const doUpdate = (e) => {
            setValue(e);
            handleChange(e);
        }

        if (isNumber)
            return (
                <TextField
                    label={inputLabels[numericType]}
                    size="small"
                    id="mininput"
                    value={values[0] ?? ''}
                    onChange={(e) => handleChange(e.target.value)}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">{numericType === NumericType.Money ? '$' : ''}</InputAdornment>,
                    }}
                />
            )
        else
            return (
                <div>
                    <DateInputField
                        numericType={numericType}
                        label={inputLabels[numericType]}
                        minDate={minRange1}
                        maxDate={maxRange1}
                        minTime={minRange1}
                        maxTime={maxRange1}
                        value={values[0] ?? null}
                        handleChange={(value) => handleChange(value)}
                    />
                </div>
            );

    };
    if (RESQ === 0)
        return (
            <div className="row px-1 justify-content-center">
                <div className="container mb-0">
                    {choices && choices.length > 0 && (
                        <div >
                            <div className="row gx-2 px-0 gy-1 mb-1 justify-content-center">
                                {choices.map((choice, index) => (
                                    <div className={colWidth} key={index}>
                                        <EditItemDisplay choiceId={index} question={displayQuestion} >
                                        </EditItemDisplay>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <div className="row  mt-3 justify-content-center">
                    <div className="col-5">
                        {isNumber ?
                            <TextField
                                label={inputLabels[numericType]}
                                size="small"
                                id="mininput"
                                value={values[0] ?? ''}
                                onChange={(e) => handleChange(e.target.value)}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">{numericType === NumericType.Money ? '$' : ''}</InputAdornment>,
                                }}
                            /> :
                            <InputField />}
                    </div>
                    <div className="col-12 smallfont error-text">{errorMessage}</div>
                </div>
                <RespondSummary handleSubmit={handleSubmit} displayQuestion={displayQuestion} canSubmit={canSubmit({ question: displayQuestion, inputChoices })} />
            </div>
        )
    else
        return (
            <div className="row px-2 justify-content-center">
                <div className="container mb-0">
                    {choices && choices.length > 0 && (
                        <div >
                            <div className="row gx-2 px-0 gy-1 mb-1 justify-content-center">
                                {choices.map((choice, index) => (
                                    <div className={colWidth} key={index}>
                                        <EditItemDisplay choiceId={index} question={displayQuestion} >
                                            <span className="activeColor float-end">
                                                <QuestionUserCount choiceId={index} question={displayQuestion} />
                                            </span>
                                        </EditItemDisplay>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <ResultsSummary key={questionId + "9"} displayQuestion={displayQuestion} />
            </div>
        )
});

export default connect(mapStateToProps, null)(NumericInput);