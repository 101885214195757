import React from "react";
import { connect } from "react-redux";
import Similarity from "./Similarity";


const mapStateToProps = (state, ownProps) => {
  return {
    questions: state.feed.items,
    cmost : state.feed.cmost
  };
};

const CMOST = React.memo(function CMOST({ cmost, questions }) {
  const value=cmost.value?cmost.value:0;
  return (
    <Similarity singleUser value={value} />
  )
})

export default connect(mapStateToProps, null)(CMOST);