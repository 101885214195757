import { API, DataStore, Predicates, SortDirection, graphqlOperation } from "aws-amplify";
import { Interaction, Profile, ProfileFeed, Question, UserFeed } from "../models/index";
import * as queries from "../graphql/lyqnessQueries";
import { emptyUserFeed } from "./modelHelpers";
import { ObjectType } from "../redux/constants";
import { forEachChild } from "typescript";

export async function queryLocalQuestions(payload) {
    const { feedId, profileId } = payload;
    try {
        let items = await DataStore.query(UserFeed, (c) => c.publicTime.ne(null),
            { sort: (s) => s.updatedAt(SortDirection.DESCENDING) });
        let result = await buildLocalFeed({ profileId: profileId, items: items, feedId: feedId });
        result.objectType = ObjectType.QUESTION;

        return result;
    }
    catch (error) {
        console.log(error)
    }
}

export async function getLocalDrafts(payload) {
    const { feedId, targetId } = payload;
    try {
        let items = await DataStore.query(Question, (c) => c.draft.eq(true),
            { sort: (s) => s.updatedAt(SortDirection.DESCENDING) });
        let creator = structuredClone(await DataStore.query(Profile, targetId));
        creator.myProfileFeed = null;
        let newList = [];
        for (const question of items) {
            let userFeed = await DataStore.query(UserFeed, { profileId: question.creatorId, questionId: question.questionId });
            let displayQuestion = structuredClone(question)
            displayQuestion.userFeed = userFeed;
            displayQuestion.creator = creator;
            newList.push(displayQuestion)
        }
        let result = { items: newList, feedId: feedId, isFirstFeed: true };

        return result;
    }
    catch (error) {
        console.log(error)
    }
}

export async function queryLocalQuestionsByInteraction(payload) {
    const { feedId } = payload;
    const action = feedId.substring(1, 5);
    try {
        let items = [];
        const data = await DataStore.query(Interaction, (c) => c.action.eq(action),
            { sort: (s) => s.updatedAt(SortDirection.DESCENDING) });

        for (let i = 0; i < data.length; i++) {
            const { profileId, associatedId, relatedId, interactionId } = data[i];
            const questionId = (action === "LINQ" || action === "BORQ") ? relatedId : associatedId;
            let question = await DataStore.query(Question, associatedId);
            if (question && !question.draft) {
                let displayQuestion = await interactionToDisplayQuestion({ profileId: profileId, questionId: questionId })
                items.push(displayQuestion)
            }
            else {
                await DataStore.delete(Interaction, { profileId: profileId, interactionId: interactionId })
            }
        }
        let result = { items: items, feedId: feedId, isFirstFeed: true };
        result.objectType = ObjectType.QUESTION;
        return result;
    }

    catch (error) {
        console.log(error)
    }
}

export async function getLocalQuestion(payload) {
    const { feedId, profileId, targetId } = payload;
    try {
        let feed = await DataStore.query(UserFeed, { profileId: profileId, questionId: targetId });
        let newList = [];
        let displayQuestion = await userFeedToDisplayQuestion({ feed: feed });
        newList.push(structuredClone(displayQuestion))
        const result = { items: newList, feedId: feedId, isFirstFeed: true };
        return result;
    }
    catch (error) {
        console.log(error)
    }
}

export async function buildLocalFeed(payload) {
    const { items, feedId } = payload;
    let newList = [];
    try {
        for (const feed of items) {
            let displayQuestion = await userFeedToDisplayQuestion({ feed: feed });
            newList.push(displayQuestion)
        }
        let update = { items: newList, feedId: feedId, isFirstFeed: true };
        return update;
    }
    catch (error) {
        console.log(error)
    }
}

export async function checkForLocalQuestion(payload) {
    const { question, profileId, question: { questionId } } = payload;
    try {
        const userFeed = await DataStore.query(UserFeed, { profileId: profileId, questionId: questionId });
        if (userFeed){
            let newQuestion= structuredClone(question);
            newQuestion.userFeed=userFeed;
            return {question:newQuestion};
        }
        else
            return null;
    }
    catch (error) {
        console.log(error)
    }
}

export async function buildLocalQuestion(payload) {
    const { userFeed, userFeed: { profileId, questionId } } = payload;
    try {
        let question = await DataStore.query(Question, questionId);
        if (question) {
            let newQuestion = structuredClone(question);
            newQuestion.userFeed = userFeed;
            return {question:newQuestion};
        }
        else
            return null;
    }
    catch (error) {
        console.log(error)
    }
}
export async function userFeedToDisplayQuestion({ feed }) {
    try {
        let question = await DataStore.query(Question, feed.questionId);
        const creator = await DataStore.query(Profile, question.creatorId);
        const profileFeed = await DataStore.query(ProfileFeed, { profileId: feed.profileId, associatedId: question.creatorId });
        let displayQuestion = structuredClone(question);
        displayQuestion.creator = structuredClone(creator);
        displayQuestion.creator.myProfileFeed = profileFeed;
        displayQuestion.creator.profileFeed = null;
        displayQuestion.userFeed = feed;
        return displayQuestion;
    }
    catch (err) {
        console.log("error in userFeedToDisplayQuestion", err)
    }
}

export async function listPublicFeed(payload) {
    const { targetId, feedId, nextToken } = payload;
    try {
        const ds = await API.graphql(graphqlOperation(queries.listPublicFeed,
            { targetId, nextToken: nextToken }));
        let qry = ds.data.listPublicFeed;
        for (let i = 0; i < qry.items.length; i++) {
            const { questionId, userFeed } = qry.items[i];
            const localQuestion = await DataStore.query(Question, questionId);
            if (localQuestion)
                qry.items[i].qmosr = localQuestion.qmosr;
            qry.items[i].userFeed = userFeed ?? emptyUserFeed;
        }

        let result = { ...qry, feedId: feedId, isFirstFeed: nextToken ? false : true };
        result.objectType = ObjectType.QUESTION;
        return result;
    }
    catch (error) {
        console.log(error)
    }
}

export async function getSharedQuestion(payload) {
    const { questionId, targetId, feedId, profileId } = payload;
    let idToGet = questionId ?? targetId;
    try {
        let items = [];
        const ds = await API.graphql(graphqlOperation(queries.getSharedQuestion,
            { questionId: idToGet }));
        let question = ds.data.getSharedQuestion;
        const userFeed = await DataStore.query(UserFeed, { profileId: profileId, questionId: idToGet });
        question.userFeed = userFeed ?? emptyUserFeed;
        const localQuestion = await DataStore.query(Question, questionId);

        if (localQuestion)
            question.qmosr = localQuestion.qmosr;
        items.push(question);
        let result = { items: items, feedId: feedId, isFirstFeed: true }
        result.objectType = ObjectType.QUESTION;
        return result;
    }
    catch (error) {
        console.log(error)
    }
}

export async function getQuestion(payload) {
    const { questionId, targetId, feedId, profileId } = payload;
    let idToGet = questionId ?? targetId;
    try {
        let items = [];
        const ds = await API.graphql(graphqlOperation(queries.getQuestion, { questionId: idToGet }));
        let question = ds.data.getQuestion;
        const userFeed = await DataStore.query(UserFeed, { profileId: profileId, questionId: idToGet });
        question.userFeed = userFeed ?? emptyUserFeed;
        const localQuestion = await DataStore.query(Question, idToGet);
        if (localQuestion)
            question.qmosr = localQuestion.qmosr;
        items.push(question);
        let result = { items: items, feedId: feedId, isFirstFeed: true }
        result.objectType = ObjectType.QUESTION;
        return result;
    }
    catch (error) {
        console.log(error)
    }
}

export async function listUserFeed(payload) {
    const { feedId, viewProfileId } = payload;
    if (performance.navigation.type === 1) {
        console.log("Page was refreshed!");
    } else {
        console.log("Normal page load");
    }
    try {
        let items = await DataStore.query(Question, Predicates.ALL,
            { sort: (s) => s.updatedAt(SortDirection.DESCENDING) });
        let feed = [];
        for (let i = 0; i < items.length; i++) {
            let question = structuredClone(items[i]);
            question.userFeed = await DataStore.query(UserFeed, { profileId: viewProfileId, questionId: items[i].questionId })
            feed.push(question);
        }
        let result = { items: feed, feedId: feedId, isFirstFeed: true }
        result.objectType = ObjectType.QUESTION;
        return result;
    }
    catch (error) {
        console.log(error)
    }

    // const ds = await API.graphql(graphqlOperation(queries.listUserFeed,
    //     { nextToken: nextToken }));
    // let qry = ds.data.listUserFeed;
    // for (let i = 0; i < qry.items.length; i++) {
    //     const { userFeed } = qry.items[i];
    //     qry.items[i].userFeed = userFeed ?? emptyUserFeed;
    //     const localQuestion = await DataStore.query(Question,qry.items[i].questionId);
    //     if (localQuestion)
    //         qry.items[i].qmosr = localQuestion.qmosr;
    // }

    // let result = { ...qry, feedId: feedId, isFirstFeed: nextToken ? false : true };
    // result.objectType = ObjectType.QUESTION;
    // return result;
    // }
    // catch (error) {
    //     console.log(error)
    // }
}

export async function listQuestionsByInteraction(payload) {
    const { targetId, feedId, viewProfileId, nextToken, limit } = payload;

    try {
        const ds = await API.graphql(
            graphqlOperation(queries.listQuestionsByInteraction, { targetId: targetId, viewProfileId: viewProfileId, feedId: feedId, nextToken: nextToken, limit: limit }));

        let qry = ds.data.listQuestionsByInteraction;
        qry.items = await prepQuestionFeed({ items: qry.items });
        let result = { ...qry, feedId: feedId, isFirstFeed: nextToken === null };
        result.category = ObjectType.QUESTION;

        return result;
    }

    catch (error) {
        console.log(error)
    }
}

export async function listQuestionsByParent(payload) {
    const { targetId, feedId, nextToken, limit } = payload;

    try {
        const ds = await API.graphql(
            graphqlOperation(queries.listQuestionsByParent, { questionId: targetId, feedId: feedId, nextToken: nextToken, limit: limit }));

        let qry = ds.data.listQuestionsByParent;
        qry.items = await prepQuestionFeed({ items: qry.items });
        let result = { ...qry, feedId: feedId, isFirstFeed: nextToken === null };
        result.category = ObjectType.QUESTION;
        return result;
    }
    catch (error) {
        console.log(error)
    }
}

export async function interactionToDisplayQuestion({ profileId, questionId }) {
    try {
        const question = await DataStore.query(Question, questionId);
        const creator = await question.creator;
        const profileFeed = await creator.profileFeed;
        const userFeed = await DataStore.query(UserFeed, { profileId: profileId, questionId: questionId });
        let displayQuestion = structuredClone(question);
        displayQuestion.creator = structuredClone(creator);
        displayQuestion.creator.profileFeed = structuredClone(profileFeed);
        displayQuestion.userFeed = structuredClone(userFeed);
        return displayQuestion;
    }
    catch (error) {
        console.log("error in interactonToDisplayQuestion", error)

    }
}

export async function prepQuestionFeed(payload) {
    let { items } = payload;
    for (let i = 0; i < items.length; i++) {
        items[i].userFeed = items[i].userFeed ?? emptyUserFeed;
        const localQuestion = await DataStore.query(Question, items[i].questionId);
        if (localQuestion)
            items[i].qmosr = localQuestion.qmosr;
    }
    return items;
}


