// src/redux/store/index.js
import { createStore, applyMiddleware, compose } from "redux";
import lyqnessApp from "../reducers/index";
import createSagaMiddleware from "redux-saga";
import sagas from "../sagas/index";
import config from "../../config";


const SagaMiddleware = createSagaMiddleware();
//const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const composeEnhancers =
  (config.env !== 'production' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const store = createStore(
 lyqnessApp,
  composeEnhancers(
    applyMiddleware(SagaMiddleware)
  )
);
SagaMiddleware.run(sagas);


export default store;