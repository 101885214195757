
import { Feeds } from "../../constants/feedids";
import { PanelType } from "../../constants/panel";
import { ViewType } from "../../constants/profile";
import { FeedActions } from "../constants";
import { ContextActions as Actions, ContextActions, PanelActions } from "../constants/actions";


const initialState = {
    isSupported: false,
    permission: false,
    view: {
        crumbs: [],
        profileId: null,
        feedId: null,
        targetId: null,
        viewType: ViewType.MainView
    },
    panel: {
        panelType: PanelType.Default,
        feedId: null,
        targetId: null,
        title: "Title gose here!",
        isOpen: false,
        leftPanelIsOpen: false,
        displayQuestion: null,
    },
    speedDial: {
        isOpen: false,
        parentId: null
    }
};

function contextReducers(state = initialState, action) {
    let data = action.payload;
    let newView = { ...state.view, ...data };
    if (newView.feedId !== Feeds.ParentQuestions && newView.feedId !== Feeds.ChildQuestions)
        delete newView.targetId;
    try {
        switch (action.type) {
            case FeedActions.QUESTION_FEED_REQUESTED:
                return {
                    ...state,
                    panel:{...initialState.panel}
                }
            case FeedActions.FEED_DATA_RECEIVED:
                {
                const {feedId}=state.view;
                if(feedId===Feeds.ChildQuestions || feedId===Feeds.ParentQuestions)
                {
                    const items=action.payload.items;
                    if(items.length>0){
                        const {parentId} = items[0];
                       return {...state,view:{...state.view,parentId:parentId?parentId:null}}
                    }
                }
                    return {...state}
            }
            case "SIGN_OUT":
                return { ...initialState };
            case "SET_NOTIFICATION":
                return { ...state, ...data };

            case Actions.CLEAR_PANEL_VIEW:
                return {
                    ...state,
                    panel: initialState.panel
                }
            case Actions.CLOSE_SPEEDDIAL:
                return {
                    ...state,
                    speedDial: initialState.speedDial
                }
            case Actions.OPEN_SPEEDDIAL:
                return {
                    ...state,
                    speedDial: { isOpen: true, parentId: action.payload.parentId }
                }
            case Actions.SET_SCREEN_SIZE:
                return {
                    ...state, screenWidth:data.screenSize.width, screenHeight:data.screenSize.height
                }
            case Actions.SET_VIEW:
                {
                    const { feedId, name } = data;
                    let viewType = data.viewType ?? state.view.viewType;
                    let feedCategory = feedId[0];
                    switch (feedCategory) {
                        case "U":
                            viewType = feedId === Feeds.UserProfile ? ViewType.UserProfile : ViewType.PremiumUserProfile;
                            break;
                        default:
                            break;
                    }

                    return {
                        ...state,
                        view: { ...state.view, ...data, viewType: viewType, name: name }
                    }
                }
            case Actions.SET_PANEL_VIEW:
                return {
                    ...state,
                    panel: {
                        ...initialState.panel, ...data,
                        isOpen: true
                    }
                }

            case PanelActions.RESET_TO_DEFAULT:
                return {
                    ...state, panel: initialState.panel
                }

            // case ChatActions.CONVERSATION_STARTED:
            //     return{
            //         ...state,conversation:action.payload
            //     }
            case PanelActions.SET_PANEL_OPEN:
                return {
                    ...state,
                    panel: {
                        ...state.panel,
                        isOpen: action.payload.isOpen
                    }

                }
            case ContextActions.CLOSE_PANELS:
                return {
                    ...state,
                    panel: {
                        ...state.panel,
                        isOpen: false,
                        leftPanelIsOpen: false
                    }
                }
            case PanelActions.TOGGLE_LEFT_PANEL:
                return {
                    ...state,
                    panel: {
                        ...state.panel,
                        leftPanelIsOpen: !state.panel.leftPanelIsOpen
                    }
                }
            case PanelActions.SHOW_PANEL:
                return {
                    ...state,
                    panel: {
                        ...state.panel,
                        isOpen: true,
                        leftPanelIsOpen: false
                    }
                }
            case PanelActions.SHOW_LEFTPANEL:
                return {
                    ...state,
                    panel: {
                        ...state.panel,
                        leftPanelIsOpen: true,
                        isOpen: false
                    }
                }
            case PanelActions.HIDE_LEFTPANEL:
                return {
                    ...state,
                    panel: {
                        ...state.panel,
                        leftPanelIsOpen: false,
                        isOpen: false
                    }
                }
            case PanelActions.HIDE_PANEL:
                return {
                    ...state,
                    panel: {
                        ...state.panel,
                        isOpen: false
                    }
                }

            default:
                return state;
        }
    }
    catch {
        console.log("*** context-reducer error")
    }
}

export default contextReducers