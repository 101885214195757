import React, { useState } from "react";
import RatingScales from "./RatingScales"; // Import RatingScales from the external file
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap styles
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material"; // MUI imports
import { connect } from "react-redux";
import { updateQuestionChoices } from "../../../redux/actions/questionedit-actions";

function mapStateToProps(state) {
  return {
    question: state.questionedit.question,
  };
}

const CreateRatingQuestion = ({ question, dispatch }) => {
  const [selectedScale, setSelectedScale] = useState(RatingScales[0].name); // Default to the first scale
  const [response, setResponse] = useState(""); // Stores the user's response

  const handleScaleChange = (event) => {
    const scaleName = event.target.value;
    const scale = RatingScales.find((scale) => scale.name === scaleName).choices;
    const choices = scale.map((item) => {
      return { text: item, "imageKey": "", "imageURL": "" }
    })
    dispatch(updateQuestionChoices({ choices: choices }));
    setSelectedScale(scale);
    setResponse(""); // Reset the response when scale changes
  };

  return (
    <div className="row mx-1 mt-4 justify-content-center mx-0">
      {/* Rating table layout */}
      <table
        className="table text-center m-0"
        style={{
          tableLayout: "fixed", // Ensures fixed column widths
          width: "100%", // Occupies full container width
        }}
      >
        <thead>
          <tr>
            {question.choices.map((choice, index) => (
              <th
                key={index}
                style={{
                  // width: "86px", // Fixed width for each column
                  padding: "1px",
                  wordBreak: "break-word", // Wraps long words
                  whiteSpace: "normal", // Allows wrapping
                  textAlign: "center",
                  fontSize: ".75em",
                  fontWeight: "normal",
                }}
              >
                {choice.text}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {question.choices.map((_, index) => (
              <td
                key={index}
                style={{
                  width: "86px", // Fixed width for cells
                  padding: "4px",
                  textAlign: "center",
                  verticalAlign: "middle",
                }}
              >
                <input
                  type="radio"
                  name="likert"
                  value={index + 1}
                  style={{ margin: "5px" }}
                />
              </td>
            ))}
          </tr>
        </tbody>
      </table>

      <div className="col-7">

        {/* Dropdown for selecting the scale */}
        <FormControl
          fullWidth
          variant="outlined"
          className="mt-4"
          size="small" // Dense styling for FormControl
          style={{ marginBottom: "8px" }} // Reduce bottom margin
        >
          <InputLabel id="scaleDropdownLabel">Select rating scale</InputLabel>
          <Select
            labelId="scaleDropdownLabel"
            value={selectedScale.name}
            onChange={handleScaleChange}
            label="Select rating scale"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200, // Reduce dropdown height
                  paddingTop: "4px", // Reduce spacing inside dropdown
                  paddingBottom: "4px",
                },
              },
            }}
          >
            {RatingScales.map((scale) => (
              <MenuItem
                key={scale.name}
                value={scale.name}
                style={{
                  paddingTop: "4px", // Reduce spacing on menu items
                  paddingBottom: "4px",
                }}
              >
                {scale.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Display selected response */}
        {response && (
          <div className="mt-4 text-success text-center">
            You selected: <strong>{selectedScale.choices[response - 1]}</strong>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, null)(CreateRatingQuestion);
