import React from "react";
import { connect } from "react-redux";
import "./index.css";
import { updateProfileData } from "../redux/actions/profile-actions";
import { useFormFields } from "../shared/FormFields";
import { avatarUrl, cardImageUrl } from "../components/question/Utilities";
import { newId } from "../shared/utilities";
import { Avatar, TextField } from "@mui/material";
import { setView } from "../redux/actions/context-actions";
import { UserFeeds } from "../constants/feedids";
import ImageEditMenu from "../components/menus/ImageEditMenu";
import { AVATAR_SIZE, CARD_IMAGE_HEIGHT, CARD_IMAGE_WIDTH } from "../constants/image";
import { ViewType } from "../constants/profile";

const mapStateToProps = (state) => {
    return {
        currentFeedId: state.feed.currentFeedId,
        profile: state.profile.currentUser,
        view: state.context.view,
    };
};
const PremiumUserProfileEdit = React.memo(function PremiumUserProfileEdit({ profile, dispatch }) {
    const [fields, handleFieldChange] = useFormFields({
        firstName: profile.firstName,
        lastName: profile.lastName,
        userName: profile.userName,
        title: profile.title,
        description: profile.description ? profile.description : "",
    });

    let fileInput = React.createRef();
    let photoInput = React.createRef();

    const avatarImgUrl = profile.avatarKey
        ? avatarUrl(profile.identityId, profile.avatarKey)
        : null;

    const photoUrl = profile.photoKey
        ? cardImageUrl(profile.identityId, profile.photoKey)
        : null;

    const [previewUrl, setPreviewUrl] = React.useState(avatarImgUrl)
    const [newImage, setNewImage] = React.useState(null)
    const [photoPreviewUrl, setPhotoPreviewUrl] = React.useState(photoUrl)
    const [newPhoto, setNewPhoto] = React.useState(null)
    const [avatarChanged, setAvatarChanged] = React.useState(false);
    const [photoChanged, setPhotoChanged] = React.useState(false);
    const userName = profile.userName ?? "";

    const handleEdit = () => {
        fileInput.current.click();
    };

    const handlePhotoEdit = () => {
        photoInput.current.click();
    };

    const handleAvatarChange = e => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            //  scaleImage(file);
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
            setNewImage(file);
        }
        setAvatarChanged(true);
    };

    const handleAvatarRemove = () => {
        setPreviewUrl(null);
        setAvatarChanged(profile.avatarKey ? true : false);
    };

    const handlePhotoChange = e => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            //  scaleImage(file);
            reader.onloadend = () => {
                setPhotoPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
            setNewPhoto(file);
        }
        setPhotoChanged(true);
    };

    const handlePhotoRemove = () => {
        setPhotoPreviewUrl(null);
        setPhotoChanged(profile.photoKey ? true : false);
    };

    function ShowImage() {
        return (
            <div  >
                <Avatar src={previewUrl} className="mx-auto" style={{ height: AVATAR_SIZE, width: AVATAR_SIZE }}>you</Avatar>
            </div>
        )
    }

    function ShowPhoto() {
        return (
            <div  >
               <img src={photoPreviewUrl} alt="..." className=" mx-auto" style={{ height: CARD_IMAGE_HEIGHT, width: CARD_IMAGE_WIDTH, objectFit: "cover" }} /> 
              {/*   <Avatar src={photoPreviewUrl} variant="square" className="mx-auto" style={{ height: CARD_IMAGE_HEIGHT, width: CARD_IMAGE_WIDTH}}>Logo</Avatar> */}
            </div>
        )
    }

    const hasChanged =
        fields.firstName !== profile.firstName ||
        fields.lastName !== profile.lastName ||
        fields.title !== profile.title ||
        fields.description !== profile.description ||
        avatarChanged || photoChanged;

    const backToFeed = () => {
        const { profileId } = profile;
        // navigate("/feed");
        dispatch(
            setView({
                viewType: ViewType.PremiumUserView,
                profileId: profileId,
                targetId: profileId,
                feedId: UserFeeds.UserFeedQuestions,
            })
        );
    };

    async function handleSave(event) {
        const { avatarKey: oldAvatarKey, photoKey: oldPhotoKey } = profile;

        let avatarKey = avatarChanged ? (newImage ? newId() : null) : oldAvatarKey;
        let photoKey = photoChanged ? (newPhoto ? newId() : null) : oldPhotoKey;

        let imageActions = [];

        if (avatarChanged && oldAvatarKey) {
            imageActions.push({
                action: "delete",
                imageKey: oldAvatarKey,
                image: null,
            });
        }

        if (photoChanged && oldPhotoKey) {
            imageActions.push({
                action: "delete",
                imageKey: oldPhotoKey,
                image: null,
            });
        }

        if (avatarChanged && newImage)
            imageActions.push({
                action: "add",
                imageKey: avatarKey,
                image: newImage,
                profileId: profile.profileId,
                creatorId: profile.profileId,
            });

        if (photoChanged && newPhoto)
            imageActions.push({
                action: "add",
                imageKey: photoKey,
                image: newPhoto,
                profileId: profile.profileId,
                creatorId: profile.profileId,
            });

        let data = {
            lastName: fields.lastName,
            firstName: fields.firstName,
            title: fields.title,
            profileId: profile.profileId,
            userName: userName,
            description: fields.description,
            avatarKey: avatarKey,
            photoKey: photoKey
        };
        let updateData = {
            data: data,
            imageActions: imageActions,
        };
        dispatch(updateProfileData(updateData));
        setAvatarChanged(false);
        setPhotoChanged(false);
    }

    if (profile) {


        return (
            <div className="container p-0 brand1-bg h-100">
                <input type="file" hidden accept='image/*,video/*' id="test" name="test" onChange={handleAvatarChange} ref={fileInput} />
                <input type="file" hidden accept='image/*,video/*' id="photo" name="photo" onChange={handlePhotoChange} ref={photoInput} />
                <div className=" brand1-bg py-2 text-center px-0 brand1-bg">
                    <span className="h4 black-text">Edit Profile</span>
                </div>
                <div className="text-center">
                    <div key="viewProfileId" className="container p-3">
                        <div>
                            <div className=" text-center">
                                <h5>Icon</h5>
                                <ImageEditMenu
                                    handleEdit={handleEdit}
                                    handleDelete={handleAvatarRemove}
                                >
                                    <ShowImage />
                                </ImageEditMenu>
                            </div>
                            <div className=" text-center">
                                <h5>Photo</h5>
                                <ImageEditMenu
                                    handleEdit={handlePhotoEdit}
                                    handleDelete={handlePhotoRemove}
                                >
                                    <ShowPhoto />
                                </ImageEditMenu>
                            </div>
                        </div>
                        <div className="row no-gutters mt-3">
                            <div className="col mr-2">
                                <TextField
                                    id="firstName"
                                    margin="dense"
                                    size="small"
                                    className="myform"
                                    label="First name"
                                    defaultValue={fields.firstName}
                                    helperText="Required"
                                    onChange={handleFieldChange}
                                />
                            </div>
                            <div className="col">
                                <TextField
                                    id="lastName"
                                    margin="dense"
                                    size="small"
                                    className="myform"
                                    label="Last name"
                                    defaultValue={fields.lastName}
                                    helperText="Required"
                                    onChange={handleFieldChange}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <TextField
                                    id="title"
                                    margin="dense"
                                    size="small"
                                    className="myform w-100"
                                    label="Who or what are you?"
                                    defaultValue={fields.title ?? ""}
                                    //helperText="Required"
                                    onChange={handleFieldChange}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <TextField
                                    id="description"
                                    margin="dense"
                                    size="small"
                                    className="myform w-100"
                                    multiline
                                    rows={2}
                                    label="Say something about youself"
                                    defaultValue={fields.description ?? ""}
                                    onChange={handleFieldChange}
                                />
                            </div>
                        </div>
                        <div className="row justify-content-center mt-3">
                            {hasChanged ? (
                                <button
                                    className="btn btn-sm btn-primary "
                                    onClick={() => handleSave()}
                                >
                                    Save
                                </button>
                            ) : (
                                <button className="btn btn-sm brand1-bg" disabled>
                                    Save
                                </button>
                            )}
                            <button
                                className="btn btn-sm btn-primary mx-2"
                                onClick={() => backToFeed()}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else return <span />;
});

export default connect(mapStateToProps, null)(PremiumUserProfileEdit);
