// Firebase Cloud Messaging Configuration File.
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { Analytics, Auth } from 'aws-amplify';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyBIJiXvkOQOWKb6o64p6MiK2AO7UFdLSfQ",
    authDomain: "lyqness-1563567344991.firebaseapp.com",
    projectId: "lyqness-1563567344991",
    storageBucket: "lyqness-1563567344991.appspot.com",
    messagingSenderId: "527762350935",
    appId: "1:527762350935:web:814b4e660b40f0ac119412",
    measurementId: "G-08DXCKSSB4"
  };
  

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = async () => {
    // The method getToken(): Promise<string> allows FCM to use the VAPID key credential
    // when sending message requests to different push services
    const data = await Auth.currentAuthenticatedUser();
    const {sub:profileId,family_name:lastName, given_name:firstName, email} = data.attributes;
   // const userId = data.idToken.payload.sub;
   // const profile = store.getState().profile.currentUser;
    //const {profileId,email,firstName,lastName} = profile;
    try {
        const swRegistration = await navigator.serviceWorker.register(
            '/app/firebase-messaging-sw.js',
            {
              scope: '/app/'
            })

        const currentToken = await getToken(messaging, { vapidKey: `BDb4ZX8pGEeWVdyNFqWQ15WRpezKelqV755KiKXI7zNw09AsrdtzQwNrosF_6lK6EWq-qUHJdchALUbyeW1IiBA`,
            serviceWorkerRegistration:swRegistration  })
            ;
        if (currentToken) {
            console.log('current token for client: ', currentToken);

            if (localStorage.getItem('fcmToken') && currentToken !== localStorage.getItem('fcmToken')) {
                localStorage.setItem('fcmToken', currentToken);
            }

            else if (!localStorage.getItem('fcmToken')) {
                localStorage.setItem('fcmToken', currentToken);

            }
            Analytics.updateEndpoint({
                address: currentToken,
                channelType: "GCM",
                OptOut: 'NONE',
                UserId: profileId,
                attributes:{
                  email:[email],
                  lastName:[lastName],
                  firstName:[firstName]
                }
              }).then(data => {
                console.log("analytics endpoint updated. token:", JSON.stringify(data))
              }).catch(error => {
                console.log(error)
              });

        } else {
            console.log('No registration token available. Request permission to generate one.');
        }
    } catch (err) {
        console.log('An error occurred while retrieving token. ', err);
    }
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });


