import React from "react";
import { connect } from "react-redux";
import { hideModal } from "../../redux/actions/modal-actions";
import "./index.css"
import { Modal } from "@mui/base";
import { Box } from "@mui/system";

const mapStateToProps = state => {
    return {
        isOpen: state.modal.modalType === "MSG_MODAL"
    }
};

const MsgModal = React.memo(function MsgModal({ isOpen, title, content, dispatch }) {

    const toggle = () => {
        dispatch(hideModal())
    }

    if (isOpen)
        return (
            <Modal
            open={isOpen}
            className="question-modal-overlay "
            onClose={toggle}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="qmodal">
                  <div className="row brand3-bg no-gutters p-1" >
                        <div className="col-11 brand1">
                            {title}
                        </div>
                        <div className=" col-1 h4 text-right pointer " onClick={() => toggle()} ><i className="far fa-times"></i></div>
                    </div>
                    <div className="container py-3 modal-body">
                        {content}
                    </div>
                    <div className="p-3 container text-center">
                        <button className="btn btn-primary btn-sm" onClick={() => toggle()}>Close</button>
                    </div>
                </Box>
            </Modal>
        )
    else
        return (<span />)

}
)

export default connect(mapStateToProps, null)(MsgModal);