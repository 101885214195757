import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ViewType } from "../constants/profile";
import { UserFeeds } from "../constants/feedids";
import { setView } from "../redux/actions/context-actions";
import { useParams } from "react-router-dom";
import { base64ToUuid } from "../shared/Base64Conversions";
import { processSharedQuestion } from "../repository/crud";


const mapStateToProps = (state, ownProps) => {
  return {
    profileId: state.profile.currentUser.profileId,
  };
};

const SharedView = React.memo(function QuestionView({ profileId, dispatch }) {
  const { shareQuestionId, shareProfileId, sourceId } = useParams();
  const questionId = base64ToUuid(shareQuestionId);
  const sharerId = base64ToUuid(shareProfileId);
  let feedToLoad = UserFeeds.UserFeedQuestions;
  const viewToLoad = ViewType.UserView;
  const payload = {questionId:questionId, profileId:sharerId, sourceId:sourceId};

  // trigger on component mount
  useEffect(() => {
    processSharedQuestion(payload);

}, []);

  if(profileId) {
    dispatch(setView({ viewType: viewToLoad, profileId: profileId, feedId: feedToLoad }));
    return (
      <></>
    )
  }
  else {
    <div><i className="fas fa-spinner fa-spin fa-lg"></i> loading</div>
  }

})

export default connect(mapStateToProps, null)(SharedView);