import React from 'react';
import { Route, useLocation, Routes } from "react-router-dom";
import MainView from "./views/MainView";
import UserView from "./views/UserView";
import QuestionView from "./views/QuestionView";
import PublicView from "./views/PublicView";
import SharedView from "./views/SharedView";
import CollectionView from "./views/CollectionView";
import ProfileView from "./views/ProfileView";
import PremiumPublicView from "./views/PremiumPublicView";

const MyRoutes = React.memo(function MyRoutes({ currentUserProfileId }) {
    const location = useLocation();
    const { state } = location;
    const profileId = state ? state.profileId : null;


    return (
        <Routes>
            <Route path="/" element={<MainView />} />
            <Route path="/feed" element={<UserView />} />
            <Route exact path="/q/:shareQuestionId/:shareProfileId/:sourceId" element={<SharedView />} />
            <Route exact path="/c/:profileId/:tag" element={<CollectionView />} />
            <Route key="question" exact path="/question" element={<QuestionView />} />
            <Route key="public" exact path="/publicview" element={<PublicView profileId={profileId} />} />
            <Route key="premium" exact path="/premiumpublicview" element={<PremiumPublicView profileId={profileId} />} />
            <Route key="u" exact path="/u" element={<ProfileView />} />
        </Routes>
    );
})

export default MyRoutes;