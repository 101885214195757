

// const hostname = window && window.location && window.location.hostname;



const local = {
  "s3": {
    "bucket": "lyqness"
  },
  env: "local",
  hostname: "http://localhost:3000/app",
  oauth: {
    "domain": "cognito.lyqness.com",
    "redirectSignIn": "http://localhost:3000/app/",
    "redirectSignOut": "http://localhost:3000/app/",
  }
};

const prod = {
  "s3": {
    "bucket": "lyqness"
  },
  env: "local",
  hostname: "https://lyqness.com/app",
  oauth: {
    "domain": "cognito.lyqness.com",
    "redirectSignIn": "https://lyqness.com/app/",
    "redirectSignOut": "https://lyqness.com/app/",
  }
};

console.log("environment: ",process.env.NODE_ENV);
const config = process.env.NODE_ENV === 'development'? local : prod;

export default config;