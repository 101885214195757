import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import { Amplify,  Storage } from 'aws-amplify';
import store from "./redux/store/index"
import { Provider } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router } from "react-router-dom"
import MyRoutes from './MyRoutes';
import { Authenticator, Image, View, useTheme } from '@aws-amplify/ui-react';
import awsconfig from './aws-exports';
import Notification from './firebaseNotifications/Notification';
import config from './config';
import '@aws-amplify/ui-react/styles.css';


Amplify.configure({...awsconfig,oauth:{...awsconfig.oauth,...config.oauth}});
Storage.configure({ customPrefix: { protected: 'images/' } })

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('app/worker.js')
      .then(function(registration) {
        console.log('ServiceWorker registration successful with scope: ', registration.scope);
      })
      .catch(function(error) {
        console.log('ServiceWorker registration failed: ', error);
      });
  }
  

const components = {
    Header() {
        const { tokens } = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.small}>
               <a href="https://lyqness.com">
                <Image
                    height="35%"
                    width="35%"
                    alt="Lyqness logo"
                    src="https://images.lyqness.com/images/assets/lyqness.png"
                />
                </a>
            </View>
        );
    },
    Footer() {
        const { tokens } = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.small}>
 <span className="text-center mt-2 brand0 h5">Ask. Answer. Discover!</span>
            </View>
        );
    },
}

const formFields = {
    signIn: {
      username: {
        placeholder: 'Enter your email',
        isRequired: true,
        size:"small",
      },
      password: {
        label: "Password",
        placeholder: 'Enter your Password:',
        isRequired: true,
        size:"small",
      },
    },
    signUp: {
      email: {
        label: "Email",
        isRequired: true,
        size:"small",
        order:1,
      },
      given_name: {
        label: "First Name",
        placeholder: 'Enter your first name:',
        isRequired: true,
        size:"small",
        order:2,
      },
      family_name: {
        label: "Last Name",
        placeholder: 'Enter your last name:',
        isRequired: true,
        size:"small",
        order:3,
      },
      password: {
        label: "Password/Confirmation",
        placeholder: 'Enter your Password:',
        isRequired: true,
        size:"small",
      },
      confirm_password: {
        label: null,
        isRequired: true,
        size:"small",
      },
    },
    
    forceNewPassword: {
      password: {
        placeholder: 'Enter your Password:',
        size:"small",
      },
    },
    forgotPassword: {
      username: {
        placeholder: 'Enter your email:',
        size:"small",
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        placeholder: 'Enter your Confirmation Code:',
        label: 'Confirmation Code',
        size:"small",
        isRequired: false,
      },
      confirm_password: {
        placeholder: 'Enter your Password Please:',
        size:"small",
      },
    },
    setupTotp: {
      QR: {
        totpIssuer: 'test issuer',
        totpUsername: 'amplify_qr_test_user',
      },
      confirmation_code: {
        label: 'New Label',
        placeholder: 'Enter your Confirmation Code:',
        isRequired: false,
      },
    },
    confirmSignIn: {
      confirmation_code: {
        label: 'Confirmation Code',
        placeholder: 'Enter your Confirmation Code:',
        size:"small",
        isRequired: false,
      },
    },
}

Amplify.Logger.LOG_LEVEL = "INFO";
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <Authenticator formFields={formFields} socialProviders={['facebook', 'google']} components={components} className="mt-2 px-2" >
            {({ signOut, user }) => (
                <Router basename='/app'>
                    <Notification/>
                        <Toaster position="top-center"/>
                        <MyRoutes />
                        <div className="">
                            <App />
                        </div>
                </Router>
            )}
        </Authenticator>
    </Provider>,
);
//serviceWorker.register();
