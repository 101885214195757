import React from "react";
import { connect } from "react-redux";
import { AvatarXs } from "../../shared/Avatars";
import BasicProfileLink from "../../shared/BasicProfileLink";
import { ChatImageDisplay } from "./ChatImage";
import { chatImageUrl } from "../question/Utilities";

import { DateShort } from "../../shared/Dates";


const mapStateToProps = (state, ownProps) => {
  return { 
            currentUserId:state.profile.currentUser.profileId
          };
};

const ChatMessage = React.memo(function ChatMessage({message ,sameAsPrev,sameAsNext, isLast, handleLoad,currentUserId }) {
    const {author, content, messageId, createdAt,fileKey,isSent} = message;
    const {profileId, lastName,firstName,identityId,avatarKey} = author;
    let initials=firstName[0]+lastName[0];
    const me=(profileId===currentUserId);
    const msgImageUrl=fileKey?isSent?chatImageUrl(identityId,fileKey):fileKey:null;

    return (
        <div key={messageId} className="border-0 px-3">
            {(!sameAsPrev || !isSent) && <div className="text-center small mt-1">{isSent?DateShort(createdAt):'sending...'}</div>}
            <div className={me ? "w-85 ml-auto container-fluid " : "w-85 mr-auto container-fluid"}>
                <div className="w-45 container-fluid">
                    {!(sameAsNext || me|| content==="") && <AvatarXs initials={initials} avatarKey={avatarKey} userFolder={identityId} />}
                </div>
                <div className="container">
                    <div className="container-fluid" >
                        {!(sameAsPrev || me) && <BasicProfileLink firstName={firstName} lastName={lastName} profileId={profileId} close={true} />}
                    </div>
                    {msgImageUrl && <div className="container-fluid">
                        <div className={me ? "ml-auto" : "w-45"}><ChatImageDisplay handleLoad={handleLoad} imageUrl={msgImageUrl} /></div>
                    </div>}
                    {content &&
                        <div className="d-flex">
                            <div className={me ? "me brand0 ml-auto" : "them grey black-text mr-auto"}>
                                {content}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
})

export default connect(mapStateToProps,null)(ChatMessage);