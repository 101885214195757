import React from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { hidePanel, showLeftPanel } from "../redux/actions/panel-actions";
import { PublicFeeds } from "../constants/feedids";




const mapStateToProps = (state, ownProps) => {
    return { 
            isOpen:state.context.panel.isOpen,
            currentFeedId:state.feed.currentFeedId,
            viewProfileId:state.context.view.profileId
            };
  };

const BasicProfileLink = React.memo( function BasicProfileLink({dispatch, viewProfileId, isOpen, close, firstName, lastName, profileId}) {
    let fullName=firstName + " " + lastName;
    const loadFeed=()=>{
            if(isOpen && close)
            {
              dispatch(hidePanel());
              dispatch(showLeftPanel());
            }
      }
     if(viewProfileId===profileId)
        return (    
             <span className="black-text mr-1"> {fullName}</span>
        )
    else
        return (
            <Link onClick={()=>loadFeed()}
            to={{pathname: "/publicview", state: {profileId: profileId, isCurrentUser:false, feedId:PublicFeeds.AllQuestions}}}>
            <span className="brand1 mr-1"> {fullName}</span>
            </Link>
        )
    })

export default connect(mapStateToProps,null)(BasicProfileLink);