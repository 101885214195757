export const RatingScales =[
  {"name":"Agreement","choices":["Strongly Agree", "Disagree", "Neutral","Agree", "Strongly Agree"]},
  {"name":"Approval","choices":["Strongly Disapprove"," Disapprove", "Neutral","Approve", "Strongly Approve"]},
  {"name":"Awareness","choices":["Unaware", "Slightly Aware", "Moderately Aware","Very Aware", "Extremely Aware"]},
  {"name":"Frequency","choices":["Never", "Rarely", "Some Times","Often", "Always"]},
  {"name":"Importance","choices":["Not Important", "Somewhat Important", "Important","Very Important", "Extremely Important"]},
  {"name":"Intensity","choices":["None", "Very Mild", "Mild","Moderate", "Severe"]},
  {"name":"Likelihood","choices":["Definitely Not", "Probably Not", "Possibly","Probably", "Definitely"]},
  {"name":"Likeliness","choices":["Extremely Unlikely", "Unlikely", "Neutral","Likely", "Extremely Likely"]},
  {"name":"Performance","choices":["Not Acceptable", "Below Standards", "Meets Standards","Above Standards", "Exceptional"]},
  {"name":"Quality","choices":["Very Poor", "Poor", "Fair","Good", "Excellent"]},
  {"name":"Satisfaction","choices":["Very Unsatisfied", "Unsatisfied", "Somewhat Satisfied","Satisfied", "Very Satisfied"]},
  {"name":"Value","choices":["No Value", "Little Value", "Has Value","High Value", "Exceptional Value"]},
  {"name":"Baseball","choices":["Strike Out", "Base Hit", "Double","Triple", "Home Run"]},
  {"name":"Golf","choices":["Double Bogey", "Bogey", "Par","Birdie", "Eagle"]},
  {"name":"Football","choices":["Fumble", "Sack", "1st Down","Field Goal", "Touchdown"]},
  {"name":"Soccer","choices":["Red Card", " Card", "Good Play","Goal Strike", "Goal"]},
  {"name":"Basketball","choices":["Foul", "Turnover", "Pass","Free Throw", "3 Pointer"]},
  {"name":"Street","choices":["Hell No!", "No", "Not Sure","Yes", "Fuck Yeah!"]},
];


export default RatingScales;
