import { takeEvery, call, put, all } from "redux-saga/effects";
import { ProfileActions as Actions } from "../constants";
import { processImageActions } from "./image-sagas";
import { API, DataStore, graphqlOperation } from "aws-amplify";
import { Profile } from "../../models";
import * as queries from "../../graphql/lyqnessQueries"
import { dsProcessProfileInteraction } from "../../repository/datastore";

//****  process question interaction   */
export function* watchProcessProfileInteraction() {
  yield takeEvery(Actions.PROFILE_INTERACTION_REQUESTED, profileInteractionWorker);
}

function* profileInteractionWorker(action) {
  try {
    let payload = yield call(dsProcessProfileInteraction, action.payload);
    if(payload)
      yield put({ type: Actions.PROFILE_INTERACTION_PROCESSED, payload });
  } catch (e) {
    yield put({ type: Actions.PROFILE_API_ERROR, payload: e });
  }
}
//***         Process get view profile             ******/
export function* watchGetViewProfile() {
  yield takeEvery(Actions.VIEW_PROFILE_REQUESTED, getViewProfileWorker);
}

function* getViewProfileWorker(action) {
  try {
    const payload = yield call(getViewProfile, action.payload);
    yield put({ type: Actions.VIEW_PROFILE_RECEIVED, payload });
  }
  catch (e) {
    yield put({ type: Actions.PROFILE_API_ERROR, payload: e });
  }
}

async function getViewProfile(payload) {
  const { profileId } = payload;
  try {
    let result = await API.graphql(graphqlOperation(queries.getProfile, { profileId: profileId }));
    console.log(`user profile loaded: ${result.data.getProfile.profileId}`);
    return (result.data.getProfile)
  }
  catch (e) {
    console.log("getprofile error")
  }
}

//***         Get user profile             ******/
export function* watchGetUserProfile() {
  yield takeEvery(Actions.USER_PROFILE_REQUESTED, getUserProfileWorker);
}

function* getUserProfileWorker(action) {
  try {
    const payload = yield call(getUserProfile, action.payload);
    yield put({ type: Actions.USER_PROFILE_RECEIVED, payload });
  }
  catch (e) {
    yield put({ type: Actions.PROFILE_API_ERROR, payload: e });
  }
}

async function getUserProfile(payload) {
  const { profileId } = payload;
  try {
    let result = await DataStore.query(Profile, profileId);
    console.log(`user profile loaded: ${JSON.stringify(result.data)}`);
    return ({ ...result })
  }
  catch (e) {

  }
}

//***  Update profile             ******/
export function* watchUpdateProfile() {
  yield takeEvery(Actions.PROFILE_UPDATE_REQUESTED, updateProfileWorker);
}

function* updateProfileWorker(action) {
  try {
    if (action.payload.imageActions) {
      yield call(processImageActions, action.payload.imageActions);
    }
    const payload = yield call(updateProfile, action.payload);

    yield put({ type: Actions.PROFILE_UPDATED, payload });
  }
  catch (e) {
    yield put({ type: Actions.PROFILE_API_ERROR, payload: e });
  }
}

async function updateProfile(payload) {
  try {
    let data = payload.data;
    const original = await DataStore.query(Profile, data.profileId);

    let result = await DataStore.save(
      Profile.copyOf(original, updated => {
        updated.firstName = data.firstName;
        updated.avatarKey = data.avatarKey;
        updated.photoKey = data.photoKey;
        updated.avatarURL = data.avatarURL;
        updated.photoURL = data.photoURL;
        updated.profileURL = data.profileURL;
        updated.lastName = data.lastName;
        updated.title = data.title;
        updated.description = data.description;
      })
    );

    return result;
  } catch (error) {
    console.log('**** error  **** : ', error)
  }
}


export default function* rootSaga() {
  yield all([
    watchProcessProfileInteraction(),
    watchGetViewProfile(),
    watchGetUserProfile(),
    watchUpdateProfile(),
  ])
};