import React from "react";
import { connect } from "react-redux";
import { setView } from "../../redux/actions/context-actions";
import ProfileMenuItem from "../menus/ProfileMenuItem";

const mapStateToProps = (state, ownProps) => {
    return {
      view:state.context.view,
      isOpen:state.context.panel.leftPanelIsOpen,
      panelType:state.context.panel.panelType,
      viewType:state.context.view.viewType
    };
  };

const ActionViewQuestionItem = React.memo(function ActionViewQuestionItem({icon, view, isActive, viewType, caption, feedId, dispatch}) {
    const isSelected=isActive? feedId!==view.feedId: false;

    const loadFeed = () => {
          dispatch(setView({ viewType:viewType, targetId:view.profileId, tag:"",feedId:feedId}));
      }

      
      return (
        <ProfileMenuItem icon={icon} feedId={feedId} isActive={isSelected } caption={caption} handleClick={()=>loadFeed()} />
      )      
});


export default connect(mapStateToProps, null)(ActionViewQuestionItem);