import React from "react";
import { connect } from "react-redux";
import { PublicFeeds, UserFeeds, WhoFeeds } from "../../constants/feedids";
import { SystemId, ViewType } from "../../constants/profile";
import { AvatarLg } from "../../shared/Avatars";
import "../../index.css";
import { prettyNum } from "../../shared/utilities";
import { setPanelView, setView } from "../../redux/actions/context-actions";
import { Action } from "../../redux/constants";
import CMOST from "../question/core/MOS/CMOST";
import { PanelType } from "../../constants/panel";
import { hideLeftPanel, showPanel, toggleLeftPanel } from "../../redux/actions/panel-actions";
import FollowLink from "../links/FollowLink";
import CardImage from "./CardImage";
import { Icons } from "../../shared/QIcons";
import QButton from "../../shared/QButton";
import TagFeed from "../feeds/TagFeed";
import HideSmUp from "../../shared/HideSmUp";
import { TrendingUpOutlined } from "@mui/icons-material";

const mapStateToProps = (state) => {
    let user;
    let profileIsLoaded = false;
    switch (state.context.view.profileId) {
        case SystemId:
            user = state.profile.systemUser;
            break;
        case state.profile.currentUser.profileId:
            user = state.profile.currentUser;
            profileIsLoaded = state.profile.userProfileIsLoaded;
            break;
        default:
            user = state.profile.viewProfile;
            profileIsLoaded = state.profile.viewProfileIsLoaded;
            break;
    }
    return {
        userProfileIsLoaded: state.profile.userProfileIsLoaded,
        profileIsLoaded: profileIsLoaded,
        viewProfileIsLoaded: state.profile.viewProfileIsLoaded,
        profile: user,
        isUser: state.profile.currentUser.profileId === user.profileId,
        isPremiumUser: state.profile.isPremiumUser,
        context: state.context,
        feedId: state.feed.feedId
    };
};


const ProfileCard = React.memo(function ProfileCard({ context, profileIsLoaded, viewProfileIsLoaded, isUser, isPremiumUser, profile, dispatch }) {

    const { profileId, firstName, lastName, title, stats, initials, description, myProfileFeed, profileURL } = profile;
    const { view: { viewType, feedId }, panel: { feedId: panelFeedId, panelType } } = context;

    function activeColor(count) {
        if (Number(count) > 0)
            return " activeColor ";
        else
            return " inactiveColor ";
    }

    function isActive(action) {
        const lookupId = lookupFeedId(action);
        if (feedId === lookupId || stats.Question[action] === 0)
            return "col text-center ";
        else
            return "col text-center pointer";
    }

    function isSelectedFeed(action, count) {
        const lookupId = lookupFeedId(action);
        if (lookupId === feedId)
            return "statLabel inactiveBackgroundColor";
        else
            if (stats.Question[action] === 0)
                return "statLabel inactiveColor"
            else
                return "statLabel activeColor pointer";
    }

    function panelIsActive(feedId) {
        if (feedId === panelFeedId)
            return true;
        else
            return false;
    }

    function lookupFeedId(action) {
        let lookupId;
        switch (viewType) {
            case ViewType.UserView:
                lookupId = `L${action}`
                break;
            case ViewType.UserProfile:
            case ViewType.PublicView:
                if (action === "LINQ")
                    lookupId = `X${action}`
                else
                    lookupId = `P${action}`
                break;
            default:
                break;
        }
        return lookupId;
    }

    const drawerToggle = () => {
        dispatch(toggleLeftPanel({ isOpen: false }));
    };

    const loadQuestionFeed = (feedId) => {
        const count = stats.Question[feedId];
        if (count === 0)
            return;
        const feedToLoad = lookupFeedId(feedId);
        dispatch(setView({ viewType: viewType, targetId: profileId, feedId: feedToLoad }));
    }

    const loadFollowingFeed = () => {
        const feedToLoad = PublicFeeds.FollowingUsers;
        if (panelType === PanelType.Users && panelFeedId === feedToLoad) {
            dispatch(hideLeftPanel())
            dispatch(showPanel());
        }
        else {
            const count = stats.Profile.FOLU;
            if (count === 0)
                return;
            dispatch(setPanelView({ panelType: PanelType.Users, targetId: profileId, feedId: feedToLoad }));
        }
    }

    const loadFollowerFeed = () => {
        const feedToLoad = PublicFeeds.FollowingUsers;
        if (panelType === PanelType.Users && panelFeedId === feedToLoad) {
            dispatch(hideLeftPanel())
            dispatch(showPanel());
        }
        else {
            const count = stats.Tally.FOLU;
            if (count === 0)
                return;
            dispatch(setPanelView({ panelType: PanelType.Users, targetId: profileId, feedId: feedToLoad }));
        }
    }

    const loadUserQuestionFeed = () => {
        const feedToLoad = isUser ? UserFeeds.UserFeedQuestions : PublicFeeds.UserFeedQuestions;
        dispatch(setView({ viewType: viewType, targetId: profileId, feedId: feedToLoad }));
    }


    if (profileIsLoaded) {
        const { Question: { LIKQ, RESQ, LINQ, CREQ }, Tally: { FOLU: followers }, Profile: { FOLU: following } } = stats;

        return (
            <div className="drawerCard white shadow-none " style={{ width: "310px", height: "100%" }} key={"userFeed2"}>
                <div className="">
                    <CardImage >
                        <HideSmUp>
                            <i className={Icons.PANEL_OPEN + "float-end m-2 h4 panelAction fa-lg "} onClick={() => drawerToggle()} ></i>
                        </HideSmUp>
                    </CardImage>
                    <div>
                        <div className="profileAvatar text-center mx-auto mb-1 w-100">
                            <AvatarLg url={profileURL} initials={initials.toUpperCase()} square={false} />
                            {/* {!isUser &&
                                       <span className="float-end"><FollowLink displayProfile={profile} following={following} /></span>
                                    } */}
                        </div>

                        <div className="container text-center mt-2 mb-2 " >
                            <div className="nameFont brand5">
                                {`${firstName} ${lastName}`}
                            </div>
                            <div className="">
                                {title} &nbsp;
                            </div>
                            <div className="">
                                {description ? description : ""}&nbsp;<br/>&nbsp;
                            </div>
                            <div className="text-center ">
                                <span>{!isUser ? "CMOST here" : <span>&nbsp;</span>}</span>
                            </div>
                            {/* <div className="description yellow">

                            </div> */}
                        </div>




                        {/* Question stats */}
                        <div className="row mt-3 w-100 px-2 mx-auto no-gutters ">
                            <div className={isActive("CREQ")} onClick={() => loadQuestionFeed(Action.CREQ)}>
                                <div className={isSelectedFeed("CREQ", CREQ)}>Asked</div>
                                <div className={activeColor(CREQ)}>{prettyNum(CREQ)}</div>
                            </div>
                            <div className={isActive("RESQ")} onClick={() => loadQuestionFeed(Action.RESQ)}>
                                <div className={isSelectedFeed("RESQ", RESQ)}>Answered</div>
                                <div className={activeColor(RESQ)}>{prettyNum(RESQ)}</div>
                            </div>
                            <div className={isActive("LIKQ")} onClick={() => loadQuestionFeed(Action.LIKQ)}>
                                <div className={isSelectedFeed("LIKQ", LIKQ)}>Liked</div>
                                <div className={activeColor(LIKQ)}>{prettyNum(LIKQ)}</div>
                            </div>
                            <div className={isActive("LINQ")} onClick={() => loadQuestionFeed(Action.LINQ)}>
                                <div className={isSelectedFeed("LINQ", LINQ)}>Related</div>
                                <div className={activeColor(LINQ)}>{prettyNum(LINQ)}</div>
                            </div>
                        </div>
                        <div className="text-center mt-2  w-100 mx-auto no-gutters">

                        </div>
                        {/* Followers */}
                        <div className="row my-2  border-top pt-3 px-0 mx-auto g-0">

                            <div className={panelIsActive(PublicFeeds.FollowingUsers) ? "col text-center statLabel" : "col text-center statlabel pointer"} onClick={() => loadFollowingFeed()}>
                                <div className={panelIsActive(PublicFeeds.FollowingUsers) ? "inactiveBackgroundColor" : "inactiveColor"}>Following</div>
                                <div className={activeColor(following)}>{prettyNum(following)}</div>
                            </div>
                            <div className={panelIsActive(WhoFeeds.FollowingUsers) ? "col text-center statLabel" : "col text-center statlabel pointer"} onClick={() => loadFollowerFeed()}>
                                <div className={panelIsActive(WhoFeeds.FollowingUsers) ? "inactiveBackgroundColor" : "inactiveColor"}>Followers</div>
                                <div className={activeColor(followers)}>{prettyNum(followers)}</div>
                            </div>

                            {isUser ?
                                <div className={isActive(following)}>
                                    <QButton active={!feedId.includes("DRFT")} text={`Drafts`} handleClick={() => loadQuestionFeed(Action.DRFT)} />
                                </div>
                                :
                                <div className={isActive("DRFT")} >
                                    <div className="activeColor stalLabel pointer">&nbsp;</div>
                                </div>
                            }
                            <div className={isActive(following)}>
                                <QButton active={!feedId.includes("ALLQ")} text={`Feed`} handleClick={loadUserQuestionFeed} />
                            </div>
                        </div>
                    </div>
                </div >
            </div>
        )
    }
    else {
        return (
            <div className="drawerCard white shadow-none " style={{ width: "310px", height: "100%" }} key={"userFeed2"}>
                <div className="">
                    <CardImage >
                        <HideSmUp>
                            <i className={Icons.PANEL_OPEN + "float-end m-2 h4 panelAction fa-lg "} onClick={() => drawerToggle()} ></i>
                        </HideSmUp>
                    </CardImage>
                    <div>
                        <div className="profileAvatar text-center mx-auto mb-1 w-100">
                            <AvatarLg url={profileURL} initials="LY" square={false} />
                        </div>
                        <div className="container text-center mt-2 mb-2 " >
                            <div className="nameFont brand5">
                                &nbsp;
                            </div>
                            <div className="">
                                {title} &nbsp;
                            </div>
                            <div className="description small ">
                                &nbsp;
                            </div>
                        </div>
                        <div className="row justify-content-center no-gutters" >
                            <div className="col "></div>
                            <div className="col text-center d-flex">
                                <span><span>&nbsp;</span></span>
                            </div>
                            <div className="col "></div>
                        </div>
                        {/* Question stats */}
                        <div className="row mt-3 w-100 px-2 mx-auto no-gutters ">
                            <div className="col text-center " >
                                <div className="statLabel inactiveColor">Asked</div>
                                <div className="col text-center activeColor">{prettyNum(0)}</div>
                            </div>
                            <div className="col text-center" >
                                <div className="statLabel inactiveColor">Answered</div>
                                <div className="col text-center ">{prettyNum(0)}</div>
                            </div>
                            <div className="col text-center" >
                                <div className="statLabel inactiveColor">Liked</div>
                                <div className="col text-center  activeColor">{prettyNum(0)}</div>
                            </div>
                            <div className="col text-center" onClick={() => loadQuestionFeed(Action.LINQ)}>
                                <div className="statLabel inactiveColor">Related</div>
                                <div className="col text-center  activeColor">{prettyNum(0)}</div>
                            </div>
                        </div>
                        <div className="text-center mt-2  w-100 mx-auto no-gutters">
                            <div className="col statlabel text-center ">
                                <QButton active={false} text={`User Feed`} />
                            </div>
                        </div>
                        {/* Followers */}
                        <div className="row my-2  border-top pt-3 mx-auto g-0">
                            <div className={TrendingUpOutlined ? "col text-center statlabel" : "col text-center statlabel pointer"} >
                                <div className={false ? "inactiveBackgroundColor" : "inactiveColor"}>Following</div>
                                <div className=" activeColor ">0</div>
                            </div>
                            <div className={true ? "col text-center statlabel" : "col text-center statlabel pointer"} >
                                <div className={false ? "inactiveBackgroundColor" : "inactiveColor"}>Followers</div>
                                <div className=" activeColor ">0</div>
                            </div>
                            <div className="col text-center pointer">
                            </div>
                        </div>
                    </div>
                </div >
            </div>
        )
    }

})

export default connect(mapStateToProps, null)(ProfileCard);
