import React from "react";
import { connect } from "react-redux";
import "../timeline/index.css";
import "../../index";
import { Icons } from "../../shared/QIcons";
import { setPanelOpen } from "../../redux/actions/panel-actions";
import ShowMdDn from "../../shared/ShowMdDn";
import FeedHeader from "../feeds/feedDisplays/FeedHeader";
import PanelFeedHeader from "../feeds/feedDisplays/PanelFeedHeader";

const mapStateToProps = (state, ownProps) => {
    return {
        displayQuestion: state.feed.items[state.feed.index[ownProps.questionId]],
        viewProfileId: state.profile.viewProfile.profileId,
        screenWidth:state.context.screenWidth,
        user: state.profile.currentUser
    };
};

const Panel = React.memo(function Panel({ title, body,bottomLeft,bottomRight, avatar, topRight,headerColor,handleSearch, dispatch }) {

    const toggle = (shouldOpen) => {
        dispatch(setPanelOpen({ isOpen: shouldOpen }));
    };
    const CloseButton = topRight??<ShowMdDn><span className="float-end"><i onClick={() => toggle(false)} className={Icons.PANEL_CLOSE + "panelAction fa-lg "}  ></i></span></ShowMdDn>;
    

    return (
        <React.Fragment>

        <div className="panelcard shadow-none m-0 ">
        <PanelFeedHeader
                avatar={avatar}
                title={<span className="h5">{title}</span>}
                titleFont="panelHeader-text h6"
                topRight={CloseButton}
                bottomLeft={<span className="white-text">{bottomLeft}</span>}
                bottomRight={bottomRight}
            />
                <div className="h6 p-0 m-0 card-timeline card-plain ">
                    {body}
                </div>
            </div>
        </React.Fragment>
    )
})

export default connect(mapStateToProps, null)(Panel);