import React from "react";

const MiddleThing = React.memo(function MiddleThing({ left, center, right }) {

    return (
        <div className="px-0">
            <div className="row g-0 my-1 middleThing" >
                <div className="col-4 d-flex">
                    {left}
                </div>
                <div className="col-4 my-auto text-center" >
                <span className="float-end pe-2"> {center}</span>
                </div>
                <div className="col-4 " >
                    <span className="float-end">{right}</span>
                </div>
            </div>
        </div>
    );
})

export default MiddleThing;