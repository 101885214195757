
const FollowsBadge = ({userIsFollowing}) =>{
    if(userIsFollowing)
    {
    return (
        <span className="badge badge-pill px-1 brand1  brand3-bg">F</span>
    )
    }
    else{
        return <span className="px-1 py-0 ">&nbsp;</span>
    }
}

export default FollowsBadge;