
export const gqlChoices = `
  text
  imageKey
  imageURL
`;

export const gqlProfileStub = `
  lastName
  firstName
  identityId
  avatarKey
  `;


export const gqlFeedStats = `
  CHAQ
  CREA
  CREI
  CREQ
  HCRQ
  HLNQ
  HREQ
  HLKQ
  FEAQ
  FLAQ
  INCA
  INCQ
  INCR
  LIKQ
  LINQ
  MODQ
  RESQ
  SHAQ
  SPOQ
  STEA
  TAGQ
`;

export const gqlProfileStats = `
  CHAU
  FEAU
  FLAU
  FOLU
  LIKU
  MODU
  SHAU
  SPOU
`;

export const gqlQuestionIncentives = `
    count
    frequency
    imageKey
    description
    selections
`;

export const gqlUserIncentives = `
    achievedDate
    redeemedDate
    redemptionCode
    posCode
`;

export const gqlTallyStats = `
  CHAU
  FLAU
  FOLU
  SHAU
  SHAC
  FOLC
  FLAC
  CHAC
`;

export const gqlHashtag = `
  _deleted
  _version
  count
  createdAt
  imageKey
  imageURL
  profileId
  questionId
  sortId
  tag
  tagId
  updatedAt
`;

export const gqlProfileFeed = `
        _deleted
        _lastChangedAt
        _version
        createdAt
        associatedId
        lastMessageTime
        lastViewed
        profileId
        targetId
        userFollows
        updatedAt
        stats {
            CHAU
            FEAU
            FLAU
            FOLU
            LIKU
            MODU
            SHAU
            SPOU
        } 
`;

export const gqlCreator = `
  _deleted
  avatarURL
  createdAt
  description
  email
  identityId
  firstName
  lastName  lastLogon
  myProfileFeed {${gqlProfileFeed}}
  profileFeed {${gqlProfileFeed}}
  title
  profileId
  updatedAt
  userName
`;

export const gqlInteraction = `
    _deleted
    _lastChangedAt
    _version
    action
    actionTime
    associatedId
    category
    createdAt
    interactionId
    order
    profileId
    relatedId
    shareInfo {
      shareAction
      choiceOrder
      users
    }
    targetId
    updatedAt
    userFeedId
    viewFeedId
    viewProfileId
`;

export const gqlUserFeed = `
    _deleted
    _lastChangedAt
    _version
    category
    createdAt
    feedTime
    hidden
    lastMessageTime
    profileId
    publicTime
    questionId
    selections
    steps
    values
    incentive { ${gqlUserIncentives} }
    sharedBy
    stats {${gqlFeedStats}}
    stealthTime
    tags
    targetId
    toggledStealth
    updatedAt
    weight
    isOwner
`;

export const gqlQuestion = `
    audience
    buckets
    imageURL
    imageKey
    createdAt
    choices {
      ${gqlChoices}
    }
    choiceHeadings
    conversationId
    creatorId
    profileStub{ ${gqlProfileStub}}
    endTime
    howLong
    tags
    howOften
    linkSelections
    incentive{ ${gqlQuestionIncentives} }
    questionId
    landscapeImages
    choiceStats {
      count
    }
    key
    stats { ${gqlFeedStats} }
    stepStats
    isModerated
    isMultipleChoice
    isOrdered
    isPremium
    draft
    isNew
    targetId
    minRange1
    minRange2
    maxRange1
    maxRange2
    maxSel
    minSel
    numericType
    parentId
    questionType
    questionText
    qmosr
    startTime
    status
    stealthTime
    updatedAt
    updateDate
    updateTime
    userFeed { ${gqlUserFeed}}
    viewProfileId
    viewUserFeed {${gqlUserFeed} }
    weight
    _version
    _deleted
    _lastChangedAt
    __typename
`;

export const gqlProfile = `
    _deleted
    _lastChangedAt
    _version
    avatar
    avatarKey
    avatarURL
    category
    conversationId
    createdAt
    creatorId
    description
    email
    firstName
    follower
    identityId
    lastFeedView
    lastLogon
    lastName
    photo
    photoKey
    photoURL
    profileId
    profileURL
    showTooltips
      stats {
        Question {${gqlFeedStats}}
        Profile {${gqlProfileStats}}
        Tally {${gqlTallyStats}}
      }
    targetId
    title
    updatedAt
    userFeedQuestionId
    userName
    myProfileFeed {
        ${gqlProfileFeed}
    }
    profileFeed {
        ${gqlProfileFeed}
    }
    userFeed {
      ${gqlUserFeed}
    }
`;

export const questionFields = `{
  items {
    audience
    buckets
    imageURL
    imageKey
    choices {
      text
      image
    }
    choiceHeadings
    conversationId
    creatorId
    profileStub{ ${gqlProfileStub}}
    creator {
        _deleted
        _lastChangedAt
        _version
        avatarURL
        category
        createdAt
        creatorId
        description
        firstName
        lastName
        title
        identityId
        updatedAt
        userName
        profileId
        photoKey
        myProfileFeed {${gqlProfileFeed} }
        profileFeed { ${gqlProfileFeed} }
    }
    endTime
    howLong
    tags
    howOften
    linkSelections
    incentive{
      count
      frequency
      imageKey
      description
      selections
    }
    questionId
    landscapeImages
    choiceStats {
      count
    }
    stepStats
    key
    stats { ${gqlFeedStats} }
    isModerated
    isMultipleChoice
    isOrdered
    isPremium
    draft
    isNew
    targetId
    minRange1
    minRange2
    maxRange1
    maxRange2
    maxSel
    minSel
    parentId
    questionType
    questionText
    qmosr
    startTime
    status
    stealthTime
    updatedAt
    updateDate
    updateTime
    userFeed { ${gqlUserFeed}}
    viewUserFeed {${gqlUserFeed} }
  nextToken
  startedAt
}`;

export const profileFields = `
avatar
avatarKey
avatarURL
category
conversationId
createdAt
creator {
  avatar
  avatarKey
  avatarURL
  category
  conversationId
  createdAt
  creatorId
  description
  email
  firstName
  follower
  lastLogon
  lastName
  photo
  photoKey
  photoURL
  profileId
  profileURL
  showTooltips
  targetId
  title
  updatedAt
  userFeedQuestionId
  userName
  _version
  _deleted
  _lastChangedAt
  __typename
}
creatorId
description
email
firstName
follower
identityId
lastLogon
lastName
photo
photoKey
photoURL
profileFeed {
  profileId
  associatedId
  lastViewed
  category
  userFollows
  isOwner
  targetId
  lastMessageTime
  updatedAt
  stats { ${gqlProfileStats}}
  createdAt
  _version
  _deleted
  _lastChangedAt
  __typename
}
profileId
profileURL
questions {
  nextToken
  startedAt
  __typename
}
showTooltips
stats {
  Question {${gqlFeedStats}}
  Profile {${gqlProfileStats}}
  Tally {${gqlTallyStats}}
}
myProfileFeed {
  profileId
  associatedId
  lastViewed
  category
  userFollows
  isOwner
  targetId
  lastMessageTime
  stats { ${gqlProfileStats}}
  updatedAt
  createdAt
  _version
  _deleted
  _lastChangedAt
  __typename
}
targetId
title
updatedAt
userFeed {
  category
  createdAt
  publicTime
  feedTime
  updatedAt
  profileId
  questionId
  tags
  selections
  hidden
  lastMessageTime
  stats {${gqlFeedStats}}
  stealthTime
  toggledStealth
  targetId
  isOwner
  weight
  _version
  _deleted
  _lastChangedAt
  __typename
}
userFeedQuestionId
lastFeedView
userName
_version
_deleted
_lastChangedAt
__typename
`;