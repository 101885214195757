import React from "react";

import QCheckbox from "../../../../../shared/QCheckbox";
import {connect} from "react-redux";
import LyqPopover from "../../../core/LyqPopOver";
import { Icons } from "../../../../../shared/QIcons";
import { updateEditQuestion } from "../../../../../redux/actions/questionedit-actions";

function mapStateToProps(state) {
    return {
        question: state.questionedit.question,
    };
};

const MultiChoiceCheckbox = React.memo(function MultiChoiceCheckBox({ question, dispatch }) {
    const { isMultipleChoice } = question;
    const msg = () => {
        return (<span>When this box is checked the user will be able to select more than one choice.
            The selection input will change from<i className={`${Icons.RADIO} mx-1`}></i>
            to<i className={`${Icons.CHECKBOX} ml-1`}></i> .
        </span>
        )
    }

    const updateIsMultiple = (isChecked) => {
        const {choices} = question;
        if (isChecked) {
            dispatch(updateEditQuestion({ question:{isMultipleChoice: isChecked, maxSel:choices.length }}))
        }
        else {
            dispatch(updateEditQuestion({ question:{isMultipleChoice: isChecked, minSel: 1, maxSel: 1, isOrdered: false }}))
        }
    }

    return (
        <div className="clearfix">
            <span className="float-start">Allow multiple selections?
                <LyqPopover title={"Multiple Selections"} content={msg()}>test</LyqPopover>
            </span>
            <span className="float-end ">
                <QCheckbox checked={isMultipleChoice} toggle={() => updateIsMultiple(!isMultipleChoice)} />
            </span>
        </div>

    );
})

export default connect(mapStateToProps, null)(MultiChoiceCheckbox);