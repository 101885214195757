import { Icons } from "../../shared/QIcons";

export const fontStyle = "fal "

export const fontStyleActive = "far "

export const captionColor = "";

export const menuIcons ={
    About: {icon:Icons.INFO, text:"About"},
    Account: {icon:Icons.ACCOUNT, text:"Your Account"},
    Active: {icon:Icons.ACTIVE, text:"Most active"},
    Activity: {icon:Icons.ACTIVITY, text:"Activity"},    
    Advertise: {icon:Icons.AD, text:"Ads "},
    Ask: {icon:Icons.QUESTIONS, text:"Asked"},
    Chat: {icon:"fa-comments ", text:"Chatted"},
    Conversation: {icon: Icons.CONVERSATION, text:"Conversations"},
    Coffee: {icon:Icons.COFFEE, text:"Tags"},
    Collection: {icon: Icons.CONNECTED, text: "Collections"},
    Dashboard: {icon:Icons.DASHBOARD, text:"Dashboard"},
    Delete: {icon:Icons.TRASH, text:"Delete"},
    Edit: {icon:Icons.EDIT, text:"Edit"},
    Examples: {icon:Icons.EXAMPLES, text:"Example Questions"},
    Expiring: {icon:Icons.EXPIRING, text:"Expiring"},
    Feature: {icon:Icons.FEATURE, text:"Featured"},
    Feed: {icon:Icons.USERFEED, text:"Feed"},
    Followers: {icon:Icons.FOLLOWERS, text:"Followers"},
    Following: {icon:Icons.FOLLOWING, text:"Following"},
    Hashtags: {icon:Icons.HASHTAG, text:"Hashtags"},
    Hide: {icon:Icons.HIDDEN, text:"Hide in feed"},
    Incentives: {icon:Icons.AWARD, text:"Incentives"},
    Instructions: {icon:Icons.LIGHTBULB, text: "How it works"},
    Like: {icon:Icons.FAVORITE, text:"Liked"},
    Link: {icon:Icons.LINK, text:"Related"},
    Linking: {icon:Icons.LINK, text:"Related Questions"},
    LinkActive: {icon:"fa-link fa-spin ", text:"related"},
    Lyqness: {icon:Icons.GLASSES, text:"Similarity"},
    Messages: {icon:Icons.MESSAGES, text:"Messages"},
    Navigation: {icon:Icons.ROUTE + " fa-lg ", text:"Getting Around"},
    Network: {icon:Icons.NETWORK, text:"Connections"},
    Premium: {icon:Icons.PREMIUM, text:"Premium"}, 
    Profile: {icon:Icons.PROFILE, text:"Profile"},
    ProfileEdit: {icon:Icons.PROFILEEDIT, text:"Edit Profile"},
    Promote: {icon:Icons.PROMOTION, text:"Promotions"},
    Questions: {icon:Icons.QUESTIONS, text:"Questions"},
    Overview: {icon:Icons.OVERVIEW, text:"How it works"},
    Remove: {icon:Icons.TRASH, text:"Remove from feed"},
    Reply: {icon:Icons.RESPOND, text:"Answered"},
    Report: {icon:Icons.REPORT, text:"Report question"},
    Search: {icon:Icons.SEARCH, text:"Search"},
    Share: {icon:Icons.SHARE, text:"Shared "},
    Similarity: {icon:Icons.SIMILARITY + " fa-lg ", text:"Discovering Similarity"},
    Signout: {icon:Icons.SIGNOUT, text:"Sign out"},
    Tag: {icon:Icons.TAG + "fa-lg ", text:"Organizing Interests"},
    Tagging: {icon:Icons.TAG, text:"Tagging"},
    Time: {icon:Icons.CLOCK, text:"Expiring Soon"},
    Users: {icon:Icons.USERS, text: "Finding Others"},
    ExploringUsers: {icon:Icons.USERS +" fa-lg ", text:"Viewing Others"},
    UserTags: {icon:Icons.TAG , text:"User Tags"},
    UserFeed: {icon:Icons.USERFEED, text:"Feed"},
    World: {icon:Icons.WORLD, text:"Expiring"},
}