import { LocalizationProvider, MobileDatePicker, MobileTimePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs';
import { useState } from "react";
import { connect } from 'react-redux';
import { updateQuestionRanges } from '../../../redux/actions/questionedit-actions';
import { NumericType } from '../../../constants/question';
import { xsScreenSize } from '../../../constants';
import { CreateChoicesButton, endLabels, numericParms, startLabels } from './utilities';

dayjs.extend(customParseFormat)

function mapStateToProps(state) {
  return {
    minRange1: state.questionedit.question.minRange1,
    maxRange1: state.questionedit.question.maxRange1,
    screenWidth: state.context.screenWidth,
  };
}

export const isTimeInRange = (time, minTime, maxTime) => {
  const time24 = dayjs(time, "hh:mm A").format("HH:mm");
  const minTime24 = dayjs(minTime, "hh:mm A").format("HH:mm");
  const maxTime24 = dayjs(maxTime, "hh:mm A").format("HH:mm");

  if (minTime24 > maxTime24) {
    // Overnight range
    return time24 >= minTime24 || time24 <= maxTime24;
  } else {
    // Regular range
    return time24 >= minTime24 && time24 <= maxTime24;
  }
};

export const DateInputField = ({ numericType, label, value, minDate, maxDate, minTime, maxTime, handleChange }) => {
  const parms = numericParms(numericType);
  if (numericType === NumericType.Time30Min)
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileTimePicker
          openTo={parms.openTo}
          minutesStep={30}
          //minTime={dayjs(minTime, parms.format)}
          //maxTime={dayjs(maxTime, parms.format)}
          size="small"
          slotProps={{ textField: { size: 'small' } }}
          label={label}
          closeOnSelect={false}
          value={value ? dayjs(value, parms.format) : null}
          onAccept={(e) => handleChange(dayjs(e).format(parms.format))}
          format={parms.format}
          views={parms.views}
        />
      </LocalizationProvider>
    )
  else
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          aria-hidden="false"
          openTo={parms.openTo}
          size="small"
          minDate={ dayjs(minDate) }
          maxDate={dayjs(maxDate )}
          slotProps={{ textField: { size: 'small' } }}
          label={label}
          value={value ? dayjs(value) : null}
          closeOnSelect={true}
          onAccept={(e) => handleChange(dayjs(e).format(parms.format))}
          format={parms.format}
          views={parms.views}
        />
      </LocalizationProvider>
    )
}

const MinMaxDate = ({ numericType, minRange1, maxRange1, screenWidth, dispatch }) => {
  const [errMsg, setErrMsg] = useState("");
  const [minValue, setMinValue] = useState(minRange1);
  const [maxValue, setMaxValue] = useState(maxRange1);
  const colWidth = numericType === NumericType.DateYear ? "col-5 p-1" : `col-6 ${screenWidth > xsScreenSize && "px-2"}`;
  const parms = numericParms(numericType);

  const handleMinValueChange = (val) => {
    if (numericType === NumericType.Time30Min) {
      if (dayjs(val, parms.format).startOf("minute").toString() !== dayjs(maxValue, parms.format).startOf("minute").toString())
        setErrMsg('');
      else
        setErrMsg("start and end time cannot be the same");
    }
    else
      if (dayjs(val) < dayjs(maxValue)) {
        setErrMsg("")
      }
      else
        setErrMsg("minimum must be less than maximum");
    dispatch(updateQuestionRanges({ question: { minRange1: val } }));
    setMinValue(val);
  };

  const handleMaxValueChange = (val) => {

    if (numericType === NumericType.Time30Min) {
      if (dayjs(val, parms.format).startOf("minute").toString() !== dayjs(minValue, parms.format).startOf("minute").toString())
        setErrMsg('');
      else
        setErrMsg("start and end time cannot be the same");
    }
    else
      if (dayjs(val) > dayjs(minValue)) {
        setErrMsg("")
      }
      else
        setErrMsg("minimum must be less than maximum");
    setMaxValue(val);
    dispatch(updateQuestionRanges({ question: { maxRange1: val } }));
  };


  const showDateInput = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="col-5 p-1">
          <DateInputField
            numericType={numericType}
            minDate={null}
            maxDate={null}
            minTime={null}
            maxTime={null}
            label={startLabels[numericType]}
            value={minValue}
            handleChange={(value) => handleMinValueChange(value)}
          />
        </div>
        <div className="col-5 p-1">
          <DateInputField
            numericType={numericType}
            minDate={null}
            maxDate={null}
            minTime={null}
            maxTime={null}
            label={endLabels[numericType]}
            value={maxValue}
            handleChange={(value) => handleMaxValueChange(value)}
          />
        </div>
      </LocalizationProvider>
    );
  };

  return (
    <div className="row mb-0 mx-0 justify-content-center">
      {showDateInput()}
      <div className="error-text smallfont">{errMsg}&nbsp;</div>
            <CreateChoicesButton canCreate={errMsg==="" && minRange1!==null && maxRange1!==null}/>
    </div>
  )
};

export default connect(mapStateToProps, null)(MinMaxDate);
