import React, { useEffect, useState } from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import "./MoveMatchChoices.css";
import { connect } from "react-redux";
import EditItemDisplay from "../core/Choices/EditItemDisplay";
import ResultsSummary from "../core/ResultsSummary";
import RespondSummary from "../core/RespondSummary";
import { respondToQuestion } from "../../../redux/actions/question-actions";
import QuestionUserCount from "../core/Choices/QuestionUserCount";
import { pictureChoiceUrl, smsImageUrl } from "../Utilities";
import FullscreenImageViewer from "./FullScreenImageViewer";


const mapStateToProps = (state) => {
  return {
    viewProfileId: state.context.view.profileId,
  };
};

const RespondToMoveMatch = ({ question, viewProfileId, dispatch }) => {
  const { questionId,  choiceHeadings, userFeed: { selections, stats: { RESQ }}, creator:{identityId} } = question;
  const [choices, setChoices] = useState(question.choices);
  const [inputChoices, setInputChoices] = useState(Array.from(question.choices, (_, index) => index))
  const colWidth = choices.length === 2 ? "col-5" : "col-4 d";

  // Populate initial choices or defaults
  useEffect(() => {
    setChoices(question.choices);
    setInputChoices(Array.from(question.choices, (_, index) => index));
    return () => console.log("leaving respondtomovematch");
  }, []);


  const instructions = "Drag pictures to match your choices and press submit";

  const canSubmit = true;

  let canReset = Array.from(question.choices, (_, index) => index).toString() !== inputChoices.toString();


  const handleSubmit = () => {
    var data = {
      "displayQuestion": question,
      "viewProfileId": viewProfileId,
      "selections": inputChoices,
    };
    dispatch(respondToQuestion({ ...data }))
  }

  const handleReset = () => {
    setChoices(question.choices);
    setInputChoices(Array.from(question.choices, (_, index) => index));
  }

  const [fullscreenImage, setFullscreenImage] = useState(null);

  const handleSortEnd = (oldIndex, newIndex) => {
    if (oldIndex !== newIndex) {
      setChoices(prevSelections => arrayMoveImmutable(prevSelections, oldIndex, newIndex));
      setInputChoices(prevSelections => arrayMoveImmutable(prevSelections, oldIndex, newIndex));
    }
  };

  const toggleFullscreenImage = (imageURL) => {
    setFullscreenImage(fullscreenImage === imageURL ? null : imageURL);
  };

  if (!RESQ)
    return (
      <div className="px-2">
        <FullscreenImageViewer fullscreenImage={fullscreenImage} identityId={identityId} toggleFullscreenImage={toggleFullscreenImage}/>
        <div className="row justify-content-center px-2">
          {choiceHeadings.map((heading, index) => (
            <div key={index} className={`${colWidth} px-1 bigfont brand1`}>
              <b>{heading}</b>
            </div>
          ))}
        </div>
        <SortableList
          onSortEnd={handleSortEnd}
          className="row justify-content-center g-2"
          draggedItemClassName="dragged"
        >
          {choices.map((choice,index) => (
            <SortableItem key={choice.imageKey}>
              <div key={questionId+index} className={`${colWidth} grid-item`}> 
              <img
                src={pictureChoiceUrl({identityId, imageKey:choice.imageKey})}
                alt="Uploaded Preview"
                className="image-thumbnail"
                onClick={() => toggleFullscreenImage(smsImageUrl({ identityId, imageKey:choice.imageKey }))} // Open fullscreen
              />
                <EditItemDisplay choiceId={inputChoices[index]} orderId={index} question={question} />
              </div>
            </SortableItem>
          ))}
        </SortableList>
        <RespondSummary handleSubmit={handleSubmit} instructions={instructions} handleReset={handleReset} displayQuestion={question} canSubmit={canSubmit} canReset={canReset} />
      </div>
    );
  else {
    return (
      <div className="px-2">
        <FullscreenImageViewer fullscreenImage={fullscreenImage} identityId={identityId} toggleFullscreenImage={toggleFullscreenImage}/>
        <div className="row justify-content-center g-2">
          {choiceHeadings.map((heading, index) => (
            <div key={index} className={`${colWidth} bigfont brand1`}>
              <b>{heading}</b>
            </div>
          ))}
        </div>
        <div
          className="row justify-content-center g-2"
        >
          {selections.map((selection, index) => (
            <div key={question.choices[selection].imageKey} className={`${colWidth} grid-item`}> {/* Bootstrap col-6 for 2 items per row */}
              <img
                src={pictureChoiceUrl({identityId, imageKey:question.choices[selection].imageKey})}
                alt="Uploaded Preview"
                className="image-thumbnail"
                onClick={() => toggleFullscreenImage(smsImageUrl({ identityId, imageKey:question.choices[selection].imageKey }))} // Open fullscreen
              />

              {/* Text input that updates immediately as the user types */}
              <EditItemDisplay choiceId={selection} orderId={index} question={question} >
                <QuestionUserCount choiceId={selection} orderId={index} question={question} />
              </EditItemDisplay>
            </div>

          ))}
          <ResultsSummary key={questionId + "9"} displayQuestion={question} />
        </div>
      </div>
    );
  }
};

export default connect(mapStateToProps, null)(RespondToMoveMatch);