import React, { useState } from "react";
import { MenuItem, Select,  Button } from "@mui/material";
import 'bootstrap/dist/css/bootstrap.min.css';
import MinMaxDate  from "./DateInput";
import  MinMaxNumber from "./NumberInput";
import { connect } from "react-redux";
import {  updateEditQuestion, updateQuestionChoices, updateQuestionRanges } from "../../../redux/actions/questionedit-actions";
import { calculateRanges } from "./utilities";
import { NumericType } from "../../../constants/question";

function mapStateToProps(state) {
  return {
    choices: state.questionedit.question.choices,
  };
}

const CreateNumericQuestion = ({choices, dispatch}) => {
  const [numericType, setInputType] = useState(NumericType.Number);

  const handleTypeChange = (event) => {
    const numericType=event.target.value;
    setInputType(numericType);
    dispatch(updateQuestionRanges({ question: { minRange1: null, maxRange1:null, choices:[], buckets:0, choiceStats:null, numericType:numericType }}));
  };

  const createChoices=()=>{
    const choices = calculateRanges({numericType})
    dispatch(updateEditQuestion( { question: {choices, numericType:numericType}}));
  }

  const InputField = ({ label, value }) => {
    switch (numericType) {
      case NumericType.DateYear:
      case NumericType.DateMonth:
      case NumericType.DateDay:
      case NumericType.Time30Min:
        return (
          <MinMaxDate
            numericType={numericType}
          />
        )
      case NumericType.Money:
      case NumericType.Number:
        return (
          <MinMaxNumber
            numericType={numericType}
          />
        )
      default:
        break;
    }
  };

  return (
    <div className="container mt-4 mb-2 px-1">
      {choices && choices.length > 0 && (
        <div className="mt-4">
          <div className="row gx-1 gy-1 mb-3 justify-content-center">
            {choices.map((choice, index) => (
              <div className="col-6 col-md-5" key={index}>
                <div className="border p-1 ps-1 text-start" style={{ fontSize: ".9em" }}>{choice.text}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    <div className="row mb-0 mx-0 justify-content-center">
      <InputField />

      </div>
      <div className="mt-3">Select Numeric Type</div>
        <Select
          aria-hidden="false"
          labelId="input-type-label"
          type="outlined"
          className="w-50 mb-3"
          size="small"
          value={numericType}
          onChange={handleTypeChange}
        >
          <MenuItem value={NumericType.Number}>Number</MenuItem>
          <MenuItem value={NumericType.Time30Min} >Time</MenuItem>
          <MenuItem value={NumericType.DateDay}>Date</MenuItem>
          <MenuItem value={NumericType.DateMonth}>Months</MenuItem>
          <MenuItem value={NumericType.DateYear}>Years</MenuItem>
          <MenuItem value={NumericType.Money}>Money</MenuItem>
        </Select>
        <div>
      </div>
    </div>
    
  );

};

export default connect(mapStateToProps, null)(CreateNumericQuestion);