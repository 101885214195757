import { DataStore } from "aws-amplify";
import global from "../constants";
import { ProfileFeed, Question, UserFeed } from "../models/index";
import { Interaction } from "../models/index";
import store from "../redux/store/index";
import * as crud from "./crud";


export async function dsRespondToQuestion(payload) {
    const { displayQuestion: { questionId }, selections, viewProfileId, values, steps } = payload;
    let userId = global.userId;

    try {
        let responseInput = {
            profileId: userId,
            questionId: questionId,
            viewProfileId: viewProfileId,
            selections: selections,
            values: values ?? null,
            steps: steps?? null,
        };
        let result = await crud.addResponse({ ...responseInput });
        return result;
    }
    catch (error) {
        console.log(error)
    }
}

export async function dsAddQuestion(input) {
    const profileState = store.getState().profile;
    const { currentUser } = profileState;
    const { questionData } = input;
    let now = new Date().toISOString();

    try {
        let payload = {
            ...questionData, updatedAt: now,
            isNew: true
        };
        let question = await crud.addQuestion(payload);
        let newDisplayQuestion = {
            ...question, creator: currentUser,
        };
        return newDisplayQuestion;
    }
    catch (error) {
        console.log(error)
    }
}

export async function dsProcessQuestionInteraction(payload) {
    const { questionId, relatedId, state, profileId,  action } = payload;
    const interactionId = action.concat(questionId, relatedId ?? "");
    try {
        let interaction = await DataStore.query(Interaction, { profileId: profileId, interactionId: interactionId });
        let newInteraction = null;
        if (state && !interaction)
            newInteraction = await crud.processQuestionInteraction(payload);
        else
            if (!state && interaction)
                newInteraction = await crud.processQuestionInteraction( payload );
        return newInteraction;
    }
    catch (err) {
        return null;
    }
}

export async function dsUpdateUserTags(payload) {
    try {
        let userFeed = await crud.updateUserTags(payload);

        return userFeed;
    }
    catch (error) {
        console.log(error)
    }
}

export async function dsProcessProfileInteraction(payload) {
    const { associatedId, state, profileId, action, relatedId } = payload;
    try {
        let interaction = await DataStore.query(Interaction, { profileId: profileId, interactionId: action.concat(associatedId, relatedId ?? "") });
        let newProfileFeed;
        if (state && !interaction) {
            newProfileFeed = await crud.processProfileInteraction(payload);
        }
        else
            if (!state && interaction) {
                newProfileFeed = await crud.processProfileInteraction({ ...payload });
            }
            else{
                newProfileFeed = await DataStore.query(ProfileFeed,{profileId:profileId, associatedId:associatedId});
            }
        return {profileFeed:newProfileFeed};
    }
    catch (err) {
        return null;
    }
}

export async function dsUpdateQuestion(payload) {
    try {
        let question = await crud.updateQuestion(payload);

        return question;
    }
    catch (error) {
        console.log(error)
    }
}

export async function dsDeleteQuestion(payload) {
    const { questionId } = payload;
    try {
        let data = await DataStore.query(Question, questionId)
        if (data) {
            const { _version } = data;
            payload["_version"] = _version;
            let question = await crud.deleteQuestion(payload);
            return question;
        }
        else {
            return null;
        }
    }
    catch (error) {
        console.log(error)
    }
}

export async function dsRemoveUserFeed(payload) {
    const { questionId, profileId } = payload;
    try {
        let userFeed = await DataStore.query(UserFeed, { profileId: profileId, questionId: questionId });
        if (userFeed) {
            const { _version } = userFeed;
            payload["_version"] = _version;
            let result = await crud.deleteUserFeed(payload);
            return result;
        }
        else {
            return null;
        }
    }
    catch (error) {
        console.log(error)
    }
}