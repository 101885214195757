
export const ProfileListTitle = {
    Responded:"Users who have Responded...",
    Watching:"Is being Watched by...",
    Linked:"Has been Related by...",
    Shared:"Has been Shared by...",
    FollowedBy: "Is being Followed by this User",
    Following:"Is Following this User",
    SICFollowers:"Follow both of you",
    SICFollowing:"You both follow",
    LYQness:"LYQness",
}   

export const ViewType = {
    MainView: 0,
    UserView: 1,
    PublicView: 2,
    QuestionView: 3,
    GroupView: 4,
    OrganizationView: 5,
    SharedView: 6,
    UserProfile:7,
    PremiumPublicView:8,
    PremiumUserView:9,
    PremiumUserProfile:10,
    } 

export const SystemId="LYQrAqtrKr6ZEJqFltness";
export const LyqnessId="05608209-6023-431e-9d18-764257a83b69";
