import { DataStore } from "aws-amplify";
import { processQuestionSubscription, processUserFeedSubscription, profileFeedSubscriptionReceived, questionSubscriptionReceived, userFeedSubscriptionReceived } from "./redux/actions/feed-actions";
import { hashtagsReceived, profileSubscriptionReceived } from "./redux/actions/profile-actions";
import { Hashtag, Interaction, Profile, ProfileFeed, Question, UserFeed } from "./models";
import store from "./redux/store/index"
import { SystemId } from "./constants/profile";

export function configureSubscriptions(userId) {

  DataStore.observe(Profile, (p) =>
    p.or((p) => [p.profileId.eq(SystemId), p.profileId.eq(userId)])
  ).subscribe((msg) => {
    console.log("Profile subscription received", msg.opType, msg.element.profileId);
    store.dispatch(profileSubscriptionReceived({ profile: msg.element }));
  });

  DataStore.observe(UserFeed).subscribe((msg) => {
    console.log("userFeed subscription received");
    store.dispatch(processUserFeedSubscription({ userFeed: msg.element }));
  });

  DataStore.observe(Question).subscribe((msg) => {
    console.log("question subscription received");
    store.dispatch(processQuestionSubscription({ question: msg.element, profileId:userId }));
  });


  DataStore.observe(Interaction).subscribe((msg) => {
    console.log("Interaction subscription received", msg.opType);
  });

  DataStore.observe(ProfileFeed).subscribe((msg) => {
    console.log("ProfileFeed subscription received " + msg.opType);
    store.dispatch(profileFeedSubscriptionReceived({ profileFeed: msg.element }));
  });

  DataStore.observe(Hashtag).subscribe((msg) => {
    console.log("Hashtag update received " + msg.opType);
    //store.dispatch(hashtagsReceived({ hashTag: msg.element }));
  });
}

export function configureDatastore(userId) {
  // DataStore.configure({
  //   syncExpressions: [
  //     syncExpression(ProfileFeed, (item) => item.profileId.eq(userId)),
  //     syncExpression(Profile),
  //     syncExpression(Question),
  //     syncExpression(Hashtag, (item) => item.tagId.eq(userId)),
  //     syncExpression(UserFeed, (item) => item.profileId.eq(userId)),
  //     syncExpression(Response, (item) => item.profileId.eq(userId)),
  //     syncExpression(Interaction, (item) => item.profileId.eq(userId)),
  //   ],
  // });
}