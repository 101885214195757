import React from "react";

export default function ActivityBadge({ icon, count }) {
    let color = count >= 30 ? " fas brand1 fa-sm" :
        count >= 20 ? " fas brand1 fa-sm" :
            count >= 10 ? " fas brand1 fa-sm" : count > 5 ? " fas brand1 fa-sm" : "fas brand1 fa-sm";

    if (count > 0)
        return (
            <span className="px-1"><i className={icon + color} /></span>
        )
    else
        return (
            <span className="px-1"><i className={icon + "brand3  fa-sm"} /></span>
        )

}