import React, { useEffect } from "react";
import { connect } from "react-redux";
import { SystemId, ViewType } from "../constants/profile";
import { Feeds} from "../constants/feedids";
import {  setView } from "../redux/actions/context-actions";


const mapStateToProps = (state) => {
  return { 
          profileId:state.profile.currentUser.profileId
          };
};


const MainView = React.memo(function  MainView ({feedId, profileId, dispatch}) {

    const feedToLoad=feedId?feedId: Feeds.AllQuestions;
    const viewToLoad = ViewType.MainView;

    useEffect(() => {
      dispatch(setView({viewType:viewToLoad, profileId:SystemId, feedId:feedToLoad}));
    }); 

     return (
        <></>
      )
     }
)

export default connect(mapStateToProps, null)(MainView);