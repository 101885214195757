import React from "react"
import "../../../index";
import { LinearProgress, Typography } from "@mui/joy";

export const LyqnessProgress = React.memo(function LyqnessProgress({ value, children }) {
    return (
            <div style={{width:"100px"}}>
            <LinearProgress
                determinate
                color="warning"
                size="sm"
                className=" similarity similarity-bg mx-auto px-0 smallfont"
                thickness={20}
                value={Number(value * 100)}
                sx={{
                    '--LinearProgress-radius': '10px',
                    '--LinearProgress-thickness': '21px',
                }}
            >
                <Typography
                    level="body-xs"
                    fontWeight='sm'
                    sx={{ mixBlendMode:"hard-light" }}
                >
                    <span className="white-text">{children}</span>
                </Typography>
            </LinearProgress>
            </div>
    )
})

