import React from "react";
import "./index.css"
import { connect } from "react-redux";
import PublicQuestionCard from "../../publicviews/PublicQuestionCard";
import { hideModal } from "../../redux/actions/modal-actions";
import { MyURLs } from "../../constants/url";

const mapStateToProps = state => {
    return {
        isOpen: state.modal.modalType === "PREVIEW_MODAL",
        questionId: state.modal.questionId,
        publicQuestion: state.question.publicQuestion,
        viewProfileId: state.profile.viewProfile.profileId
    };
};

const PreviewModal = React.memo(function PreviewModal({ publicQuestion, isOpen, dispatch }) {
    var viewUrl;
    if (publicQuestion)
        viewUrl = MyURLs.QUESTION_PREVIEW + "/f/" + publicQuestion.questionId + publicQuestion.questionId;

    return (
        <div className="modal-overlay ">
            <div className="mymodal" isOpen={true} toggle={() => dispatch(hideModal())}>
                <div className="row brand1-bg no-gutters p-3" toggle={() => dispatch(hideModal())}>
                    <div className="col-11 h4 brand1">
                        Public Question Preview
                    </div>
                    <div className=" col-1 h4 text-right pointer " onClick={() => dispatch(hideModal())} ><i className="far fa-times"></i></div>
                </div>
                <div className="container py-3 brand1 modal-body">
                    <PublicQuestionCard question={publicQuestion} />
                </div>
                <div className="p-3 container text-center">
                    <small> {viewUrl}</small>
                </div>
            </div>
        </div>
    )
}
)

export default connect(mapStateToProps, null)(PreviewModal);

