import { Drawer, useMediaQuery } from '@mui/material';
import React from 'react';
import { connect } from "react-redux";
import PanelSelector from './PanelSelector';
import { setPanelOpen } from '../../redux/actions/panel-actions';

const mapStateToProps = (state) => {
    return {
      isOpen:state.context.panel.isOpen,
      panel:state.context.panel.panelType
    };
  };

const MobilePanelDisplay = React.memo(function MobilePanelDisplay({isOpen, panel, children,dispatch}){

  const handleDrawerToggle = () => {
    dispatch(setPanelOpen(!isOpen));
  };

  const smallSize = useMediaQuery('(max-width: 430px)');

  return (
    <React.Fragment>
        <Drawer 
          open={isOpen} 
          onBlur={()=>handleDrawerToggle}
          onClose={handleDrawerToggle} 
          PaperProps={smallSize? {
            sx: {
              width: "100%",
          }
        } :
        {
        sx: {
          width: 400,
      }
      }}
          transitionDuration={{enter:100, exit:100}}
          anchor='right'
          variant="temporary" ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}>
               < PanelSelector/>           
        </Drawer>
    </React.Fragment>
  );
})

export default connect(mapStateToProps, null)(MobilePanelDisplay);