import { Feeds, PublicFeeds, UserFeeds, WhoFeeds } from "../constants/feedids"
import { Icons } from "./QIcons";

export const titles = {
    LDRFT: "Draft Questions",
    LTAGQ: "You Bookmarked ",
    LUSRT: "You Tagged",
    LBORQ: "You Borrowed ",
    LCHAQ: "You Chatted ",
    LCREQ: "You Asked ",
    LFEAQ: "You Featured ",
    LFLAQ: "You Flagged ",
    LLIKQ: "You Liked ",
    LLINQ: "You Asked Related ",
    LMODQ: "You Moderated ",
    LRESQ: "You Answered ",
    LSHAQ: "You Shared ",
    LSPOQ: "You Sponsored ",
    LHCRQ: "You Created Private",
    LHLKQ: "You Liked Private",
    LHLNQ: "You Linked Private",
    LHREQ: "You Responded Private",
    LALLQ: "Your Feed",
    LSTEA: "You Stealthed ",
    LCHAU: "You Chatted ",
    LFEAU: "You Featured ",
    LFLAU: "You Flagged ",
    LFOLU: "You Followed ",
    LLIKU: "You Liked ",
    LMODU: "You Moderated ",
    LSHAU: "You Shared ",
    LSPOU: "You Sponsored ",
    LCHAC: "You Chatted ",
    LCREC: "You Created ",
    LFEAC: "You Featured ",
    LFLAC: "You Flagged ",
    LFOLC: "You Followed ",
    LLIKC: "You Liked ",
    LMODC: "You Moderated ",
    LSHAC: "You Shared ",
    LSPOC: "You Sponsored ",
    LCONV: "Conversations",
    LCREA: "Your Advertisements",
    LCREI: "Your Incentives",
    ATAGQ: " ",
    ABORQ: "Borrowed ",
    ACHAQ: "Chatted ",
    ACREQ: "Asked ",
    AFEAQ: "Featured ",
    AFLAQ: "Flagged ",
    ALIKQ: "Liked ",
    ALINQ: "Related ",
    AMODQ: "Moderated ",
    ARESQ: "",
    ASHAQ: "Shared ",
    ASPOQ: "Sponsored ",
    ACHAU: "Chatted ",
    AFEAU: "Featured ",
    AFLAU: "Flagged ",
    AFOLU: "Following ",
    AHCRQ: "Created Private",
    AHLNQ: "Linked Private",
    AHREQ: "Responded Private",
    AHLKQ: "Liked Private",
    ALIKU: "Liked ",
    AMODU: "Moderated ",
    ASHAU: "Shared ",
    ASPOU: "Sponsored ",
    AALLU: "Users",
    ACHAC: "Chatted ",
    ACREC: "Created ",
    AFEAC: "Featured ",
    AFLAC: "Flagged ",
    AFOLC: "Following ",
    ALIKC: "Liked ",
    AMODC: "Moderated ",
    ASHAC: "Shared ",
    ASPOC: "Sponsored ",

    PUSRT: "Tags",
    PBORQ: "Borrowed Questions",
    PCHAQ: "Chatted Questions",
    PCREQ: "Asked",
    PFEAQ: "Featured Questions",
    PFLAQ: "Flagged Questions",
    PLIKQ: "Favorites",
    XLINQ: "Related Questions",
    PMODQ: "Moderated Questions",
    PRESQ: "Answered",
    PSHAQ: "Shared Questions",
    PSPOQ: "Sponsored Questions",
    PCHAU: "Chatted Users",
    PFEAU: "Featured Users",
    PFLAU: "Flagged Users",
    PFOLU: "Followed Users",
    PLIKU: "Favorite Users",
    PMODU: "Moderated Users",
    PSHAU: "Shared Users",
    PSPOU: "Sponsored Users",
    PTAQT: "tagged",
    ZHSHT: "Hashtags",
    ZLINQ: "Previous Question",
    RLINQ: "Related Questions",
    FVIEW: "View Question",
    FORDR: "",
    ZALLQ: "Questions",
    ZALLU: "Users",
    PALLQ: "Feed"
}

let owners = [PublicFeeds.UserTags, PublicFeeds.LikedQuestions, PublicFeeds.UserFeedQuestions];


const tags = [UserFeeds.UserTags,PublicFeeds.UserTags,  PublicFeeds.TaggedQuestions, UserFeeds.TaggedQuestions];
const hashtagQuestions = [UserFeeds.UserHashtags, PublicFeeds.TaggedQuestions,Feeds.TaggedQuestions,Feeds.AllHashtags,PublicFeeds.UserHashtags,UserFeeds.UserHashtags];
const feedQuestions = [UserFeeds.UserFeedQuestions,  PublicFeeds.UserFeedQuestions, Feeds.FeedQuestions, Feeds.AllQuestions];
const answeredQuestions = [UserFeeds.RespondedQuestions, WhoFeeds.RespondedQuestions, PublicFeeds.RespondedQuestions];
const likedQuestions = [UserFeeds.LikedQuestions, WhoFeeds.LikedQuestions,PublicFeeds.LikedQuestions];
const chattedQuestions = [UserFeeds.ChattedQuestions,WhoFeeds.ChattedQuestions, PublicFeeds.ChattedQuestions,Feeds.ChildQuestions, UserFeeds.Conversations];
const linkedQuestions = [UserFeeds.LinkedQuestions];
const askedQuestions = [UserFeeds.CreatedQuestions, PublicFeeds.CreatedQuestions]
const singular = [PublicFeeds.CreatedQuestions,PublicFeeds.RespondedQuestions,PublicFeeds.TaggedQuestions,PublicFeeds.LinkedQuestions];

export const tagIcon=(feedId)=>{
    if(feedId === UserFeeds.Advertisements) return <i className={Icons.AD + "fa-sm"} ></i>
    if(feedId === UserFeeds.Incentives) return <i className={Icons.AWARD + "fa-sm"} ></i> 
    if(feedId === Feeds.ChoiceUsers) return "User who chose..."
    if(feedId===WhoFeeds.QuestionsTagged) return <i className={Icons.TAG } ></i>
    if(tags.includes(feedId)) return <i className={Icons.TAG + " fa-sm"} ></i>
    if(feedQuestions.includes(feedId)) return <i className={Icons.FEED + ""} ></i>
    if(askedQuestions.includes(feedId)) return <i className={Icons.QUESTIONS + ""}></i>
    if(answeredQuestions.includes(feedId)) return <i className={Icons.RESPOND + ""} ></i>
    if(linkedQuestions.includes(feedId)) return <i className={Icons.LINK + ""} ></i>    
    if(likedQuestions.includes(feedId)) return <i className={Icons.FAVORITE + ""} ></i>
    if(chattedQuestions.includes(feedId)) return <i className={Icons.CHAT + ""} ></i>  
    if(hashtagQuestions.includes(feedId)) return <i className={Icons.HASHTAG + ""} ></i>  
    if(Feeds.AllUsers) return <i className={Icons.USERS + ""} ></i>      
}



export function title(feedId, name, hashtag) {
    switch (feedId[0]) {
        case "A":
            return <span> {titles[feedId]}</span>;
        case "P":
        case "X":
            if(tags.includes(feedId))
                return <span>{name} {tagIcon(feedId)}: {hashtag && hashtag }</span>
            else
                return <span> {name}{singular.includes(feedId)?"":"'s"} {titles[feedId]}</span>
        case "L":
            if (feedId === UserFeeds.DraftQuestions)
                return <span>{titles[feedId]}</span>
            else
            if (feedId === UserFeeds.TaggedQuestions)
                return <span>Tagged: {hashtag && hashtag }</span>
            else
                return <span> {titles[feedId]}</span>;
        case "Z":
            if (feedId === Feeds.TaggedQuestions)
                return <span> {hashtag}</span>
            else
                return <span> {titles[feedId]}</span>;
        default:
            if (owners.includes(feedId)){
                return <span>`${name}'s ${titles[feedId]}`{tagIcon(feedId)}</span>
            }
            else
            {
                return <span> {titles[feedId]}</span>
            }
    }
}