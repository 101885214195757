import { gqlChoices, gqlCreator, gqlFeedStats, gqlProfile, gqlProfileStats, gqlQuestion, gqlTallyStats, gqlUserFeed } from "./gqlConstants";

export const questionFields = `{
  items {
    ${gqlQuestion}
  }
  nextToken
  startedAt
}`;

export const profileFields = `
avatar
avatarKey
avatarURL
category
conversationId
createdAt
creator {
  avatar
  avatarKey
  avatarURL
  category
  conversationId
  createdAt
  creatorId
  description
  email
  firstName
  follower
  identityId
  lastLogon
  lastName
  photo
  photoKey
  photoURL
  profileId
  profileURL
  showTooltips
  targetId
  title
  updatedAt
  userFeedQuestionId
  userName
  _version
  _deleted
  _lastChangedAt
  __typename
}
creatorId
description
email
firstName
follower
identityId
lastLogon
lastName
photo
photoKey
photoURL
profileFeed {
  profileId
  associatedId
  lastViewed
  category
  userFollows
  isOwner
  targetId
  lastMessageTime
  updatedAt
  stats { ${gqlProfileStats}}
  createdAt
  _version
  _deleted
  _lastChangedAt
  __typename
}
profileId
profileURL
questions {
  nextToken
  startedAt
  __typename
}
showTooltips
stats {
  Question {${gqlFeedStats}}
  Profile {${gqlProfileStats}}
  Tally {${gqlTallyStats}}
}
myProfileFeed {
  profileId
  associatedId
  lastViewed
  category
  userFollows
  isOwner
  targetId
  lastMessageTime
  stats { ${gqlProfileStats}}
  updatedAt
  createdAt
  _version
  _deleted
  _lastChangedAt
  __typename
}
targetId
title
updatedAt
userFeed {
  category
  
  createdAt
  publicTime
  feedTime
  updatedAt
  profileId
  questionId
  tags
  selections
  values
  hidden
  lastMessageTime
  stats {${gqlFeedStats}}
  stealthTime
  toggledStealth
  targetId
  isOwner
  weight
  _version
  _deleted
  _lastChangedAt
  __typename
}
userFeedQuestionId
lastFeedView
userName
_version
_deleted
_lastChangedAt
__typename
`;

export const listTagsByProfile = /* GraphQL */ `
  query ListTagsByProfile($profileId: ID, $limit: Int, $nextToken: String) {
    listTagsByProfile(
      profileId: $profileId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tag
        count
      }
      nextToken
    }
  }
`;

export const listQuestionsByTag = /* GraphQL */ `
  query ListQuestionsByTag(
    $targetId: ID
    $tag: String!
    $limit: Int
    $nextToken: String
  ) {
    listQuestionsByTag(
      targetId: $targetId
      tag: $tag
      limit: $limit
      nextToken: $nextToken
    )
     ${questionFields}
  }
`;

export const listQuestionsByInteraction = /* GraphQL */ `
  query ListQuestionsByInteraction(
    $targetId: ID!
    $feedId: FeedIds!
    $limit: Int
    $nextToken: String
  ) {
    listQuestionsByInteraction(
      targetId: $targetId
      feedId: $feedId
      limit: $limit
      nextToken: $nextToken
    ) 
    ${questionFields}
  }
`;

export const listPublicFeed = /* GraphQL */ `
  query ListPublicFeed($targetId: ID!, $nextToken: String, $limit: Int) {
    listPublicFeed(targetId: $targetId, nextToken: $nextToken, limit: $limit) 
      ${questionFields}
  }
`;

export const listUserFeed = /* GraphQL */ `
  query ListUserFeed( $nextToken: String, $limit: Int) {
    listUserFeed( nextToken: $nextToken, limit: $limit) 
      ${questionFields}
  }
`;

export const listProfilesByInteraction = /* GraphQL */ `
  query ListProfilesByInteraction(
    $targetId: ID!
    $feedId: FeedIds!
    $limit: Int
    $nextToken: String
  ) {
    listProfilesByInteraction(
      targetId: $targetId
      feedId: $feedId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${profileFields}
     }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const listProfilesByChoiceOrder = /* GraphQL */ `
  query ListProfilesByChoiceOrder(
    $targetId: ID!
    $choiceOrderStr: String!
    $limit: Int
    $nextToken: String
  ) {
    listProfilesByChoiceOrder(
      targetId: $targetId
      choiceOrderStr: $choiceOrderStr
      limit: $limit
      nextToken: $nextToken
    ){
        items {
          ${profileFields}
        }
      }
  }
`;

export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $profileId: ID
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProfiles(
      profileId: $profileId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ${gqlProfile}
    }
    }
  }
`;

export const getProfile = /* GraphQL */ `
  query GetProfile($profileId: ID!) {
    getProfile(profileId: $profileId) {
      ${gqlProfile}
    }
  }
`;

export const syncQuestions = /* GraphQL */ `
  query SyncQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        audience
        buckets
        choices {
          ${gqlChoices}
        }
        choiceHeadings
        category
        conversationId
        creatorId
        draft
        endTime
        howLong
        howOften
        linkSelections
        incentive{
          count
          frequency
          imageKey
          description
          selections
        }
        questionId
        tags
        imageKey
        imageURL
        landscapeImages
        choiceStats {
          count
        }
        key
        stats {
          CHAQ
            CREA
            CREI
            CREQ
            HCRQ
            HLNQ
            HREQ
            HLKQ
            FEAQ
            FLAQ
            INCA
            INCQ
            INCR
            LIKQ
            LINQ
            MODQ
            RESQ
            SHAQ
            SPOQ
            STEA
            TAGQ
        }
        isModerated
        isMultipleChoice
        isOrdered
        isPremium
        isPrivate
        isNew
        targetId
        minRange1
        minRange2
        maxRange1
        maxRange2
        maxSel
        minSel
        numericType
        parentId
        questionType
        questionText
        qmosr
        startTime
        status
        stealthTime
        updatedAt
        updateDate
        updateTime
        weight
        createdAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const getSharedQuestion = /* GraphQL */ `
  query GetSharedQuestion($questionId: ID) {
    getSharedQuestion(questionId: $questionId) {
      audience
      buckets
      choices {
        ${gqlChoices}
      }
      choiceHeadings
      category
      conversationId
      creatorId
      endTime
      howLong
      howOften
      linkSelections
      incentive{
        count
        frequency
        imageKey
        description
        selections
      }
      questionId
      imageURL
      choiceStats {
        count
        __typename
      }
      key
      stats {
        CHAQ
            CREA
            CREI
            CREQ
            HCRQ
            HLNQ
            HREQ
            HLKQ
            FEAQ
            FLAQ
            INCA
            INCQ
            INCR
            LIKQ
            LINQ
            MODQ
            RESQ
            SHAQ
            SPOQ
            STEA
            TAGQ
        __typename
      }
      tags
      landscapeImages
      isModerated
      isMultipleChoice
      isOrdered
      isPremium
      isPrivate
      draft
      isNew
      targetId
      minRange1
      minRange2
      maxRange1
      maxRange2
      maxSel
      minSel
      numericType
      parentId
      questionType
      questionText
      qmosr
      startTime
      status
      stealthTime
      updatedAt
      updateDate
      updateTime
      userFeed {
        category
        createdAt
        
        publicTime
        profileId
        questionId
        tags
        selections
        hidden
        incentive{
            achievedDate
            redeemedDate
            redemptionCode
            posCode
          }
        lastMessageTime
        stealthTime
        toggledStealth
        targetId
        isOwner
        updatedAt
        weight
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      viewUserFeed {
        category
        createdAt
        
        publicTime
        profileId
        questionId
        tags
        selections
        lastMessageTime
        stealthTime
        toggledStealth
        targetId
        isOwner
        updatedAt
        weight
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      viewProfileId
      weight
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;

export const listQuestionsByParent = /* GraphQL */ `
  query ListQuestionsByParent(
    $parentId: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionsByParent(
      parentId: $parentId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) 
    ${questionFields}
  }
`;

export const getQuestion = /* GraphQL */ `
  query GetQuestion($questionId: ID!) {
    getQuestion(questionId: $questionId) {
      audience
      buckets
      choices {
        ${gqlChoices}
      }
      choiceHeadings
      category
      conversationId
      creatorId
      profileStub {${gqlCreator}}
        questions {
          nextToken
          startedAt
        }
        profileId
        userFeedQuestionId
        userFeed {
          ${gqlUserFeed}
        }
        title
        updatedAt
        userName
        _version
        _deleted
        _lastChangedAt
      }
      endTime
      howLong
      howOften
      questionId
      imageKey
      imageURL
      choiceStats {
        count
      }
      key
      stats {
        ${gqlFeedStats}
      }
      tags
      linkSelections
      incentive {
        count
        frequency
        imageKey
        description
        selections
      }
      landscapeImages
      isModerated
      isMultipleChoice
      isOrdered
      isPremium
      isPrivate
      draft
      isNew
      targetId
      minRange1
      minRange2
      maxRange1
      maxRange2
      maxSel
      minSel
      numericType
      parentId
      questionType
      questionText
      qmosr
      startTime
      status
      stealthTime
      updatedAt
      updateDate
      updateTime
      userFeed {
        ${gqlUserFeed}
        }
        hidden
        lastMessageTime
        stealthTime
        incentive {
          achievedDate
          redeemedDate
          redemptionCode
          posCode
        }
        toggledStealth
        targetId
        isOwner
        updatedAt
        weight
        _version
        _deleted
        _lastChangedAt
      }
      viewUserFeed {
        category
        createdAt
        
        publicTime
        profileId
        questionId
        tags
        selections
        stats {
          CHAQ
          CREA
          CREI
          CREQ
          HCRQ
          HLNQ
          HREQ
          HLKQ
          FEAQ
          FLAQ
          INCA
          INCQ
          INCR
          LIKQ
          LINQ
          MODQ
          RESQ
          SHAQ
          SPOQ
          STEA
          TAGQ
        }
        hidden
        lastMessageTime
        stealthTime
        incentive {
          achievedDate
          redeemedDate
          redemptionCode
          posCode
        }
        toggledStealth
        targetId
        isOwner
        updatedAt
        weight
        _version
        _deleted
        _lastChangedAt
      }
      viewProfileId
      weight
      createdAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const syncProfiles = /* GraphQL */ `
  query SyncProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        ${profileFields}
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

