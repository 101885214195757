import React, { useEffect } from "react";
import { connect } from "react-redux";
import "../index.css";
import { onError } from "../../shared/errorLib";
import MessageFeedDisplay from "./feedDisplays/MessageFeedDisplay";
import ChatHeader from "../chat/ChatHeader";
import ChatFooter from "../chat/ChatFooter";
import { clearMessages, getConversationMessages, updateLastConversationViewTime } from "../../redux/actions/chat-actions";
import { Hidden } from "@mui/material";
import Panel from "../panel/Panel";


const mapStateToProps = state => {
    return {
        feed: state.chat.messages,
        panel: state.context.panel,
        subscription: state.chat.subscription,
        firstName: state.profile.viewProfile.firstName,
    };
};

const ChatFeed = React.memo(function ChatFeed({ feed, panel, dispatch }) {
    const { items, isLoading, conversationId: currentConversationId, isLoaded, nextToken } = feed;
    const { conversationId, conversation } = panel;
    useEffect(() => {
        async function onLoad() {
            try {
                dispatch(updateLastConversationViewTime({ conversationId: conversationId }));
                if (currentConversationId !== conversationId && currentConversationId !== null)
                    dispatch(clearMessages());
                if ((!isLoading && conversation !== null && !isLoaded) || (currentConversationId !== conversationId && conversation !== null))
                    loadNextPage();
                //  dispatch(getConversationMessages({ conversationId: conversationId, nextToken: nextToken }));
            }
            catch (e) {
                if (e !== 'No current user') {
                    onError(e);
                }
            }
        };

        onLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversationId]);

    useEffect(() => {
        return () => {
            //dispatch(updateLastConversationViewTime({ conversationId: conversationId }));
            //dispatch(clearMessages())
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    function loadNextPage() {
        dispatch(getConversationMessages({ conversationId: conversationId, nextToken: nextToken }));
    }

    // const title=()=>{
    //     return (titles[feedId])
    // }

    return (
        <React.Fragment>
            <Hidden mdDown>
                <div className="col px-0 py-0 h-100 ">
                    <Panel
                        title={<ChatHeader />}
                        body={
                            <MessageFeedDisplay
                                isLoading={isLoading}
                                offset={145}
                                hasMore={nextToken !== null}
                                items={items}
                                key={"messages" + conversationId}
                                loadNextPage={loadNextPage}
                                noLoadStr="no messages"
                            />
                        }
                    />

                    <div className="row px-2 my-0 py-1">
                        <ChatFooter />
                    </div>
                </div>
            </Hidden>
            <Hidden lgUp>
                <div className={"p-0 panel "}>
                    <Panel
                        title={<ChatHeader />}
                        body={
                            <MessageFeedDisplay
                                isLoading={isLoading}
                                offset={145}
                                hasMore={nextToken !== null}
                                items={items}
                                key={"messages" + conversationId}
                                loadNextPage={loadNextPage}
                                noLoadStr="no messages"
                            />
                        }
                    />
                    <div className="row px-2 my-0 py-1">
                        <ChatFooter />
                    </div>
                </div>
            </Hidden>
        </React.Fragment>
    );
})

export default connect(mapStateToProps, null)(ChatFeed);