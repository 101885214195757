import { Analytics, Auth, DataStore, Hub } from "aws-amplify";
import { CONNECTION_STATE_CHANGE } from '@aws-amplify/pubsub';
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import AuthNav from "./components/navbar/AuthNav";
import "./App.css";
import "./index.css";
import ErrorBoundary from "./components/ErrorBoundary";
import { connect } from "react-redux";
import {
  getCurrentUserProfile,
  setUserGroups,
} from "./redux/actions/profile-actions";
import { onError } from "./shared/errorLib";

import global, { lgScreenSize, tabletSize } from "./constants";
import ActionViewSelector from "./components/actionviews/ActionViewSelector";
import MobilePanelDisplay from "./components/panel/MobilePanelDisplay";
import PanelSelector from "./components/panel/PanelSelector";
import MobileViewSelector from "./components/actionviews/MobileViewSelector";
import BasicSpeedDial from "./components/speeddial/SpeedDial";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ModalRoot from "./components/modal/ModalRoot";
import MainViewSelector from "./components/actionviews/MainViewSelector";
import NotificationSupportChecker from "./NotificationSupportChecker";
import React, { useEffect } from 'react';
import { configureDatastore, configureSubscriptions } from "./dataStoreConfig";
import Notification from "./firebaseNotifications/Notification";
import { setScreenSize } from "./redux/actions/context-actions";
import { decode, encode } from "js-base64";


const mapStateToProps = (state) => {
  return {
    userProfileIsLoaded: state.profile.userProfileIsLoaded,
    viewProfileId: state.context.view.profileId,
    authenticated: state.profile.authenticated,
    userProfile: state.profile.currentUser,
    screenWidth: state.context.screenWidth,
    userMessageSubscription: state.chat.userMessageSubscription,
  };
};

function App({
  user,
  screenWidth,
  userProfile,
  authenticated,
  user: {
    attributes: { given_name: firstName, family_name: lastName, sub: userId },
  },
  dispatch,
}) {

  const initials = firstName[0] + lastName[0];
  let onReady = Promise.resolve();

  async function listener(data) {
    const { event } = data.payload;
    switch (event) {
      case CONNECTION_STATE_CHANGE:
        console.log("connection changed");
        break;
      case "oAuthSignOut":
      case "signOut":
        // await DataStore.clear();
        // await DataStore.stop();
        // await Auth.signOut();
        break;
      case "signIn":
        await onReady;
        await DataStore.start();
        const credentials = await Auth.currentUserCredentials();
        global.userId = userId;
        dispatch(
          getCurrentUserProfile({
            attributes: user.attributes,
            identityId: credentials.identityId,
          })
        );
        break;
      default:
        break;
    }
  }

  document.addEventListener("DOMContentLoaded", function () {
    document.addEventListener('hide.bs.modal', function (event) {
        if (document.activeElement) {
            document.activeElement.blur();
        }
    });
});


  function getScreenSize() {
    return { width: window.innerWidth, height: window.innerHeight };
  }

  useEffect(() => {
    const screenSize = getScreenSize();
    dispatch(setScreenSize({ screenSize }))

    const handleResize = () => {
      const screenSize = getScreenSize();
      dispatch(setScreenSize({ screenSize }))
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line 
  }, []);


  useEffect(() => {
    async function onLoad() {
      await DataStore.clear();
      window.scrollTo(0, 1);
      Hub.listen("auth", listener);
      Hub.listen("datastore", listener);
      try {
        let auth = await Auth.currentSession();

        let userId = auth.idToken.payload.sub;
        global.userId = userId;
        configureSubscriptions(userId);
        configureDatastore(userId);
        const credentials = await Auth.currentUserCredentials();
        const groups = auth.idToken.payload["cognito:groups"] ?? [];
        dispatch(setUserGroups({ groups: groups }));
        dispatch(
          getCurrentUserProfile({
            attributes: user.attributes,
            identityId: credentials.identityId,
          })
        );
      } catch (e) {
        if (e !== "No current user") {
          onError(e);
        }
      }
    }

    onLoad()
      //   .then(response => userHasAuthenticated(true))
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { email, profileId } = userProfile;
    return () => {
      Analytics.startSession();
      Analytics.autoTrack('pageView', {
        enable: true,
        eventName: 'pageView',
        attributes: {
          profiledId: profileId,
          email: email
        },
        type: 'spa',
        provider: 'AWSPinpoint',
        getUrl: () => {
          return window.location.origin + window.location.pathname;
        }
      });;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => Hub.remove("auth", listener),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (authenticated && userProfile)
    return (
      <div className="flyout p-0 m-0 ">
        <NotificationSupportChecker profileId={userId} />
        <Notification />
        <AuthNav firstName={firstName} initials={initials} />
        <ModalRoot />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ErrorBoundary>
            <BasicSpeedDial />
            <div className="row mt-0 pt-0  ">
              <div className="col-xl-11 col-lg-11 mt-0 pt-0">
                <div className="row justify-content-center g-0">
                  {screenWidth >= tabletSize ?
                    <div className="col">
                      <div className="p-0 px-1">
                        <div className="drawerCard float-end">
                          <ActionViewSelector />
                        </div>
                      </div>
                    </div>
                    :

                   <MobileViewSelector />
                  }

                  <div className="col" >
                    <MainViewSelector />
                  </div>
                  {screenWidth < lgScreenSize ?
                    <div className={"col rightpanel px-0 py-0 h-100 w-100"}>
                      <div className="row g-0 w-100">
                        <div className="col-12">
                          <MobilePanelDisplay />
                        </div>
                      </div>
                    </div>
                    :
                    <div className={"col panelcard px-1 py-0 h-100 w-100"}>
                      <PanelSelector />
                    </div>
                  }
                </div>
              </div>
            </div>
          </ErrorBoundary>
        </LocalizationProvider>
      </div>
    );
  else
    return (
      <div>
        <i className="fas fa-spinner fa-spin fa-lg"></i> loading
      </div>
    );
}

export default connect(mapStateToProps, null)(withAuthenticator(App));
