import { QuestionActions as Actions} from "../constants";

export function addQuestion(payload) {
  return { type: Actions.QUESTION_ADD_REQUESTED,payload };
}

export function deleteQuestion(payload) {
  return { type: Actions.QUESTION_DELETE_REQUESTED,payload };
}

export function shareQuestion(payload) {
  return { type: Actions.QUESTION_SHARE_REQUESTED,payload };
}

export function removeFromFeed(payload) {
  return { type: Actions.QUESTION_REMOVE_REQUESTED,payload };
}

export function updateQuestion(payload) {
  return { type: Actions.QUESTION_UPDATE_REQUESTED,payload };
}

export function toggleQuestionInteraction(payload) {
  return { type: Actions.QUESTION_INTERACTION_REQUESTED,payload };
}

export function updateUserTags(payload) {
    return { type: Actions.UPDATE_USER_TAGS_REQUESTED,payload };
  }

export function respondToQuestion(payload) {
  return { type: Actions.SUBMIT_RESPONSE_REQUESTED,payload };
}

export function getPublicQuestion(payload) {
  return { type: Actions.PUBLIC_QUESTION_REQUESTED, payload };
}