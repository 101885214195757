import { ProfileActions as Actions} from "../constants";


export function getCurrentUserProfile(payload) {
  return { type: Actions.CURRENT_USER_PROFILE_REQUESTED, payload };
}

export function setCurrentProfileView(payload) {
    return { type: Actions.SET_CURRENT_PROFILE_VIEW, payload };
  }

  export function getViewProfile(payload) {
    return { type: Actions.VIEW_PROFILE_REQUESTED, payload };
  }

export function getProfileFeed(payload) {
  return { type: Actions.PROFILE_FEED_DATA_REQUESTED, payload };
}


export function hashtagsReceived(payload) {
  return { type: Actions.HASHTAGS_RECEIVED, payload };
}

export function getProfileTags(payload) {
  return { type: Actions.PROFILE_TAGS_REQUESTED, payload };
}

export function removeProfileFeedData(payload) {
  return { type: Actions.REMOVE_PROFILE_FEED_DATA_REQUESTED, payload };
}

export function setCurrentProfileToView(payload) {
  return { type: Actions.PROFILE_SET_CURRENT_TO_VIEW, payload };
}

export function clearProfileFeed(payload) {
    return { type: Actions.CLEAR_PROFILE_FEED_REQUESTED, payload };
  }

export function setUserGroups(payload) {
  return { type: Actions.SET_USER_GROUPS, payload };
}

export function toggleProfileInteraction(payload) {
  return { type: Actions.PROFILE_INTERACTION_REQUESTED,payload };
}
export function getUserProfile(payload) {
  return { type:Actions.USER_PROFILE_REQUESTED,payload};
}
export function updateProfileData(payload) {
  return { type:Actions.PROFILE_UPDATE_REQUESTED,payload};
}

export function getAssociatedUserFeed(payload) {
  return { type: Actions.ASSOCIATED_USER_FEED_REQUESTED, payload };
}

export function profileSubscriptionReceived(payload) {
    return { type:Actions.PROFILE_SUBSCRIPTION_RECEIVED,payload};
  }

  export function viewProfileReceived(payload) {
    return { type:Actions.VIEW_PROFILE_RECEIVED,payload};
  }

  export function resetViewProfile(){
    return { type:Actions.RESET_VIEW_PROFILE};
  }

  export function retrieveUserList() {
    return { type:Actions.RETRIEVE_USERLIST};
  }  

