import keymirror from "keymirror";

export const ModalTypes = keymirror({
    PROFILE_LIST_MODAL:null,
    QUESTION_SHARE_MODAL:null,
    QUESTION_MODAL:null,    
    PROFILE_EDIT_MODAL:null,
    PREVIEW_MODAL:null,
    LOGIN_MODAL:null,
    STEALTH_MSG_MODAL:null,
    CONFIRM_LINK_MODAL:null,
    CONFIRM_MODAL:null,
    MSG_MODAL:null,
    TAG_MODAL:null,
    TAG_LINK_MODAL:null,
    QR_MODAL:null,
    USER_PROFILE_MODAL:null,
    TEST_MODAL:null,
    HASHTAG_MODAL:null
});

export const ModalActions = keymirror({
    HIDE_MODAL:null,
    SHOW_MODAL:null,
    TOGGLE_AUTH_MODAL:null
});
