import React from "react";
import "../timeline/index.css";
import "../../index.css";
import { connect } from "react-redux";
import { DateShort } from "../../shared/Dates";
import ActivityBadge from "../activity/ActivityBadge";
import { Icons } from "../../shared/QIcons";
import ProfileHeader from "./ProfileHeader";
import FollowLink from "../links/FollowLink";

const mapStateToProps = state => {

    return {
        updatingComments: state.profile.updatingComments,
        choices: state.context.panel.choices,
        userProfileId: state.profile.currentUser.profileId
    };
};

function UserBubble({ displayProfile, userProfileId }) {
    const { identityId, createdAt, profileId, myProfileFeed, stats } = displayProfile;
    const following = myProfileFeed  && myProfileFeed.stats.FOLU;
    return (
        <div key={profileId} className="mx-1 white profileCard ">
            <ProfileHeader key={identityId}
                profileStub={displayProfile}
                topRight={<DateShort date={createdAt} />}
                bottomLeft={following?<FollowLink key={profileId} displayProfile={displayProfile} profileId={profileId} following={following}></FollowLink>: null}
            />
            <div className="text-right py-1 pr-1">
                <ActivityBadge icon={Icons.QUESTIONS} count={stats.Question.CREQ} />
                <ActivityBadge icon={Icons.RESPOND} count={stats.Question.RESQ} />
                <ActivityBadge icon={Icons.FAVORITE} count={stats.Question.LIKQ} />
                <ActivityBadge icon={Icons.TAG} count={stats.Question.TAGQ} />
                <ActivityBadge icon={Icons.SHARE} count={stats.Question.SHAQ} />
                <ActivityBadge icon={Icons.LINK} count={stats.Question.LINQ} />
                <ActivityBadge icon={Icons.FOLLOWING} count={stats.Profile.FOLU} />
                <ActivityBadge icon={Icons.FOLLOWERS} count={stats.Tally.FOLU} />
            </div>
        </div>
    )
}

export default connect(mapStateToProps, null)(UserBubble);