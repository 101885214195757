import React from "react";
import "../../../index.css";

export default function UserChoiceDisplay({ questionId, selections, center, isOwner,userSelections, isOrdered, choices, children }) {

    return (
        <div className={"w-100 smallfont mx-1 text-left "}>
            {selections.length>0?
                selections.map(function (value, index) {
                    let isSelected=false;
                    if(isOrdered)
                        isSelected = userSelections && userSelections[index]===value?true:false;
                    else
                        isSelected = center?false: userSelections?userSelections.includes(value)?true:false:false;
                    
                    return (
                        <span className="" key={questionId+index}>
                            {isOwner
                            ?<span className= {isSelected?"pr-1 brand3":"my-1"}>{choices[value].text}</span>
                            
                        :<span className= {isSelected?" brand1 pr-1":"brand3"}>{choices[value].text}</span>}
                            
                            {index !== selections.length - 1 && <span className="">, </span>}
                        </span>
                    )
                })
                :
                <span className="brand1">no similar choices</span>
            }
        </div>
    )
};