import React, { useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import "./index.css";

const PanelFeedList = ({ items, isPanel, renderItem }) => {
  const containerRef = useRef(null);

  useEffect(() => {

    const adjustHeight = () => {
      const headerHeight = document.querySelector(".navBar").offsetHeight;

      const availableHeight = window.innerHeight - headerHeight;
      console.log("panel available height:", availableHeight," header height:",headerHeight);
      if (containerRef.current) {
        containerRef.current.style.height = `${availableHeight}px`;
      }
    }

    adjustHeight();
    window.addEventListener("resize", adjustHeight);

    return () => {
      window.removeEventListener("resize", adjustHeight);
    };
  }, [isPanel]);
  
  if(items.length===0)
    return;
  return (
      <div ref={containerRef} className="scrollable-container">
        {items.map((item, index) => (
          <div key={index} >
             {renderItem(item,index)}
          </div>
        ))}
      </div>
    );
};



export default PanelFeedList;
