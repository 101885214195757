import React from "react";
import { connect } from "react-redux";
import { setView } from "../../redux/actions/context-actions";
import { Feeds} from "../../constants/feedids";
import { Icons } from "../../shared/QIcons";
import "../../index.css";
import { ViewType } from "../../constants/profile";


const mapStateToProps = (state,ownProps) => {

  return {  
            profileId:state.profile.currentUser.profileId
        };
};

const LinkNext = React.memo(function LinkNext({  profileId, dispatch, displayQuestion }) {
    const { questionId, stats: { LINQ } } = displayQuestion;


    const loadFeed = (e) => {
            dispatch(setView({ viewType:ViewType.PublicView, feedId:Feeds.ChildQuestions, targetId:questionId}));
        }

    if (LINQ)
        return (
            <span className="activeColor pointer" onClick={(e) => loadFeed(e)}>
                <i  onClick={(e) => loadFeed(e)} className= {Icons.LINK_NEXT  + "fa-lg pointer" } /> 
            </span>  
        )
    else
        return (
            <span className="inactiveColor " >
                <i  className= {Icons.LINK_NEXT + "fa-lg" } /> 
            </span> 
        )
})

export default connect(mapStateToProps,null)(LinkNext);