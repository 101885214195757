
export const FeedData = {
    targetId:"",
    cmosr:{weight:0, value:0},
    cmosrCount:0,
    cmost:{weight:0, value:0},
    cmostCount:0,
    feedId:0,
    currentFeedId:0,
    associatedId:null,
    isLoading: false,
    isLoaded:false,
    isFirstFeed:true,
    error: false,
    hasMore: false,
    nextToken:null,
    items:[],
    isScrolling:false,
    index: []
  };

  export const ItemFeed = {
    feedId:0,
    isLoading: false,
    isLoaded:false,
    isFirstFeed:true,
    nextToken:null,
    items:[],
    index: []
  };

