
import { v4 as uuidv4 } from 'uuid';

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { WhoCanView } from '../constants/question';
import { titles } from './Titles';


export function factorial(num) {
    if (num === 0 || num === 1)
        return 1;
    for (var i = num - 1; i >= 1; i--) {
        num *= i;
    }
    return num;
}


export function initials(profile){
    const {firstName,lastName} = profile;
    let initials=firstName[0] + lastName[0];
    return initials.toUpperCase();
}

export function combinations(n, r) {
    return Math.trunc(factorial(n) / (factorial(r) * factorial(n - r)));
}

export function permutations(n, r) {
    return Math.trunc(factorial(n) / factorial(n - r));
}

export function queryString(name, url = window.location.href) {
    name = name.replace(/[[]]/g, "\\$&");

    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
    const results = regex.exec(url);

    if (!results) {
        return null;
    }
    if (!results[2]) {
        return "";
    }

    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function getQueryParam(query) {
    let search = window.location.search;
    let params = new URLSearchParams(search);

    return params.get({ query });
};

export function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}


export function prettyNum(num) {
    if (num < 1000)
        return num;
    else
        if (num < 100000)
            return Math.round(num / 1000 * 10) / 10 + "K";
        else
            if (num < 999999)
                return Math.round(num / 1000) + "K";
            else
                if (num < 100000000)
                    return Math.round(num / 1000000 * 10) / 10 + "M";
                else
                    if (num < 999999999)
                        return Math.round(num / 1000000) + "M";
                    else
                        return Math.round(num / 1000000000 * 10) / 10 + "B";
}

/* Extract tags text from string as an array */
export function getHashTags(inputText) {
    var regex = /(?:^|\s)(?:#)([a-zA-Z\d]+)/gm;
    var matches = [];
    var match;

    while ((match = regex.exec(inputText))) {
        matches.push(match[1]);
    }

    return matches;
}


export function newId() {
    const guid = uuidv4().toString();
    return guid;
}

export function VisibilityText(whoCanView) {
    switch (whoCanView) {
        case WhoCanView.Noone:
            return "draft";
        case WhoCanView.Link:
            return "related";
        case WhoCanView.Everyone:
            return "public"
        default:
            return "draft"
    }
}

export function formatMoney(amount) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return (formatter.format(amount))
}

export function orderText(order) {
    switch (order) {
        case 1:
            return "1st";
        case 2:
            return "2nd";
        case 3:
            return "3rd";
        default:
            return order + "th";
    }
}

export function QuestionFeedTitle(name, feedId) {
    return name + ' ' + titles[feedId];

}

export function initNumericChoiceStats({buckets, minSel, maxSel, isOrdered, isNumeric}) {
    const hashBuckets= (!isOrdered && !isNumeric)?1:minSel;
    let choices = new Array(buckets-1).fill(new Array(hashBuckets).fill({ count: 0 }))
    choices.push(new Array(isNumeric?maxSel:hashBuckets).fill({ count: 0 }))
    return choices;
}

export function initChoiceStats(rows, cols) {
    let choices = new Array(rows).fill(new Array(cols).fill({ count: 0 }))
    return choices;
}

export function updateChoiceStats(choiceStats, selections) {
    var newStats = structuredClone(choiceStats);
    let rows = choiceStats[0].length;
    let isOrdered = rows > 1;
    if (isOrdered) {
        for (let x = 0; x < selections.length; x++) {
            let c = parseInt(selections[x]);
            newStats[c][x].count++;
        }
        return newStats;
    }
    else {
        for (let x = 0; x < selections.length; x++) {
            let c = parseInt(selections[x]);
            newStats[c][0].count++;
        }
        return newStats;
    }


}


