import React, { useState } from "react";
import { connect } from "react-redux";
import { hideModal } from "../../redux/actions/modal-actions";
import { toggleQuestionInteraction, updateUserTags } from "../../redux/actions/question-actions";
import { Input, Modal, ModalDialog, Typography, IconButton, ModalClose } from "@mui/joy";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded"; // Material UI icon
import { LyqnessActions } from "../../redux/constants";
import { QuestionTypeIcon } from "../../constants/question";
import { Icons } from "../../shared/QIcons";

const mapStateToProps = (state) => {
    return {
        isOpen: state.modal.modalType === "TAG_LINK_MODAL",
        viewProfileId: state.context.view.profileId,
        viewType: state.profile.viewProfile.viewType,
        isObserver: state.profile.currentUser.isPremium,
        userId: state.profile.currentUser.profileId,
    };
};

const TagLinkModal = React.memo(function TagLinkModal({
    isOpen,
    displayQuestion,
    isObserver,
    userId,
    viewProfileId,
    dispatch,
}) {
    const {
        questionId,
        creatorId,
        userFeed: { tags, stats: { TAGQ } },
    } = displayQuestion;
    let start = tags.length > 0 ? tags.join(" ") : [];

    const [content, setContent] = React.useState(start);
    const [canSave, setCanSave] = React.useState(false);
    const [isValid, setIsValid] = React.useState(true);
    const [errMsg, setErrMsg] = useState(null);
    const maxTags = 5;
    const tagExp = /^[a-zA-Z]+[a-zA-Z0-9]*/;

    const TagMsg = () => (
        <Typography
            level="body2"
            color={isValid ? "neutral" : "danger"}
            sx={{ mb: 1 }}
        >
            Create up to {maxTags} tags. Letters and numbers only. Can't start with a number. No duplicates. Press "return" to save.
        </Typography>
    );

    const handleChange = (e) => {
        const value = e.target.value;
        setContent(value);
        isValidTags(value);
    };

    const isValidTags = (e) => {
        let newTags = e.length > 0 ? e.trim().split(" ") : [];
        let tagLen = newTags.length;

        if (tagLen === 0) {
            setIsValid(true);
            setCanSave(tags.length > 0 && tagLen === 0);
            return true;
        }

        if (tagLen > maxTags) {
            setIsValid(false);
            setCanSave(false);
            return false;
        }

        let tagsGood = newTags.every((tag) => tagExp.test(tag));
        if (!tagsGood) {
            setIsValid(false);
            setCanSave(false);
            return false;
        }

        let hasDups = newTags.length !== new Set(newTags).size;        if (hasDups) {
            setErrMsg("duplicate tags are not allowed")
            setIsValid(false);
            setCanSave(false);
            return false;
        }
        else
            setErrMsg(null);

        setIsValid(true);
        setCanSave(true);
        return true;
    };

    const handleKeyDown = (e) => {
        const allowed = /^[a-zA-Z0-9]*$/;
        const key = e.key;

        if (key !== " " && !allowed.test(key)) e.preventDefault();

        if (key === "Enter" && canSave) {
            let newTags = content.length > 0 ? content.trim().split(" ") : [];

            if ((TAGQ === 0 && newTags.length > 0) || (TAGQ === 1 && newTags.length === 0)) {
                dispatch(
                    toggleQuestionInteraction({
                        displayQuestion: displayQuestion,
                        questionId: questionId,
                        profileId: userId,
                        associatedId: questionId,
                        creatorId: creatorId,
                        viewProfileId: viewProfileId,
                        state: !TAGQ,
                        action: LyqnessActions.TAGQ,
                        tags: newTags,
                    })
                );
            } else if (newTags.length > 0 && newTags !== tags) {
                dispatch(
                    updateUserTags({
                        profileId: userId,
                        questionId: questionId,
                        tags: newTags,
                    })
                );
            }
            handleClose();
        }
    };

    const handleClose = () => {
        setContent("");
        dispatch(hideModal());
    };

    return (
        <Modal open={isOpen} onClose={handleClose}>
            <ModalDialog
                aria-labelledby="tag-link-modal-title"
                aria-describedby="tag-link-modal-description"
                sx={{
                    borderRadius: 'md',
                    width: '350px',
                    maxWidth: "400px",
                    padding: 0,
                    pb: 2,
                    margin: 0,
                }}
            >
                <ModalClose variant="plain" sx={{ m: -1, color: "neutral" }} onClick={handleClose} />
                <div className="brand1 modalHeaderColor no-gutters px-2 py-2">
                    <Typography id="question-modal-title" component="h2" level="h5">
                        <small className="brand0 pe-1"><i className={Icons.TAG + "fa-lg"}></i></small>
                        New Text Choice Question
                    </Typography>
                </div>
                <div className="popover-body px-2">
                    <TagMsg />
                    <Input
                        autoFocus
                        variant="outlined"
                        sx={{ fontSize: ".90rem", borderRadius: 0, mt: 1, mb: 0 }}
                        size="sm"
                        fullWidth
                        defaultValue={start}
                        onKeyDown={handleKeyDown}
                        onChange={handleChange}
                    />
                </div>
                <div className="error-text text-center">{errMsg}&nbsp;</div>
            </ModalDialog>
        </Modal>
    );
});

export default connect(mapStateToProps, null)(TagLinkModal);
