import React, { useState } from "react";
import { connect } from "react-redux";
import { QuestionAskType } from "../../../constants/question";
import { updateQuestionText } from "../../../redux/actions/questionedit-actions";


const EditQuestionText = React.memo(function EditQuestionText({ questionText, choices, questionType, hasResponses, dispatch }) {

    const [editor, setEditor] = useState(questionText ?? '');

    function handleOnKeyDown(e) {
        const qmIndex = editor.indexOf('?');
        const scIndex = editor.indexOf(';')

        if (e.key === 'Enter') {
            if (questionType in [ QuestionAskType.Select,QuestionAskType.Rank,QuestionAskType.SelectInOrder]) {
                if (choices && choices.length === 0) {
                    if (qmIndex && scIndex && (qmIndex < scIndex)) {
                        e.preventDefault();
                        const choiceStr = editor.substr(qmIndex + 1).trim();
                        var addwords = [];
                        let temp;
                        let i;
                        var items = choiceStr.split(';');
                        for (i = 0; i < items.length; i++) {
                            temp = items[i].trim();
                            if (temp !== "")
                                addwords[i] = { "text": temp, "imageKey": "", "imageURL":"" };
                        }
                        setEditor(editor.substr(0, qmIndex));
                        let cleanedText = questionText.trim();
                        if (cleanedText[cleanedText.length - 1] === "?")
                            cleanedText = cleanedText.slice(0, cleanedText.length - 1);
                        let tags = cleanedText.match(/#[a-z_0-9]+/gi)??[];
                        let lowerTags = tags.map(x => x.toLowerCase());
                        let updates = {
                            questionText: editor.substr(0, qmIndex),
                            choices: addwords,
                            buckets: addwords.length,
                            tags: lowerTags
                        };
                        if (questionType === QuestionAskType.Rank) {
                            const buckets = addwords.length;
                            updates = { ...updates, minSel: buckets, maxSel: buckets, buckets: buckets };
                        }
                        dispatch(updateQuestionText({ question: { ...updates } }));
                        return;
                    }
                }
            }
            let cleanedText = qmIndex>0?editor.substr(qmIndex + 1).trim() : editor.trim();
            let tags = cleanedText.match(/#[a-z_0-9]+/gi)??[];
            let lowerTags = tags.map(x => x.toLowerCase());
            dispatch(updateQuestionText({ question: { questionText: cleanedText, tags:lowerTags } }));
        }
    }

    function handleChange(e) {
        const value = e.target.value;
        const lastChar = value[value.length - 1];
        if (lastChar !== '\n' && lastChar!=="?")
        {
            setEditor(value);
            dispatch(updateQuestionText({ question: { questionText: value } }));
        }
    }

    const label = questionType === QuestionAskType.Lottery ? "Describe the prize here" : "Enter your question here";
    return (
        <div>
            <textarea
                className={"px-1 w-100 roundedBorder"}
                placeholder="Ask question here…"
                name="questionText"
                size="md"
                multiline="true"
                rows="2"
                onKeyDown={(e) => handleOnKeyDown(e)}
                onChange={(e) => handleChange(e)}
                onBlur={() => handleOnKeyDown({ key: "Enter" })}
                variant="outlined"
                label={label}
                value={editor}
            />
        </div>
    )
});

export default connect(null, null)(EditQuestionText);