

// NotificationSupportChecker.js
import { useEffect, useState } from 'react';
import { onError } from './shared/errorLib';
import { requestForToken } from './firebaseNotifications/firebase';
import { showNotification } from './NotificationContext';
import Notify from './components/notify/Notify';

export const NotificationSupportChecker = ({ profileId }) => {
  const [permission, setPermission] = useState("default");
  const [isSupported, setisSupported] = useState(false);

  useEffect(() => {
    async function onLoad() {
      try {
        if ('Notification' in window) {
          console.log("permission:", Notification.permission);
          setPermission(Notification.permission);
          setisSupported(true);
          console.log("notifications are supported");
          requestForToken(profileId);
        }
        else{
          Notify("Notifications are only supported if app is installed on home screen");
        }
      }
      catch (e) {
        if (e !== 'error configuring notification') {
          onError(e);
        }
      }
    };

    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [profileId]);


  const requestPermission = async (profileId) => {
    const notifyApproved = await Notification.requestPermission();
    if (notifyApproved) {
      try {
        showNotification("Hello!",  "This is how notifications will appear.");
        setPermission(notifyApproved);
      }
      catch (error) {
        console.error('You will not receive notifications', error);
      };
    }
    else {
      Notify("You will not receive notification alerts.");
    }
  }

  if (isSupported && permission==="default")
    return (
      <div className="text-center">
          <button onClick={() => requestPermission(profileId)}>
            Enable Notifications
          </button> 
          <span />
      </div>
    )
  else
    return (<span />)
}

export default NotificationSupportChecker;