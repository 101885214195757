import React from "react";
import { connect } from "react-redux";
import { prettyNum } from "../../../../shared/utilities";
import { Feeds } from "../../../../constants/feedids";
import { setPanelView } from "../../../../redux/actions/context-actions";
import { showPanel } from "../../../../redux/actions/panel-actions";
import { PanelType } from "../../../../constants/panel";
import { choiceStatsSummary } from "../../Utilities";
import { QuestionAskType } from "../../../../constants/question";
import { clearProfileFeed } from "../../../../redux/actions/profile-actions";


const mapStateToProps = (state, ownProps) => {
    return {
        //displayQuestion: state.feed.items[state.feed.index[ownProps.questionId]],
        panel: state.context.panel,
        isObserver: state.profile.isPremium,
    };
};

const QuestionUserCount = React.memo(function QuestionUserCount({ choiceId, orderId=null, question, panel, dispatch }) {
    const { questionType, isOrdered, questionId, choiceStats, userFeed: { selections } } = question;
    const statSummary=choiceStatsSummary(choiceStats);
    const isRating = questionType===QuestionAskType.Rate;
    let indicator = "", count = statSummary[choiceId].count;

    if (isOrdered) {
        count = choiceStats[choiceId][orderId].count;
    }
    else
        if (isOrdered) {
            const order=selections.indexOf(choiceId)
            indicator = order>=0?order+1:indicator;
        }
        else {
            if(selections.includes(choiceId))
                indicator="*";
        };

    function showProfiles() {
        if (count > 0 && (questionId === panel.targetId && choiceId === panel.choiceId &&  orderId === panel.orderId)) {
            dispatch(showPanel());
        }
        else {
            dispatch(clearProfileFeed());
            const feedId = Feeds.ChoiceUsers;
            dispatch(setPanelView({
                panelType: PanelType.QuestionUsers, displayQuestion: question,
                targetId: questionId, feedId: feedId, choiceId: choiceId, orderId: isOrdered ? orderId : null
            }));
        }
    }

    return (
        <span className={`${isRating?"":"float-end"} pointer brand0 `} onClick={() => showProfiles()}>
            <span>{count > 0 ? prettyNum(count) : isRating?"-":""}</span><sup><small>{indicator }</small></sup>
        </span>
    )

})



export default connect(mapStateToProps, null)(QuestionUserCount)
