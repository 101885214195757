import keymirror from "keymirror";

export const ProfileActionEnums = ['CHAC','CHAG','CHAU','CREC','CREG','CREO','FEAC','FLAU','FOLC','LOFU','FOLU','GROU','LIKC','SHAC'];
export const FeedActionEnums = [ 
    'CHAQ', 
    'CREA', 
    'CREI', 
    'CREQ', 
    'HCRQ', 
    'HLNQ', 
    'HREQ', 
    'HLKQ', 
    'FEAQ', 
    'FLAQ', 
    'INCA', 
    'INCQ', 
    'INCR', 
    'LIKQ', 
    'LINQ', 
    'MODQ', 
    'RESQ', 
    'SHAQ', 
    'SPOQ', 
    'STEA', 
    'TAGQ', 
    ]


export const ProfileFeedActionEnums = ['CHAU','CREQ','FLAU','HCRQ','LIKU','FOLU','LOFU','SHAU'];

export const ShareActions = keymirror({
    FOLLOWERS:null,
    LIKED:null,
    SELECTED:null,
    RESPONDED:null,
    EXTERNAL:null,
});

export const UserFeedStats = {
"CHAQ": 0, 
"CREA": 0, 
"CREI": 0, 
"CREQ": 0, 
"HCRQ": 0, 
"HLNQ": 0, 
"HREQ": 0, 
"HLKQ": 0, 
"FEAQ": 0, 
"FLAQ": 0, 
"INCA": 0, 
"INCQ": 0, 
"INCR": 0, 
"LIKQ": 0, 
"LINQ": 0, 
"MODQ": 0, 
"RESQ": 0, 
"SHAQ": 0, 
"SPOQ": 0, 
"STEA": 0, 
"TAGQ": 0, 
}


export const ProfileFeedStats = {
    'CHAU': 0,
    'FEAU': 0,
    'FLAU': 0,
    'FOLU': 0,
    'LIKU': 0,
    'MODU': 0,
    'SHAU': 0,
    'SPOU': 0
}

export const TallyStats = {
    'CHAU': 0,
    'FLAU': 0,
    'FOLU': 0,
    'SHAU': 0,
    'SHAC': 0,
    'FOLC': 0,
    'FLAC': 0,
    'CHAC': 0,            
  }

export const ProfileStats = {
    Question: UserFeedStats,
    Profile: ProfileFeedStats,
    Tally: TallyStats
    }    

export const QuestionComponent = keymirror({
    QUESTION:null,
    USERFEED:null,
    PROFILE:null,
    IMAGE:null
});

export const ObjectType = keymirror({
	QUESTION:null,
	GROUP:null,
    MESSAGE:null,
    CONVERSATION:null,
	PRIVATE:null,
	USER:null
});

export const chatType = keymirror({
	CHAQ:null,
	CHAU:null,
	CHAG:null,
	CHAC:null
});

export const Action = keymirror({
    BORQ: null,
    CHAC: null,
    CHAQ: null,
    CHAU: null,
    CREA:null,
    CREC: null,
    CREI:null,
    CREQ: null,
    DRFT:null,
    FEAC: null,
    FEAQ: null,
    FEAU: null,
    FLAC: null,
    FLAQ: null,
    FLAU: null,
    FOLC: null,
    FOLU: null,
    HCRQ: null,
    HLKQ: null,
    HLNQ: null,
    HREQ: null,
    INCA:null,
    INCQ:null,
    INCR: null,
    LIKC: null,
    LIKQ: null,
    LIKU: null,
    LINQ: null,
    MODC: null,
    MODQ: null,
    MODU: null,
    RESQ: null,
    SHAC: null,
    SHAQ: null,
    SHAU: null,
    SPOC: null,
    SPOQ: null,
    SPOU: null,
    STEA: null,
    TAGQ: null,
});

export const LyqnessActions = {
    BOOC : "BOOC",
    TAGQ : "TAGQ",
    BORQ : "BORQ",
    CHAC : "CHAC",
    CHAG : "CHAG",
    CHAQ : "CHAQ",
    CHAU : "CHAU",
    CREQ : "CREQ",
    CREC : "CREC",
    CREG : "CREG",
    CREL : "CREL",
    FEAC : "FEAC",
    FEAQ : "FEAQ",
    FEAU : "FEAU",
    FLAC : "FLAC",
    FLAG : "FLAG",
    FLAQ : "FLAQ",
    FLAU : "FLAU",
    FOLC : "FOLC",
    FOLU : "FOLU",
    GROM : "GROM",
    GROU : "GROU",
    HCRQ: "HCRQ",
    HLNQ: "HLNQ",
    HREQ: "HREQ",
    HLKQ: "HLKQ",
    LIKC : "LIKC",
    LIKQ : "LIKQ",
    LINQ : "LINQ",
    MODC : "MODC",
    MODG : "MODG",
    MODQ : "MODQ",
    ORGA : "ORGA",
    ORGM : "ORGM",
    REGB : "REGB",
    REGP : "REGP",
    REGU : "REGU",
    RESQ : "RESQ",
    SHAC : "SHAC",
    SHAQ : "SHAQ",
    SPON : "SPON",
    STEA : "STEA",
    FEED : "FEED",
    INCA: "INCA",
    INCR:"INCR",
    INCQ:"INCQ",
    CREA:"CREA",
    CREI:"CREI"
  }
export const AppsyncActions = keymirror({
    QUESTION_FEED:null,
    QUESTIONS_BY_INTERACTION_RECEIVED:null,
    QUESTIONS_BY_INTERACTION_REQUESTED:null, 
    ASSOCIATED_USER_FEED_RECEIVED:null,
    APPSYNC_API_ERROR:null
});

//******     Chat     ******/
export const ChatActions = keymirror({
    CLEAR_MESSAGES:null,
    CREATE_MESSAGE_REQUESTED:null,
    MESSAGE_CREATED:null,
    OPTIMISTIC_MESSAGE_CREATED:null,
    CONVERSATION_MESSAGES_REQUESTED: null,
    CONVERSATION_MESSAGES_RECEIVED: null,
    UPDATE_LAST_CONVO_VIEW_TIME_REQUESTED:null,
    CONVERSATION_LAST_VIEW_TIME_UPDATED:null,
    USER_MESSAGES_REQUESTED: null,
    USER_MESSAGES_RECEIVED: null,
    USER_MESSAGE_RECEIVED:null,
    QUESTION_MESSAGE_RECEIVED:null,
    SUBSCRIBED_TO_MESSAGES:null,
    CREATE_SUBSCRIPTION:null,
    SET_MESSAGE_SUBSCRIPTION:null,
    SET_SUBSCRIPTION:null,
    SET_VIEW_SUBSCRIPTION:null,
    CONVERSATION_MESSAGE_RECEIVED: null,
    VIEW_MESSAGE_RECEIVED: null,
    PUBLIC_CONVERSATIONS_REQUESTED:null,
    PUBLIC_CONVERSATIONS_RECEIVED:null,    
    USER_CONVERSATIONS_RECEIVED:null,
    USER_CONVERSATION_RECEIVED:null, 
    USER_CONVERSATIONS_REQUESTED:null,
    START_CONVERSATION:null,
    JOIN_CONVERSATION:null,
    CONVERSATION_STARTED:null,
    CONVERSATION_JOINED:null,
    RESET_CHAT:null,
    STAGE_CHAT_IMAGE:null,
    CLEAR_CHAT_IMAGE:null,
    SET_CHAT_PREVIEW_URL:null,
    CHAT_API_ERROR:null,
});

//******     Question      ******/
export const DefinitionActions = keymirror({
    UPDATE_BUCKETS:null,
    UPDATE_CHOICES:null,
    STAGE_FOR_EDIT:null,
    UPDATE_EDITQUESTION: null,
    UPDATE_RANGES:null,
    UPDATE_QUESTION_IMAGE: null,
    UPDATE_PICTURE_CHOICES: null,
    UPDATE_QUESTION_CHOICES:null,
    UPDATE_CHOICEIMAGES:null,
    UPDATE_CHOICETEXT:null,  
    UPDATE_CHOICEIMAGE:null,       
    TOGGLE_ORDERED:null,
    TOGGLE_STEALTH:null,
    RESET_DEFINITION:null,
    SET_ERRORMSG:null,
    STAGE_HEADER_IMAGE:null,
    UPDATE_QUESTION_TEXT: null,
    STAGE_HEADER_PREVIEW:null,
    STAGE_CHOICE_IMAGE:null,
    STAGE_CHOICE_PREVIEW:null,
    REMOVE_CHOICE_IMAGE:null,
    REMOVE_HEADER_IMAGE:null
});

export const ContextActions = keymirror({
    SET_VIEW:null,
    SET_SCREEN_SIZE:null,
    SET_PANEL_VIEW:null,
    CLOSE_PANELS:null,
    SET_NOTIFICATION:null,
    CLEAR_PANEL_VIEW:null,
    OPEN_SPEEDDIAL:null,
    CLOSE_SPEEDDIAL:null,  
});

export const PanelActions = keymirror({
    SET_PANEL_OPEN:null,
    TOGGLE_LEFT_PANEL:null,
    CLOSE_PANELS:null,
    SHOW_PANEL:null,
    LINK_FEED_DATA_RECEIVED:null,
    SHOW_LEFTPANEL:null,
    HIDE_LEFTPANEL:null,
    HIDE_PANEL:null,
    RESET_TO_DEFAULT:null,
 
    LOAD_ANNOUNCEMENTS_REQUESTED:null,
    ANNOUNCEMENTS_LOADED:null,
    PANEL_API_ERROR:null
});

//******     Question      ******/
export const QuestionActions = keymirror({
    ADD_CACHED_IMAGE:null,
    CACHE_CHOICE_IMAGES:null,
    CACHE_IMAGE:null,
    CHECK_ID:null,
    CLEAR_CACHED_CHOICE_IMAGES:null,
    CLEAR_CACHED_IMAGE:null,
    COMMENT_ADD_REQUESTED:null,
    CREATE_REQUESTED:null,
    DATA_RECEIVED:null,
    DELETE_IMAGE_REQUESTED:null,
    FAVORITE_UPDATED:null,
    FEATURED_UPDATED:null,
    FILTER_DATA_RECEIVED:null,
    FOLLOW_UPDATED:null,
    IMAGES_PROCESSED:null,
    INTERACTION_PROCESSED:null,
    PICTURE_DELETED:null,
    PICTURE_SAVED:null,
    PROCESS_IMAGES:null,
    PROCESS_RESPONSE_REQUESTED:null,
    PUBLIC_QUESTION_RECEIVED:null,
    PUBLIC_QUESTION_REQUESTED:null,
    QUESTION_ADD_REQUESTED:null,
    QUESTION_ADDED:null,
    QUESTION_API_ERROR:null,
    QUESTION_DATA_RECEIVED:null,
    QUESTION_DATA_REQUESTED:null,
    QUESTION_DELETE_REQUESTED:null,
    QUESTION_DELETED: null,
    QUESTION_EDIT_REQUESTED:null,
    QUESTION_INTERACTION_PROCESSED:null,
    QUESTION_INTERACTION_REQUESTED:null,
    QUESTION_REMOVE_REQUESTED:null,
    QUESTION_RESPONSE_PROCESSED:null,
    QUESTION_SHARE_REQUESTED:null,
    QUESTION_SHARE_PROCESSED:null,
    QUESTION_STATS_UPDATED:null,
    QUESTION_SUBSCRIPTION_RECEIVED:null,
    PROCESS_QUESTION_SUBSCRIPTION:null,
    QUESTION_UPDATE_REQUESTED:null,
    QUESTION_UPDATED:null,
    SAVE_QUESTION_IMAGES:null,
    SHARE_REQUESTED:null,
    SHARE_UPDATED:null,
    STEALTH_TOGGLED:null,
    SUBMIT_RESPONSE_REQUESTED:null,
    TOGGLE_FAVORITE_REQUESTED:null,
    TOGGLE_FEATURED_REQUESTED:null,
    TOGGLE_FOLLOW_REQUESTED:null,
    TOGGLE_STEALTH_REQUESTED:null,
    TOGGLE_WATCH_REQUESTED:null,
    UPDATE_USER_TAGS_REQUESTED:null,
    USERSTAT_UPDATED:null,
    WATCH_UPDATED:null,  
});

//******     Feed      ******/
export const FeedActions = keymirror({
    PROCESS_QUESTION_SUBSCRIPTION:null,
    PROCESS_USERFEED_SUBSCRIPTION:null,
    QUESTION_FEED_RECEIVED:null,
    QUESTION_FEED_REQUESTED:null, 
    USER_FEED_RECEIVED:null,
    USER_FEED_REQUESTED:null,
    TAG_FEED_REQUESTED:null,
    TAG_FEED_RECEIVED:null,
   
    // below have not been validated as used
    LOCAL_QUESTION_UPDATE_RECEIVED:null,
    LOCAL_QUESTION_UPDATED:null,
    LOCAL_FEED_QUERY_RECEIVED:null,
    LOCAL_FEED_QUERY_PROCESSED:null,
    LOCAL_PROFILE_UPDATE_RECEIVED:null,
    LOCAL_PROFILE_UPDATED:null,         
    FEED_API_ERROR:null,
    FEED_DATA_RECEIVED:null,
    FEED_SNAPSHOT_RECEIVED:null,
    PROCESS_QUESTION_SUBSCRIPTION:null,
    QUESTION_SUBSCRIPTION_RECEIVED:null,
    USERFEED_SUBSCRIPTION_RECEIVED:null,
    INTERACTION_SUBSCRIPTION_RECEIVED:null,
    PROFILE_SUBSCRIPTION_RECEIVED:null,
    PROFILEFEED_SUBSCRIPTION_RECEIVED:null, 
    PROFILE_INTERACTION_PROCESSED:null,
    FEED_UPDATE_RECEIVED:null,
    QUESTION_FEED_DATA_REQUESTED:null,  
    SET_CURRENT_PROFILE_VIEW:null,
    SET_FEED_SUBSCRIPTION:null,
    CLEAR_FEED_SUBSCRIPTION:null,
    SIGN_OUT:null,
   
    ASSOCIATED_INTERACTION_FEED_REQUESTED:null,
    BEACON_QUESTION_RECEIVED:null,
    BEACON_QUESTION_REQUESTED:null,
    FILTER_DATA_REQUESTED:null,
    GLOBAL_SEARCH_DATA_REQUESTED:null,
    LINK_FEED_DATA_RECEIVED:null,
    LINK_FEED_DATA_REQUESTED:null,
    LINKED_QUESTIONS_REQUESTED:null,
    LINKED_TO_QUESTIONS_REQUESTED:null,
    PARENT_QUESTION_FEED_RECEIVED:null,
    POP_FEED:null,
    PROFILE_QUESTIONS_REQUESTED:null,
    QUESTION_ADDED:null,
    QUESTION_DELETED: null,
    QUESTION_AND_LINKED_RECEIVED:null,
    QUESTION_AND_LINKED_REQUESTED:null,
    QUESTION_COMMENTS_DELETE_REQUESTED:null,
    QUESTION_COMMENTS_UPDATE_REQUESTED:null,
    QUESTION_COMMENTS_UPDATED:null,
    QUESTION_INTERACTION_FEED_REQUESTED:null,
    QUESTION_INTERACTION_RECEIVED:null,
    QUESTION_LINKS_REQUESTED:null,
    QUESTION_RESPONSE_RECEIVED:null,
    QUESTION_UPDATED:null,
    USERFEED_UPDATED:null,
    QUESTION_INTERACTION_PROCESSED:null,   
    VIEW_QUESTION_UPDATED:null,
    BUILD_UPDATE_FROM_QUESTION:null,
    BUILD_UPDATE_FROM_USERFEED:null,
    QUESTION_DELETE_RECEIVED:null,    
    QUESTION_VIEW_REQUESTED:null,
    RELATED_INTERACTION_FEED_REQUESTED:null,
    REMOVE_FEED_DATA_REQUESTED:null,
    SET_PUBLIC_PROFILE:null,
    SET_QUESTION_SUBSCRIPTION:null,
    SET_VIEW_INTERACTION_SUBSCRIPTION:null,
    SET_VIEW_RESPONSE_SUBSCRIPTION:null,
    SET_USER_PROFILEID_SUBSCRIPTION:null,
    SIC_FEED_DATA_RECEIVED:null,
    SIC_FEED_REQUESTED:null,
    STEALTH_TOGGLED:null,
    TOGGLE_SEARCHING:null,
    USER_INTERACTION_FEED_REQUESTED:null,
    OWNER_RESPONSE_RECEIVED:null,
    USER_QUESTIONS_REQUESTED:null,
    VIEW_PROFILE_RECEIVED:null,
    VIEW_RESPONSE_RECEIVED:null,
    VIEW_QUESTION_REQUESTED:null,
    VIEW_QUESTION_RECEIVED:null
});

//********  Profile actions    *******/
export const ProfileActions = keymirror({
    PROFILE_SUBSCRIPTION_RECEIVED: null,
    ASSOCIATED_USER_FEED_REQUESTED: null,
    ASSOCIATED_USER_FEED_RECEIVED: null,
    SET_CURRENT_VIEW: null,
    PROFILE_UPDATED: null,
    CURRENT_USER_PROFILE_REQUESTED: null,
    CURRENT_USER_PROFILE_RECEIVED: null,
    SYSTEM_USER_PROFILE_RECEIVED: null,
    LOAD_CURRENT_USER_PROFILE_REQUESTED: null,
    VIEW_PROFILE_REQUESTED: null,
    VIEW_PROFILE_RECEIVED: null,
    RESET_PROFILE_WITH_STATS_LOADED: null,
    PROFILE_FEED_DATA_REQUESTED: null,
    PROFILE_FEED_DATA_RECEIVED: null,
    RETRIEVE_USERLIST: null,
    REMOVE_PROFILE_FEED_DATA_REQUESTED: null,
    CLEAR_PROFILE_FEED_REQUESTED: null,
    USER_PROFILE_REQUESTED: null,
    USER_PROFILE_RECEIVED: null,
    PROFILE_WITH_SIC_REQUESTED: null,
    USER_PROFILE_SIGN_IN: null,
    FAVORITE_UPDATED: null,
    HASHTAGS_REQUESTED:null,
    HASHTAGS_RECEIVED:null,
    LINK_UPDATED: null,
    SHARE_UPDATED: null,
    QUESTION_RESPONSE_PROCESSED: null,
    QUESTION_ADDED: null,
    QUESTION_UPDATED: null,
    QUESTION_DELETED: null,
    PROFILELIST_DATA_REQUESTED: null,
    PROFILELIST_DATA_RECEIVED: null,
    PROFILE_SEARCH_REQUESTED: null,
    PROFILE_SEARCH_DATA_RECEIVED: null,
    PROFILE_SET_CURRENT_TO_VIEW: null,
    SHOW_PROFILE_FEED_MODAL: null,
    SET_CURRENT_PROFILE_VIEW: null,
    SET_CURRENT_VIEW_PROFILEID: null,
    SET_USER_GROUPS: null,
    HIDE_MODAL: null,
    TOGGLE_FOLLOW: null,
    SHOW_PROFILE_MODAL: null,
    HIDE_PROFILE_MODAL: null,
    RESET_VIEW_PROFILE: null,
    PROFILE_API_ERROR: null,
    PROFILE_INTERACTION_REQUESTED: null,
    PROFILE_INTERACTION_PROCESSED: null,
    PROFILE_TAGS_REQUESTED:null,
    PROFILE_TAGS_RECEIVED:null,
    PROFILE_UPDATE_REQUESTED: null,
    USER_PROFILE_UPDATED: null,
    USER_COMMENT_UPDATE_REQUESTED: null,
    USER_COMMENT_DELETE_REQUESTED: null,
    USER_COMMENT_UPDATE_CANCELLED: null,
    USER_COMMENTS_UPDATED: null
});

//********  Profile actions    *******/
export const AuthActions = keymirror({
    SIGN_IN:null,
    SIGN_OUT:null,
    SIGN_UP:null,
    CONFIRM_SIGN_IN:null,
    CONFIRM_SIGN_UP:null,
    FORGOT_PASSWORD:null,
    REQUIRE_NEW_PASSWORD:null,
    VERIFY_CONTACT:null,
    SIGNED_IN:null,
    CURRENT_USER_PROFILE_DATA_RECEIVED:null,
});

//******     UserProfilee      ******/
export const UserProfileActions = keymirror({
    VIEW_REQUESTED:null,
    EDIT_AVATAR_REQUESTED:null,
    SAVE_AVATAR_REQUESTED:null,
    DELETE_AVATAR_REQUESTED:null,
    CREATE_PROFILE:null,
    UPDATE_REQUESTED:null,
    DELETE_REQUESTED:null,
    DELETE_EXECUTE_REQUESTED:null,
    CACHE_AVATAR_IMAGE:null,
    DELETE_CACHED_AVATAR:null,
    DATA_RECEIVED:null,
    SHOW_AVATAR_EDIT:null,
    AVATAR_SAVED:null,
    AVATAR_DELETED:null,
    UPDATED:null,
    DELETE_CONFIRMED:null,
    DELETED:null,
    USERPROFILE_API_ERROR:null
});

export const LinkActions = keymirror({
    TOGGLE_LINKING_REQUESTED:null,
    LINK_CREATE_REQUESTED:null,
    LINK_ADDED:null,
    LINK_UPDATED:null,
    LINK_DELETED:null,
    LINK_DELETE_REQUESTED:null,
    REMOVE_LINK_FEED_DATA_REQUESTED:null, 
    LINK_API_ERROR:null
});

//******     Image      ******/
export const ImageActions = keymirror({
    SAVE_IMAGE_REQUESTED:null,
        SAVE_QUESTION_IMAGES:null,
        QUESTION_UPDATED:null,
    DELETE_IMAGE_REQUESTED:null,
    IMAGE_DELETED:null,
        IMAGE_SAVED:null,
    CACHE_AVATAR_IMAGE:null,
    CLEAR_AVATAR_IMAGE:null,
    CACHE_HEADER_IMAGE:null,
    CLEAR_HEADER_IMAGE:null,
        CACHE_CHOICE_IMAGE:null,
    CLEAR_CHOICE_IMAGE:null,
        PROCESS_IMAGES_REQUESTED:null,
        IMAGES_PROCESSED:null,
        CLEAR_ALL_IMAGES:null,
        IMAGE_STORAGE_ERROR:null,
        TESTING:null
    })
