import { API, DataStore, Predicates, SortDirection, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/lyqnessQueries";
import {  Hashtag, UserFeed } from "../models";
import { buildLocalFeed, prepQuestionFeed } from "./questionQueries";
import { ObjectType } from "../redux/constants";



export async function listLocalUserTags(payload) {
    const { feedId} = payload;
    try {
        let data= await DataStore.query(Hashtag, Predicates.ALL, {
            sort: s => s.updatedAt(SortDirection.DESCENDING)
        });
        const result = { items: data, feedId: feedId, isFirstFeed: true };
        return result;
    }
    catch (error) {
        console.log(error)
    }
}

export async function listLocalQuestionsTagged(payload) {
    const { profileId,feedId, tag} = payload;
    try {
        let items = await DataStore.query(UserFeed,(u)=>u.tags.contains(tag));

        let result = await buildLocalFeed({ profileId: profileId, items: items, feedId: feedId });
        result.objectType = ObjectType.QUESTION;
        return result;
    }
    catch (error) {
        console.log(error)
    }
}

export async function listTagsByProfile(payload) {
    const {feedId, targetId, nextToken} = payload;
    try {
        const gql = await API.graphql(graphqlOperation(queries.listTagsByProfile , {profileId:targetId, nextToken: nextToken}));
        const data = gql.data.listTagsByProfile;
        const {items, nextToken:newNextToken} = data;
        let result = {items: items };
        return result;
    }
    catch (error) {
        console.log(error)
    }
}

export async function listQuestionsByTag(payload) {
    const { viewProfileId, targetId, feedId, tag, nextToken } = payload;
    try {
        const ds = await API.graphql(graphqlOperation(queries.listQuestionsByTag,
            { viewProfileId:viewProfileId, targetId:targetId, tag:tag, nextToken: nextToken }));
        let qry = ds.data.listQuestionsByTag;
        qry.items = await prepQuestionFeed({items:qry.items});
          let result = { ...qry, feedId: feedId, isFirstFeed: nextToken ? false : true };
          result.objectType = ObjectType.QUESTION;
        return result;
    }
    catch (error) {
        console.log(error)
    }
}
