
export const UserFeeds={
    Advertisements: 'LCREA',
    ChattedQuestions: 'LCHAQ',
    ChattedUsers: 'LCHAU',
    Conversations: 'LCONV',
    CreatedPrivateQuestions: 'LHCRQ',
    CreatedQuestions: 'LCREQ',
    DraftQuestions: "LDRFT",
    FeaturedQuestions: 'LFEAQ',
    FeaturedUsers: 'LFEAU',
    FlaggedQuestions: 'LFLAQ',
    FlaggedUsers: 'LFLAU',
    FollowingUsers: 'LFOLU',
    Incentives: 'LCREI',
    LikedQuestions: 'LLIKQ',
    LikedUsers: 'LLIKU',
    LinkedPrivateQuestions: 'LHLNQ',
    LinkedQuestions: 'LLINQ',
    ModeratedQuestions: 'LMODQ',
    ModeratedUsers: 'AMODU',
    Question:"LQQQZ",
    RespondedPrivateQuestions: 'LHREQ',
    RespondedQuestions: 'LRESQ',
    SharedQuestions: 'LSHAQ',
    SharedUsers: 'LSHAU',
    SponsoredQuestions: 'LSPOQ',
    SponsoredUsers: 'LSPOU',
    StealthedQuestions: 'LSTEA',
    TaggedQuestions: 'LTAQT',
    UserFeedQuestions: 'LALLQ',    
    UserHashTagQuestions:"LHSHT",
    UserHashtags: "LHSHT",
    UserTags: 'LUSRT',
    }
    
    export const WhoFeeds={
        ChattedQuestions: 'ACHAQ',
        ChattedUsers: 'ACHAU',
        CreatedPrivateQuestions: 'AHCRQ',
        CreatedQuestions: 'ACREQ',
        FeaturedQuestions: 'AFEAQ',
        FeaturedUsers: 'AFEAU',
        FlaggedQuestions: 'AFLAQ',
        FlaggedUsers: 'AFLAU',
        FollowingUsers: 'AFOLU',
        LikedQuestions: 'ALIKQ',
        LikedUsers: 'ALIKU',
        LinkedPrivateQuestions: 'AHLNQ',
        LinkedQuestions: 'ALINQ',
        ModeratedQuestions: 'AMODQ',
        ModeratedUsers: 'AMODU',
        QuestionsTagged: 'ATAGQ',        
        RespondedPrivateQuestions: 'AHREQ',
        RespondedQuestions: 'ARESQ',
        SharedQuestions: 'ASHAQ',
        SharedUsers: 'ALIKU',
        SponsoredQuestions: 'ASPOQ',
        SponsoredUsers: 'ASHAU',
        UserTags: 'AUSRT',
    }
    
    export const PublicFeeds={
        BorrowedQuestions: 'PBORQ',
        ChattedQuestions: 'PCHAQ',
        ChattedUsers: 'PCHAU',
        CreatedQuestions: 'PCREQ',
        FeaturedQuestions: 'PFEAQ',
        FeaturedUsers: 'PFEAU',
        FlaggedQuestions: 'PFLAQ',
        FlaggedUsers: 'PFLAU',
        FollowingUsers: 'PFOLU',
        LikedQuestions: 'PLIKQ',
        LikedUsers: 'PLIKU',
        LinkedQuestions: 'XLINQ',
        ModeratedQuestions: 'PMODQ',
        ModeratedUsers: 'PMODU',
        RespondedQuestions: 'PRESQ',
        SharedQuestions: 'PSHAQ',
        SharedUsers: 'PSHAU',
        SponsoredQuestions: 'PSPOQ',
        SponsoredUsers: 'PSPOU',
        StealthedQuestions: 'PSTEQ',
        TaggedQuestions: 'PTAQT',
        UserFeedQuestions: 'PALLQ',  
        UserHashtagQuestions: 'PHSHT',
        UserHashtags: "PHSHT",
        UserTags: 'PUSRT',
    }
    
    export const Feeds={       
        AdminMessages:"ADMIN",
        AllHashtags: 'ZHSHT',
        AllQuestions: 'ZALLQ', 
        AllTimedQuestions: 'ZTIMQ',
        AllUsers: "ZALLU",
        ChildQuestions: 'RLINQ',
        ChoiceOrderUsers: 'FCORD',
        ChoiceUsers: 'FORDR',
        ParentQuestions: 'ZLINQ',
        PremiumProfile:"UPREM",
        QuestionView: 'FVIEW',
        RecentChattedQuestions: 'ZCHAQ',
        TaggedQuestions:"ZTAGQ",
        UserProfile:"UPROF",
        UserPublicQuestions: 'PALLQ',
    }
