import React from "react";
import { connect } from "react-redux";
import { AvatarSm } from "../../shared/Avatars";
import { initials } from "../../shared/utilities";
import { hidePanel, toggleLeftPanel } from "../../redux/actions/panel-actions";

const mapStateToProps = (state) => {
   return {
      feedId: state.context.view.feedId,
      profile: state.profile.currentUser,
      viewProfileId: state.context.view.profileId,
      profileIsLoaded: state.profile.userProfileIsLoaded
   };
};

const UserFeedSelector = React.memo(function UserFeedSelector({ profile,  feedId, profileIsLoaded, dispatch }) {
   if (!profileIsLoaded) return (<span/>);
   const { avatarURL } = profile;

   const handleDrawerToggle = () => {
      dispatch(hidePanel());
      dispatch(toggleLeftPanel());
   };

   const inits = initials(profile);
      return (
         <div className=" text-center pointer navbaricon d-flex py-1 ">
            <span onClick={()=>handleDrawerToggle()} >
            <AvatarSm url={avatarURL} initials={inits} />
            </span>
         </div>
      )

})

export default connect(mapStateToProps, null)(UserFeedSelector);