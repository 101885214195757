import React, { useEffect } from "react";
import "../index.css";
import { connect } from "react-redux";
import ItemFeedDisplay from "./feedDisplays/ItemFeedDisplay";
import { getQuestionFeed, setFeedSubscription, viewQuestionReceived } from "../../redux/actions/feed-actions";
import { API, graphqlOperation } from "aws-amplify";
import * as subscriptions from "../../graphql/lyqnessSubscriptions"
import { title } from "../../shared/Titles";
import Similarity from "../question/core/MOS/Similarity";
import { Feeds, PublicFeeds, UserFeeds } from "../../constants/feedids";
import { LyqnessId, SystemId } from "../../constants/profile";
import { closePanels } from "../../redux/actions/panel-actions";
import QuestionContainer from "../question/QuestionContainer";
import { Icons } from "../../shared/QIcons";
import LinkPrevious from "../links/LinkPrevious";
import UserFeedSelector from "../navbar/UserFeedSelector";
import AvatarLink from "../profile/AvatarLink";
import LyqnessProfileSelector from "../navbar/LyqnessProfileSelector";
import FeedHeader from "./feedDisplays/FeedHeader";

const mapStateToProps = state => {
    return {
        feed: state.feed,
        profile: state.profile.viewProfile,
        userId: state.profile.currentUser.profileId,
        firstName: state.profile.viewProfile.firstName,
        viewContext: state.context.view
    };
};

const QuestionFeed = ({ viewContext, firstName, feed, userId, dispatch, profile }) => {
    const { items, nextToken, cmosr: { value: cmosrValue } } = feed;
    const { profileId, targetId, questionId, feedId, tag, name, heading } = viewContext;
    const isLinkView = feedId === Feeds.ParentQuestions || feedId === Feeds.ChildQuestions;

    useEffect(() => {
        async function onLoad() {
            if(!profileId)
                return;
            dispatch(closePanels());
            loadNextPage(userId);
            let subscription = null;
            // if (profileId === SystemId) {
            // subscription = API.graphql(
            //     graphqlOperation(subscriptions.onPushPublicQuestion, { targetId: profileId })
            // ).subscribe({
            //     next: ({ provider, value: { data: { onPushPublicQuestion: question } } }) => {
            //         dispatch(viewQuestionReceived({ question }));
            //         console.log("view question received")
            //     },
            //     error: (error) => console.warn(error)
            // });
            // dispatch(setFeedSubscription(subscription));
            // }
            return;
        }
        if (feedId !== Feeds.UserProfile)
            onLoad();
        return () => {
            dispatch(setFeedSubscription(null));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feedId, profileId, targetId, tag]);

    if (!profileId) return (<span />);

    function loadNextPage(userId) {
        switch (feedId) {
            case Feeds.QuestionView:
                dispatch(getQuestionFeed({ questionId: questionId, profileId: profileId, feedId: feedId, nextToken: nextToken, dispatch: dispatch }))
                break
            case Feeds.ChildQuestions:
            case Feeds.ParentQuestions:
            case PublicFeeds.TaggedQuestions:
                dispatch(getQuestionFeed({ viewProfileId: profileId, profileId: userId, targetId: targetId, tag: tag, feedId: feedId, nextToken: nextToken, dispatch: dispatch }))
                break;
            case Feeds.TaggedQuestions:
                dispatch(getQuestionFeed({ viewProfileId: profileId, targetId: SystemId, tag: tag, feedId: feedId, nextToken: nextToken, dispatch: dispatch }))
                break;
            default:
                dispatch(getQuestionFeed({ viewProfileId: profileId, targetId: profileId, tag: tag, feedId: feedId, nextToken: nextToken, dispatch: dispatch }))
                break;
        }
    }

    const selector = () => {
        if (profileId === SystemId)
            return <LyqnessProfileSelector />
        else if (profileId === userId)
            return <UserFeedSelector />
        else
            return <AvatarLink profile={profile} />
    }

    const renderItem = (item, idx) => {
        return (
            <QuestionContainer key={`${item.questionId}${idx}`} displayQuestion={item} cnt={idx} />
        )
    }

    return (
        <div className="lyqcard shadow-none">

            {targetId !== LyqnessId ?
                <FeedHeader
                    avatar={selector()}
                    title={title(feedId, name ?? firstName, tag)}
                    titleFont="panelHeader-text h6"
                    topRight={<Similarity value={cmosrValue} />}
                />
                :
                <div className="brand3-bg  pl-2 ">
                    <div className={"m-0 pt-2 brand4 h5 pb-2 text-left h5"} >
                        <i className={Icons.INFORMATION + "mr-1"} ></i>{heading}
                        <span className="float-right w-25 small mr-2 pt-1">
                            <small>
                                <Similarity value={cmosrValue} />
                            </small>
                        </span>
                    </div>
                </div>
            }
            {items.length === 0 && isLinkView && <LinkPrevious />
            }
            <ItemFeedDisplay
                key={"question" + feed.currentFeedId}
                isLoading={feed.isLoading}
                isFirstFeed={feed.isFirstFeed}
                hasMore={feed.hasMore}
                feedId={feed.feedId}
                items={items}
                renderItem={renderItem}
                loadNextPage={loadNextPage}
                noLoadStr="no questions in feed"
            />

        </div>
    );
}

export default connect(mapStateToProps, null)(QuestionFeed);
