import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "../index.css";
import { onError } from "../../shared/errorLib";
import { getUserFeed } from "../../redux/actions/feed-actions";
import UserBubble from "../profile/UserBubble";
import { WhoFeeds } from "../../constants/feedids";
import { Icons } from "../../shared/QIcons";
import ItemFeedDisplay from "./feedDisplays/ItemFeedDisplay";
import Panel from "../panel/Panel";
import UserSearch from "../search/UserSearch";

const mapStateToProps = state => {
    return {
        feed: state.profile.feed,
        panel: state.context.panel,
        firstName: state.profile.viewProfile.firstName,
    };
};

const UsersFeed = React.memo(function UsersFeed({ feed, panel, dispatch }) {
    const { items, isLoading,nextToken } = feed;
    const { targetId, feedId } = panel;
    const [searchStr, setSearchStr] = useState("");
    useEffect(() => {
        async function onLoad() {
            try {
                loadNextPage();
            }
            catch (e) {
                if (e !== 'No current user') {
                    onError(e);
                }
            }
        };

        onLoad();

    }, [panel]);


    function loadNextPage() {
        dispatch(getUserFeed({ feedId: feedId, targetId: targetId, nextToken: nextToken }));
    }

    const renderItem = (item, idx) => {
        const { profileId } = item;
        return (
            <UserBubble key={profileId} hideChat={feedId === WhoFeeds.ChattedUsers} displayProfile={item} />
        )
    }

    const handleSearch = (str) => {
        setSearchStr(str);
        if (str || (!str && searchStr))
            loadNextPage(str);
    };

    if(!isLoading)
    return (
        <div className="lyqcard shadow-none">
            <Panel
                avatar={<i className={Icons.USERS + "fa-lg m-1 pt-2 white-text"} ></i>}
                title={<span className="h5 "> Users</span>}
                bottomRight={<UserSearch/>}
            />
            <div className="px-0">
                <ItemFeedDisplay
                    key={"question" + feed.currentFeedId}
                    isLoading={isLoading}
                    hasMore={nextToken !== null}
                    items={items}
                    isPanel={true}
                    renderItem={renderItem}
                    loadNextPage={loadNextPage}
                    noLoadStr="no responses"
                />
            </div>
        </div>
    )
    else
        return <span/>
})

export default connect(mapStateToProps, null)(UsersFeed);