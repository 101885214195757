import {
    ProfileActions as Actions,
    FeedActions,
    AppsyncActions,
    ContextActions
} from "../constants/actions";

import { ViewType, SystemId } from "../../constants/profile";
import { ItemFeed } from "../../constants/feedData";
import global from "../../constants";

// Initial State
const initialState = {
    identityId: null,
    checkedProfiles: [],
    isLoaded: false,
    authenticated: false,
    profileWithStatsLoaded: false,
    profileIsLoaded: false,
    viewProfileIsLoaded: false,
    userProfileIsLoaded: false,
    systemProfileIsLoaded: false,
    currentViewProfileId: null,
    pendingInteractions: [],
    viewType: ViewType.MainView,
    viewProfile: {
        profileId: SystemId,
        profileFeed: null,
        stats: [],
        tagFeed: ItemFeed,
    },
    feed: ItemFeed,
    cache: null,
    currentUser: {},
    systemUser: {},
    listType: 0,
    questionId: "",
    isSelect: false,
    profileModalIsOpen: false,
};

const handleSignOut = () => ({ ...initialState });

// Reducer Function
function profileReducers(state = initialState, action) {
    switch (action.type) {
        case Actions.PROFILE_INTERACTION_REQUESTED:
            return handleProfileInteractionRequested(state, action);

        case Actions.HASHTAGS_RECEIVED:
            return handleHashtagsReceived(state, action);

        case Actions.PROFILE_INTERACTION_PROCESSED:
            return handleProfileInteractionProcessed(state, action);

        case FeedActions.USER_FEED_REQUESTED:
            return handleUserFeedRequested(state);
        case Actions.VIEW_PROFILE_REQUESTED:
            if (action.payload.profileId === state.viewProfile.profileId)
                return state
            else
                return { ...state, viewProfileIsLoaded: false }
        case Actions.VIEW_PROFILE_RECEIVED:
            {
                let profile = action.payload;
                profile.initials = profile.firstName.substring(0, 1) + profile.lastName.substring(0, 1);
                profile.fullName = `${profile.firstName} ${profile.lastName}`;
                profile.tagFeed = [];
                global.viewProfile = profile;
                return Object.assign({}, state, {
                    ...state,
                    viewProfile: profile,
                    viewProfileIsLoaded: true
                });
            }
        case FeedActions.USER_FEED_RECEIVED:
        case AppsyncActions.ASSOCIATED_USER_FEED_RECEIVED:
            return handleUserFeedReceived(state, action);
        case Actions.CLEAR_PROFILE_FEED_REQUESTED:
            return handleClearProfileFeed(state, action);
        case Actions.PROFILE_SUBSCRIPTION_RECEIVED:
            return handleProfileSubscriptionReceived(state, action);

        case Actions.SIGN_OUT:
        case "SIGN_OUT":
            return handleSignOut();

        default:
            return state;
    }
}

// Action Handlers

const handleProfileInteractionRequested = (state, action) => {
    const { associatedId, action: interaction } = action.payload;
    if (interaction === "FOLU") {
        return {
            ...state,
            pendingInteractions: [...state.pendingInteractions, associatedId],
        };
    }
    return state;
};


const handleHashtagsReceived = (state, action) => {
    const { hashTag } = action.payload;
    let newTags = state.currentUser.tags ?? [];

    const index = newTags.findIndex(item => item.tag === hashTag.tag);
    if (index !== -1) {
        if (hashTag._deleted) {
            newTags.splice(index, 1);
        } else {
            newTags[index] = hashTag;
        }
    } else if (!hashTag._deleted) {
        newTags.unshift(hashTag);
    }

    return {
        ...state,
        currentUser: { ...state.currentUser, tags: newTags },
    };
};

const handleProfileInteractionProcessed = (state, action) => {
    const { profileFeed } = action.payload;
    const { associatedId } = profileFeed;

    return {
        ...state,
        feed: {
            ...state.feed,
            items: state.feed.items.map(el =>
                el.profileId === associatedId ? { ...el, myProfileFeed: profileFeed } : el
            ),
        },
    };
};

const handleClearProfileFeed = (state) => ({
    ...state,
    feed: { ...state.feed, isLoading: true, items: [], isLoaded: false },
});

const handleUserFeedRequested = (state) => ({
    ...state,
    feed: { ...state.feed, isLoading: true, items: [] },
});

const handleUserFeedReceived = (state, action) => {
    const { nextToken, items, isFirstFeed, feedId, associatedId, choiceId } = action.payload;
    const newArray = isFirstFeed ? items : [...state.feed.items, ...items];

    return {
        ...state,
        feed: {
            ...state.feed,
            items: newArray,
            hasMore: nextToken !== null,
            currentFeedId: feedId,
            questionId: associatedId,
            currentChoiceId: choiceId,
            isFirstFeed,
            profileFeedLoaded: true,
            isLoaded: true,
            isLoading: false,
            index: newArray.reduce((map, obj, i) => {
                map[obj.profileId] = i;
                return map;
            }, {}),
        },
    };
};

const handleProfileSubscriptionReceived = (state, action) => {
    const { profile } = action.payload;
    const userProfile = {
        ...profile,
        initials: profile.firstName[0] + profile.lastName[0],
        fullName: `${profile.firstName} ${profile.lastName}`,
    };

    if (userProfile.profileId === SystemId) {
        return {
            ...state,
            authenticated: true,
            systemUser: { ...userProfile, tags: state.systemUser.tags },
            systemProfileIsLoaded: true,
        };
    } else {
        return {
            ...state,
            authenticated: true,
            currentUser: { ...userProfile, tags: state.currentUser.tags },
            userProfileIsLoaded: true,
        };
    }
};

export default profileReducers;
