import React from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { ViewType } from "../../constants/profile";
import FollowsBadge from "./FollowsBadge";
import { PublicFeeds} from "../../constants/feedids";

const mapStateToProps = (state, ownProps) => {
    return {
        currentFeedId: state.feed.currentFeedId,
        view: state.context.view,
        isOpen: state.context.panel.isOpen,
        currentProfileView: state.profile.currentProfileView,
        userId: state.profile.currentUser.profileId,
        userName: state.profile.currentUser.fullName,
        currentUser: state.profile.currentUser
    };
};

const ProfileLink2 = React.memo(function ProfileLink2({ view, profile, userId, profile: { firstName, lastName, profileId, profileFeed, category, conversation } }) {
    const { profileId: viewProfileId, viewType } = view;
    let userIsFollowing = false;
    if (userId && userId !== profileId) {
        userIsFollowing = profileFeed && profileFeed.userFollows ? true : false;
    }

    const isOwner = profileId === userId;
    const profileName = `${firstName} ${lastName} ${isOwner ? " (you)" : ""}`;
    const isActive = profileId === viewProfileId && (viewType === ViewType.PublicView || viewType === ViewType.UserView);

    if (isActive)
        return (
            <span className={"text-muted"}> {profileName}<FollowsBadge userIsFollowing={userIsFollowing} /></span>
        )
    else
            if (category === "User" )
                return (
                    <Link to="/publicView" style={{ textDecoration: 'none' }}
                        state={{ profileId: profileId, feedId: PublicFeeds.UserFeedQuestions }}>
                        <span className={isActive ? "brand3" : " activeColor"}> {profileName}
                            <FollowsBadge userIsFollowing={userIsFollowing} /></span>
                    </Link>
                )

})

export default connect(mapStateToProps, null)(ProfileLink2);

