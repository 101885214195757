//******     URLs      ******/
export const MyURLs ={
    QUESTIONFEED:"/question/feed",
    ADD:"/question/add",
    DELETE:"/question/delete",
    GET:"/question/{id}",
    UPDATE:"/question/update",
    SHARE:"https://lyqness.com/app/q/",
    VIEW:"https://lyqness.com/view/",
    INTERACTION:"/question/interaction",
    PROFILE_INTERACTION:"/profile/interaction",
    BEACON:"/question/beacon",
    RESPOND:"/question",
    QUESTION_VIEW:"/question/view",
    VIEW_QUESTION:"/question/view", 
    PROFILE_FEED:"/profile/feed",
    PROFILE_UPDATE:"/profile/update",
    CHAT_START:"/chat/start",
    QUESTION_PREVIEW:"https://lyqness.com/view",
    IMAGE_URL:"https://images.lyqness.com/",
    AUTHENTICATION_URL:"https://cognito.lyqness.com",
    API_URL: "https://lyqness.com/s"
}

export const IMAGE_URL = "https://images.lyqness.com/";