import {ModalActions} from "../../constants/modal"

export function showModal(payload) {
  return {
    type: ModalActions.SHOW_MODAL,
    payload
  }
};

export function toggleAuthModal(payload) {
  return {
    type: ModalActions.TOGGLE_AUTH_MODAL,
    payload
  }
};



export function showLoginModal(data) {
  const payload={
  modalType: 'LOGIN_MODAL',
  modalProps: {
     data:data
  }}
  return {
    type: ModalActions.SHOW_MODAL,
    payload
  }
};


export function showMsgModal(data) {
  const payload={
  modalType: 'MSG_MODAL',
  modalProps: {
    title: data.title,
    content: data.content
  }}
  return {
    type: ModalActions.SHOW_MODAL,
    payload
  }
};

export function showQRModal(data) {
  const payload={
  modalType: 'QR_MODAL',
  modalProps: {
    title: data.title,
    content: data.content
  }}
  return {
    type: ModalActions.SHOW_MODAL,
    payload
  }
};

export function showConfirmLinkModal(data) {
  const payload={
  modalType: 'CONFIRM_LINK_MODAL',
  modalProps: {
    questionId: data.questionId,
    handleConfirm:data.handleConfirm
  }}
  return {
    type: ModalActions.SHOW_MODAL,
    payload
  }
};

export function showStealthMsgModal(data) {
  const payload={
  modalType: 'STEALTH_MSG_MODAL',
  modalProps: {
    handleConfirm:data.handleConfirm
  }}
  return {
    type: ModalActions.SHOW_MODAL,
    payload
  }
};

export function showConfirmModal(data) {
    const payload={
    modalType: 'CONFIRM_MODAL',
    modalProps: {
      content: data.content,
      handleConfirm: data.handleConfirm,
      title:data.title
    }}
    return {
      type: ModalActions.SHOW_MODAL,
      payload
    }
  };

  export function showTaglinkModal(data) {
    const payload={
    modalType: 'TAG_LINK_MODAL',
    modalProps: {
      displayQuestion: data.displayQuestion
    }}
    return {
      type: ModalActions.SHOW_MODAL,
      payload
    }
  };

  export function showTagModal(data) {
    const payload={
    modalType: 'TAG_MODAL',
    modalProps: {
      feedId: data.feedId,
      profileId: data.targetId,
      name:data.name
    }}
    return {
      type: ModalActions.SHOW_MODAL,
      payload
    }
  };

export function showPreviewModal(data) {
  const payload={
  modalType: 'PREVIEW_MODAL',
  modalProps: {
    title: data.title,
    targetId: data.targetId
  }}
  return {
    type: ModalActions.SHOW_MODAL,
    payload
  }
};

export function showProfileListModal(data) {
  const payload={
  modalType: 'PROFILE_LIST_MODAL',
  modalProps: {
    title: "Select Profiles",
    handleSelected: data.handleSelected,
    questionId: data.questionId,
  }}
  return {
    type: ModalActions.SHOW_MODAL,
    payload
  }
};


export function showQuestionModal(data) {
    const payload={
    modalType: 'QUESTION_MODAL',
    modalProps: {
        ...data,
    } }
    return {
      type: ModalActions.SHOW_MODAL,
      payload
    }
  };
  

export function hideModal(){
  return{
    type: ModalActions.HIDE_MODAL
  }
};
