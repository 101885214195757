import React from "react";
import { connect } from "react-redux";
import { ViewType } from "../constants/profile";
import { Feeds } from "../constants/feedids";
import { setView } from "../redux/actions/context-actions";


const mapStateToProps = (state, ownProps) => {
  return {
    profileId: state.profile.currentUser
  };
};

const QuestionView = React.memo(function QuestionView({ profile, props, feedId, dispatch }) {
  if (profile) {
    const { profileId } = profile;
    let feedToLoad = feedId ?? props.location.state ? props.location.state.feedId : Feeds.QuestionView;
    const viewToLoad = ViewType.QuestionView;


    dispatch(setView({ viewType: viewToLoad, profileId: profileId, feedId: feedToLoad }));

    return (
      <></>
    )
  }
  else
    return <span />
})


export default connect(mapStateToProps, null)(QuestionView);