import React, { useEffect } from "react";
import { Icons } from "../../shared/QIcons";
import "./index.css";

export const ChatImageEdit = React.memo(function ChatImage({ imageUrl, handleLoad, isEditing, right, handleClick }) {

    useEffect(() => {
        loadImage(imageUrl,handleLoad);

// eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

        return (
            <div className="d-flex text-right ml-auto mr-1 " >
            <div className="chatImage">
                <img src={imageUrl}  alt="..." />
                 <i className={Icons.REMOVE + " fa-lg white-text brand0 pointer"}  onClick={handleClick}></i>   
            </div>
            </div>
        )
})

function loadImage(imageUrl,handleLoad) {
    var im = document.createElement("img");
    im.onload=function() {handleLoad('image load')} // assign before src
    im.src=imageUrl;
}

export const ChatImageDisplay = React.memo(function ChatImage({ imageUrl, handleLoad}) {
    if(handleLoad)
        loadImage(imageUrl,handleLoad);
    return (
        <div className="chatImage">
            <img  src={imageUrl} alt="..." />  
        </div>
    )
})

