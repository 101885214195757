import React from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { hidePanel, toggleLeftPanel } from "../../redux/actions/panel-actions";
import FollowsBadge from "../links/FollowsBadge";
import AvatarLink from "./AvatarLink";
import FeedHeader from "../feeds/feedDisplays/FeedHeader";
import BasicProfileLink from "../../shared/BasicProfileLink";
import { PublicFeeds, UserFeeds } from "../../constants/feedids";

const mapStateToProps = (state) => {
    return {
        viewContext: state.context.view,
        userId: state.profile.currentUser.profileId,
    };
};

const ProfileHeader = React.memo(function ProfileHeader({ viewContext, profileStub, profileFeed, userId, profile, background, title, topRight, bottomLeft, bottomCenter, bottomRight, dispatch }) {
    const navigate = useNavigate();
    const { firstName, lastName, profileId, identityId } = profileStub;
    const { profileId: viewProfileId } = viewContext;
    const isActive = profileId === viewProfileId;
    const isOwner = userId === profileId;
    let userIsFollowing = false;
    const userName = firstName + " " + lastName;

    if (userId && !isOwner) {
        userIsFollowing = profileFeed && profileFeed.stats.FOLU ? true : false;
    }

    function loadFeed() {
        if (isOwner) {
            navigate('/feed');
        }
        else {
            navigate(`/publicView`, { state: { profileId: profileId } });
        }
    }

    const handleDrawerToggle = () => {
        dispatch(hidePanel());
        dispatch(toggleLeftPanel());
    };

    const TitleText = () => {
        if (title) {
            <div>
                <span className={"brand3 "}> {title}</span>
            </div>
        }
        else
            if (isActive)
                return (
                    <span className={"brand4 "} onClick={() => handleDrawerToggle()}> {userName} <small>{isOwner ? " (you)" : ""}</small>
                        <FollowsBadge userIsFollowing={userIsFollowing} />
                    </span>
                )
            else
                if (isOwner)
                    return (
                        <span onClick={() => loadFeed()}>
                            {userName} <small>{isOwner ? " (you)" : ""}</small>
                        </span>
                    )
                else
                    return (
                        <span onClick={() => loadFeed()}>
                            {userName} <small>{isOwner ? " (you)" : ""}</small>
                            <FollowsBadge userIsFollowing={userIsFollowing} />
                        </span>
                    )
    };

    return (
        <div >
            <div className="d-flex py-0">
                <div className="ps-1">
                    <AvatarLink profile={profileStub} />
                </div>
                <div className="w-100 ms-1">
                    <div className="d-flex justify-content-between brand0 fw-bold align-items-center mb-0">
                        {<TitleText/>}
                        <span className="pe-2">{topRight}</span>
                    </div>
                    <div className="line  justify-content-between align-items-center mb-0 pe-2">
                        <div className="row ">
                            <div className="col">{bottomLeft}</div>
                            <div className="col">{bottomCenter}</div>
                            <div className="col"><span className="float-end">{bottomRight}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

})

export default connect(mapStateToProps, null)(ProfileHeader);

