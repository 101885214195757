import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../shared/QIcons";
import { hidePanel, toggleLeftPanel } from "../../redux/actions/panel-actions";
import { SystemId } from "../../constants/profile";
import { setView } from "../../redux/actions/context-actions";
import { Feeds } from "../../constants/feedids";
import ShowLgUp from "../../shared/ShowLgUp";
import ShowMdDn from "../../shared/ShowMdDn";

const mapStateToProps = (state) => {
   return {
      profileId: state.context.view.profileId,
   };
};

const HomeSelector = React.memo(function HomeSelector({ profileId, dispatch }) {
   const navigate = useNavigate();
   const isSelected = profileId === SystemId;

   function loadFeed() {
      if (isSelected)
         handleDrawerToggle();
      else {
         navigate('/');
         dispatch(setView({ profileId: SystemId, targetId: SystemId, feedId: Feeds.AllQuestions }));
      }
   }

   const handleDrawerToggle = () => {
      dispatch(hidePanel());
      dispatch(toggleLeftPanel());
   };

   function iconToShow() {
      if (isSelected)
         return Icons.HOME + " brand3";
      else
         return Icons.HOME;
   }

   return (
      <div className=" text-center pointer activeColor" onClick={() => loadFeed()}>
         <ShowLgUp> <i className={Icons.HOME + "fa-lg"}></i><span className="ml-1 pt-1">Home{isSelected && "*"}</span></ShowLgUp>
         <ShowMdDn>  <i className={iconToShow()}></i><div className="col-12 footerlabel">Home{isSelected && "*"}</div></ShowMdDn>
      </div>
   )
})

export default connect(mapStateToProps, null)(HomeSelector);