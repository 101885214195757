import React from "react";
import { connect } from "react-redux";
import { PanelType } from "../../constants/panel";
import { setPanelView } from "../../redux/actions/context-actions";
import LinesEllipsis from 'react-lines-ellipsis'
import "./index.css";
import { chatType } from "../../redux/constants";
import { Icons } from "../../shared/QIcons";
import { UserFeeds } from "../../constants/feedids";
import { clearMessages, updateLastConversationViewTime } from "../../redux/actions/chat-actions";

const mapStateToProps = (state, ownProps) => {
    return {
        feedId:state.context.panel.feedId,
        name:state.context.panel.name,
        convoType:state.context.panel.chatType,
        conversationId:state.chat.messages.conversationId,
        hasConvos:state.chat.conversations.length>0
    };
}; 



const ChatHeader = React.memo(function ChatHeader({ name, conversationId,convoType, dispatch }) {
    const isUserConvo = convoType===chatType.CHAU;
    const showUserConversations = () => {
        dispatch(updateLastConversationViewTime({conversationId:conversationId}))
        dispatch(clearMessages());
        dispatch(setPanelView({panelType:PanelType.Conversations,feedId:UserFeeds.User}));
    }
    function ChatName(){
        switch (convoType) {
            case chatType.CHAU:
                return  (<div className="row">
                            <div className="px-1 "><i className={`${Icons.USER} fa-lg`} ></i><sup><i className={`${Icons.CHAT}  brand1`} ></i></sup></div>
                            <div className="px-1"> <LinesEllipsis text={name} maxLine='2' /></div>
                </div>)
            default:
                return  (<div className="row">
                            <div className="px-2"><i className={`${Icons.QUESTIONS} fa-lg`} ></i><sup><i className={`${Icons.CHAT}  brand1`} ></i></sup></div>
                            <div className="px-2"> <LinesEllipsis text={name} maxLine='2' /></div>
                </div>)
        }
    }

    return (
        <div className="container px-2 ">
            <div disabled className="w-100 brand0  h6 p-1 mb-0">
                <ChatName/>
                {isUserConvo && <link className="btn-sm px-1 py-0 flat white-text brand0 float-end pointer" onClick={()=>showUserConversations()}>chats</link>}
            </div>
        </div>
    )
})

export default connect(mapStateToProps,null)(ChatHeader);