import React from "react";
import { connect } from "react-redux";
import ShareMenu from "../../components/menus/ShareMenu";


const mapStateToProps = (state, ownProps) => {
  return { 
 //     displayQuestion: state.feed.items[state.feed.index[ownProps.questionId]],
             viewProfileId: state.context.view.profileId
          };
};

//checked

const ShareLink = React.memo(function ShareLink({displayQuestion, profileId} ) {
    const {choices, profileStub, userFeed} = displayQuestion;
    let fullName =`${profileStub.firstName} ${profileStub.lastName}`;    
    const {stats:{SHAQ}} = userFeed; 
    return(
      <span>
          <ShareMenu displayQuestion={displayQuestion} hasShared={SHAQ} ownerName={fullName} choices={choices}  />
        </span>     
    )

})

export default connect(mapStateToProps,null)(ShareLink);


