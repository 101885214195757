import React from "react";
import "../index.css";
import { Icons } from "../../shared/QIcons";
import { connect } from "react-redux";
import Notify from "../notify/Notify";
import HideLgUp from "../../shared/HideLgUp";
import HideLgDn from "../../shared/HideLgDn";

const mapStateToProps = (state, ownProps) => {
    return {
        isOpen: state.context.panel.isOpen,
    };
};

const Search = React.memo(function Search({ isMenu, isOpen, currentStr, handleSearch }) {
    // const [searchStr, setSearchStr] = React.useState("");
    // var isDisabled = (searchStr === "" && !currentStr) || (currentStr && searchStr === currentStr);

    // const canReset = searchStr !== "" || (currentStr && currentStr !== "");

    // const handleOnKeyDown = (e) => {
    //     if (e.key === 'Enter') {
    //         if (!isDisabled) handleSearchClick();
    //     }
    // }

    // const handleResetClick = (e) => {
    //     setSearchStr("");
    //     if (currentStr !== "")
    //         handleSearch("");
    // }

    // const handleSearchClick = (e) => {
    //     handleSearch(searchStr);
    // }

    if (isOpen)
        return (
            <div className="text-center pointer inactiveColor" >
                <i className={Icons.SEARCH + " fa-lg pt-2"} onClick={() => Notify("feature not implemented yet")}></i>
            </div>
        )
    else
        return (
            <div className=" text-center pointer inactiveColor" onClick={() => Notify("feature not implemented yet")}>
                <HideLgUp>
                    <div><i className={Icons.SEARCH + "fa-lg"}></i><span className="ml-1 pt-1">Search</span></div>
                </HideLgUp>
                <HideLgDn>
                    <div><i className={Icons.SEARCH}></i><div className="col-12 footerlabel">Search</div></div>
                </HideLgDn>
            </div>

            // <Input
            //     className="pl-1 py-0 rounded search"
            //     value={searchStr}
            //     disableUnderline
            //     onChange={(e) => setSearchStr(e.target.value)}
            //     endAdornment={<InputAdornment position="end">
            //                 {canReset? <i onClick={() => handleResetClick()} className="fal fa-undo fa-sm brand1 pointer mr-1" ></i> :
            //                 <i className="fal fa-undo white-text mr-1" ></i>}
            //         </InputAdornment>}
            //     onKeyDown={(e) => handleOnKeyDown(e)}
            //     placeholder="Search"
            //     aria-label="Search"
            // />
        )

})
export default connect(mapStateToProps, null)(Search);
