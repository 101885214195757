import dayjs from "dayjs";
import { combinations, permutations } from "../../../shared/utilities";
import LotteryInstructions from "./LotteryInstructions";
import { DateElapsed, DateShort } from "../../../shared/Dates";
import { DateCountdown } from "../../../shared/Countdown";

export default function LotteryOdds({ buckets, minSel, respond, endTime, isOrdered }) {
    let odds = 0;
    if (minSel && buckets) {
        if (isOrdered)
            odds = permutations(buckets, minSel);
        else
            odds = combinations(buckets, minSel);
    }

    const drawing = new Date(endTime);

    // Get the current time
    const now = new Date();
    
    // Calculate the difference in milliseconds
    const diffMs = drawing - now;

    let countDown='';
    // Ensure the difference is not negative
    if (diffMs > 0) {
      // Convert milliseconds to hours and minutes
      const hours = Math.floor(diffMs / (1000 * 60 * 60));
      const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
      console.log(hours);
    countDown = `${hours} hours and ${minutes} minutes`
    }

    const value = odds.toLocaleString('en-US');
    const oddsDisplay = `Odds of winning: 1 in ${value}`;
    return (
        <div className="m-1">
            {respond && <LotteryInstructions buckets={buckets} minSel={minSel} isOrdered={isOrdered} />}
            <div className="col-12 text-center small brand1">{oddsDisplay}</div>
            <div className="mx-auto text-center"> &nbsp;{<DateCountdown date={endTime}/>}</div>
        </div>
    )


};