import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import "../index.css";

// Extend Day.js with plugins
dayjs.extend(relativeTime);
dayjs.extend(duration);

export function DateElapsed({ date }) {
  const diffInHours = dayjs().diff(dayjs(date), 'hour'); // Difference in hours

  if (diffInHours >= 30) {
    return (
      <small className="time-text">{dayjs(date).format('MMM D, YYYY')}</small>
    );
  } else {
    return <small className="time-text">{diffInHours}</small>;
  }
}

export function DateShort({ date }) {
  return (
    <span className="time-text">
      {dayjs(date).toNow(true)} {/* Returns a human-readable difference (e.g., "2 hours") */}
    </span>
  );
}





  

