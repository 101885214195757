import React, { useEffect, useRef, useState } from "react";
import "../MoveMatchQuestions/MoveMatchChoices.css";
import { connect } from "react-redux";
import EditItemDisplay  from "../core/Choices/EditItemDisplay";
import ResultsSummary from "../core/ResultsSummary";
import RespondSummary from "../core/RespondSummary";
import { respondToQuestion } from "../../../redux/actions/question-actions";
import QuestionUserCount from "../core/Choices/QuestionUserCount";
import InputSelector from "../core/Choices/InputSelector";
import { SelectorType } from "../../../constants/question";
import {  pictureChoiceUrl, smsImageUrl } from "../Utilities";
import FullscreenImageViewer from "../MoveMatchQuestions/FullScreenImageViewer";

const mapStateToProps = (state) => {
  return {
    viewProfileId: state.context.view.profileId,
  };
};

const RespondToPictureQuestion = ({ question, viewProfileId, dispatch }) => {
  const { questionId, isOrdered, isMultipleChoice,minSel, maxSel, userFeed: {  stats: { RESQ } }, creator:{identityId} } = question;
  const [choices, setChoices] = useState(question.choices);
  const [checkedItems, setCheckedItems] = useState([]);
  const [canSubmit, setCanSubmit] = useState(false);
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const selector = isOrdered ? SelectorType.Counter : isMultipleChoice ? SelectorType.Multi : SelectorType.Single;
  const timer = useRef()
  // Populate initial choices or defaults
  useEffect(() => {
    setChoices(question.choices)

  }, [question]);

  let canReset = checkedItems.length > 0;

  const handleSelection = (item) => {
    var newarray = checkedItems.slice();
    if (selector === SelectorType.Single) {
      if (checkedItems.includes(item))
        newarray.pop()
      else {
        newarray.pop();
        newarray.push(item)
      }
    }
    if (selector === SelectorType.Multi || selector === SelectorType.Ordered || selector === SelectorType.Counter) {
      if (checkedItems.includes(item)) {
        const itemIndex = newarray.indexOf(item);
        newarray.splice(itemIndex, 1);
      }
      else {
        if (newarray.length < maxSel)
          newarray.push(item);
      }
    }

    setCheckedItems(newarray);
    if (newarray.length >= minSel)
      setCanSubmit(true)
    else
      setCanSubmit(false)
  }

  const handleSubmit = () => {
    var data = {
      "displayQuestion": question,
      "viewProfileId": viewProfileId,
      "selections": checkedItems,
    };
    dispatch(respondToQuestion({ ...data }))
  }

  const handleReset = () => {
    setCheckedItems([]);
    setCanSubmit(false);
  }

  const toggleFullscreenImage = (imageURL) => {
    setFullscreenImage(fullscreenImage === imageURL ? null : imageURL);
  };

  if (!RESQ)
    return (
      <div className="px-2">
        <FullscreenImageViewer fullscreenImage={fullscreenImage} identityId={identityId} toggleFullscreenImage={toggleFullscreenImage}/>
        <div className="row justify-content-center p-1">
          {choices.map((choice, index) => (
            <div key={questionId + index} className="col-6 grid-item p-1">
              <img
                src={pictureChoiceUrl({identityId, imageKey:choice.imageKey})}
                alt="Uploaded Preview"
                className="image-thumbnail"
                onClick={(e) => toggleFullscreenImage(smsImageUrl({ identityId, imageKey:choice.imageKey }))} // Open fullscreen
              />
              {/* Text input that updates immediately as the user types */}
              <div onClick={()=>handleSelection(index)}>
              <EditItemDisplay choiceId={index} question={question} >
                <span className="activeColor float-end">
                  <InputSelector selector={selector} id={index} order={checkedItems.indexOf(index) + 1} isChecked={checkedItems.includes(index)} handleSelection={handleSelection} />
                </span>
              </EditItemDisplay>
              </div>
            </div>
          ))}
        </div>
        <RespondSummary handleSubmit={handleSubmit} handleReset={handleReset} displayQuestion={question} canSubmit={canSubmit} canReset={canReset} />
      </div>
    );
  else {
    return (
      <div className="px-2">
        {fullscreenImage && (
          <div
            className="fullscreen-overlay"
            onClick={() => setFullscreenImage(null)}
          >
            <img
              src={smsImageUrl({identityId, imageKey:fullscreenImage})}
              alt="Fullscreen Preview"
              className="fullscreen-image"
            />
          </div>
        )}

        <div className="row justify-content-center p-1">
        <FullscreenImageViewer fullscreenImage={fullscreenImage} identityId={identityId} toggleFullscreenImage={toggleFullscreenImage}/>
          {choices.map((choice, index) => (
            <div key={questionId + index} className="col-6 grid-item p-1"> {/* Bootstrap col-5 for 2 items per row */}
              <img
                src={pictureChoiceUrl({identityId, imageKey:choice.imageKey})}
                alt="Uploaded Preview"
                className="image-thumbnail"
                onClick={(e) => toggleFullscreenImage(smsImageUrl({ identityId, imageKey:choice.imageKey }))} // Open fullscreen
              />
              <EditItemDisplay  choiceId={index} question={question} >
              <span className="activeColor float-end">
                <QuestionUserCount choiceId={index}  question={question} />
                </span>
              </EditItemDisplay>
            </div>
          ))}
        </div>
        <ResultsSummary key={questionId + "9"} displayQuestion={question} />
      </div>
    );
  }
};

export default connect(mapStateToProps, null)(RespondToPictureQuestion);
