import { useEffect } from "react";
import { prettyNum } from "../../../shared/utilities";

 const LotteryBallView=({ buckets, bucketStats, checkedAction, handleBallClick }) =>{
    const ballSize="50px";
    const balls = [];
    for (let i = 0; i < buckets; i++)
        balls.push(i);

    const handleClick=(i)=>{
        if(handleBallClick)
            handleBallClick(i)
    }

    return (
        <div className="row justify-content-center ">
            {balls.map((i) => (
                <div
                    key={i}
                    onClick={()=>handleClick(i)}
                    className="d-flex flex-column justify-content-center align-items-center rounded-circle text-black m-1"
                    style={{
                        width: `${ballSize}`,
                        height: `${ballSize}`,
                        background: "radial-gradient(circle at 30% 30%, #ffffff, #e0e0e0, #bdbdbd)",
                        boxShadow: "4px 4px 12px rgba(0, 0, 0, 0.3), -2px -2px 6px rgba(255, 255, 255, 0.6)",
                        color: "black",
                        border: "1px solid rgba(0, 0, 0, 0.1)",
                    }}
                >
                    <span style={{ fontSize: "1.2em", fontWeight: "bold", marginBottom: "0px", lineHeight: "1em" }} className="brand4">{i + 1}</span>
                    <span className="p-0 m-8 activeColor" style={{ fontSize: ".9em", color: "#189ad6" }} >
                        {checkedAction && checkedAction(i)}9.99k
                    </span>
                </div>
            ))}
        </div>
    )
};

export default LotteryBallView;