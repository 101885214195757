import React from "react";
import "../question/core/index.css";
import ProfileLink from "../links/ProfileLink";
import { connect } from "react-redux";
import { DateShort } from "../../shared/Dates";
import FollowLink from "../links/FollowLink";
import UserChoiceDisplay from "../question/core/UserChoiceDisplay";
import { PublicFeeds, UserFeeds, WhoFeeds } from "../../constants/feedids";
import TagList from "../tags/TagList";
import Similarity from "../question/core/MOS/Similarity";
import { computeQMOST } from "../../shared/MOS";
import { choicesLabel } from "../../constants/question";
import FeedHeader from "../feeds/feedDisplays/FeedHeader";
import AvatarLink from "./AvatarLink";

const mapStateToProps = state => {
    return {
        updatingComments: state.profile.updatingComments,
        displayQuestion: state.context.panel.displayQuestion,
        panel: state.context.panel,
        userId: state.profile.currentUser.profileId
    };
};

function QuestionUserBubble({ displayProfile, displayQuestion, panel, userId }) {
    const { questionId, isOrdered, choices, questionType, userFeed: { selections: userSelections },creator } = displayQuestion;
    const { feedId } = panel;
    const { profileId, firstName,  myProfileFeed, createdAt, userFeed: { selections,  tags } } = displayProfile;
    const isOwner = profileId === userId;
    const following = myProfileFeed  && myProfileFeed.stats.FOLU;
    const qmost = computeQMOST(userSelections, selections, questionType, isOrdered)

    function ProfileDetails(){
        if(feedId === WhoFeeds.QuestionsTagged)
            return (
            <div className="mt-4 small">
                <div className="border-bottom muted">Tags</div>
                <TagList tags={tags} profileId={profileId} name={firstName} feedId={userId === profileId ? UserFeeds.UserTags : PublicFeeds.UserTags} />
            </div>
            )
            else
            return (
                <UserChoiceDisplay
                    questionId={questionId}
                    questionType={questionType}
                    selections={selections}
                    isOwner={isOwner}
                    userSelections={userSelections}
                    isOrdered={isOrdered}
                    choices={choices}
                >{choicesLabel}
                </UserChoiceDisplay>
            )
    }

    return (
        <div key={profileId} className="white profileCard">
            <FeedHeader
                avatar={<AvatarLink profile={creator} />}
                title={<ProfileLink profile={displayProfile} />}
                headerColor={""}
                topRight={<span className="float-end"><DateShort date={createdAt} /></span>}
                bottomRight={!isOwner && <Similarity value={qmost} />}
                bottomLeft={
                    <FollowLink key={profileId} displayProfile={displayProfile} profileId={profileId} following={following}></FollowLink>
                }
                details={ProfileDetails()}
            />
        </div >
    )
}

export default connect(mapStateToProps, null)(QuestionUserBubble);