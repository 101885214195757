import React from "react";
import QuestionFooter from "./core/QuestionFooter";
import Question from "./core/Question";
import {QuestionResponder} from "./QuestionRouting";
import "../../index.css";
import TagList from "../tags/TagList";
import { UserFeeds } from "../../constants/feedids";
import { connect } from "react-redux";
import { QuestionAskType } from "../../constants/question";
import AdHeader from "./core/AdHeader";
import ProfileHeader from "../profile/ProfileHeader";
import QuestionMenu from "../menus/QuestionMenu";
import { DateShort } from "../../shared/Dates";
import FollowLink from "../links/FollowLink";
import QuestionImage from "./QuestionImage";

function mapStateToProps(state) {
    return {
        isObserver: state.profile.isPremiumUser,
    };
};

function QuestionContainer({ displayQuestion, isLink }) {
    try {
        const { questionId, imageURL, creator: ownerProfile, createdAt, tags: hashTags, questionType, questionText, userFeed } = displayQuestion;
        const following = ownerProfile && ownerProfile.myProfileFeed && ownerProfile.myProfileFeed.stats.FOLU;
        const { tags, profileId} = userFeed;
        const profileStub=displayQuestion.profileStub;
        const isAd = questionType === QuestionAskType.Advertisement;
        return (
            <div className="questioncard"  >
                {isAd ?
                    <AdHeader key={questionId} displayQuestion={displayQuestion} questionId={questionId} />
                    :
                    <ProfileHeader key={questionId}
                        isLink={isLink}
                        profileStub= {profileStub}
                        topRight={<QuestionMenu question={displayQuestion} />}
                        background={"brand2-bg"}
                        bottomRight={<DateShort date={createdAt} />}
                        //bottomLeft={<FollowLink key={profileId} displayProfile={ownerProfile} profileId={profileId} following={following}></FollowLink>}
                    />
                }
               <div className="main-image text-center px-0">
                    <QuestionImage question={displayQuestion}/> 
                </div>
                <div className="">
                    {!isAd &&
                        <div className="py-1">
                            <Question key={questionId + "1"} questionId={questionId} questionType={questionType} tags={hashTags} questionText={questionText} noImage={imageURL ? 0 : 1} />
                        </div>
                    }
                    <div className="text-center">
                        <QuestionResponder key={questionId + "3"} displayQuestion={displayQuestion} />
                    </div>

                    <TagList tags={tags ?? []} placement={"end"} questionType={questionType} showIcon={true} targetId={profileId} feedId={UserFeeds.UserTags} />
                </div>
                <div className=" m-0 p-0 p-0">
                    <QuestionFooter key={questionId + "6"} displayQuestion={displayQuestion} />
                </div>
            </div>

        );
    }
    catch {
        console.log("question problem");
        alert("question render problem");
    }
}
export default connect(mapStateToProps, null)(QuestionContainer);