import React, { useEffect } from "react";
import { connect } from "react-redux";
import { hideModal } from "../../redux/actions/modal-actions";
import { PremiumQuestion, QuestionAskTitle, QuestionTypeIcon } from "../../constants/question";
import { resetDefinition } from "../../redux/actions/questionedit-actions";
import CreateEditQuestion from "../question/CreateQuestion/CreateEditQuestion";
import { Input, Modal, ModalDialog, Typography, IconButton, ModalClose } from "@mui/joy";

import CloseIcon from "@mui/icons-material/Close";

const mapStateToProps = (state) => {
    const { parentId, isNew, questionType, questionId } = state.modal.modalProps;
    return {
        isOpen: state.modal.modalType === "QUESTION_MODAL",
        questionId,
        parentId,
        questionType,
        isNew,
    };
};

const QuestionModal = React.memo(function QuestionModal({ isOpen, parentId, questionType, isNew, dispatch }) {
    useEffect(() => {
        return () => {
            // Clean up when modal is closed
            // dispatch(resetDefinition());
        };
    }, [isOpen, dispatch]);

    const toggle = () => {
        // dispatch(resetDefinition());
        dispatch(hideModal());
    };

    if (!isOpen) return null;

    return (
        <Modal open={isOpen} onClose={toggle}>
            <ModalDialog
                variant="outlined"
                layout="center"
                className="w-100"
                sx={{
                    maxWidth: "430px",
                    borderRadius: "lg",
                    boxShadow: "lg",
                    bgcolor: "background.surface",
                }}
            >
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: "10px" }}>
                    <Typography level="h5">
                        <small>{QuestionTypeIcon(questionType)}</small>
                        {isNew ? (parentId ? "Ask Related Question" : `New ${QuestionAskTitle(questionType)} Question`) : "Edit Question"}
                        <PremiumQuestion questionType={questionType} />
                    </Typography>
                    <IconButton variant="plain" size="sm" onClick={toggle}>
                        <CloseIcon />
                    </IconButton>
                </div>

                <CreateEditQuestion closeModal={toggle} isNew={isNew} />
            </ModalDialog>
        </Modal>
    );
});

export default connect(mapStateToProps, null)(QuestionModal);

