import React, { useEffect, useState } from "react";
import { Select, MenuItem, FormControl, InputLabel, TextField } from "@mui/material";
import LotteryOdds from "./LotteryOdds";
import LotteryBallView from "./LotteryBallView";
import { connect } from "react-redux";
import { updateEditQuestion } from "../../../redux/actions/questionedit-actions";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import dayjs from "dayjs";

const CreateLotteryQuestion = ({ dispatch }) => {
    const minBalls = 15;
    const maxBalls = 69;
    const minChoices = 3;
    const maxChoices = 8;
    var now = new Date();
    now.setMinutes(now.getMinutes() + 30);
    now.setMinutes(0);
    const [buckets, setbuckets] = useState(minBalls);
    const [minSel, setMinSel] = useState(minChoices);
    const [startTime, setStartTime] = useState(now);
    const [endTime, setEndTime] = useState(now);
    const [error, setError] = useState(null);
    const timeMsg = "Start time must be 1 hour from now. End time must be at least 1 hour after start.";

    useEffect(() => {
        var newChoices = [];
        let i;
        for (i = 1; i <= buckets; i++) {
            newChoices = [...newChoices, { "text": `${i}`, "imageKey": "" }];
        }
        dispatch(updateEditQuestion({
            question: {
                choices: newChoices,
                buckets: buckets,
                minSel: minSel,
                maxSel: minSel,
                endTime: endTime,
            }
        }));
    }, []);

    const handleBallsChange = (event) => {
        const balls = event.target.value;
        setbuckets(balls);
        const inRange = balls >= minBalls && balls <= maxBalls;
        if (inRange) {
            var newChoices = [];
            let i;
            for (i = 1; i <= balls; i++) {
                const ball = i;
                newChoices = [...newChoices, { "text": `${i}`, "imageKey": "" }];
            }
            dispatch(updateEditQuestion({
                question: {
                    choices: newChoices,
                    buckets: balls,
                }
            }));
        }
    };

    const checkStartTime = (newValue) => {
        const now = new Date();
        const minDate = new Date(now.getTime() + 60 * 60 * 1000); // 1hours from now
        if (newValue && newValue < minDate) {
            setError(timeMsg);
        } else {
            setError(<span>&nbsp;</span>);
        }
    };

    const checkEndTime = (newValue) => {
        const now = new Date();
        const minDate = new Date(now.getTime() + 60 * 60 * 1000); // 1 hour from start
        if (newValue && newValue < minDate) {
            setError(timeMsg);
        } else {
            setError(<span>&nbsp;</span>);
        }
    };

    const handleStartChange = (newValue) => {
        const now = new Date();
        const minDate = new Date(now.getTime() + 60 * 60 * 1000); // 24 hours from now
        if (newValue && newValue < minDate) {
            setError(timeMsg);
        } else {
            setError(null);
            setStartTime(newValue);
            //newValue.setMinutes (now.getMinutes() + 30);
            newValue.setMinutes(0);
            newValue.setSeconds(0);
            newValue.setMilliseconds(0);

            let isoTime = new Date(newValue).toISOString();
            dispatch(updateEditQuestion({
                question: {
                    startTime: isoTime,
                }
            }));
        }
    };

    const handleEndChange = (newValue) => {
        const now = new Date(startTime);
        const minDate = new Date(now.getTime() + 60 * 60 * 1000); // 24 hours from now
        if (newValue && newValue < minDate) {
            setError(timeMsg);
        } else {
            setError(null);
            setEndTime(newValue);
            //newValue.setMinutes (now.getMinutes() + 30);
            newValue.setMinutes(0);
            newValue.setSeconds(0);
            newValue.setMilliseconds(0);

            let isoTime = new Date(newValue).toISOString();
            dispatch(updateEditQuestion({
                question: {
                    endTime: isoTime,
                }
            }));
        }
    };


    const handleChoicesChange = (event) => {
        const choices = event.target.value;
        setMinSel(choices);
        const inRange = choices >= minChoices && choices <= maxChoices;
        if (inRange) {
            dispatch(updateEditQuestion({
                question: {
                    minSel: choices,
                    maxSel: choices,
                }
            }));
        }
    };



    return (
        <div className="container mt-2 px-1 text-center">
            <LotteryBallView buckets={buckets} minSel={minSel} />
            <LotteryOdds buckets={buckets + 1} minSel={minSel} />
            <FormControl fullWidth className="w-25 mx-2">
                <div># of Balls</div>
                <Select
                    labelId="number-of-balls-label"
                    value={buckets}
                    size="small"
                    onChange={handleBallsChange}
                >
                    {Array.from({ length: maxBalls }, (_, i) => i + minBalls).map((number) => (
                        <MenuItem key={number} value={number}>
                            {number}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth className="w-25 mx-2">
                <div># of Picks</div>
                <Select
                    labelId="number-of-balls-label"
                    value={minSel}
                    size="small"
                    onChange={handleChoicesChange}
                >
                    {Array.from({ length: maxChoices - minChoices + 1 }, (_, i) => i + minChoices).map((number) => (
                        <MenuItem key={number} value={number}>
                            {number}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div className="row g-2 my-1">
                    <div className="col">
                    <div className="mt-2">Start Time</div>
                        <DateTimePicker
                            size="small"
                            minutesStep={60}
                            value={endTime}
                            slotProps={{ textField: { size: 'small', margin:"0px" } }}
                            format="MM/dd/yyyy hh a"
                            views={["year", "month", "day", "hours"]}
                            container="inline"
                            //onChange={checkStartTime}
                            onAccept={handleStartChange}
                        />
                    </div>
                    <div className="col">
                    <div className="mt-2">End Time</div>
                        <DateTimePicker
                            size="small"
                            minutesStep={60}
                            value={endTime}
                            slotProps={{ textField: { size: 'small' } }}
                            format="MM/dd/yyyy hh a"
                            views={["year", "month", "day", "hours"]}
                            container="inline"
                            //onChange={checkEndTime}
                            onAccept={handleEndChange}
                        />
                    </div>
                    <div className="col-12 px-2" style={{ color: "red" }}>{error}</div>
                </div>
            </LocalizationProvider>

        </div >
    );
};

export default connect(null, null)(CreateLotteryQuestion);






