/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:20707454-f923-4e36-82bf-8869d106bf65",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_W8Z4Lbtoh",
    "aws_user_pools_web_client_id": "6c9ltipsb1ls09thbnn9o6m77a",
    "oauth": {
        "domain": "cognito.lyqness.com.auth.us-east-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": "http://localhost:3000/app/,https://lyqness.com/app/",
        "redirectSignOut": "http://localhost:3000/app/,https://lyqness.com/app/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "GIVEN_NAME",
        "FAMILY_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "lyqness",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://hzetieukmnajzpdv3jpnmlvu2q.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-7t2dfvx76jbq7inzmsjgpl4ngi",
    "aws_mobile_analytics_app_id": "f1e94dc823874fd9a91b400c43098092",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "f1e94dc823874fd9a91b400c43098092",
            "region": "us-east-1"
        }
    },
    "aws_user_files_s3_protected_level": "protected"
};


export default awsmobile;
