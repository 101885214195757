import React from "react"
import { Icons } from "../../../../shared/QIcons";
import { SelectorType } from "../../../../constants/question";


const InputSelector = React.memo(function InputSelector({ selector, isChecked, order, id, handleSelection }) {
    const iconSize="fa-sm";
        switch (selector) {
            case SelectorType.None:
                return (
                    <span />
                )
            case SelectorType.Single:
                return (
                    <i className={`${isChecked ? Icons.RADIO_ACTIVE : Icons.RADIO} ${iconSize}`} text-primary="true" onClick={() => handleSelection(id)}></i>
                )
            case SelectorType.Multi:
                return (
                    <i className={`${isChecked ? Icons.CHECKBOX_ACTIVE : Icons.CHECKBOX} ${iconSize}`} text-primary="true" onClick={() => handleSelection(id)}></i>
                )
            case SelectorType.Ranking:
                return (
                    <i className={Icons.UP_DOWN} />
                )
            case SelectorType.Drag:
                return (
                    <i className={Icons.MOVE} />
                )
            case SelectorType.Ordered:
            case SelectorType.Counter:
                if (isChecked)
                    return (<span className="text-primary pointer " onClick={() => handleSelection(id)}>{order}</span>)
                else
                    return (<i className={`${Icons.DIAMOND} ${iconSize}`} text-primary="true" onClick={() => handleSelection(id)}></i>)
            default:
                return (
                    <i className={isChecked ? Icons.RADIO_ACTIVE : Icons.RADIO} text-primary="true" onClick={() => handleSelection(id)}></i>
                )
        }

});

export default InputSelector