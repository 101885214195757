import React from "react";

const ProfileMenuItem = React.memo(function ProfileMenuItem({ feedId, icon, isActive, caption, handleClick }) {
    const iconStr= icon.icon.concat(isActive ? ' activeColor' : 'fal inactiveColor');
    if (isActive) {
        return (
            <div className="col-4 p-0 mb-2 text-center pointer" onClick={() => handleClick(feedId)}>
                <i className={iconStr}></i> 
                <div className="activeColor statLabel">{caption??icon.text}</div>
            </div>
        )
    }
    else {
        return (
            <div className="col-4 text-center mb-2" >
                <i className={iconStr}></i>
                <div className="inactiveColor statLabel " onClick={() => handleClick(feedId)}>{caption??icon.text}</div>
            </div>
        )
    }
}); 

export default ProfileMenuItem;
