import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "../index.css";
import { onError } from "../../shared/errorLib";
import UserBubble from "../profile/UserBubble";
import BubbleFeedDisplay from "./feedDisplays/BubbleFeedDisplay";
import Panel from "../panel/Panel";
import { title} from "../../shared/Titles";
import { UserFeeds } from "../../constants/feedids";


const mapStateToProps = state => {
    return {
        feed: state.chat.userConversations,
        panel: state.context.panel,
    };
};

const UserConversationFeed = React.memo(function UserConversationFeed({ feed, panel, dispatch }) {
    const { items, isLoading, isLoaded, nextToken } = feed;
    const feedId=panel.feedId?? UserFeeds.Conversations;
    const [searchStr, setSearchStr] = useState("");

    useEffect(() => {
        async function onLoad() {

            try {
                //if (feed.items.length===0 && !isLoading && !isLoaded)
                //  loadNextPage();
            }
            catch (e) {
                if (e !== 'No current user') {
                    onError(e);
                }
            }
        };

        onLoad();
    }, []);

    function loadNextPage() {
        //    dispatch(getUserConversationFeed({ feedId: UserFeeds.User, targetId:targetId, nextToken: nextToken }));
    }

    const renderConversation = (index) => {
        let item = feed.items[index];
        return (
            <UserBubble key={item.profileId + "convo"} feedId={feedId} displayProfile={item} />
        )
    }

    const handleSearch = (str) => {
        setSearchStr(str);
        if (str || (!str && searchStr))
            loadNextPage(str);
    };

    return (
        <div className="col px-0 py-0 h-100 ">
            <Panel
                handleSearch={handleSearch}
                title={<span className="h4 thin black-text">
                    <small>{title(feedId)}</small>
                </span>}
                body={
                    <BubbleFeedDisplay
                        isLoading={isLoading}
                        isLoaded={isLoaded}
                        offset={145}
                        hasMore={nextToken !== null}
                        items={items}
                        key={"userconversations" + feedId}
                        loadNextPage={loadNextPage}
                        renderItem={renderConversation}
                        noLoadStr="no user conversations"
                    />} >
            </Panel>
        </div>
    );
})

export default connect(mapStateToProps, null)(UserConversationFeed);