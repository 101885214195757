import React, { useEffect } from "react";
import { connect } from "react-redux";
import "../index.css";
import { onError } from "../../shared/errorLib";
import QuestionUserBubble from "../profile/QuestionUserBubble";
import { Feeds, WhoFeeds } from "../../constants/feedids";
import { getUserFeed } from "../../redux/actions/feed-actions";
import ItemFeedDisplay from "./feedDisplays/ItemFeedDisplay";
import Panel from "../panel/Panel";
import { Icons } from "../../shared/QIcons";
import FeedHeader from "./feedDisplays/FeedHeader";
import PanelFeedList from "./feedDisplays/PanelFeedList";
import UserSearch from "../search/UserSearch";

const mapStateToProps = state => {
    return {
        feed: state.profile.feed,
        panel: state.context.panel,
    };
};

const QuestionUserFeed = React.memo(function QuestionUserFeed({ feed, panel, dispatch }) {
    const { items, isLoading, feedId: currentFeedId, isLoaded, nextToken } = feed;
    const { feedId, displayQuestion: { questionId, questionText, choices }, choiceId, orderId } = panel;
    const displayText = questionText.length > 36 ? questionText.substring(0, 35) + "..." : questionText;
    const isChoice = feedId === Feeds.ChoiceUsers || feedId === Feeds.ChoiceOrderUsers;
   // const isQuestionUser = isChoice || feedId===Feeds.
    let choiceText = choiceId >= 0 ? choices[choiceId].text : '';
    choiceText = choiceText > 14 ? choiceText.substring(0, 15) : choiceText;
    // const [searchStr, setSearchStr] = useState("");
    if (orderId !== null)
        choiceText = `${orderText(orderId)} choice - "${choiceText}"`;
    else
        choiceText = `"${choiceText}"`;

    useEffect(() => {
        async function onLoad() {
            try {
                if ((!isLoading && !isLoaded) || currentFeedId !== feedId)
                    loadNextPage();
            }
            catch (e) {
                if (e !== 'No current user') {
                    onError(e);
                }
            }
        };
        onLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionId, choiceId, orderId]);



    function loadNextPage() {
        dispatch(getUserFeed({ feedId: feedId, targetId: questionId, choice: choiceId, order: orderId, nextToken: nextToken }));
    }

    // const handleSearch = (str) => {
    //     setSearchStr(str);
    //     if (str || (!str && searchStr))
    //         loadNextPage(str);
    // };

    const renderItem = (item, idx) => {
        let el = items[idx];
        return (
            <QuestionUserBubble key={questionId + idx} displayProfile={el} choiceId={choiceId} questionId={questionId} />
        )
    }

    function orderText(order) {
        switch (orderId) {
            case 0:
                return " 1st";;
            case 1:
                return " 2nd";
            case 2:
                return " 3rd";
            default:
                return ` ${orderId + 1}th`;
        }
    }

    const panelTitle = () => {
        switch (feedId) {
            case Feeds.ChoiceUsers:
            case Feeds.ChoiceOrderUsers:
                return "Users who chose..."
            case WhoFeeds.RespondedQuestions:
                return "Users who responded to..."
            case WhoFeeds.LikedQuestions:
                return "Users who liked..."
            case WhoFeeds.LinkedQuestions:
                return "Users who asked question related to..."
            case WhoFeeds.QuestionsTagged:
                return "Users who tagged..."
            default:
                break;
        }
    }
if(items.length>0 && items[0].userFeed)
    return (
        <div className="lyqcard shadow-none">
            <Panel
                avatar={<i className={Icons.USERS + "white-text"}></i>}
                title={panelTitle()}
                bottomLeft={<span className="ps-2">{isChoice ? choiceText : displayText}</span>}
                bottomRight={<UserSearch/>}
            />
            <div>
                { !isLoading &&
                <PanelFeedList
                    key={"question" + feed.currentFeedId}
                    isLoading={isLoading}
                    hasMore={nextToken !== null}
                    items={items}
                    isPanel={true}
                    renderItem={renderItem}
                    loadNextPage={loadNextPage}
                // noLoadStr="no responses"
                />
                }
            </div>
        </div>
    )

})

export default connect(mapStateToProps, null)(QuestionUserFeed);