import {  gqlChoices, gqlProfileStub, gqlFeedStats,  gqlProfile, gqlQuestionIncentives, gqlUserFeed } from "./gqlConstants";


export const shareQuestion = /* GraphQL */ `
  mutation ShareQuestion($input: ShareQuestionInput) {
    shareQuestion(input: $input) {
      action
      actionTime
      category
      createdAt
      interactionId
      targetId
      updatedAt
      order
      shareInfo {
        shareAction
        users
        choiceOrder
        __typename
      }
      userFeedId
      viewFeedId
      question {
        audience
        buckets
        category
        conversationId
        creatorId
        profileStub{ ${gqlProfileStub}}
        endTime
        howLong
        howOften
        questionId
        imageURL
        imageKey
        key
        tags
        linkSelections
        landscapeImages
        isModerated
        isMultipleChoice
        isOrdered
        isPremium
        isPrivate
        draft
        isNew
        targetId
        minRange1
        minRange2
        maxRange1
        maxRange2
        maxSel
        minSel
        numericType
        parentId
        questionType
        questionText
        qmosr
        startTime
        status
        stealthTime
        updatedAt
        updateDate
        updateTime
        viewProfileId
        weight
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      profileFeed {
        profileId
        associatedId
        lastViewed
        category
        userFollows
        isOwner
        targetId
        lastMessageTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      viewProfileId
      profile {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      profileId
      associatedId
      relatedId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;

export const respondToQuestion = /* GraphQL */ `
  mutation RespondToQuestion($input: QuestionResponseInput) {
    respondToQuestion(input: $input) {
      audience
      buckets
      choices { ${gqlChoices}}
      choiceHeadings
      category
      conversationId
      draft
      creatorId
      profileStub{ ${gqlProfileStub}}
      endTime
      howLong
      howOften
      questionId
      imageURL
      imageKey
      choiceStats {
        count
        __typename
      }
      key
      stats {
        ${gqlFeedStats}
      }
      tags
      linkSelections
      incentive {
        count
        frequency
        imageKey
        description
        selections
        __typename
      }
      landscapeImages
      isModerated
      isMultipleChoice
      isOrdered
      isPremium
      isPrivate
      draft
      isNew
      targetId
      minRange1
      minRange2
      maxRange1
      maxRange2
      maxSel
      minSel
      numericType
      parentId
      questionType
      questionText
      qmosr
      shareProfile {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      startTime
      status
      stealthTime
      updatedAt
      updateDate
      updateTime
      userFeed {
        ${gqlUserFeed}
      }
      viewUserFeed {
        ${gqlUserFeed}
      }
      viewProfileId
      weight
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;

export const createProfile = /* GraphQL */ `
  mutation CreateProfile($input: CreateProfileInput) {
    createProfile(input: $input) {
      ${gqlProfile}
    }
  }
`;

export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion($input: CreateQuestionInput) {
    createQuestion(input: $input) {
      audience
      buckets
      choices { ${gqlChoices} }
      choiceHeadings
      category
      conversationId
      creatorId
      profileStub{ ${gqlProfileStub}}
      endTime
      howLong
      howOften
      questionId
      imageURL
      imageKey
      choiceStats {
        count
        __typename
      }
      key
      stats {
        ${gqlFeedStats}
      }
      tags
      linkSelections
      incentive { ${gqlQuestionIncentives} }
      landscapeImages
      isModerated
      isMultipleChoice
      isOrdered
      isPremium
      isPrivate
      draft
      isNew
      targetId
      minRange1
      minRange2
      maxRange1
      maxRange2
      maxSel
      minSel
      numericType
      parentId
      questionType
      questionText
      qmosr
      startTime
      status
      stealthTime
      updatedAt
      updateDate
      updateTime
      userFeed {
        ${gqlUserFeed}
      }
      viewUserFeed {
        ${gqlUserFeed}
      }
      viewProfileId
      weight
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;

export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion($input: UpdateQuestionInput) {
    updateQuestion(input: $input) {
      audience
      buckets
      choices { ${gqlChoices} }
      choiceHeadings
      category
      conversationId
      creatorId
      profileStub{ ${gqlProfileStub}}
      endTime
      howLong
      howOften
      questionId
      imageURL
      imageKey
      choiceStats {
        count
        __typename
      }
      key
      stats {
        ${gqlFeedStats}
      }
      tags
      linkSelections
      incentive { ${gqlQuestionIncentives} }
      landscapeImages
      isModerated
      isMultipleChoice
      isOrdered
      isPremium
      isPrivate
      draft
      isNew
      targetId
      minRange1
      minRange2
      maxRange1
      maxRange2
      maxSel
      minSel
      numericType
      parentId
      questionType
      questionText
      qmosr
      startTime
      status
      stealthTime
      updatedAt
      updateDate
      updateTime
      userFeed {
        ${gqlUserFeed}
      }
      viewUserFeed {
        ${gqlUserFeed}
      }
      viewProfileId
      weight
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;

export const processProfileInteraction = /* GraphQL */ `
  mutation ProcessProfileInteraction($input: ProfileInteractionInput) {
    processProfileInteraction(input: $input) {
      profileId
      associatedId
      lastViewed
      category
      stats {
        CHAU
        FEAU
        FLAU
        FOLU
        LIKU
        MODU
        SHAU
        SPOU
      }
      userFollows
      isOwner
      targetId
      lastMessageTime
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const processQuestionInteraction = /* GraphQL */ `
  mutation ProcessQuestionInteraction($input: QuestionInteractionInput) {
    processQuestionInteraction(input: $input) {
      audience
      buckets
      choices { ${gqlChoices}}
      choiceHeadings
      category
      conversationId
      creatorId
      profileStub{ ${gqlProfileStub}}
      draft
      endTime
      howLong
      howOften
      questionId
      imageURL
      imageKey
      choiceStats {
        count
        __typename
      }
      key
      stats {
        ${gqlFeedStats}
      }
      tags
      linkSelections
      incentive {
        count
        frequency
        imageKey
        description
        selections
        __typename
      }
      landscapeImages
      isModerated
      isMultipleChoice
      isOrdered
      isPremium
      isPrivate
      draft
      isNew
      targetId
      minRange1
      minRange2
      maxRange1
      maxRange2
      maxSel
      minSel
      numericType
      parentId
      questionType
      questionText
      qmosr
      shareProfile {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      startTime
      status
      stealthTime
      updatedAt
      updateDate
      updateTime
      userFeed {
        ${gqlUserFeed}
      }
      viewUserFeed {
        ${gqlUserFeed}
      }
      viewProfileId
      weight
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;

export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion($input: DeleteQuestionInput) {
    deleteQuestion(input: $input) {
      audience
      buckets
      choices {
        text
        imageKey
        __typename
      }
      category
      conversationId
      creatorId
      endTime
      howLong
      howOften
      questionId
      imageURL
      imageKey
      choiceStats {
        count
        __typename
      }
      key
      stats {
        CHAQ
        CREA
        CREI
        CREQ
        HCRQ
        HLNQ
        HREQ
        HLKQ
        FEAQ
        FLAQ
        INCA
        INCQ
        INCR
        LIKQ
        LINQ
        MODQ
        RESQ
        SHAQ
        SPOQ
        STEA
        TAGQ
        __typename
      }
      tags
      linkSelections
      incentive {
        count
        frequency
        imageKey
        description
        selections
        __typename
      }
      landscapeImages
      isModerated
      isMultipleChoice
      isOrdered
      isPremium
      isPrivate
      draft
      isNew
      targetId
      minRange1
      minRange2
      maxRange1
      maxRange2
      maxSel
      minSel
      numericType
      parentId
      questionType
      questionText
      qmosr
      shareProfile {
        avatar
        avatarKey
        avatarURL
        category
        conversationId
        createdAt
        creatorId
        description
        email
        firstName
        follower
        identityId
        lastLogon
        lastName
        photo
        photoKey
        photoURL
        profileId
        profileURL
        showTooltips
        targetId
        title
        updatedAt
        userFeedQuestionId
        userName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      startTime
      status
      stealthTime
      updatedAt
      updateDate
      updateTime
      userFeed {
        category
        createdAt
        publicTime
        feedTime
        updatedAt
        profileId
        questionId
        tags
        selections
        hidden
        lastMessageTime
        stealthTime
        toggledStealth
        targetId
        isOwner
        weight
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      viewUserFeed {
        category
        createdAt
        publicTime
        feedTime
        updatedAt
        profileId
        questionId
        tags
        selections
        hidden
        lastMessageTime
        stealthTime
        toggledStealth
        targetId
        isOwner
        weight
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      viewProfileId
      weight
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;