import React from "react";
import { ViewType } from "../../constants/profile"
import { connect } from "react-redux";
import QuestionFeed from "../feeds/QuestionFeed";
import UserProfileEdit from "../../views/UserProfileEdit";
import PremiumUserProfileEdit from "../../views/PremiumUserProfileEdit";

const mapStateToProps = (state, ownProps) => {
  return {
    viewType: state.context.view.viewType,
  };
};

const LyqnessProfileSelector = React.memo(function LyqnessProfileSelector({ viewType, profileId }) {
  switch (viewType) {
    case ViewType.PremiumUserProfile:
      return (<PremiumUserProfileEdit />)
    case ViewType.UserProfile:
      return (<UserProfileEdit />)
    default:
      return (
        <QuestionFeed />
      )
  }
});

export default connect(mapStateToProps, null)(LyqnessProfileSelector);