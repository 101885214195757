import React from "react";
import { connect } from "react-redux";
import { hideModal } from "../../redux/actions/modal-actions";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import IconButton from "@mui/joy/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { QRCodeCanvas } from "qrcode.react";
import { avatarUrl } from "../question/Utilities";
import config from "../../config";

const mapStateToProps = (state) => ({
    isOpen: state.modal.modalType === "QR_MODAL",
    lyqness: state.profile.systemUser,
    collection: state.modal.modalProps.content,
    profile: state.profile.currentUser,
});

const QRModal = React.memo(function QRModal({ isOpen, profile, collection, lyqness, dispatch }) {
    const { avatarKey, identityId, profileId, firstName, lastName } = profile;

    const toggle = () => {
        dispatch(hideModal());
    };

    const image = avatarKey ? avatarUrl(identityId, avatarKey) : avatarUrl(lyqness.identityId, lyqness.avatarKey);
    const url = `${config.hostname}/c/${profileId}?${collection}`;

    return (
        <Modal open={isOpen} onClose={toggle}>
            <ModalDialog
                variant="outlined"
                layout="center"
                sx={{
                    maxWidth: "400px",
                    borderRadius: "md",
                    boxShadow: "lg",
                    bgcolor: "background.surface",
                    p: 2,
                    textAlign: "center",
                }}
            >
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography level="h5">Your Survey</Typography>
                    <IconButton variant="plain" size="sm" onClick={toggle}>
                        <CloseIcon />
                    </IconButton>
                </div>

                <Typography level="h6" sx={{ mt: 1, fontWeight: "bold" }}>
                    {firstName} {lastName}
                </Typography>

                <div style={{ display: "flex", justifyContent: "center", margin: "16px 0" }}>
                    <QRCodeCanvas
                        value={url}
                        size={200}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"L"}
                        includeMargin={false}
                        imageSettings={{
                            src: image,
                            height: 40,
                            width: 40,
                            excavate: true,
                        }}
                    />
                </div>

                <Typography level="body2" sx={{ fontStyle: "italic", mb: 2 }}>
                    Ask. Answer. Discover.
                </Typography>

                <Typography level="h6" sx={{ bgcolor: "background.level2", p: 1, borderRadius: "sm" }}>
                    {collection}
                </Typography>

                <div style={{ display: "flex", justifyContent: "center", gap: "12px", marginTop: "16px" }}>
                    <Button variant="solid" color="primary" size="sm" onClick={toggle}>
                        Print
                    </Button>
                    <Button variant="soft" color="neutral" size="sm" onClick={toggle}>
                        Close
                    </Button>
                </div>
            </ModalDialog>
        </Modal>
    );
});

export default connect(mapStateToProps, null)(QRModal);
