import React from "react";


const PanelFeedHeader = ({ avatar, title, titleFont, headerColor = "panelHeader-bg", topRight, details, bottomLeft, bottomCenter, bottomRight }) => {

    return (
        <div className={`feedHeader d-flex ${headerColor} py-1`}>
            <div className="flex-shrink-0 ps-1">
                {avatar}
            </div>
            <div className="flex-grow-1 ms-1 white-text">
                <div className=" w-100 "><strong>{title}</strong><span className="float-end me-2">{topRight}</span></div>
                <div className=" w-100">{bottomLeft}<span className="float-end me-2">{bottomRight}</span></div>
                {/* <div className="row g-0 font ">
                    <div className={`col-11  ${titleFont} yellow`}>
                        <strong>{title}</strong></div>
                    <div className="col-1 pe-2">{topRight}</div>
                </div>
                <div className="row g-0 font orange">
                    <div className="col-8 ">
                        {bottomLeft}
                    </div>
                    <div className="col-4 ">
                        <span className="float-end pe-2">{bottomRight}</span>
                    </div>
                </div>
                <div className="row g-0 ">
                    {details}
                </div> */}
            </div>
        </div>
    )
}

export default PanelFeedHeader;

