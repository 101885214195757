export const schema = {
    "models": {
        "ChoiceOrder": {
            "name": "ChoiceOrder",
            "fields": {
                "choice": {
                    "name": "choice",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "choiceOrderTime": {
                    "name": "choiceOrderTime",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "choiceOrder": {
                    "name": "choiceOrder",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "questionChoice": {
                    "name": "questionChoice",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "order": {
                    "name": "order",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "questionId": {
                    "name": "questionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "profileId": {
                    "name": "profileId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "ChoiceOrders",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "profileId",
                            "questionChoice"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byQuestion",
                        "queryField": null,
                        "fields": [
                            "questionId",
                            "choiceOrderTime"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCreated",
                        "queryField": null,
                        "fields": [
                            "profileId",
                            "createdAt"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Conversation": {
            "name": "Conversation",
            "fields": {
                "actionTime": {
                    "name": "actionTime",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "activeCount": {
                    "name": "activeCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "category": {
                    "name": "category",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "chatType": {
                    "name": "chatType",
                    "isArray": false,
                    "type": {
                        "enum": "ChatType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "conversationId": {
                    "name": "conversationId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "creatorId": {
                    "name": "creatorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "lastMessageTime": {
                    "name": "lastMessageTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "messages": {
                    "name": "messages",
                    "isArray": true,
                    "type": {
                        "model": "Message"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "conversation"
                        ]
                    }
                },
                "relatedId": {
                    "name": "relatedId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Conversations",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "conversationId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Interaction": {
            "name": "Interaction",
            "fields": {
                "action": {
                    "name": "action",
                    "isArray": false,
                    "type": {
                        "enum": "ActionEnum"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "actionTime": {
                    "name": "actionTime",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "category": {
                    "name": "category",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "interactionId": {
                    "name": "interactionId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "targetId": {
                    "name": "targetId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "order": {
                    "name": "order",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "shareInfo": {
                    "name": "shareInfo",
                    "isArray": false,
                    "type": {
                        "nonModel": "ShareInfo"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "userFeedId": {
                    "name": "userFeedId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "viewFeedId": {
                    "name": "viewFeedId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "viewProfileId": {
                    "name": "viewProfileId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "profileId": {
                    "name": "profileId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "associatedId": {
                    "name": "associatedId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "relatedId": {
                    "name": "relatedId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Interactions",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "queries": null,
                        "subscriptions": {
                            "onCreate": [
                                "onCreateInteraction"
                            ],
                            "onDelete": [
                                "onDeleteInteraction"
                            ],
                            "onUpdate": null
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "profileId",
                            "interactionId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byProfile",
                        "queryField": null,
                        "fields": [
                            "profileId",
                            "actionTime"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCreated",
                        "queryField": null,
                        "fields": [
                            "profileId",
                            "createdAt"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byAssociated",
                        "queryField": null,
                        "fields": [
                            "associatedId",
                            "actionTime"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byRelated",
                        "queryField": null,
                        "fields": [
                            "relatedId",
                            "actionTime"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Message": {
            "name": "Message",
            "fields": {
                "action": {
                    "name": "action",
                    "isArray": false,
                    "type": {
                        "enum": "ActionEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "sender": {
                    "name": "sender",
                    "isArray": false,
                    "type": {
                        "model": "Profile"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": [
                            "profileId"
                        ],
                        "targetNames": [
                            "senderId"
                        ]
                    }
                },
                "content": {
                    "name": "content",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "category": {
                    "name": "category",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "conversationId": {
                    "name": "conversationId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "conversation": {
                    "name": "conversation",
                    "isArray": false,
                    "type": {
                        "model": "Conversation"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "conversationId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "fileKey": {
                    "name": "fileKey",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "isSent": {
                    "name": "isSent",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "messageId": {
                    "name": "messageId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "questionId": {
                    "name": "questionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "question": {
                    "name": "question",
                    "isArray": false,
                    "type": {
                        "model": "Question"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": [
                            "questionId"
                        ],
                        "targetNames": [
                            "relatedId"
                        ]
                    }
                },
                "relatedId": {
                    "name": "relatedId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "senderId": {
                    "name": "senderId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "viewProfileId": {
                    "name": "viewProfileId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Messages",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "messageId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByConversation",
                        "queryField": null,
                        "fields": [
                            "conversationId",
                            "createdAt"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byProfile",
                        "queryField": null,
                        "fields": [
                            "senderId",
                            "createdAt"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Profile": {
            "name": "Profile",
            "fields": {
                "avatar": {
                    "name": "avatar",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "avatarKey": {
                    "name": "avatarKey",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "avatarURL": {
                    "name": "avatarURL",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "category": {
                    "name": "category",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "conversationId": {
                    "name": "conversationId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "creatorId": {
                    "name": "creatorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "email": {
                    "name": "email",
                    "isArray": false,
                    "type": "AWSEmail",
                    "isRequired": false,
                    "attributes": []
                },
                "firstName": {
                    "name": "firstName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "follower": {
                    "name": "follower",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "identityId": {
                    "name": "identityId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "lastLogon": {
                    "name": "lastLogon",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "lastName": {
                    "name": "lastName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "photo": {
                    "name": "photo",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "photoKey": {
                    "name": "photoKey",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "photoURL": {
                    "name": "photoURL",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "profileFeed": {
                    "name": "profileFeed",
                    "isArray": false,
                    "type": {
                        "model": "ProfileFeed"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": [
                            "profileId",
                            "associatedId"
                        ],
                        "targetNames": [
                            "profileId",
                            "targetId"
                        ]
                    }
                },
                "profileId": {
                    "name": "profileId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "profileURL": {
                    "name": "profileURL",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "questions": {
                    "name": "questions",
                    "isArray": true,
                    "type": {
                        "model": "Question"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "questionId"
                        ]
                    }
                },
                "showTooltips": {
                    "name": "showTooltips",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "stats": {
                    "name": "stats",
                    "isArray": false,
                    "type": {
                        "nonModel": "ProfileStats"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "myProfileFeed": {
                    "name": "myProfileFeed",
                    "isArray": false,
                    "type": {
                        "model": "ProfileFeed"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": [
                            "profileId",
                            "associatedId"
                        ],
                        "targetNames": [
                            "targetId",
                            "profileId"
                        ]
                    }
                },
                "targetId": {
                    "name": "targetId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "lastFeedView": {
                    "name": "lastFeedView",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "userFeed": {
                    "name": "userFeed",
                    "isArray": false,
                    "type": {
                        "model": "UserFeed"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": [
                            "profileId",
                            "questionId"
                        ],
                        "targetNames": [
                            "profileId",
                            "userFeedQuestionId"
                        ]
                    }
                },
                "userFeedQuestionId": {
                    "name": "userFeedQuestionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "userName": {
                    "name": "userName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Profiles",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "queries": {
                            "get": "getProfile",
                            "list": "listProfiles"
                        },
                        "mutations": {
                            "create": null,
                            "delete": "deleteProfile",
                            "update": "updateProfile"
                        },
                        "subscriptions": {
                            "onCreate": null,
                            "onUpdate": null,
                            "onDelete": [
                                "onDeleteProfile"
                            ]
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "profileId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUserName",
                        "queryField": "usersByUserName",
                        "fields": [
                            "userName"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ProfileFeed": {
            "name": "ProfileFeed",
            "fields": {
                "profileId": {
                    "name": "profileId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "associatedId": {
                    "name": "associatedId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "lastViewed": {
                    "name": "lastViewed",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "category": {
                    "name": "category",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "stats": {
                    "name": "stats",
                    "isArray": false,
                    "type": {
                        "nonModel": "ProfileFeedStats"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "userFollows": {
                    "name": "userFollows",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "isOwner": {
                    "name": "isOwner",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "targetId": {
                    "name": "targetId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "lastMessageTime": {
                    "name": "lastMessageTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "ProfileFeeds",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "queries": null,
                        "subscriptions": {
                            "onUpdate": null,
                            "onCreate": [
                                "onCreateProfileFeed"
                            ],
                            "onDelete": [
                                "onDeleteProfileFeed"
                            ]
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "profileId",
                            "associatedId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byProfile",
                        "queryField": null,
                        "fields": [
                            "profileId",
                            "updatedAt"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byAssociated",
                        "queryField": "updatedAt",
                        "fields": [
                            "associatedId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Question": {
            "name": "Question",
            "fields": {
                "audience": {
                    "name": "audience",
                    "isArray": false,
                    "type": {
                        "enum": "VisibilityEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "buckets": {
                    "name": "buckets",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "choices": {
                    "name": "choices",
                    "isArray": true,
                    "type": {
                        "nonModel": "Choice"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "category": {
                    "name": "category",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "choiceHeadings": {
                    "name": "choiceHeadings",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "conversationId": {
                    "name": "conversationId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "creatorId": {
                    "name": "creatorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "profileStub": {
                    "name": "profileStub",
                    "isArray": false,
                    "type": {
                        "nonModel": "ProfileStub"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "endTime": {
                    "name": "endTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "howLong": {
                    "name": "howLong",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "howOften": {
                    "name": "howOften",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "questionId": {
                    "name": "questionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "imageURL": {
                    "name": "imageURL",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "imageKey": {
                    "name": "imageKey",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "choiceStats": {
                    "name": "choiceStats",
                    "isArray": true,
                    "type": {
                        "nonModel": "ChoiceStat"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "stepStats": {
                    "name": "stepStats",
                    "isArray": true,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "key": {
                    "name": "key",
                    "isArray": true,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "stats": {
                    "name": "stats",
                    "isArray": false,
                    "type": {
                        "nonModel": "UserFeedStats"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "tags": {
                    "name": "tags",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "linkSelections": {
                    "name": "linkSelections",
                    "isArray": true,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "incentive": {
                    "name": "incentive",
                    "isArray": false,
                    "type": {
                        "nonModel": "QuestionIncentive"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "landscapeImages": {
                    "name": "landscapeImages",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "isModerated": {
                    "name": "isModerated",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "isMultipleChoice": {
                    "name": "isMultipleChoice",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "isOrdered": {
                    "name": "isOrdered",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "isPremium": {
                    "name": "isPremium",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "draft": {
                    "name": "draft",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "isPrivate": {
                    "name": "isPrivate",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "isNew": {
                    "name": "isNew",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "targetId": {
                    "name": "targetId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "minRange1": {
                    "name": "minRange1",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "minRange2": {
                    "name": "minRange2",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "maxRange1": {
                    "name": "maxRange1",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "maxRange2": {
                    "name": "maxRange2",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "maxSel": {
                    "name": "maxSel",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "minSel": {
                    "name": "minSel",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "numericType": {
                    "name": "numericType",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "parentId": {
                    "name": "parentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "questionType": {
                    "name": "questionType",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "questionText": {
                    "name": "questionText",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "qmosr": {
                    "name": "qmosr",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "startTime": {
                    "name": "startTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "QuestionStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "stealthTime": {
                    "name": "stealthTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "updateDate": {
                    "name": "updateDate",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "updateTime": {
                    "name": "updateTime",
                    "isArray": false,
                    "type": "AWSTime",
                    "isRequired": false,
                    "attributes": []
                },
                "viewProfileId": {
                    "name": "viewProfileId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "weight": {
                    "name": "weight",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Questions",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "queries": {
                            "get": "getQuestion"
                        },
                        "mutations": {
                            "delete": null,
                            "update": "updateQuestion",
                            "create": null
                        },
                        "subscriptions": null
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "questionId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byProfile",
                        "queryField": null,
                        "fields": [
                            "creatorId",
                            "updatedAt"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUpdated",
                        "queryField": null,
                        "fields": [
                            "updateDate",
                            "updateTime"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "gsi-Profile.questions",
                        "fields": [
                            "questionId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Response": {
            "name": "Response",
            "fields": {
                "profileId": {
                    "name": "profileId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "questionId": {
                    "name": "questionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "viewProfileId": {
                    "name": "viewProfileId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "selections": {
                    "name": "selections",
                    "isArray": true,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "order": {
                    "name": "order",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "twins": {
                    "name": "twins",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "values": {
                    "name": "values",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                }
            },
            "syncable": true,
            "pluralName": "Responses",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "profileId",
                            "questionId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCreated",
                        "queryField": null,
                        "fields": [
                            "profileId",
                            "createdAt"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byQuestion",
                        "queryField": null,
                        "fields": [
                            "questionId",
                            "createdAt"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Hashtag": {
            "name": "Hashtag",
            "fields": {
                "tagId": {
                    "name": "tagId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "tag": {
                    "name": "tag",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "sortId": {
                    "name": "sortId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "count": {
                    "name": "count",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "imageKey": {
                    "name": "imageKey",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "imageURL": {
                    "name": "imageURL",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "questionId": {
                    "name": "questionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "profileId": {
                    "name": "profileId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Hashtags",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": {
                            "onUpdate": null,
                            "onCreate": [
                                "onCreateHashtag"
                            ],
                            "onDelete": [
                                "onDeleteHashtag"
                            ]
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "tagId",
                            "tag"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "TagCounts",
                        "queryField": null,
                        "fields": [
                            "tagId",
                            "updatedAt"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "tagDetail",
                        "queryField": null,
                        "fields": [
                            "sortId",
                            "createdAt"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byQuestion",
                        "queryField": null,
                        "fields": [
                            "questionId",
                            "createdAt"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "UserFeed": {
            "name": "UserFeed",
            "fields": {
                "category": {
                    "name": "category",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "publicTime": {
                    "name": "publicTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "feedTime": {
                    "name": "feedTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "pushTime": {
                    "name": "pushTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "profileId": {
                    "name": "profileId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "questionId": {
                    "name": "questionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tags": {
                    "name": "tags",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "isPrivate": {
                    "name": "isPrivate",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "selections": {
                    "name": "selections",
                    "isArray": true,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "values": {
                    "name": "values",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "steps": {
                    "name": "steps",
                    "isArray": true,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "stats": {
                    "name": "stats",
                    "isArray": false,
                    "type": {
                        "nonModel": "UserFeedStats"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "hidden": {
                    "name": "hidden",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "lastMessageTime": {
                    "name": "lastMessageTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "sharedBy": {
                    "name": "sharedBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "sharerProfile": {
                    "name": "sharerProfile",
                    "isArray": false,
                    "type": {
                        "model": "Profile"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "sharedBy"
                        ]
                    }
                },
                "stealthTime": {
                    "name": "stealthTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "incentive": {
                    "name": "incentive",
                    "isArray": false,
                    "type": {
                        "nonModel": "UserIncentive"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "toggledStealth": {
                    "name": "toggledStealth",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "targetId": {
                    "name": "targetId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "ownerId": {
                    "name": "ownerId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "isOwner": {
                    "name": "isOwner",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "weight": {
                    "name": "weight",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "UserFeeds",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "queries": null,
                        "subscriptions": {
                            "onUpdate": null,
                            "onCreate": [
                                "onCreateUserFeed"
                            ],
                            "onDelete": [
                                "onDeleteUserFeed"
                            ]
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "profileId",
                            "questionId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byPublicTime",
                        "queryField": null,
                        "fields": [
                            "profileId",
                            "publicTime"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byFeedTime",
                        "queryField": null,
                        "fields": [
                            "profileId",
                            "feedTime"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byQuestion",
                        "queryField": null,
                        "fields": [
                            "questionId",
                            "createdAt"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byPushTime",
                        "queryField": null,
                        "fields": [
                            "questionId",
                            "feedTime"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        }
    },
    "enums": {
        "ShareActionEnum": {
            "name": "ShareActionEnum",
            "values": [
                "FOLLOWERS",
                "LIKED",
                "SELECTED",
                "RESPONDED",
                "EXTERNAL"
            ]
        },
        "ActionEnum": {
            "name": "ActionEnum",
            "values": [
                "BORQ",
                "CHAC",
                "CHAQ",
                "CHAU",
                "CREA",
                "CREC",
                "CREI",
                "CREQ",
                "FEAC",
                "FEAQ",
                "FEAU",
                "FLAC",
                "FLAQ",
                "FLAU",
                "FOLC",
                "FOLU",
                "HCRQ",
                "HLNQ",
                "HREQ",
                "HLKQ",
                "INCA",
                "INCQ",
                "INCR",
                "LIKC",
                "LIKQ",
                "LIKU",
                "LINQ",
                "MODC",
                "MODQ",
                "MODU",
                "RESQ",
                "SHAC",
                "SHAQ",
                "SHAU",
                "SPOC",
                "SPOQ",
                "SPOU",
                "STEA",
                "TAGQ"
            ]
        },
        "ChatType": {
            "name": "ChatType",
            "values": [
                "CHAC",
                "CHAG",
                "CHAQ",
                "CHAU"
            ]
        },
        "FeedIds": {
            "name": "FeedIds",
            "values": [
                "ABORQ",
                "ACHAC",
                "ACHAQ",
                "ACHAU",
                "ACREC",
                "ACREQ",
                "AHCRQ",
                "AHLNQ",
                "AHREQ",
                "AHLKQ",
                "AFEAC",
                "AFEAQ",
                "AFEAU",
                "AFLAC",
                "AFLAQ",
                "AFLAU",
                "AFOLC",
                "AFOLU",
                "AINCA",
                "AINCQ",
                "AINCR",
                "ALIKC",
                "ALIKQ",
                "ALIKU",
                "ALNPQ",
                "AMODC",
                "AMODQ",
                "AMODU",
                "ARESQ",
                "ASHAC",
                "ASHAQ",
                "ASHAU",
                "ASPOC",
                "ASPOQ",
                "ASPOU",
                "ATAGQ",
                "PBORQ",
                "PCHAC",
                "PCHAQ",
                "PCHAU",
                "PCREC",
                "PCREQ",
                "PHCRQ",
                "PHLNQ",
                "PHREQ",
                "PHLKQ",
                "PFEAC",
                "PFEAQ",
                "PFEAU",
                "PFLAC",
                "PFLAQ",
                "PFLAU",
                "PFOLC",
                "PFOLU",
                "PINCA",
                "PINCQ",
                "PINCR",
                "PLIKC",
                "PLIKQ",
                "PLIKU",
                "PLINQ",
                "PMODC",
                "PMODQ",
                "PMODU",
                "PRESQ",
                "PSHAC",
                "PSHAQ",
                "PSHAU",
                "PSPOC",
                "PSPOQ",
                "PSPOU",
                "PTAGQ",
                "RLINQ",
                "ZLINQ",
                "XLINQ"
            ]
        },
        "QuestionStatus": {
            "name": "QuestionStatus",
            "values": [
                "SCHEDULED",
                "ACTIVE",
                "EXPIRED"
            ]
        },
        "VisibilityEnum": {
            "name": "VisibilityEnum",
            "values": [
                "NOONE",
                "LINK",
                "COLL",
                "ALL"
            ]
        },
        "ModelSortDirection": {
            "name": "ModelSortDirection",
            "values": [
                "ASC",
                "DESC"
            ]
        },
        "ModelAttributeTypes": {
            "name": "ModelAttributeTypes",
            "values": [
                "binary",
                "binarySet",
                "bool",
                "list",
                "map",
                "number",
                "numberSet",
                "string",
                "stringSet",
                "_null"
            ]
        }
    },
    "nonModels": {
        "QuestionIncentive": {
            "name": "QuestionIncentive",
            "fields": {
                "count": {
                    "name": "count",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "frequency": {
                    "name": "frequency",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "imageKey": {
                    "name": "imageKey",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "selections": {
                    "name": "selections",
                    "isArray": true,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "values": {
                    "name": "values",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "UserIncentive": {
            "name": "UserIncentive",
            "fields": {
                "achievedDate": {
                    "name": "achievedDate",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "redeemedDate": {
                    "name": "redeemedDate",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "redemptionCode": {
                    "name": "redemptionCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "posCode": {
                    "name": "posCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ProfileStub": {
            "name": "ProfileStub",
            "fields": {
                "avatarKey": {
                    "name": "avatarKey",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "lastName": {
                    "name": "lastName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "firstName": {
                    "name": "firstName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "identityId": {
                    "name": "identityId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Choice": {
            "name": "Choice",
            "fields": {
                "text": {
                    "name": "text",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "imageKey": {
                    "name": "imageKey",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "imageURL": {
                    "name": "imageURL",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ShareInfo": {
            "name": "ShareInfo",
            "fields": {
                "shareAction": {
                    "name": "shareAction",
                    "isArray": false,
                    "type": {
                        "enum": "ShareActionEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "users": {
                    "name": "users",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "choiceOrder": {
                    "name": "choiceOrder",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ChoiceStat": {
            "name": "ChoiceStat",
            "fields": {
                "count": {
                    "name": "count",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Count": {
            "name": "Count",
            "fields": {
                "count": {
                    "name": "count",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Result": {
            "name": "Result",
            "fields": {
                "accepted": {
                    "name": "accepted",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "reason": {
                    "name": "reason",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ProfileStats": {
            "name": "ProfileStats",
            "fields": {
                "Profile": {
                    "name": "Profile",
                    "isArray": false,
                    "type": {
                        "nonModel": "ProfileFeedStats"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "Question": {
                    "name": "Question",
                    "isArray": false,
                    "type": {
                        "nonModel": "UserFeedStats"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "Tally": {
                    "name": "Tally",
                    "isArray": false,
                    "type": {
                        "nonModel": "TallyStats"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "UpdateTimes": {
            "name": "UpdateTimes",
            "fields": {
                "RESQ": {
                    "name": "RESQ",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "LIKQ": {
                    "name": "LIKQ",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "LINQ": {
                    "name": "LINQ",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "TAGQ": {
                    "name": "TAGQ",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "CHAQ": {
                    "name": "CHAQ",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "UserFeedStats": {
            "name": "UserFeedStats",
            "fields": {
                "CHAQ": {
                    "name": "CHAQ",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "CREA": {
                    "name": "CREA",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "CREI": {
                    "name": "CREI",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "CREQ": {
                    "name": "CREQ",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "HCRQ": {
                    "name": "HCRQ",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "HLNQ": {
                    "name": "HLNQ",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "HREQ": {
                    "name": "HREQ",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "HLKQ": {
                    "name": "HLKQ",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "FEAQ": {
                    "name": "FEAQ",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "FLAQ": {
                    "name": "FLAQ",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "INCA": {
                    "name": "INCA",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "INCQ": {
                    "name": "INCQ",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "INCR": {
                    "name": "INCR",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "LIKQ": {
                    "name": "LIKQ",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "LINQ": {
                    "name": "LINQ",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "MODQ": {
                    "name": "MODQ",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "RESQ": {
                    "name": "RESQ",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "SHAQ": {
                    "name": "SHAQ",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "SPOQ": {
                    "name": "SPOQ",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "STEA": {
                    "name": "STEA",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "TAGQ": {
                    "name": "TAGQ",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ProfileFeedStats": {
            "name": "ProfileFeedStats",
            "fields": {
                "CHAU": {
                    "name": "CHAU",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "FEAU": {
                    "name": "FEAU",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "FLAU": {
                    "name": "FLAU",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "FOLU": {
                    "name": "FOLU",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "LIKU": {
                    "name": "LIKU",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "MODU": {
                    "name": "MODU",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "SHAU": {
                    "name": "SHAU",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "SPOU": {
                    "name": "SPOU",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "TallyStats": {
            "name": "TallyStats",
            "fields": {
                "CHAU": {
                    "name": "CHAU",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "FLAU": {
                    "name": "FLAU",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "FOLU": {
                    "name": "FOLU",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "SHAU": {
                    "name": "SHAU",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "SHAC": {
                    "name": "SHAC",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "FOLC": {
                    "name": "FOLC",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "FLAC": {
                    "name": "FLAC",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "CHAC": {
                    "name": "CHAC",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "DisplayProfile": {
            "name": "DisplayProfile",
            "fields": {
                "avatarKey": {
                    "name": "avatarKey",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "category": {
                    "name": "category",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "conversation": {
                    "name": "conversation",
                    "isArray": false,
                    "type": {
                        "model": "Conversation"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "firstName": {
                    "name": "firstName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "following": {
                    "name": "following",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "identityId": {
                    "name": "identityId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "lastName": {
                    "name": "lastName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "creatorId": {
                    "name": "creatorId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "creator": {
                    "name": "creator",
                    "isArray": false,
                    "type": {
                        "model": "Profile"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "photoKey": {
                    "name": "photoKey",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "profileId": {
                    "name": "profileId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "userFeedQuestionId": {
                    "name": "userFeedQuestionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "userFeed": {
                    "name": "userFeed",
                    "isArray": false,
                    "type": {
                        "model": "UserFeed"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "stats": {
                    "name": "stats",
                    "isArray": false,
                    "type": {
                        "nonModel": "ProfileStats"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "userFollows": {
                    "name": "userFollows",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "userName": {
                    "name": "userName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ActivityUpdate": {
            "name": "ActivityUpdate",
            "fields": {
                "action": {
                    "name": "action",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "profileId": {
                    "name": "profileId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "creatorId": {
                    "name": "creatorId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "viewProfileId": {
                    "name": "viewProfileId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "associatedId": {
                    "name": "associatedId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "activity": {
                    "name": "activity",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "questionStats": {
                    "name": "questionStats",
                    "isArray": false,
                    "type": {
                        "nonModel": "UserFeedStats"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "choiceStats": {
                    "name": "choiceStats",
                    "isArray": true,
                    "type": {
                        "nonModel": "ChoiceStat"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "ModelInteractionConnection": {
            "name": "ModelInteractionConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "Interaction"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "startedAt": {
                    "name": "startedAt",
                    "isArray": false,
                    "type": "AWSTimestamp",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ModelProfileConnection": {
            "name": "ModelProfileConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "Profile"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "startedAt": {
                    "name": "startedAt",
                    "isArray": false,
                    "type": "AWSTimestamp",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ModelQuestionConnection": {
            "name": "ModelQuestionConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "Question"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "startedAt": {
                    "name": "startedAt",
                    "isArray": false,
                    "type": "AWSTimestamp",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "DisplayProfileConnection": {
            "name": "DisplayProfileConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "nonModel": "DisplayProfile"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "lastSync": {
                    "name": "lastSync",
                    "isArray": false,
                    "type": "AWSTimestamp",
                    "isRequired": false,
                    "attributes": []
                },
                "startedAt": {
                    "name": "startedAt",
                    "isArray": false,
                    "type": "AWSTimestamp",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "cmos": {
            "name": "cmos",
            "fields": {
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "weight": {
                    "name": "weight",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ModelProfileFeedConnection": {
            "name": "ModelProfileFeedConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "ProfileFeed"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "startedAt": {
                    "name": "startedAt",
                    "isArray": false,
                    "type": "AWSTimestamp",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ModelUserFeedConnection": {
            "name": "ModelUserFeedConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "UserFeed"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "startedAt": {
                    "name": "startedAt",
                    "isArray": false,
                    "type": "AWSTimestamp",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "tag": {
            "name": "tag",
            "fields": {
                "tag": {
                    "name": "tag",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "ModelHashtagConnection": {
            "name": "ModelHashtagConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "Hashtag"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "startedAt": {
                    "name": "startedAt",
                    "isArray": false,
                    "type": "AWSTimestamp",
                    "isRequired": false,
                    "attributes": []
                }
            }
        }
    },
    "codegenVersion": "3.4.4",
    "version": "2bad97b6e28e881274104a9988e95b5d"
};