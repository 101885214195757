import React from "react";


const ActionViewSection = React.memo(function ActionViewSection({ text, centered, children, icon }) {  
    const iconStr= icon.icon.concat('fal activeColor' );
        return (
            <div className="mt-2 mb-0 ">
                <div className={` brand5 brand3-bg px-2  ${centered?"":"text-start"}`}>
                <i className={iconStr + " brand1"}></i> {text??icon.text}
                </div>
                {children}
            </div>
        )
});

export default ActionViewSection