import { combineReducers } from "redux";
import  questionedit  from "./questionedit-reducers";
import  feed  from "./feed-reducers";
import profile from "./profile-reducers";
import modal from "./modal-reducers";
import chat from "./chat-reducers";
import context from "./context-reducers";

const lyqnessApp = combineReducers({
questionedit,
modal,
profile,
feed,
context,
chat
})

export default lyqnessApp