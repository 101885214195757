// src/redux/modalreducers/index.js
import { AuthActions} from "../constants";
import {ModalActions} from "../../constants/modal"
const initialState = {
    modalType: null,
    modalProps: {},
    authModalIsOpen: false,
    tagFeed:{
        items:[], 
        isFirstFeed:true, 
        nextToken:null
    }
  }
  
  export default function modal(state = initialState, action) {
    switch (action.type) {

      case ModalActions.SHOW_MODAL: 
        return {...state,
          modalType: action.payload.modalType,
          modalProps: action.payload.modalProps
        }
      case ModalActions.TOGGLE_AUTH_MODAL:
        return {
          authModalIsOpen: !state.authModalIsOpen
        }
      case AuthActions.SIGN_OUT:
      case ModalActions.HIDE_MODAL:
        return {...initialState}
      default:
        return state
    }
  }