/* eslint-disable */
// this is an auto generated file. This will be overwritten

import { UserFeedStats } from "../redux/constants";
import { gqlChoices, gqlCreator, gqlProfileStub, gqlFeedStats, gqlProfileFeed, gqlUserFeed } from "./gqlConstants";

export const onPushPublicQuestion = /* GraphQL */ `
  subscription OnPushPublicQuestion($targetId: ID!) {
    onPushPublicQuestion(targetId: $targetId) {
      _deleted
    _lastChangedAt
    audience
    buckets
    choiceStats {
      count
    }
    choices {
      ${gqlChoices}
    }
    choiceHeadings
    conversationId
    createdAt
    profileStub {${gqlProfileStub}}
    creatorId
    endTime
    howLong
    howOften
    imageKey
    imageURL
    incentive {
      count
      frequency
      imageKey
      description
      selections
    }
    isModerated
    isMultipleChoice
    isOrdered
    isPremium
    isPrivate
    draft
    key
    landscapeImages
    linkSelections
    maxRange1
    maxRange2
    maxSel
    minRange1
    minRange2
    minSel
    numericType
    parentId
    qmosr
    questionId
    questionText
    questionType
    startTime
    stats { ${gqlFeedStats} }
    stepStats
    stealthTime
    tags
    targetId
    updateDate
    updateTime
    updatedAt
    userFeed {
      category
      createdAt
      
      publicTime
      profileId
      questionId
      selections
      steps
      values
      hidden
      incentive{
        achievedDate
        redeemedDate
        redemptionCode
        posCode
      }
      sharedBy
      sharerProfile{
        avatarURL
        firstName
        lastName
        title
        userName
        profileId
        photoKey
      }
      stats {
            CHAQ
            CREA
            CREI
            CREQ
            HCRQ
            HLNQ
            HREQ
            HLKQ
            FEAQ
            FLAQ
            INCA
            INCQ
            INCR
            LIKQ
            LINQ
            MODQ
            RESQ
            SHAQ
            SPOQ
            STEA
            TAGQ
      }
      lastMessageTime
      stealthTime
      toggledStealth
      targetId
      tags
      isOwner
      updatedAt
      weight
      _version
      _deleted
      _lastChangedAt
    }
    viewProfileId
    viewUserFeed {${gqlUserFeed}}
    weight
    category
    }
  }
`;

export const onUpdateProfile = /* GraphQL */ `
  subscription OnUpdateProfile($filter: ModelSubscriptionProfileFilterInput) {
    onUpdateProfile(filter: $filter) {
      avatarKey
      category
      createdAt
      description
      firstName
      identityId
      targetId
      lastName
      creatorId
      lastLogon
      showQuestionTypeTips
      photoKey
      photoURL
      conversationId
      profileFeed {
        profileId
        associatedId
        lastViewed
        stats {
          CHAU
          FEAU
          FLAU
          FOLU
          LIKU
          MODU
          SHAU
          SPOU
        }
        userFollows
        isOwner
        targetId
        lastMessageTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
      }
      conversation {
        actionTime
        activeCount
        category
        chatType
        conversationId
        createdAt
        creatorId
        lastMessageTime
        messages {
          nextToken
          startedAt
        }
        relatedId
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      profileId
      userFeedQuestionId
      userFeed {
        category
        createdAt
        
        publicTime
        profileId
        questionId
        qmosr
        selections
        hidden
        incentive{
            achievedDate
            redeemedDate
            redemptionCode
            posCode
          }
        stats {
          CHAQ
            CREA
            CREI
            CREQ
            HCRQ
            HLNQ
            HREQ
            HLKQ
            FEAQ
            FLAQ
            INCA
            INCQ
            INCR
            LIKQ
            LINQ
            MODQ
            RESQ
            SHAQ
            SPOQ
            STEA
            TAGQ
        }
        lastMessageTime
        stealthTime
        toggledStealth
        targetId
        isOwner
        updatedAt
        weight
        _version
        _deleted
        _lastChangedAt
      }
      stats {
        Profile {
          CHAU
          FEAU
          FLAU
          FOLU
          LIKU
          MODU
          SHAU
          SPOU
        }
        Question {
          CHAQ
            CREA
            CREI
            CREQ
            HCRQ
            HLNQ
            HREQ
            HLKQ
            FEAQ
            FLAQ
            INCA
            INCQ
            INCR
            LIKQ
            LINQ
            MODQ
            RESQ
            SHAQ
            SPOQ
            STEA
            TAGQ
        }
        Tally {
          CHAU
          FLAU
          FOLU
          SHAU
          SHAC
          FOLC
          FLAC
          CHAC
        }
      }
      tags {
        items {
          tag
          count
        }
      }
      title
      updatedAt
      userName
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const onNotification = /* GraphQL */ `
  subscription OnNotification($targetId: ID) {
    onNotification(targetId: $targetId) {
      alertId
      targetId
      action
      tag
      title
      body
    }
  }
`;