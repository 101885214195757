import React from "react";
import { Icons} from "./QIcons";


const QCheckbox = React.memo(function CheckBox(props) {
    const {checked, toggle, disabled} =props;
    let active =  Icons.CHECKBOX_ACTIVE;
    let inactive = Icons.CHECKBOX; 

    if(disabled) 
        return (
                  <span className="grey "><i className={checked? active  : inactive}></i></span> 
        )
    else
        return (
            <i className={checked? active  : inactive} text-muted="true" onClick={()=>toggle()}></i>
        )      
  })

  export default QCheckbox