import React from "react";

import { QuestionAskType } from "../constants/question";
import { prettyNum } from "../shared/utilities";
import { getSortColSize, getTableWidth, imageUrl } from "../components/question/Utilities";
import { DateShort } from "../shared/Dates";
import { Icons } from "../shared/QIcons";


const PublicQuestionCard = React.memo(function PublicQuestionCard ({question}) {

  const ResponseChoices = () => {
    var colSize = question.questionType === QuestionAskType.Rate ? getTableWidth(question.choices) : getSortColSize(question.choices);
    switch (question.questionType){
      case QuestionAskType.Select:
        return (
          <div className="px-2 m-0 border-left border-right ">
            <ul className="row m-0 no-gutters p-0 justify-content-center">
              {question.choices.map((choice, index) => (
                <li className={"p-1 " + colSize}>
                  <div className="border rounded black-text text-left px-1 ">
                    <span>
                      {choice.text}
                    </span>
                    <span className="float-end grey-text">
                      <i className={question.isMultipleChoice ? "fal fa-square" : "fal fa-circle"}></i>
                    </span>
                  </div>
                </li>

              ))
              }
            </ul>
          </div>
        )
      case QuestionAskType.Rank:
        return (
          <>
            <div className="small text-center text-muted">order first/most/best/etc. from top to bottom</div>
            <div className="row justify-content-center mb-0 p-o">
              <div className={colSize}>
                <ul className="m-0 p-0 justify-content-center">
                  {
                    question.choices.map((choice, index) => (
                      <li className="p-1">
                        <div className="border rounded text-left px-1 ">
                          <span>
                            {choice.text}
                          </span>
                          <span className="float-end grey-text">
                            <i className="fal fa-sort-alt"></i>
                          </span>
                        </div>
                      </li>
                    ))
                  }
                </ul>
              </div>
            </div>
          </>
        )        
      case QuestionAskType.Rate:
        return (
          <table className={"table table-borderless table-fixed my-1 p-0 " + colSize} >
            <thead>
              <tr>
                {question.choices.map((choice, index) => (
                  <th className="m-0 p-0 small">{choice.text}</th>
                ))
                }
              </tr>
            </thead>
            <tbody>
              <tr>
                {
                  question.choices.map((choice, index) => (
                    <td className="p-0">
                      <i className="fal fa-circle"></i>
                    </td>
                  ))
                }
              </tr>
            </tbody>
          </table>
        )  
      default:
        return (<span/>)    
    }
  }


    if(question)
    {
      return (
        <>
        <div className="lyqcard">

          {/***** Header  *****/}
          <div className="card-header border pb-2 white px-2">
              <div className="media w-100 pl-0">
                {question.ownerImgKey?
                  <img className="avatar rounded-circle" src={imageUrl(question.identityId,question.ownerImgKey, 50,50,"cover")} width="50" alt=""/>
                  : <i className="fas fa-user-circle fa-2x ml-2  grey-text "></i>
                }
                  <div className="container w-100 pl-1 pr-0">
                      <div className="h6 grey-text mb-0  ">
                        <span className="float-left">{question.ownerName}</span>
                          <span className="float-end"><img className="avatar p-1 pt-0" src="~/favicon.ico" alt="" width="32" style={{position:'relative', top:'-10px', right:'-5px'}} /></span>
                      </div>
                      <div className="w-100 row no-gutters justify-content-between m-0 p-0">

                          <div className="col">

                          </div>

                          <div className="flex-column justify-content-left">
                              {question.isPrivate?
                              <i className="fal fa-user-secret grey-text "></i>
                              :
                              <span />}

                              <i className="far fa-exclamation-triangle red-text"></i>
                          </div>

                          <div className="col small m-0 text-right grey-text">
                            <DateShort date={question.created} />
                          </div>
                      </div>
                  </div>
              </div>
          </div>


         {question.questionImgKey !== null?
              <div className="main-image text-center">
                  <img className="img-fluid" src={imageUrl(question.identityId,question.questionImgKey,1200,630)}  alt=""/>
              </div>
              :<span/>
          }


        <div className="border-left border-right">
          {question.questionText.length < 45?
              <h4 className="py-1 px-2 w-100 h4-responsive text-center">
                  <small>{question.questionText}</small>
              </h4>
          :
              <h4 className="py-1 my-0 px-2 h4-responsive text-left ">
                  <small> {question.questionText}</small>
              </h4> 
          }
          </div>

          <ResponseChoices />


        

          {/***** Middle thing  *****/}
          <div className="row no-gutters pt-2 border-left border-right pb-3 m-0">
              <div className="col">

              </div>
              <div className="col justify-content-center mx-0">
                  <a href={"/question/" + question.responseId} className="btn btn-brand0 btn-sm h4 px-2">Respond or View</a>
              </div>
              <div className="col grey-text text-right pt-2 mr-3">
                  <i className={`${Icons.RESPOND} mr-2`}></i>
                  {question.responded === 0?
                  <small>be first</small>
                  :
                  <span>{question.responded}  <small>{question.responded===1 ? "response" : "responses"}</small></span>
                  }
              </div>
          </div>


          <div className="card-footer w-100 py-1 border">
              <div className="row pl-2 grey-text">
                  <div className="col p-0">
                      <i className="fal fa-heart mr-2"></i>
                      {question.favorited !== 0?
                      <span>{prettyNum(question.favorited)} <small>{question.favorited===1 ? "like" : "likes"}</small></span> : <span/>}
                  </div>

                  <div className="col col-4  p-0">
                      <i className="fal fa-share mr-2"></i>
                      {prettyNum(question.shared)} <small>{question.shared===1 ? "user" : "have"} shared</small>
                  </div>

                  <div className="col col-5 p-0">
                      <i className="fal fa-link mr-2"></i>
                      {question.linked === 0?
                      <small>no related questions</small>
                      :
                      <span>{prettyNum(question.linked)} <small>related questions</small></span>}
                  </div>
              </div>
          </div> 
        </div>
</>
      )
                      }
    else
      return(<div><i className="fas fa-spinner fa-spin fa-lg"></i> loading</div>)
  })


export default PublicQuestionCard;